import { createReducer } from "@reduxjs/toolkit";
const today = new Date();

// 2023-05-05
const firstDay = `${today.toISOString().substr(0, 7)}-01`;
const lastDay = today.toISOString().substr(0, 10);
const endDay = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0)).toISOString().substr(0, 10);

//policy filter
const initialPolicyState = {
  Policy: {
    startDate: firstDay,
    endDate: lastDay,
    startCreatedAtDate: firstDay,
    endCreatedAtDate: lastDay,
    filterOn: "IssueDate",
  },
  SelectedInsuranceType: [],
  PayoutCycle: "",
  pagination: 0,
  rowsPerPage: 25,
  InsuranceCompany: {
    _id: "",
    AliasName: " -- Select Company --",
  },
  PolicyType: {
    _id: "",
    PolicyTypeName: " -- Select PolicyType --",
  },
  Broker: {
    _id: "",
    Name: "-- Select Broker --",
  },
  POS: null,
  EnteredBy:null,
  Branch: {
    _id: "",
    BranchName: "-- Select Branch --",
  },
};

const My_Policy_Details = {
  SavedData: [],
  SavedTotalDocs: 0,
  Status: {},
  Premium: {},
  TabsValue: "PENDING",
};

export const PolicyFilter = createReducer(
  { ...initialPolicyState, ...My_Policy_Details },
  {
    updatePolicyFilters: (state, action = {}) => {
      const { key, value } = action;
      state[key] = value;
    },
    setPolicyFilterDefaultValue: (state, action = {}) => {
      const data = Object.entries(initialPolicyState);
      data.forEach(([key, value]) => {
        state[key] = value;
      });
    },
  }
);

//Rto Cluster filter

const initialRtoClusterState = {
  date: {
    DEF: "",
    DET:"",
  },
  SelectedInsuranceType: [],
  pagination: 0,
  rowsPerPage: 25,
  InsuranceCompany: {
    _id: "",
    AliasName: " -- Select Company --",
  },
};

export const RtoClusterFilter = createReducer(
  { ...initialRtoClusterState },
  {
    updateRtoClusterFilters: (state, action = {}) => {
      const { key, value } = action;
      state[key] = value;
    },
    setRtoClusterFilterDefaultValue: (state, action = {}) => {
      const data = Object.entries(initialRtoClusterState);
      data.forEach(([key, value]) => {
        state[key] = value;
      });
    },
  }
);

//cppolicy filters starts

const initialCPPolicyState = {
  Policy: {
    startDate: firstDay,
    endDate: lastDay,
    filterOn: "IssueDate",
  },
  SelectedInsuranceType: [],
  PayoutCycle: "",
  pagination: 0,
  rowsPerPage: 25,
  InsuranceCompany: {
    _id: "",
    AliasName: " -- Select Company --",
  },
  PolicyType: {
    _id: "",
    PolicyTypeName: " -- Select PolicyType --",
  },
  Broker: {
    _id: "",
    Name: "-- Select Broker --",
  },
  POS: null,
  Branch: {
    _id: "",
    BranchName: "-- Select Branch --",
  },
};

const My_CPPolicy_Details = {
  SavedData: [],
  SavedTotalDocs: 0,
  Status: {},
  Premium: {},
  TabsValue: "PENDING",
};

export const CPPolicyFilter = createReducer(
  { ...initialCPPolicyState, ...My_CPPolicy_Details },
  {
    updateCPPolicyFilters: (state, action = {}) => {
      const { key, value } = action;
      state[key] = value;
    },
    setCPPolicyFilterDefaultValue: (state, action = {}) => {
      const data = Object.entries(initialCPPolicyState);
      data.forEach(([key, value]) => {
        state[key] = value;
      });
    },
  }
);

//cp plicy fillter ends

const initialPayoutGridState = {
  PayoutGrid: {
    startDate: firstDay,
    endDate: endDay,
  },
  SavedTotalDocs: 0,
  SelectedInsuranceType: [],
  pagination: 0,
  rowsPerPage: 25,
  InsuranceCompany: {
    _id: "",
    AliasName: " -- Select Company --",
  },
  PolicyType: {
    _id: "",
    PolicyTypeName: " -- Select PolicyType --",
  },
  Broker: {
    _id: "",
    Name: "-- Select Broker --",
  },
  RTOGroup: {
    _id: "",
    GroupName: "-- Select RTO Group --",
  },
  Branch: {
    _id: "",
    BranchName: "-- Select Branch --",
  },
};

const My_PayoutGrid_Details = {
  SavedData: [],
};
export const PayoutGridFilter = createReducer(
  { ...My_PayoutGrid_Details, ...initialPayoutGridState },
  {
    updatePayoutGridFilters: (state, action = {}) => {
      const { key, value } = action;

      state[key] = value;
    },
    setPayoutGridFilterDefaultValue: (state, action = {}) => {
      const data = Object.entries(initialPayoutGridState);
      data.forEach(([key, value]) => {
        state[key] = value;
      });
    },
  }
);
