import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ToastError } from "../../Utils/Tost";
import { REACT_APP_FileURL } from "../../config";

import { Alert, AlertTitle } from "@mui/material";
import Papa from "papaparse";
import * as XLSX from "xlsx";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  validateHeaders,
  createMapping,
  validateAndUpdateField,
  updateVariant,
  updateRTO,
  updateInsuranceType,
} from "../../lib/validateData";
import "./styles.css";
import { axiosInstance } from "../../config";

const getLastAvailableProperty = (obj) => {
  return obj["vehicleClass"] || obj["subProduct"] || obj["product"] || null;
};

const transformData = (data) => {
  const result = data.reduce(
    (acc, curr) => {
      const { Variant, Make, Model } = curr;
      const insuranceCompany = curr["Insurance Company"];
      const companyBranch = curr["Insurer Branch"];
      const policyNumber = curr["Policy Number"];
      const fuelType = curr["Fuel Type"];
      const POS = curr["POS Name"];
      const vehicleNumber = curr["Vehicle Number"];

      // Add unique values to sets
      if (Variant) acc.variants.add(Variant);
      if (Make) acc.Make.add(Make);
      if (Model) acc.Model.add(Model);
      if (insuranceCompany) acc.insuranceCompany.add(insuranceCompany.trim());
      if (companyBranch) acc.companyBranch.add(companyBranch.trim());
      if (policyNumber) acc.policyNumber.add(policyNumber.toString().trim());
      if (vehicleNumber) acc.RTO.add(vehicleNumber.trim().slice(0, 4));
      if (fuelType) acc.fuelType.add(fuelType.trim());
      if (POS) acc.POS.add(POS.trim());

      // Gather product-related data
      const obj = {
        product: curr?.["Product"],
        subProduct: curr?.["Sub Product"],
        vehicleClass: curr?.["Vehicle Class"],
      };

      const lastAvailableInsuranceType = getLastAvailableProperty(obj);
      if (lastAvailableInsuranceType) {
        acc.insuranceType.add(lastAvailableInsuranceType);
      }

      return acc;
    },
    {
      variants: new Set(),
      Make: new Set(),
      Model: new Set(),
      insuranceCompany: new Set(),
      companyBranch: new Set(),
      policyNumber: new Set(),
      RTO: new Set(),
      fuelType: new Set(),
      POS: new Set(),
      insuranceType: new Set(),
    }
  );

  return {
    variants: Array.from(result.variants),
    Make: Array.from(result.Make),
    Model: Array.from(result.Model),
    insuranceCompany: Array.from(result.insuranceCompany),
    companyBranch: Array.from(result.companyBranch),
    policyNumber: Array.from(result.policyNumber),
    RTO: Array.from(result.RTO),
    fuelType: Array.from(result.fuelType),
    POS: Array.from(result.POS),
    insuranceType: Array.from(result.insuranceType),
  };
};

const ImportPolicy = () => {
  const { user } = useSelector((store) => store.user);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    variants: [],
    Make: [],
    Model: [],
    insuranceCompany: [],
    companyBranch: [],
    RTO: [],
    fuelType: [],
    POS: [],
    insuranceType: [],
  });

  const [fileData, setFileData] = useState([]);
  const [headers, setHeaders] = useState([]);

  const [insertedCount, setInsertedCount] = useState(null);
  const [bgColor, setBgColor] = useState("");

  const parseExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = jsonData[0];
      const response = validateHeaders(headers);

      if (!response?.valid) {
        setError(response);
        return;
      }

      const rows = jsonData.slice(1);

      const transformedData = rows.reduce((acc, row) => {
        if (row.length === 0) return acc;

        const transformedRow = Object.fromEntries(
          row
            .map((cell, index) => {
              const header = headers[index];
              if (
                typeof cell === "number" &&
                (header.toLowerCase().includes("date") ||
                  header.includes("DoRegn."))
              ) {
                const date = XLSX.SSF.parse_date_code(cell);
                const formattedDate = `${date.y}-${String(date.m).padStart(
                  2,
                  "0"
                )}-${String(date.d).padStart(2, "0")}`;
                return [header, formattedDate];
              }

              return [header, cell];
            })
            .filter(([key, value]) => key !== undefined && value !== undefined)
        );

        acc.push(transformedRow);
        return acc;
      }, []);

      //new code

      const validationError = [];

      if (validationError.length > 0) {
        const uniqueArray = [];
        const seen = new Map();

        for (const obj of validationError) {
          if (!seen.has(obj["Policy Number"])) {
            seen.set(obj["Policy Number"], true);
            uniqueArray.push(obj);
          }
        }

        const policyNumbers = uniqueArray.map((obj) => obj["Policy Number"]);

        setError({
          message: "Validation failed! Duplicate Policy Numbers found: ",
          valid: false,
          missingHeaders: policyNumbers,
        });
        return;
      }

      // If no validation errors, set the headers and file data
      setError(null);
      setHeaders(headers);
      setFileData(transformedData);

      //new code ends

      const result = transformData(transformedData);
      setData((prev) => ({
        ...prev,
        ...result,
      }));
    };
    reader.readAsArrayBuffer(file);
  };
  const handleFileUpload = (event) => {
    try {
      setLoading(true);
      const file = event.target.files[0];
      const fileType = file ? file.name.split(".").pop().toLowerCase() : "";

      if (fileType === "csv") {
        //   parseCsv(file);
        Papa.parse(file, {
          complete: (result) => {
            if (result?.data && result?.data?.length > 0) {
              const headers = Object.keys(result?.data[0]);

              const response = validateHeaders(headers);

              if (!response?.valid) {
                setError(response);
                return;
              }
              setHeaders(headers);
              setFileData(result.data);
              const resultData = transformData(result.data);

              setData((prev) => ({
                ...prev,
                ...resultData,
              }));
            }
          },
          header: true,
        });
      } else if (fileType === "xls" || fileType === "xlsx") {
        parseExcel(file);
      } else {
        alert("Please upload a XLSX, or XLS file.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getDataForPolicyImport = async () => {
    try {
      setLoading(true);

      const { data: res } = await axiosInstance.get(
        "/CommissionablePremium",
        data
      );

      const { data: response } = await axiosInstance.post(
        "/Policy/getDataForPolicyImport",
        data
      );

      if (response && response?.data) {
        const {
          RTO,
          insuranceCompany,
          companyBranch,
          policyNumber,
          policyType,
          fuelType,
          POS,
          variants,
          insuranceType,
          Make,
          Model,
        } = response?.data;
        // setFetchedData(response?.data);

        const validationError = [];
        if (policyNumber.length > 0) {
          const matchedPolicies = [];

          response.data.policyNumber.forEach((item) => {
            const matchingPolicy = fileData.find(
              (data) => data["Policy Number"].trim() === item.PolicyNumber
            );

            if (matchingPolicy) {
              matchedPolicies.push({
                valid: false,
                policyNumber: matchingPolicy["Policy Number"],
                fieldName: "Policy Number",
                fieldValue: matchingPolicy["Policy Number"],
                row: matchingPolicy,
              }); // Push the entire object
            }
          });

          if (matchedPolicies.length > 0) {
            validationError.push(...matchedPolicies); // Push the entire objects
          }
        }

        // Create mappings
        const mappings = {
          "Insurance Company": createMapping(
            insuranceCompany,
            "AliasName",
            "_id"
          ),
          "Insurer Branch": createMapping(companyBranch, "BranchName", "_id"),
          "Policy Type": createMapping(policyType, "PolicyTypeName", "_id"),
          "Fuel Type": createMapping(fuelType, "FuelType", "_id"),
          "POS Name": createMapping(POS, "Name", "_id"),
          Branch: createMapping(POS, "_id", "Branch"),
        };

        const mapping = {
          Make: createMapping(Make, "Make", "_id"),
          Model: createMapping(Model, "Model", "_id"),
        };

        const fieldsToValidate = [
          { excelField: "Insurance Company", dbField: "InsuranceCompany" },
          { excelField: "Insurer Branch", dbField: "CompanyBranch" },
          { excelField: "Policy Type", dbField: "PolicyType" },
          { excelField: "Fuel Type", dbField: "FuelType" },
          { excelField: "POS Name", dbField: "POS" },
        ];

        let updatedData = fileData;

        // 1) Excel data, 2) ExcelData fieldName , 3) Database fieldName 4) mappedData
        fieldsToValidate.forEach(({ excelField, dbField }) => {
          const { data: updatedFieldData, error } = validateAndUpdateField(
            updatedData,
            excelField,
            dbField,
            mappings[excelField]
          );

          updatedData = updatedFieldData;
          validationError.push(...error);
        });

        const { data: rtoUpdate, error: RTOErr } = updateRTO(RTO, updatedData);

        updatedData = rtoUpdate;

        if (RTOErr.length > 0) {
          validationError.push(...RTOErr);
        }

        const { data: variantUpdate, error: variantErr } = updateVariant(
          variants,

          updatedData,
          mapping,
          res
        );

        updatedData = variantUpdate;

        if (variantErr.length > 0) {
          validationError.push(...variantErr);
        }
        const { data: insTypeUpdate, error: insTypeErr } = updateInsuranceType(
          insuranceType,
          updatedData,
          user,
          mappings.Branch
        );

        updatedData = insTypeUpdate;

        if (insTypeErr.length > 0) {
          validationError.push(...insTypeErr);
        }

        // calculate comminssionable premium
        updatedData.forEach((policy) => {
          let premiumCalculated = false;

          for (const resObject of res.data) {
            const matchedInsuranceCompany = resObject.InsuranceCompany.find(
              (company) => company.value === policy.InsuranceCompany
            );

            const matchedInsuranceType = resObject.InsuranceType.find(
              (type) => type.value === policy.InsuranceUnderFlow[0]
            );

            const matchedPolicyType =
              resObject.PolicyType._id === policy.PolicyType;

            if (
              matchedInsuranceCompany &&
              matchedPolicyType &&
              matchedInsuranceType
            ) {
              const premiumFieldsToConsider = resObject.Premium;

              let commissionablePremium = 0;

              premiumFieldsToConsider.forEach((field) => {
                if (policy[field] !== undefined && policy[field] !== null) {
                  commissionablePremium += parseFloat(policy[field]);
                }
              });

              policy.CommissionablePremium = commissionablePremium;
              premiumCalculated = true;

              break;
            } else {
              continue;
            }
          }

          if (!premiumCalculated) {
            policy.CommissionablePremium = 0;
          }
        });

        if (validationError.length > 0) {
          const uniqueArray = [];
          const seen = new Map();

          for (const obj of validationError) {
            if (!seen.has(obj.policyNumber)) {
              seen.set(obj.policyNumber, true);
              uniqueArray.push(obj);
            }
          }

          setError({
            message: "Validation failed!",
            data: uniqueArray,
            errorList: validationError,
          });
          return;
        }
        const postResponse = await axiosInstance.post(
          "/Policy/insertExcelPolicies",
          updatedData
        );
        if (postResponse?.data?.success) {
          setInsertedCount(postResponse?.data?.count);
          setBgColor("#d4edda");
        }

        const fileInput = document.getElementById("file_upload");
        if (fileInput) {
          fileInput.value = "";
        }
        setHeaders([]);
        setFileData([]);
        setData([]);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        ToastError(error?.response?.data?.message);
      } else if (error?.message) {
        ToastError(error?.message);
      } else {
        ToastError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateDownload = (event) => {
    event.preventDefault();

    const fileUr = `${REACT_APP_FileURL}/images/Template/Policy-Template.xlsx`;
    window.open(fileUr, "_blank");
  };

  return (
    <>
      <Header />
      <div className="MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="ListDesigncol">
                        <h4 className="card-title">Import Policy</h4>
                      </div>
                      <button
                        onClick={handleTemplateDownload}
                        className="btn mybtn"
                      >
                        Download Template
                      </button>
                    </div>
                  </div>
                  {error && (
                    <Alert severity="error">
                      {error?.message && (
                        <AlertTitle>Error : {error?.message}</AlertTitle>
                      )}
                      {error?.missingHeaders &&
                        error?.missingHeaders?.length > 0 && (
                          <AlertTitle>
                            {error?.missingHeaders.join(", ")}
                          </AlertTitle>
                        )}
                    </Alert>
                  )}

                  {insertedCount !== null && (
                    <div
                      style={{
                        backgroundColor: bgColor, // Green if success
                        width: "80vw", // 80% of the viewport width
                        height: "auto", // Automatically adjust height based on content
                        padding: "20px", // Optional padding for spacing
                        fontSize: "1rem", // Adjust font size
                        margin: "20px auto",
                      }}
                    >
                      {insertedCount} Records Inserted
                    </div>
                  )}

                  <div className="container">
                    <div>
                      <div className="upload-container">
                        <input
                          type="file"
                          id="file_upload"
                          accept=".csv, .xlsx, .xls"
                          onChange={handleFileUpload}
                        />
                      </div>
                      <div className="mt-4" style={{ textAlign: "center" }}>
                        <button
                          className="btn btn-primary"
                          color="warning"
                          type="button"
                          disabled={loading || error || fileData.length === 0}
                          onClick={getDataForPolicyImport}
                        >
                          {loading ? "In Progress..." : "Import"}
                        </button>
                      </div>
                    </div>
                    {error && error?.data && error?.data?.length !== 0 && (
                      <div className="mt-6">
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Preview Data
                        </div>
                        <div
                          // className=" p-2 container"
                          style={{
                            zoom: "80%",
                            height: "60vh",
                            border: "2px solid black",
                            resize: "vertical", // Allow vertical resizing only
                            overflow: "auto",
                          }}
                        >
                          <table class="table table-striped   table-bordered  overflow-x mt-3">
                            <thead>
                              <tr>
                                {headers &&
                                  headers.length > 0 &&
                                  headers.map((header) => {
                                    let hasError;
                                    if (error && error?.errorList) {
                                      hasError = error?.errorList.find(
                                        (error) => error.fieldName === header
                                      );
                                    }
                                    return (
                                      <th
                                        style={{
                                          backgroundColor: hasError
                                            ? "#f8d7da"
                                            : "",
                                        }}
                                        scope="col"
                                      >
                                        {header}
                                      </th>
                                    );
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              {error?.data.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    {headers.map((item, index) => {
                                      let hasError;
                                      if (error && error?.errorList) {
                                        hasError = error?.errorList.find(
                                          (error) =>
                                            error?.fieldValue ===
                                            data?.row[item]
                                        );
                                      }

                                      return (
                                        <td
                                          style={{
                                            backgroundColor:
                                              hasError &&
                                              hasError?.fieldName
                                                .trim()
                                                .toLowerCase() ===
                                                item.trim().toLowerCase() &&
                                              hasError?.fieldValue ===
                                                data?.row[item]
                                                ? "#f8d7da"
                                                : "",
                                          }}
                                          key={index}
                                        >
                                          {data?.row[item]}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default ImportPolicy;
