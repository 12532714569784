import ExcelJS from "exceljs";
import {useState} from 'react'
async function createExcelFile(excelData) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Report Ledger");

  const { columns, rows, fileName = "data" } = excelData;

  const colLength = columns?.length;

  worksheet.columns = columns;

  const row = worksheet.getRow(1);

  for (let i = 1; i <= colLength; i++) {
    row.getCell(i).alignment = { vertical: "middle", horizontal: "center" };
    row.getCell(i).font = { bold: true, color: { argb: "FFFFFFFF" } };
    row.getCell(i).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0f4890" },
    };
  }

  for (let row of rows) {
    worksheet.addRow(row);
  }

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.xlsx`;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}


const ExcelExport = ({ getData, style }) => {
  const [loading, setLoading] = useState(false); // State to manage loading

  const handleExcelDownload = async () => {
    setLoading(true); // Set loading to true
    const excelData = await getData(); // Fetch data
    await createExcelFile(excelData); // Create Excel file
    setLoading(false); // Reset loading to false
  };

  return (
    <button onClick={handleExcelDownload} className="btn mybtn" disabled={loading}>
      {loading ? (
        <>
          <span className="loader"></span> {/* Loader element */}
          Loading...
        </>
      ) : (
        'Download as .xls'
      )}
    </button>
  );
};

export default ExcelExport;
