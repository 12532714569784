import { color } from "echarts";
import ReactEcharts from "echarts-for-react";

const BarLineChart = ({ height, xAxisData, seriesData, xAxisLabel }) => {
  const formatNumber = (value) => {
    if (value >= 10000000) return `${(value / 10000000).toFixed(2)} Cr`;
    if (value >= 100000) return `${(value / 100000).toFixed(2)} L`;
    if (value >= 1000) return `${(value / 1000).toFixed(2)} K`;
    return value.toString();
  };

  const formatNumberCrore = (num) => {
    return num ? (num / 10000000).toFixed(2) : 0;
  };

  const totalNWP =
    Array.isArray(seriesData[1]) && seriesData[1].length > 0
      ? seriesData[1]
          .map((value) => parseFloat(value) || 0)
          .reduce((sum, value) => sum + value, 0)
      : 0;

  const totalNOP =
    Array.isArray(seriesData[0]) && seriesData[0].length > 0
      ? seriesData[0].reduce((sum, value) => sum + (value || 0), 0)
      : 0;

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      formatter: (params) => {
        let tooltipContent = "";
        params.forEach((param) => {
          const formattedValue =
            param.seriesName === "NWP"
              ? formatNumberCrore(param.value)
              : param.value.toFixed(0);

          const percentage =
            param.seriesName === "NWP" && totalNWP > 0
              ? ((parseFloat(param.value) / totalNWP) * 100).toFixed(2)
              : "-";

          tooltipContent += `
            <div>
              <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>
              ${param.seriesName}: ${formattedValue} ${
            param.seriesName === "NWP" ? `(${percentage}%)` : ""
          }
            </div>
          `;
        });
        return tooltipContent;
      },
      position: function (point, params, dom, rect, size) {
        return [point[0] + 10, point[1] - 10];
      },
    },
    legend: {
      data: ["NWP (in Crore)", "NOP (in Thousand)"],
    },
    grid: {
      left: "1%",
      right: "2%",
      bottom: "3%", 
      top: "23%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: xAxisData,
        name: xAxisLabel,
        nameLocation: "middle",
        nameGap: -210,
        nameTextStyle: {
          fontWeight: "bolder", 
          fontSize: 14,
          color:"black" 
        },
        axisLabel: {
          rotate: 45,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "NWP",
        position: "left",
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value) => {
            if (value >= 10000000) return `${(value / 10000000).toFixed(2)} Cr`;
            if (value >= 100000) return `${(value / 100000).toFixed(2)} L`;
            if (value >= 1000) return `${(value / 1000).toFixed(2)} K`;
            return value;
          },
        },
      },
      {
        type: "value",
        name: "NOP",
        position: "right",
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value) => value.toFixed(0),
        },
      },
    ],
    series: [
      {
        name: "NWP",
        type: "bar",
        data: seriesData[1],
        yAxisIndex: 0,
        itemStyle: {
          color: "#0231bf",
        },
      },
      {
        name: "NOP",
        type: "line",
        data: seriesData[0],
        yAxisIndex: 1,
        itemStyle: {
          color: "#ff6347",
        },
      },
    ],
  };

  return (
    <ReactEcharts
      style={{
        width: "100%",
        height: height,
      }}
      option={option}
    />
  );
};

export default BarLineChart;