import React, { useCallback, useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";

import useHttpErrorHandler from "../Hooks/useHttpErrorHandler";
import { axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AutocompleteComponent from "../../Utils/AutoCompleteComponent";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";

const today = new Date();
const lastDay = today.toISOString().substr(0, 10);

const convertInsuranceToId = (arr) => {
  if (arr.length === 0) return [];
  const data = arr.map((ele) => ele.value).filter((ele) => ele !== "");
  return data;
};

const AddClubException = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};

  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const [date, setDate] = useState({
    DEF: value?.DEF || "",
    DET: value?.DET || "",
  });

  const [data, setData] = useState({
    POSOpt: [],
    branchOpt: [],
  });
  const [selectedData, setSelectedData] = useState({
    POS: value?.POS || null,
    branch: value?.POS?.Branch
      ? {
          Name: value?.POS?.Branch?.BranchName,
          _id: value?.POS?.Branch?._id,
        }
      : null,
  });
  const [HOMargin, setHOMargin] = useState(value?.HOMargin || 0);
  const [basedOn, setBasedOn] = useState(
    value?.basedOn ? value?.basedOn : "CPMargin"
  );
  const [CPMargin, setCPMargin] = useState(
    value?.basedOn === "CPMargin" ? value?.CPMargin ?? 0 : value?.POSRate ?? 0
  );

  const navigate = useNavigate();
  const httpErrorHandler = useHttpErrorHandler();

  const handleSubmit = async () => {
    try {
      if (!selectedData?.POS || !selectedData?.branch) {
        return ToastWarn("All fields are required!");
      }

      // Validate if POS ID is in the correct format
      if (!selectedData?.POS?._id || selectedData?.POS?._id.length !== 24) {
        return ToastWarn("Invalid POS ID");
      }

      const insuranceType = convertInsuranceToId(SelectedInsuranceType);

      const path = Mode
        ? `/ClubException/${value?._id}`
        : "/ClubException/create";
      const method = Mode ? "put" : "post";

      const {
        data: { success, message },
      } = await axiosInstance[method](path, {
        ...date,
        DEF: basedOn !== "ClubMargin" ? date?.DEF : "",
        DET: basedOn !== "ClubMargin" ? date?.DET : "",
        POS: selectedData?.POS?._id,
        HOMargin,
        CPMargin: basedOn === "CPMargin" ? CPMargin : 0,
        POSRate: basedOn === "CPPMargin" ? CPMargin : 0,
        InsuranceType:
          insuranceType?.length > 0 
            ? insuranceType[insuranceType.length - 1]
            : null,
        InsuranceUnderFlow:
          insuranceType.length > 0 
            ? insuranceType
            : null,
        basedOn: basedOn,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/club-exception/data");
      }
    } catch (error) {
      httpErrorHandler(error);
    }
  };
  const getBranch = async () => {
    try {
      const branchResp = await axiosInstance.get("/Branch/getBranch");

      if (branchResp && branchResp?.data) {
        const updatedData = branchResp?.data?.data.map((item) => ({
          ...item,
          Name: item?.BranchName,
        }));
        setData((prev) => ({ ...prev, branchOpt: updatedData }));
      }
    } catch (error) {}
  };

  const getDataInsuranceType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceType?Root=true");
      setSelectedInsuranceType([{ data, value: "" }]);
    } catch (error) {
      httpErrorHandler(error);
    }
  };

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get("/InsuranceType?Root=true");
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {
          httpErrorHandler(error);
        }
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      getDataInsuranceType();
    }
  };

  useEffect(() => {
    if (
      value &&
      value.InsuranceUnderFlow &&
      value.InsuranceUnderFlow.length > 0
    ) {
      callInsuranceData(value.InsuranceUnderFlow);
    } else {
      callInsuranceData([]);
    }
  }, [value]);

  useEffect(() => {
    getBranch();
  }, []);

  const getPos = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Pos/getPosByField", {
        branch: selectedData?.branch?._id,
        showFiled: {
          Code: 1,
          AliasName: 1,
          Name: 1,
          Salutation: 1,
          MiddleName: 1,
          LastName: 1,
          _id: 1,
        },
      });

      const POS = data.map(
        ({
          Code,
          AliasName,
          Name = "",
          Salutation = "",
          MiddleName = "",
          LastName = "",
          _id,
          Club,
        }) => {
          return {
            Name: `${Code}-${Salutation || ""} ${AliasName || ""} (${
              Name || ""
            } ${MiddleName || ""} ${LastName || ""})`,
            _id,
            Club,
          };
        }
      );
      setData((prev) => ({ ...prev, POSOpt: POS }));
    } catch (error) {
      ToastError(error?.response?.data?.message || "Something went wrong!");
    }
  }, [selectedData?.branch?._id]);

  useEffect(() => {
    if (selectedData?.branch) {
      getPos();
    }
  }, [getPos, selectedData?.branch]);

  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              zoom: "90%",
            }}
          >
            <h5>{view ? "View Club" : Mode ? "Edit Data" : "Add Data"}</h5>
            <hr />
            <br />
            <div className="row">
              <div className="col-md-4 align-items-center">
                <label required>Based On</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="basedOn"
                  value={basedOn}
                  onChange={(e) => setBasedOn(e.target.value)}
                  required
                >
                  <option value="CPMargin">CP Margin</option>
                  <option value="ClubMargin">Club Margin</option>
                  <option value="CPPMargin">CP & POS Margin</option>
                </select>
              </div>

              {(basedOn === "CPMargin" || basedOn === "CPPMargin") && (
                <div className="col-md-2">
                  <label>DEF Date</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="Date"
                    name="DEF"
                    value={date?.DEF}
                    onChange={(e) =>
                      setDate((prev) => ({ ...prev, DEF: e.target.value }))
                    }
                    placeholder="Enter From Age "
                    required //done
                  />
                </div>
              )}

              {(basedOn === "CPMargin" || basedOn === "CPPMargin") && (
                <div className="col-md-2">
                  <label>DET Date</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="Date"
                    name="DET"
                    value={date?.DET}
                    onChange={(e) =>
                      setDate((prev) => ({ ...prev, DET: e.target.value }))
                    }
                    placeholder="Enter From Age "
                    required //done
                  />
                </div>
              )}

              {(basedOn === "CPMargin" || basedOn === "CPPMargin"|| basedOn === "ClubMargin") &&
                SelectedInsuranceType &&
                SelectedInsuranceType.length > 0 &&
                SelectedInsuranceType.map(
                  (data, index) =>
                    data &&
                    data.data?.length > 0 && (
                      <MultiInsurance
                        key={index}
                        Options={data}
                        setSelectedInsuranceType={setSelectedInsuranceType}
                        // getMakeModal={getMakeModal}
                        view={view}
                        SelectedInsuranceType={SelectedInsuranceType}
                        index={index}
                        OptionsValue={data.value}
                      />
                    )
                )}

              <div className="col-md-4">
                <label required>Branch</label>
                <AutocompleteComponent
                  disabled={view}
                  placeholder="Select Branch"
                  value={selectedData?.branch || null}
                  options={data?.branchOpt || []}
                  getOptionLabel={(option) => option?.Name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  }
                  onChange={(event, newValue) => {
                    setSelectedData((prev) => ({
                      ...prev,
                      branch: newValue,
                    }));
                    setSelectedData((prev) => ({ ...prev, POS: null }));
                  }}
                />
              </div>
              <div className="col-md-4">
                <label required>POS Name/Code</label>
                <AutocompleteComponent
                  disabled={view}
                  placeholder="Select POS"
                  value={selectedData?.POS || null}
                  options={data?.POSOpt || []}
                  getOptionLabel={(option) => option.Name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  }
                  onChange={(event, newValue) =>
                    setSelectedData((prev) => ({ ...prev, POS: newValue }))
                  }
                />
              </div>
              <div className="col-md-2">
                <label>Club</label>
                <input
                  className="form-control "
                  disabled={true}
                  type="text"
                  name="Club"
                  value={selectedData?.POS?.Club?.Name || ""}
                  placeholder="Club"
                />
              </div>
              <div className="col-md-2">
                <label>Old HOM</label>
                <input
                  className="form-control "
                  disabled={true}
                  type="number"
                  name="HOMargin"
                  value={selectedData?.POS?.Club?.HOMargin || 0}
                  placeholder="HOMargin"
                />
              </div>
              <div className="col-md-2">
                <label>Old CPM</label>
                <input
                  className="form-control "
                  disabled={true}
                  type="number"
                  name="CPMargin"
                  value={selectedData?.POS?.Club?.CPMargin || 0}
                  placeholder="Old CPM"
                />
              </div>
              <div className="col-md-2">
                <label required>New HOM</label>
                <input
                  className="form-control "
                  disabled={view}
                  type="number"
                  name="HOMargin"
                  value={HOMargin}
                  onChange={(e) => setHOMargin(e.target.value)}
                  placeholder="HOMargin"
                  required //done
                />
              </div>
              {(basedOn === "CPMargin" || basedOn === "CPPMargin") && (
                <div className="col-md-2">
                  <label required>
                    {basedOn === "CPMargin" ? "New CPM" : "POS Rate"}
                  </label>
                  <input
                    className="form-control "
                    disabled={view}
                    type="number"
                    name="CPMargin"
                    value={CPMargin}
                    onChange={(e) => setCPMargin(e.target.value)}
                    placeholder="CPMargin"
                    required
                  />
                </div>
              )}
            </div>
          </div>

          <div className="col-lg-12" style={{ float: "left" }}>
            <center>
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => navigate("/admin/masters/club-exception/data")}
              >
                Back
              </button>
              &nbsp;
              {!view && (
                <button
                  type="submit"
                  className="btn mybtn"
                  // style={{ marginTop: 28 }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
            </center>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default AddClubException;
