import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import * as pdfjsLib from "pdfjs-dist";
// import "@react-pdf-viewer/core/lib/styles/index.css";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import FileUploadModal from "../Modals/FileUploadModal";
import ViewFileModal from "../Modals/ViewFileModal";
import Select from "react-select";
import { useSelector } from "react-redux";

import AutocompleteComponent from "../../Utils/AutoCompleteComponent";
import AlertDialogSlide from "../../Utils/AlertDialogSlide";
import AsyncSelect from "react-select/async";
import { afterOneYearDate } from "../../Utils/usefullFunction";
import {
  Business_TypeData,
  IMT_TYPE,
  policyPayingTermOpt,
  Risk_TypeData,
} from "../../Utils/CommonFields";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const convertInsuranceToId = (arr) => {
  if (arr.length === 0) return [];
  const data = arr.map((ele) => ele.value).filter((ele) => ele !== "");
  return JSON.stringify(data);
};

const regex = /^[a-zA-Z0-9]*$/;

function removeEmptyFields(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => {
      // Remove null, undefined, empty string, empty array, and empty object
      if (value === null || value === undefined) return false;
      if (typeof value === "string" && value.trim() === "") return false;
      if (Array.isArray(value) && value.length === 0) return false;
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        Object.keys(value).length === 0
      )
        return false;
      return true;
    })
  );
}

const calculateCommissionablePremium = (fieldsToSum = [], policyDetails) => {
  return fieldsToSum.reduce((total, field) => {
    return total + (+policyDetails[field] || 0);
  }, 0);
};

const CreatePolicy = () => {
  const { user } = useSelector((store) => store.user);
  const DELETE_URL = "/Policy/delete/Image";
  const { state } = useLocation();
  const { PolicyId } = useParams();
  const [error, setError] = useState("");
  const [BtnDisable, setBtnDisable] = useState("");
  const { value = "", Mode, view, FromQc = false } = state || {};
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEndorsement, setIsEndorsement] = useState({
    show: false,
    enteredByEndorse: "",
    branchEndorse: "",
  });

  const [vehicleNumberExists, setVehicleNumberExists] = useState(false);

  const [Policy, setPolicy] = useState({
    VehicleNumber: "",
    PolicyNumber: "",
    RegistrationDate: "",
    DateOfBirth: "",
    GetAmount: 0,
    IMTType: "",
    PaidBank: "",
    ReceivedBank: "",
    FuelType: "",
    Seater: "",
    GVW: "",
    Engine: "",
    nillDepth: "",
    PaymentMode: "",
    ODDiscount: "",
    IDV_Sum: "",
    TotalGSTAmount: "",
    LOB: "Motor",
    MandateLetter: "No",
    Branch: "",
    EndorsementPolicyNumber: "",
    PolicyType: "",
    CustomerType: "Individual",
    policyPayingTerm: "",
    AlternatePolicyNumber: "",
  });

  const [PolicyTypeOpt, setPolicyTypeOpt] = useState([]);
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  // IMAGES
  const [NewPolicyCopy, setNewPolicyCopy] = useState();
  const [RC1, setRC1] = useState();
  const [RC2, setRC2] = useState();
  const [PreviousPolicy1, setPreviousPolicy1] = useState();
  const [PreviousPolicy2, setPreviousPolicy2] = useState();
  const [TPGST, setTPGST] = useState(18);
  const [MakeModal, setMakeModal] = useState({
    _id: "",
    Make: "-- Select Make --",
  });
  const [Model, setModel] = useState({
    _id: "",
    Model: "-- Select Model --",
  });

  const [POS, setPOS] = useState(null);
  // const [Broker, setBroker] = useState({
  //   _id: "",
  //   Name: "-- Select Broker --",
  // });
  const [Variant, setVariant] = useState({
    VariantName: "-- Select Model --",
    _id: "",
  });

  const [DefaultMakeModalOptions, setDefaultMakeOption] = useState([
    { _id: "", Make: "-- Select Make --" },
  ]);

  const [CompanyBranchOpt, setCompanyBranchOpt] = useState([]);

  const [CompanyBranch, setCompanyBranch] = useState({
    _id: "",
    BranchName: " -- Select Company Branch --",
  });
  const [InsuranceCompanyOpt, setInsuranceCompanyOpt] = useState([]);

  const [InsuranceCompany, setInsuranceCompany] = useState({
    _id: "",
    AliasName: " -- Select Insurance Company --",
  });

  const [POSOpt, setPOSOpt] = useState([]);
  const [duplicatePolicyNo, setDuplicatePolicyNo] = useState("");
  const [FuelTypeOpt, setFuelTypeOpt] = useState([]);
  const [branch, setBranch] = useState([]);
  const [rto, setRto] = useState(null);
  const [insurance, setInsurance] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [VariantOpt, setVariantOpt] = useState([
    { VariantName: "-- Select Variant --", _id: "" },
  ]);
  const [ModelOpt, setModelOpt] = useState([
    { _id: "", Model: "-- Select Model --" },
  ]);
  const [isValidPolicyNumberMsg, setIsValidPolicyNumberMsg] = useState(false);
  // const [BrokerOpt, setBrokerOpt] = useState([]);
  // const [PaymentMode, setPaymentMode] = useState("");

  const [ModalData, setModalData] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [commissionablePrem, setCommissionablePrem] = useState({});
  const [CompanyLoading, setCompanyLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.Branch?._id && user?.Branch?.BranchCode !== "0") {
      setPolicy((prev) => ({ ...prev, Branch: user?.Branch?._id }));
    }
  }, [user?.Branch]);
  useEffect(() => {
    if (
      Variant?._id &&
      VariantOpt?.length > 0 &&
      !PolicyId &&
      !Policy?.EndorsementPolicyNumber
    ) {
      const foundData = VariantOpt.find((item) => item?._id === Variant?._id);
      if (foundData) {
        setPolicy((prev) => ({
          ...prev,
          FuelType: foundData?.FuelType,
          Seater: foundData?.Seater,
          GVW: foundData?.GVW,
          Engine: foundData?.Engine,
        }));
      }
    }
  }, [Variant, VariantOpt, PolicyId, Policy?.EndorsementPolicyNumber]);

  useEffect(() => {
    if (PolicyTypeOpt && PolicyTypeOpt.length > 0 && Policy?.PolicyType) {
      const foundData = PolicyTypeOpt.find(
        (item) => item?._id === Policy?.PolicyType
      );

      if (foundData) {
        setSelectedPolicy(foundData);
      }
    }
  }, [PolicyTypeOpt, Policy?.PolicyType]);

  useEffect(() => {
    if (
      SelectedInsuranceType &&
      SelectedInsuranceType.length > 0 &&
      SelectedInsuranceType[0].value
    ) {
      const foundData = SelectedInsuranceType[0].data.find(
        (item) => item?._id === SelectedInsuranceType[0]?.value
      );

      if (foundData) {
        setInsurance(foundData);
      }
    }
  }, [SelectedInsuranceType]);

  const getDefaultMakeOption = async (id, reset) => {
    if (!id) {
      setDefaultMakeOption([{ _id: "", Make: "-- Select Make --" }]);
      return;
    }

    try {
      const [make, fuelType] = await Promise.all([
        axiosInstance.post("/Variant/getMake", { id }),
        axiosInstance.post("/Variant/getFuelType", { id }),
      ]);
      if (fuelType && make) {
        const data = make?.data?.data;
        setDefaultMakeOption((pre) => {
          return [{ _id: "", Make: "-- Select Make --" }, ...data];
        });
        setFuelTypeOpt(fuelType?.data?.data);
      }

      if (!reset) {
        setMakeModal({ _id: "", Make: "-- Select Make --" });
        setPolicy((prev) => ({ ...prev, FuelType: "" }));
        setModelOpt((pre) => {
          return [{ _id: "", Model: "-- Select Model --" }];
        });
        setModel((pre) => {
          return {
            _id: "",
            Model: "-- Select Model --",
          };
        });
        setVariantOpt([
          {
            VariantName: "-- Select Variant --",
            _id: "",
          },
        ]);
        setVariant([
          {
            VariantName: "-- Select Variant --",
            _id: "",
          },
        ]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchModel = async (insuranceType, make) => {
      try {
        const {
          data: { data = [] },
        } = await axiosInstance.post("/Variant/getModel", {
          insuranceType,
          make,
        });

        setModelOpt((pre) => {
          return [{ _id: "", Model: "-- Select Model --" }, ...data];
        });
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (
      SelectedInsuranceType &&
      SelectedInsuranceType.length > 0 &&
      SelectedInsuranceType[0].value &&
      MakeModal?._id
    ) {
      fetchModel(SelectedInsuranceType[0].value, MakeModal?._id);
    }
  }, [SelectedInsuranceType, MakeModal]);

  useEffect(() => {
    const getVariant = async (insuranceType, make, model) => {
      try {
        const {
          data: { data },
        } = await axiosInstance.post("/Variant/getVariant", {
          insuranceType,
          make,
          model,
        });
        setVariantOpt([
          { VariantName: "-- Select Variant --", _id: "" },
          ...data,
        ]);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (
      SelectedInsuranceType &&
      SelectedInsuranceType.length > 0 &&
      SelectedInsuranceType[0].value &&
      MakeModal?._id &&
      Model?._id
    ) {
      getVariant(SelectedInsuranceType[0].value, MakeModal?._id, Model?._id);
    }
  }, [SelectedInsuranceType, MakeModal, Model]);

  const MakeModalPromiseOptions = async (inputValue) => {
    try {
      // const id = SelectedInsuranceType[0].value;

      // if (!id) {
      //   return;
      // }
      const {
        data: { data },
      } = await axiosInstance.get(`/Make?inputData=${inputValue}`);
      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setBtnDisable("yes");
    try {
      if (!user._id) {
        return ToastError("Something Went wrong");
      }

      if (isValidPolicyNumberMsg) {
        return ToastError("Policy Number is invalid");
      }

      if (!rto?.RTOName && Policy.LOB === "Motor") {
        return ToastError("Vehicle Number is invalid");
      }
      if (!Policy.RegistrationDate && Policy.LOB === "Motor") {
        return ToastError("Registration Date is Required");
      }

      if (
        Policy.RegistrationDate &&
        (new Date(Policy.RegistrationDate).getFullYear() < 1990 ||
          new Date(Policy.RegistrationDate) > new Date())
      ) {
        return ToastError("Invalid Registration Date");
      }

      if (
        !Policy?.EndorsementPolicyNumber &&
        Policy.PolicyNumber === duplicatePolicyNo
      ) {
        return ToastWarn("This Policy number is already exists");
      }

      if (!isDisabled && !Policy["VehicleNumber"]) {
        return ToastError("Vehicle Number is Required");
      }

      if (!isDisabled && !Policy["nillDepth"]) {
        return ToastError("Nill Dep Number is Required");
      }

      // if (!PaymentMode) {
      //   return ToastError("PaymentMode is Required");
      // }

      if (!isDisabled && Policy["BusinessType"] !== "NEW") {
        if (Policy["VehicleNumber"].length < 4) {
          return ToastError(
            "Vehicle Number should not be less then 4 Characters."
          );
        }
      }
      const policyData = {};
      const PA = Number(Policy?.PAPremium || 0);
      if (PA > 0) {
        policyData["PA"] = "With PA";
      } else {
        policyData["PA"] = "Without PA";
      }
      if (!isDisabled && !Policy?.PolicyType) {
        return ToastError("PolicyType is Required");
      }
      if (!Policy?.PolicyNumber) {
        return ToastError("Policy Number is Required");
      }
      if (!Policy?.InsureeName) {
        return ToastError("Insured Name is Required");
      }
      if (!Policy?.IssueDate) {
        return ToastError("Issue Date is Required");
      }
      if (!isDisabled && !Policy?.FuelType) {
        return ToastError("Fuel Type is Required");
      }

      if (Policy?.MandateLetter === "Yes" && !RC1) {
        return ToastError("Mandate Letter is Required");
      }

      if (!isDisabled && insurance && insurance?.cubicCapacity === "yes") {
        if (!Policy?.Engine) {
          return ToastError("CC/Watt is Required");
        }
      }
      if (!isDisabled && insurance && insurance?.GVW === "yes") {
        if (!Policy?.GVW) {
          return ToastError("GVW is Required");
        }
      }

      if (vehicleNumberExists && !isEndorsement) {
        return ToastError("Vehicle Number Already Exists");
      }

      if (!isDisabled && insurance && insurance?.seatingCapacity === "yes") {
        if (!Policy?.Seater) {
          return ToastError("Seating Capacity is Required");
        }
      }
      if (!isDisabled && MakeModal["_id"]) {
        policyData["MakeModal"] = MakeModal["_id"];
      } else if (!isDisabled) {
        return ToastError("Make is Required");
      }
      if (!isDisabled && Model["_id"]) {
        policyData["Model"] = Model["_id"];
      } else if (!isDisabled) {
        return ToastError("Model  is Required");
      }

      if (Variant["_id"]) {
        policyData["Variant"] = Variant["_id"];
      }
      if (InsuranceCompany["_id"]) {
        policyData["InsuranceCompany"] = InsuranceCompany["_id"];
      } else {
        return ToastError("Insurance Company is Required");
      }

      if (CompanyBranch && CompanyBranch?.["_id"]) {
        policyData["CompanyBranch"] = CompanyBranch["_id"];
      }
      if (NewPolicyCopy) {
        policyData["NewPolicyCopy"] = NewPolicyCopy;
      } else {
        return ToastError("Policy Copy is Required");
      }

      if (POS && POS["_id"]) {
        policyData["POS"] = POS["_id"];
      } else {
        return ToastError("POS  is Required");
      }

      if (
        Policy?.EndorsementPolicyNumber &&
        Policy?.AlternatePolicyNumber === ""
      ) {
        return ToastError("AlternatePolicyNumber  is Required");
      }

      if (!isDisabled && (Policy?.NCB === "" || Policy?.NCB === undefined)) {
        return ToastError("NCB Status is Required");
      }

      const PolicyData = new FormData();
      PolicyData.append("NewPolicyCopy", NewPolicyCopy);
      PolicyData.append("RC1", RC1);

      PolicyData.append("RC2", RC2);
      PolicyData.append("PreviousPolicy1", PreviousPolicy1);
      PolicyData.append("PreviousPolicy2", PreviousPolicy2);
      PolicyData.append("Employee", user._id);
      PolicyData.append("isEmployee", true);
      PolicyData.append(
        "IsNonMotorPolicy",
        Policy?.LOB === "Motor" ? false : true
      );

      if (Policy?.EndorsementPolicyNumber && Policy?.AlternatePolicyNumber) {
        PolicyData.append(
          "AlternatePolicyNumber",
          Policy?.AlternatePolicyNumber
        );
      }

      if (Policy?.PaymentMode) {
        PolicyData.append("PaymentMode", Policy?.PaymentMode);
      }

      // PolicyData.append("PaymentMode", PaymentMode);
      const insUnderflow = convertInsuranceToId(SelectedInsuranceType);

      if (insUnderflow && JSON.parse(insUnderflow).length === 0) {
        return ToastError("Product is Required!");
      }

      if (insUnderflow && JSON.parse(insUnderflow).length === 1) {
        return ToastError("Sub product is Required!");
      }

      PolicyData.append("InsuranceUnderFlow", insUnderflow);

      const data = removeEmptyFields(Policy);

      const CommissionablePremium =
        calculateCommissionablePremium(commissionablePrem?.Premium, data) ?? 0;

      PolicyData.append(
        "Policy",
        JSON.stringify({
          ...policyData,
          ...data,
          CommissionablePremium,
          EnteredByModel: user.userType,
        })
      );

      const {
        data: { success, message },
      } = await axiosInstance.post("/Policy", PolicyData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/Policy/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      setBtnDisable("");
      ToastError(error?.response?.data?.message);
    } finally {
      setBtnDisable("");
    }
  };
  console.log("user", user);
  const handleEdit = async (e) => {
    e.preventDefault();
    setBtnDisable("yes");
    try {
      const PolicyData = new FormData();

      if (user?.Branch?.BranchCode !== "0") {
        if (
          user?.Permission?.Roll === "QC" ||
          user?.Permission?.Roll === "Data Entry"
        ) {
        } else {
          ToastWarn("Not allowed to Edit policy");
          return;
        }
      }

      if (
        !Policy?.EndorsementPolicyNumber &&
        Policy.PolicyNumber === duplicatePolicyNo
      ) {
        return ToastWarn("This Policy number is already exists");
      }

      if (isValidPolicyNumberMsg) {
        return ToastError("Policy Number is invalid");
      }
      if (!rto?.RTOName && Policy.LOB === "Motor") {
        return ToastError("Vehicle Number is invalid");
      }

      if (
        Policy.RegistrationDate &&
        (new Date(Policy.RegistrationDate).getFullYear() < 1990 ||
          new Date(Policy.RegistrationDate) > new Date())
      ) {
        return ToastError("Invalid Registration Date");
      }

      if (vehicleNumberExists && !isEndorsement) {
        return ToastError("Vehicle Number Already Exists");
      }

      if (Policy?.isPurchase) {
        return ToastWarn(
          "You will not able to edit this policy because it has already Billed"
        );
      }

      // if (!PaymentMode) {
      //   return ToastError("PaymentMode is Required");
      // }

      if (!isDisabled && !Policy["nillDepth"]) {
        return ToastError("Nill Dep Number is Required");
      }

      if (!Policy.RegistrationDate && Policy.LOB === "Motor") {
        return ToastError("Registration Date is Required");
      }

      if (!isDisabled && !Policy["VehicleNumber"]) {
        return ToastError("Vehicle Number is Required");
      }
      if (!Policy?.PolicyNumber) {
        return ToastError("Policy Number is Required");
      }
      if (!Policy?.InsureeName) {
        return ToastError("Insured Name is Required");
      }
      if (!isDisabled && Policy["BusinessType"] !== "NEW") {
        if (Policy["VehicleNumber"].length < 4) {
          return ToastError(
            "Vehicle Number should not be less then 4 Characters."
          );
        }
      }

      const policyData = {};
      const PA = Number(Policy?.PAPremium || 0);
      if (PA > 200) {
        policyData["PA"] = "With PA";
      } else {
        policyData["PA"] = "Without PA";
      }
      // if (!Policy?.IMTType) {
      //   return ToastError("IMT Type is Required");
      // }

      if (!isDisabled && (Policy?.NCB === "" || Policy?.NCB === undefined)) {
        return ToastError("NCB Status is Required");
      }

      if (!isDisabled && !Policy?.PolicyType) {
        return ToastError("PolicyType is Required");
      }
      if (!Policy?.IssueDate) {
        return ToastError("Issue Date is Required");
      }

      if (!isDisabled && MakeModal["_id"]) {
        policyData["MakeModal"] = MakeModal["_id"];
      } else if (!isDisabled) {
        return ToastError("Make  is Required");
      }
      if (!isDisabled && Model["_id"]) {
        policyData["Model"] = Model["_id"];
      } else if (!isDisabled) {
        return ToastError("Model  is Required");
      }

      if (Variant["_id"]) {
        policyData["Variant"] = Variant["_id"];
      }
      if (InsuranceCompany["_id"]) {
        policyData["InsuranceCompany"] = InsuranceCompany["_id"];
      } else {
        return ToastError("InsuranceCompany  is Required");
      }

      if (CompanyBranch && CompanyBranch?.["_id"]) {
        policyData["CompanyBranch"] = CompanyBranch["_id"];
      }

      if (POS && POS["_id"]) {
        policyData["POS"] = POS["_id"];
      } else {
        return ToastError("POS  is Required");
      }

      if (Policy?.MandateLetter === "Yes" && !RC1) {
        return ToastError("Mandate Letter is Required");
      }

      if (Policy?.PaymentMode) {
        PolicyData.append("PaymentMode", Policy?.PaymentMode);
      }

      const data = removeEmptyFields(Policy);
      PolicyData.append("NewPolicyCopy", NewPolicyCopy);
      PolicyData.append("RC1", RC1);
      PolicyData.append("RC2", RC2);
      PolicyData.append("PreviousPolicy1", PreviousPolicy1);
      PolicyData.append("PreviousPolicy2", PreviousPolicy2);

      // PolicyData.append("PaymentMode", PaymentMode);
      PolicyData.append("Status", "PENDING");

      const insUnderflow = convertInsuranceToId(SelectedInsuranceType);

      if (insUnderflow && JSON.parse(insUnderflow).length === 0) {
        return ToastError("Product is Required!");
      }

      if (insUnderflow && JSON.parse(insUnderflow).length === 1) {
        return ToastError("Sub product is Required!");
      }

      PolicyData.append("InsuranceUnderFlow", insUnderflow);

      // PolicyData.append(
      //   "InsuranceUnderFlow",
      //   convertInsuranceToId(SelectedInsuranceType)
      // );

      const CommissionablePremium =
        calculateCommissionablePremium(commissionablePrem?.Premium, data) ?? 0;

      PolicyData.append(
        "Policy",
        JSON.stringify({ ...data, ...policyData, CommissionablePremium })
      );

      const {
        data: { success, message },
      } = await axiosInstance.put(`/Policy/${Policy?._id}`, PolicyData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (success) {
        ToastSusses(message);
        if (FromQc) {
          navigate(`/admin/QC/${Policy?._id}`);
        } else {
          navigate("/admin/Policy/data");
        }
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      setBtnDisable("");
      console.log("error", error);
      ToastError(error?.response?.data?.message);
    } finally {
      setBtnDisable("");
    }
  };
  const getPolicyType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/PolicyType");
      setPolicyTypeOpt(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const getCompanyBranch = async () => {
    try {
      if (CompanyBranchOpt.length > 0) {
        return false;
      }
      setCompanyLoading(true);
      const {
        data: { data },
      } = await axiosInstance.post(
        "/InsuranceCompany/CompanyBranch/getallcompanyBranch",
        {
          id: InsuranceCompany._id,
        }
      );
      setCompanyBranchOpt([
        {
          _id: "",
          BranchName: " -- Select Company Branch --",
        },
        ...data,
      ]);
      setCompanyLoading(false);
    } catch (error) {
      setCompanyLoading(false);
    }
  };
  const getInsuranceCompany = async () => {
    try {
      if (InsuranceCompanyOpt.length > 0) {
        return false;
      }
      setCompanyLoading(true);
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceCompany");
      setInsuranceCompanyOpt([
        {
          _id: "",
          AliasName: " -- Select Insurance Company --",
        },
        ...data,
      ]);
      setCompanyLoading(false);
    } catch (error) {
      setCompanyLoading(false);
    }
  };

  const handleBlur = () => {
    const value = Policy?.PAPremium;
    if (value !== "0" && (parseFloat(value) < 200 || value === "")) {
      setError("PA Premium must be greater than equal to 200");
    } else {
      setError("");
    }
  };

  const onChangeSetValue = (e, isSelectBox) => {
    if (e.target.name === "PA Premium") {
      if (
        value === "" ||
        value === "0" ||
        value === 0 ||
        parseFloat(value) >= 200
      ) {
        setPolicy((prev) => ({
          ...prev,
          [e.target.name]: value,
        }));
      }
    } else if (e.target.name === "RegistrationDate") {
      const RegistrationDate = e.target.value;

      setPolicy((prev) => ({
        ...prev,
        [e.target.name]: RegistrationDate,
        YearOfManufacture: RegistrationDate.split("-")[0] ?? "",
      }));
    } else if (
      e.target.name === "PolicyNumber" ||
      e.target.name === "VehicleNumber"
    ) {
      setPolicy((prev) => ({
        ...prev,
        [e.target.name]: String(e.target.value)
          .replace(/ /g, "")
          .toLocaleUpperCase(),
      }));
    } else if (e.target.name === "RSD") {
      const RED = afterOneYearDate(e.target.value);
      setPolicy((prev) => ({ ...prev, [e.target.name]: e.target.value, RED }));
    } else {
      setPolicy((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };
  const getDataInsuranceType = async (InsuranceCategory, InsuranceCompany) => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/InsuranceType?Root=true&InsuranceCategory=${InsuranceCategory}&InsuranceCompany=${InsuranceCompany}`
      );
      setSelectedInsuranceType([{ data, value: "" }]);
    } catch (error) {}
  };
  const callInsuranceData = async (
    InsuranceType,
    InsuranceCategory,
    reset = false
  ) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get(
          `/InsuranceType?Root=true&InsuranceCategory=${InsuranceCategory}&InsuranceCompany= ${InsuranceCompany?._id}`
        );
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${
              InsuranceType[index - 1]
            }&InsuranceCategory=${InsuranceCategory}&InsuranceCompany= ${
              InsuranceCompany?._id
            }`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }

      return reset
        ? setSelectedInsuranceType((prev) => [...prev, ...myInsuranceType])
        : setSelectedInsuranceType([
            ...SelectedInsuranceType,
            ...myInsuranceType,
          ]);
    } else {
      if (Policy?.LOB) {
        getDataInsuranceType(Policy?.LOB, InsuranceCompany?._id);
      }
    }
  };

  const getPos = async () => {
    try {
      // if (POSOpt.length > 0) {
      //   return false;
      // }
      const {
        data: { data },
      } = await axiosInstance.post("/Pos/getPosByField", {
        branch: Policy?.Branch,
        showFiled: {
          Code: 1,
          AliasName: 1,
          Name: 1,
          Salutation: 1,
          MiddleName: 1,
          LastName: 1,
          _id: 1,
        },
      });

      const POS = data.map(
        ({
          Code,
          AliasName,
          Name = "",
          MiddleName = "",
          LastName = "",
          _id,
        }) => {
          return {
            Name: `${Code}- ${AliasName || ""} (${Name || ""} ${
              MiddleName || ""
            } ${LastName || ""})`,
            _id,
          };
        }
      );
      setPOSOpt(POS);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFuelType = async () => {
    try {
      const [branchResp] = await Promise.all([
        axiosInstance.get("/Branch/getBranch"),
      ]);

      if (branchResp) {
        setBranch(branchResp?.data?.data);
      }
    } catch (error) {}
  };

  const getSingle = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/getSingleForPolicy/${PolicyId}`);

      const {
        InsuranceCompany,
        CompanyBranch,
        Variant,
        MakeModal,
        POS,
        ...rest
      } = data;

      if (data?.LOB === "Non-Motor") {
        setIsDisabled(true);
      }
      setPolicy({
        LOB: data?.LOB,
        RegistrationDate: `${data?.YearOfManufacture ?? "2023"}-01-01`,
        ...rest,
      });

      // {_id:"-- Select Variant Type --"}

      setInsuranceCompany(InsuranceCompany);
      setCompanyBranch(CompanyBranch);
      setPOS(POS);

      getDefaultMakeOption(data?.InsuranceUnderFlow[0] ?? "", true);
      setMakeModal(
        MakeModal ?? {
          _id: "",
          Make: "-- Select Make --",
        }
      );

      setModel(rest?.Model ?? { _id: "", Model: "-- Select Model --" });

      setVariant(Variant ?? { _id: "", VariantName: "-- Select Variant --" });

      callInsuranceData(data?.InsuranceUnderFlow, data?.LOB);
    } catch (error) {}
  };

  const getPolicyForEndorsement = async (PolicyNum) => {
    try {
      const {
        data: { data, endsNumber },
      } = await axiosInstance.post(`/Policy/getPolicyForEndorsement`, {
        PolicyNumber: PolicyNum,
      });

      const {
        InsuranceCompany,
        Variant,
        MakeModal,
        POS,
        PolicyNumber,
        _id,
        ODPremium,
        TPPremium,
        TerrorismPremium,
        PAPremium,
        LLPremium,
        AddOnPremium,
        ODPremiumGST,
        TPPremiumGST,
        PAPremiumGST,
        LLPremiumGST,
        TotalGSTAmount,
        NETPremium,
        GrossPremium,
        ...rest
      } = data;

      if (data?.LOB === "Non-Motor") {
        setIsDisabled(true);
      }
      setPolicy((prev) => ({
        ...prev,
        RegistrationDate: `${data?.YearOfManufacture ?? "2023"}-01-01`,
        ...rest,
        EndorsementPolicyNumber: endsNumber,
      }));

      // {_id:"-- Select Variant Type --"}

      setInsuranceCompany(InsuranceCompany);
      setPOS(POS);

      getDefaultMakeOption(data?.InsuranceUnderFlow[0] ?? "", true);
      setMakeModal(
        MakeModal ?? {
          _id: "",
          Make: "-- Select Make --",
        }
      );

      setModel(rest?.Model ?? { _id: "", Model: "-- Select Model --" });

      setVariant(Variant ?? { _id: "", VariantName: "-- Select Variant --" });
      setSelectedInsuranceType([]);
      callInsuranceData(data?.InsuranceUnderFlow, Policy?.LOB, true);
      setIsEndorsement((prev) => ({ ...prev, show: false }));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const getCommissionablePremiumByFilter = async () => {
      const { data } = await axiosInstance.post(
        "/CommissionablePremium/getCommissionablePremiumByFilter",
        {
          InsuranceCompany: InsuranceCompany?._id,
          InsuranceType: SelectedInsuranceType[0]?.value,
          PolicyType: Policy?.PolicyType,
        }
      );

      if (data) {
        setCommissionablePrem(data?.data);
      }
    };

    if (InsuranceCompany && SelectedInsuranceType && Policy?.PolicyType) {
      getCommissionablePremiumByFilter();
    }
  }, [
    InsuranceCompany,
    SelectedInsuranceType,
    Policy?.PolicyType,
    PolicyTypeOpt,
  ]);

  useEffect(() => {
    if (PolicyId) {
      getSingle();
    }

    getPolicyType();
    getFuelType();
  }, [PolicyId]);

  useEffect(() => {
    if (!PolicyId && Policy?.LOB) {
      getDataInsuranceType(Policy?.LOB, InsuranceCompany?._id);
    }
  }, [PolicyId, Policy?.LOB, InsuranceCompany]);

  useEffect(() => {
    // 18/100 *500
    const ODPremiumGST = ((18 / 100) * (Policy?.ODPremium ?? 0)).toFixed(2);
    const AddOnPremiumGST = ((18 / 100) * (Policy?.AddOnPremium ?? 0)).toFixed(
      2
    );
    const TPPremiumGST = ((TPGST / 100) * (Policy?.TPPremium ?? 0)).toFixed(2);
    const PAPremiumGST = ((18 / 100) * (Policy?.PAPremium ?? 0)).toFixed(2);
    const LLPremiumGST = ((18 / 100) * (Policy?.LLPremium ?? 0)).toFixed(2);
    const NETPremiumData =
      +(Policy?.ODPremium ?? 0) +
      +(Policy?.AddOnPremium ?? 0) +
      +(Policy?.TPPremium ?? 0) +
      +(Policy?.PAPremium ?? 0) +
      +(Policy?.LLPremium ?? 0);

    const TotalGSTAmount =
      +ODPremiumGST +
      +AddOnPremiumGST +
      +TPPremiumGST +
      +PAPremiumGST +
      +LLPremiumGST;

    const GrossPremiumData = TotalGSTAmount + NETPremiumData;

    setPolicy((prev) => ({
      ...prev,
      ODPremiumGST,
      AddOnPremiumGST,
      TPPremiumGST,
      PAPremiumGST,
      LLPremiumGST,
      TotalGSTAmount: TotalGSTAmount.toFixed(2),
      NETPremium: NETPremiumData?.toFixed(2),
      GrossPremium: GrossPremiumData?.toFixed(2),
    }));
  }, [
    Policy?.PAPremium,
    Policy?.AddOnPremium,
    Policy?.LLPremium,
    Policy?.TPPremium,
    Policy?.ODPremium,
    Policy?.InsuranceType,
    TPGST,
  ]);

  useEffect(() => {
    const SelectedInsuranceTypeData = SelectedInsuranceType.filter(
      (ele) => ele.value !== ""
    );
    const SelITData =
      SelectedInsuranceTypeData[SelectedInsuranceTypeData.length - 1];

    if (SelITData?.data?.length > 0) {
      const MyData = SelITData.data.find((ele) => ele._id === SelITData?.value);
      setTPGST(MyData?.GST ?? 18);
    }
  }, [SelectedInsuranceType]);

  const cancelPolicy = async (id, status) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(
        `/Policy/updateStatus?id=${id}&status=${status}`
      );

      if (success) {
        ToastSusses(message);
        navigate("/admin/Policy/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getIsPolicyExit = async () => {
    try {
      if (!Policy?.PolicyNumber) {
        return;
      }
      const {
        data: { data, Endorse },
      } = await axiosInstance.get(
        `/Policy/findByPolicyNumber?PolicyNumber=${Policy?.PolicyNumber}`
      );

      if (PolicyId) {
        // if (data !==0 && data !==1) {
        //   ToastWarn("This Policy number is already Exit");
        // }
        return;
      }

      if (data > 0) {
        const enteredByData = Endorse[0]?.EnteredBy.Name || "";
        const branchData = Endorse[0]?.Branch?.BranchName || "";

        setDuplicatePolicyNo(Policy?.PolicyNumber);
        setIsEndorsement({
          show: true,
          enteredByEndorse: enteredByData,
          branchEndorse: branchData,
        });
        ToastWarn("This Policy number is already exists");
      } else {
        setIsEndorsement((prev) => ({ ...prev, show: false }));
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getIsVehicleNumberExists = async () => {
    try {
      if (
        !Policy?.VehicleNumber ||
        Policy?.VehicleNumber?.length <= 4 ||
        PolicyId
      ) {
        return;
      }
      const {
        data: { data },
      } = await axiosInstance.get(
        `/Policy/findByVehicleNumber?VehicleNumber=${Policy?.VehicleNumber}`
      );

      if (data > 0) {
        setVehicleNumberExists(true);
        ToastWarn("This Vehicle number is already exists");
      } else {
        setVehicleNumberExists(false);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getRTO = async () => {
    try {
      if (!Policy?.VehicleNumber && Policy?.VehicleNumber.length < 4) {
        setRto(null);
        return;
      }
      const {
        data: { data },
      } = await axiosInstance.get(
        `/RTO/getDataByRtoCode/${Policy?.VehicleNumber.slice(0, 4)}`
      );

      if (data && data.length > 0) {
        setRto(data[0]);
      }

      if (data > 0) {
        ToastWarn("This Policy number is already exists");
      }
    } catch (error) {
      console.log("error", error);
      ToastError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (Policy?.VehicleNumber) {
      const id = setTimeout(getRTO, 1000);
      return () => clearTimeout(id);
    }
  }, [Policy?.VehicleNumber]);

  useEffect(() => {
    const id = setTimeout(getIsPolicyExit, 1000);
    return () => clearTimeout(id);
  }, [Policy?.PolicyNumber]);

  useEffect(() => {
    const id = setTimeout(getIsVehicleNumberExists, 1000);
    return () => clearTimeout(id);
  }, [Policy?.VehicleNumber]);

  useEffect(() => {
    if (Policy?.Branch) {
      getPos();
    }
  }, [Policy?.Branch]);

  const handleAlertClose = () => {
    // setPolicy((prev) => ({ ...prev, PolicyNumber: "" }));
    setIsEndorsement((prev) => ({ ...prev, show: false }));
  };

  const handleUploadPolicyCopy = (file) => {
    if (user.Permission.QC) {
      ToastSusses("PDF validated successfull !");
      setNewPolicyCopy(file);
      return;
    }
    if (
      !InsuranceCompany ||
      !InsuranceCompany.AliasName ||
      !InsuranceCompany.Name
    ) {
      ToastError("Please Select Insurance Company First");
      return;
    }

    const maxSizeMB = 20;
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    const VALID_PHRASES = [
      "good insurance",
      "goods insurance",
      "good insurance brokers",
      "heart beat",
      "79001300",
      "Good Broker",
      "good insuracne",
      "heartbeat insurance",
      "heartbeat insurance brokers",
      "79001300",
      "28001272",
      "201242018164",
      "11BRG429",
      "D5000472",
      "D3000518",
    ];
    const InsuranceCompanyPhrases = [
      InsuranceCompany.AliasName,
      InsuranceCompany.Name,
    ];
    if (file && file.size > maxSizeBytes) {
      ToastError(`File size should be less than or equal to ${maxSizeMB} MB`);
      return;
    }

    try {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const arrayBuffer = e.target.result;

        try {
          const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
          let extractedText = "";

          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            extractedText += textContent.items
              .map((item) => item.str)
              .join(" ")
              .toLocaleLowerCase();
          }
          const cleanedText = extractedText.replace(/\s+/g, " ").trim();

          const isValid = VALID_PHRASES.some((phrase) =>
            cleanedText.includes(phrase.toLowerCase())
          );

          const isValidindComapny = InsuranceCompanyPhrases.some((phrase) =>
            cleanedText.includes(phrase.toLowerCase())
          );

          if (!isValid || !isValidindComapny) {
            ToastError("Invalid PDF: Required text not found.");

            return;
          }

          ToastSusses("PDF validated successfully!");

          setNewPolicyCopy(file);
        } catch (error) {
          console.error("Error processing PDF:", error);
          ToastError("Error processing PDF file.");
          return;
        }
      };

      fileReader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error during file reading:", error);
      ToastError("Error reading the PDF file.");
    }
  };

  useEffect(() => {
    if (user?.userType === "Pos" && user?.Branch?.BranchCode !== "0") {
      setPOS(user);
    }
  }, [user, setPOS]);

  const handlePolicyNumberChange = (event) => {
    const input = event.target.value;
    onChangeSetValue(event);

    if (InsuranceCompany) {
      // Validate length
      if (InsuranceCompany.policyNumberLengths) {
        const validLengths = InsuranceCompany.policyNumberLengths
          .split(",")
          .map((len) => parseInt(len, 10));
        if (!validLengths.includes(input.length)) {
          setIsValidPolicyNumberMsg(
            "Policy number must be " +
              validLengths.join(", ") +
              " characters long."
          );
          return;
        }
      }

      // Validate type of value
      if (InsuranceCompany.typeOfValue) {
        const alphanumericRegex = /^[A-Za-z0-9/-]+$/;
        const numericRegex = /^[0-9]+$/;

        if (
          InsuranceCompany.typeOfValue === "Alphanumeric" &&
          !alphanumericRegex.test(input)
        ) {
          setIsValidPolicyNumberMsg(`Policy number must be alphanumeric.`);
          return;
        }

        if (
          InsuranceCompany.typeOfValue === "Numeric" &&
          !numericRegex.test(input)
        ) {
          setIsValidPolicyNumberMsg(`Policy number must be numeric.`);
          return;
        }
      }
    }

    setIsValidPolicyNumberMsg("");
  };

  return (
    <>
      <Header />
      <AlertDialogSlide
        open={isEndorsement.show}
        isEndorsement={isEndorsement}
        handleClose={handleAlertClose}
        Title="It's a dublicate policy.Do you want to enter endorsement."
        deleteFunction={getPolicyForEndorsement}
        SelectId={Policy?.PolicyNumber}
      />
      <ViewFileModal
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        ModalData={ModalData}
      />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        {/* <form
            onSubmit={(e) => {
              e.preventDefault();
              Mode ? handleEdit(e) : handleCreate(e);
            }}
          > */}
        <form>
          <div className="row  ">
            <section
              className="push"
              style={{
                position: "relative",
                transition: " margin-left .5s",
                zoom: "90%",
              }}
            >
              <div className="CreateCard p-4">
                <div className="">
                  <div className="row mx-2 ">
                    <h5>
                      {view
                        ? "View Policy Details"
                        : PolicyId
                        ? `Edit Policy Details ${
                            Policy?.EndorsementPolicyNumber
                              ? `(Endorsement - ${Policy?.EndorsementPolicyNumber})`
                              : ""
                          }`
                        : `Enter Policy Details ${
                            Policy?.EndorsementPolicyNumber
                              ? `(Endorsement - ${Policy?.EndorsementPolicyNumber})`
                              : ""
                          }`}
                    </h5>
                    <hr className="my-2" />
                    <div className="row">
                      {/* Insurance Company */}
                      <div
                        className="col-md-4 align-items-center"
                        onClick={() => {
                          getInsuranceCompany();
                        }}
                      >
                        <label required>Insurance Company</label>

                        <Select
                          // style={{ backgroundColor: "#F5F5F6" }}
                          className="basic-single"
                          classNamePrefix="select"
                          options={InsuranceCompanyOpt}
                          getOptionValue={(option) => option["_id"]}
                          isLoading={CompanyLoading}
                          noOptionsMessage={() => "No Company Found"}
                          getOptionLabel={(option) => option["AliasName"]}
                          onChange={(ele) => {
                            setInsuranceCompany(ele);
                            setPolicy((prev) => ({
                              ...prev,
                              PolicyNumber: "",
                            }));
                            setIsValidPolicyNumberMsg("");
                          }}
                          value={InsuranceCompany}
                        />
                      </div>

                      {/* Company Branch */}
                      <div
                        className="col-md-4 align-items-center"
                        onClick={() => {
                          getCompanyBranch();
                        }}
                      >
                        <label>Insurer Branch</label>

                        <Select
                          // style={{ backgroundColor: "#F5F5F6" }}
                          className="basic-single"
                          classNamePrefix="select"
                          options={CompanyBranchOpt}
                          getOptionValue={(option) => option["_id"]}
                          isLoading={CompanyLoading}
                          noOptionsMessage={() => "No Company Found"}
                          getOptionLabel={(option) => option["BranchName"]}
                          onChange={(ele) => {
                            setCompanyBranch(ele);
                          }}
                          value={CompanyBranch}
                        />
                      </div>

                      <div className="col-md-4 align-items-center">
                        <label required>Policy Number</label>
                        <input
                          style={{
                            backgroundColor:
                              Policy?.EndorsementPolicyNumber || view
                                ? "#90cbf9"
                                : "",
                          }}
                          disabled={Policy?.EndorsementPolicyNumber || view}
                          className="form-control text-uppercase"
                          type="text"
                          name="PolicyNumber"
                          value={Policy?.PolicyNumber}
                          onChange={handlePolicyNumberChange}
                          placeholder={
                            InsuranceCompany?.policyInputPlaceholder ??
                            "Enter  Policy Number"
                          }
                          //done
                        />
                        {isValidPolicyNumberMsg && (
                          <div
                            className="text-danger mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            {isValidPolicyNumberMsg}
                          </div>
                        )}
                      </div>

                      {Policy?.EndorsementPolicyNumber && (
                        <div className="col-md-4 align-items-center">
                          <label htmlFor="alternatePolicyNumber">
                            Alternate Policy Number
                          </label>
                          <input
                            type="text"
                            id="alternatePolicyNumber"
                            name="AlternatePolicyNumber"
                            className="form-control"
                            placeholder="Enter Alternate Policy Number"
                            value={Policy?.AlternatePolicyNumber || ""}
                            onChange={(e) => {
                              onChangeSetValue(e);
                            }}
                          />
                        </div>
                      )}

                      <div className="col-md-4 align-items-center">
                        <label required>Insured Name</label>
                        <input
                          // style={{ backgroundColor: "#F5F5F6" }}
                          disabled={view}
                          className="form-control text-uppercase "
                          type="text"
                          name="InsureeName"
                          value={Policy?.InsureeName}
                          onChange={(e) => onChangeSetValue(e)}
                          placeholder="Enter Insured Name"
                          required //done
                        />
                      </div>

                      <div className="col-md-4 align-items-center">
                        <label required>Customer Type</label>
                        <select
                          disabled={view}
                          class="form-select"
                          aria-label="Default select example"
                          name="CustomerType"
                          value={Policy?.CustomerType}
                          onChange={(e) => {
                            onChangeSetValue(e);
                          }}
                          required
                        >
                          <option value="Individual">Individual</option>
                          <option value="Corporate">Corporate</option>
                        </select>
                      </div>

                      <div className="col-md-2 align-items-center">
                        <label required>Issue Date</label>
                        <input
                          // style={{ backgroundColor: "#F5F5F6" }}
                          disabled={view}
                          className="form-control"
                          type="date"
                          name="IssueDate"
                          value={Policy?.IssueDate}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={(e) => onChangeSetValue(e)}
                          placeholder="Enter IssueDate"
                          required
                        />
                      </div>

                      <div className="col-md-2 align-items-center">
                        <label htmlFor="policyPayingTerm">
                          PPT (Policy Paying Term)
                        </label>
                        <select
                          disabled={view}
                          id="policyPayingTerm"
                          class="form-select"
                          aria-label="Default select example"
                          name="policyPayingTerm"
                          value={Policy?.policyPayingTerm}
                          onChange={(e) => {
                            onChangeSetValue(e);
                          }}
                        >
                          <option value="">-- Policy Paying Term --</option>
                          {policyPayingTermOpt.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-2 align-items-center">
                        <label required>Start Date (RSD)</label>
                        <input
                          // style={{ backgroundColor: "#F5F5F6" }}
                          disabled={view}
                          className="form-control "
                          type="date"
                          name="RSD"
                          value={Policy?.RSD}
                          onChange={(e) => onChangeSetValue(e)}
                          placeholder="RSD"
                        />
                      </div>
                      <div className="col-md-2 align-items-center">
                        <label required>Expiry Date (RED)</label>
                        <input
                          // style={{ backgroundColor: "#F5F5F6" }}
                          disabled={view}
                          className="form-control "
                          type="date"
                          name="RED"
                          value={Policy?.RED}
                          onChange={(e) => onChangeSetValue(e)}
                          placeholder="RED"
                        />
                      </div>
                      <div className="col-md-4 align-items-center">
                        <label required>LOB</label>
                        <select
                          disabled={
                            Policy?.EndorsementPolicyNumber || PolicyId || view
                          }
                          style={{
                            backgroundColor:
                              Policy?.EndorsementPolicyNumber || view
                                ? "#90cbf9"
                                : "",
                          }}
                          class="form-select"
                          aria-label="Default select example"
                          name="LOB"
                          value={Policy?.LOB}
                          // style={{ backgroundColor: "#F5F5F6" }}
                          onChange={(e) => {
                            if (e.target.value === "Motor") {
                              setIsDisabled(false);
                              setSelectedInsuranceType([]);
                            } else {
                              setIsDisabled(true);
                              setSelectedInsuranceType([]);
                            }
                            onChangeSetValue(e);
                          }}
                          required
                        >
                          <option value="Motor">Motor</option>
                          <option value="Non-Motor">Non-Motor</option>
                        </select>
                      </div>
                      <div className="col-md-2 align-items-center">
                        <label
                          required
                          style={{ color: isDisabled ? "#808080" : "" }}
                        >
                          Vehicle Number
                        </label>
                        <input
                          style={{
                            backgroundColor: isDisabled ? "#7b7b7b" : undefined,
                            color: isDisabled ? "#ffffff" : undefined,
                          }}
                          className="form-control text-uppercase "
                          type="text"
                          disabled={isDisabled || view}
                          name="VehicleNumber"
                          value={Policy?.VehicleNumber}
                          onChange={(e) => {
                            if (regex.test(e.target.value)) {
                              onChangeSetValue(e);
                              setRto((prevRto) => ({
                                ...prevRto,
                                RTOName: "",
                              }));
                            }
                          }}
                          placeholder="Enter Vehicle Number"
                          required //done
                        />
                      </div>

                      <div className="col-md-2 align-items-center">
                        <label style={{ color: "#808080" }}>RTO Name</label>
                        <input
                          style={{
                            backgroundColor: "#7b7b7b",
                            color: "#ffffff",
                          }}
                          disabled={true}
                          className="form-control text-uppercase "
                          type="text"
                          value={rto?.RTOName || ""}
                        />
                      </div>

                      <div className="col-md-2 align-items-center">
                        <label
                          required
                          style={{ color: isDisabled ? "#808080" : "" }}
                        >
                          Regn. Date
                        </label>
                        <input
                          style={{
                            backgroundColor: isDisabled ? "#7b7b7b" : undefined,
                            color: isDisabled ? "#a9a2a2" : undefined,
                          }}
                          disabled={isDisabled || view}
                          className="form-control"
                          type="date"
                          name="RegistrationDate"
                          value={Policy?.RegistrationDate}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={(e) => onChangeSetValue(e)}
                          required
                          placeholder="Enter Registration Date"
                        />
                      </div>
                      <div className="col-md-2 align-items-center">
                        <label style={{ color: isDisabled ? "#808080" : "" }}>
                          Year of Mfg.
                        </label>
                        <select
                          style={{
                            backgroundColor: isDisabled ? "#7b7b7b" : undefined,
                            color: isDisabled ? "#a9a2a2" : undefined,
                          }}
                          disabled={isDisabled || view}
                          className="form-select"
                          aria-label="Select Year of Manufacture"
                          name="YearOfManufacture"
                          value={Policy?.YearOfManufacture}
                          onChange={(e) => onChangeSetValue(e)}
                          required
                        >
                          <option value="">
                            --Select Year of Manufacture --
                          </option>
                          {Policy?.RegistrationDate &&
                            Policy?.RegistrationDate.split("-").length >= 3 && (
                              <>
                                {Array(5)
                                  .fill(2)
                                  .map((ele, index) => (
                                    <option
                                      key={index}
                                      value={
                                        Number(
                                          Policy?.RegistrationDate.split("-")[0]
                                        ) - index
                                      }
                                    >
                                      {Number(
                                        Policy?.RegistrationDate.split("-")[0]
                                      ) - index}
                                    </option>
                                  ))
                                  .reverse()}
                              </>
                            )}
                        </select>
                      </div>

                      <div className="row" style={{ marginTop: "15px" }}>
                        {SelectedInsuranceType &&
                          SelectedInsuranceType?.length > 0 &&
                          SelectedInsuranceType.map(
                            (data, index) =>
                              data &&
                              data?.data?.length > 0 && (
                                <MultiInsurance
                                  key={index}
                                  Options={data}
                                  setSelectedInsuranceType={
                                    setSelectedInsuranceType
                                  }
                                  view={view}
                                  SelectedInsuranceType={SelectedInsuranceType}
                                  index={index}
                                  OptionsValue={data.value}
                                  InsuranceCompany={InsuranceCompany?._id}
                                  getMakeModal={getDefaultMakeOption}
                                />
                              )
                          )}

                        {/* Policy Type  */}
                        <div className="col-md-4 align-items-center">
                          <label
                            required
                            style={{ color: isDisabled ? "#808080" : "" }}
                          >
                            Policy Type
                          </label>
                          <select
                            disabled={isDisabled || view}
                            style={{
                              backgroundColor: isDisabled
                                ? "#7b7b7b"
                                : undefined,
                              color: isDisabled ? "#a9a2a2" : undefined,
                            }}
                            class="form-select"
                            aria-label="Default select example"
                            name="PolicyType"
                            value={Policy?.PolicyType}
                            // style={{ backgroundColor: "#F5F5F6" }}
                            onChange={(e) => {
                              onChangeSetValue(e);
                            }}
                            required
                          >
                            <option value="">-- Select Policy Type --</option>
                            {PolicyTypeOpt &&
                              PolicyTypeOpt.map((data) => (
                                <option key={data?._id} value={data?._id}>
                                  {data.PolicyTypeName}
                                </option>
                              ))}
                          </select>
                        </div>

                        {/* Business Type */}
                        <div className="col-md-4 align-items-center">
                          <label>Business Type</label>
                          <select
                            // style={{ backgroundColor: "#F5F5F6" }}
                            class="form-select"
                            aria-label="Default select example"
                            name="BusinessType"
                            value={Policy?.BusinessType}
                            onChange={(e) => onChangeSetValue(e)}
                            placeholder="Business Type"
                          >
                            <option value="" disabled selected>
                              ---Select Business Type---
                            </option>
                            {Business_TypeData.map((option) => (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Risk Type */}
                        <div className="col-md-4 align-items-center">
                          <label>Risk Type</label>
                          <select
                            // style={{ backgroundColor: "#F5F5F6" }}
                            class="form-select"
                            aria-label="Default select example"
                            name="RiskType"
                            value={Policy?.RiskType}
                            disabled={!isDisabled || view}
                            onChange={(e) => onChangeSetValue(e)}
                            placeholder="Risk Type"
                          >
                            <option value="" disabled selected>
                              ---Select Risk Type---
                            </option>
                            {Risk_TypeData.map((option) => (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-4 align-items-center">
                          <label
                            required
                            style={{ color: isDisabled ? "#808080" : "" }}
                          >
                            Make
                          </label>

                          <AsyncSelect
                            cacheOptions
                            defaultOptions={DefaultMakeModalOptions}
                            isDisabled={isDisabled || view}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: isDisabled
                                  ? "#7b7b7b"
                                  : "white", // Apply background color based on `isDisabled`
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#90cbf9" // Background color for selected option
                                  : state.isFocused
                                  ? "#e0f0ff" // Background color on hover/focus
                                  : "white",
                              }),
                            }}
                            loadOptions={MakeModalPromiseOptions}
                            noOptionsMessage={() => "No Make Modal Found"}
                            onChange={(ele) => {
                              setPolicy((prev) => ({ ...prev, FuelType: "" }));
                              setModelOpt((pre) => {
                                return [
                                  { _id: "", Model: "-- Select Model --" },
                                ];
                              });
                              setModel((pre) => {
                                return {
                                  _id: "",
                                  Model: "-- Select Model --",
                                };
                              });
                              setVariantOpt([
                                {
                                  VariantName: "-- Select Variant --",
                                  _id: "",
                                },
                              ]);
                              setVariant([
                                {
                                  VariantName: "-- Select Variant --",
                                  _id: "",
                                },
                              ]);
                              setMakeModal(ele);
                            }}
                            value={MakeModal}
                            getOptionValue={(option) => option["_id"]}
                            getOptionLabel={(option) => option["Make"]}
                          />
                        </div>

                        {
                          <div className="col-md-4 align-items-center">
                            <label
                              required
                              style={{ color: isDisabled ? "#808080" : "" }}
                            >
                              Model
                            </label>

                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isDisabled={isDisabled || view}
                              options={ModelOpt}
                              // defaultValue={{
                              //   _id:"-- Please Select Variant --"
                              // }}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDisabled
                                    ? "#7b7b7b"
                                    : "white", // Apply background color based on `isDisabled`
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#90cbf9" // Background color for selected option
                                    : state.isFocused
                                    ? "#e0f0ff" // Background color on hover/focus
                                    : "white",
                                }),
                              }}
                              getOptionValue={(option) => option["_id"]}
                              getOptionLabel={(option) => option["Model"]}
                              noOptionsMessage={() => "No Model Found"}
                              onChange={(ele) => {
                                setPolicy((prev) => ({
                                  ...prev,
                                  FuelType: "",
                                }));
                                setVariantOpt([
                                  {
                                    VariantName: "-- Select Variant --",
                                    _id: "",
                                  },
                                ]);
                                setVariant([
                                  {
                                    VariantName: "-- Select Variant --",
                                    _id: "",
                                  },
                                ]);
                                setModel(ele);
                              }}
                              value={Model}
                            />
                          </div>
                        }

                        {
                          <div className="col-md-4 align-items-center">
                            <label
                              style={{ color: isDisabled ? "#808080" : "" }}
                            >
                              Variant
                            </label>

                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isDisabled={isDisabled || view}
                              options={VariantOpt}
                              // defaultValue={{
                              //   _id:"-- Please Select Variant --"
                              // }}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDisabled
                                    ? "#7b7b7b"
                                    : "white", // Apply background color based on `isDisabled`
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#90cbf9" // Background color for selected option
                                    : state.isFocused
                                    ? "#e0f0ff" // Background color on hover/focus
                                    : "white",
                                }),
                              }}
                              getOptionValue={(option) => option["_id"]}
                              getOptionLabel={(option) => option["VariantName"]}
                              noOptionsMessage={() => "No Variant Found"}
                              onChange={setVariant}
                              value={Variant}
                            />
                          </div>
                        }

                        {
                          <div className="col-md-4 align-items-center">
                            <label
                              required
                              style={{ color: isDisabled ? "#808080" : "" }}
                            >
                              Fuel Type
                            </label>
                            <select
                              style={{
                                backgroundColor: isDisabled
                                  ? "#7b7b7b"
                                  : undefined,
                                color: isDisabled ? "#a9a2a2" : undefined,
                              }}
                              disabled={isDisabled || view}
                              class="form-select"
                              aria-label="Default select example"
                              name="FuelType"
                              value={Policy?.FuelType}
                              onChange={(e) => {
                                // if (e.target.value === "") {
                                //   getVariant(MakeModal._id);
                                // } else {
                                //   getVariant(MakeModal._id, {
                                //     "Variant.FuelType": e.target.value,
                                //   });
                                // }
                                onChangeSetValue(e);
                              }}
                            >
                              <option value="NONE">
                                -- Select Fuel Type --
                              </option>

                              {FuelTypeOpt &&
                                FuelTypeOpt.map((data) => (
                                  <option key={data?._id} value={data?._id}>
                                    {data.FuelType}
                                  </option>
                                ))}
                            </select>
                          </div>
                        }
                        {insurance &&
                          insurance?.DOB &&
                          insurance?.DOB === "yes" && (
                            <div className="col-md-4 align-items-center">
                              <label>DOB</label>
                              <input
                                // style={{ backgroundColor: "#F5F5F6" }}
                                className="form-control"
                                type="date"
                                name="DateOfBirth"
                                value={Policy?.DateOfBirth}
                                onChange={(e) => onChangeSetValue(e)}
                                placeholder="Enter Date Of Birth"
                              />
                            </div>
                          )}

                        <div className="col-md-4 align-items-center">
                          <label
                            required
                            style={{ color: !isDisabled ? "#808080" : "" }}
                          >
                            Mandate Letter
                          </label>
                          <select
                            style={{
                              backgroundColor: !isDisabled
                                ? "#7b7b7b"
                                : undefined,
                              color: !isDisabled ? "#a9a2a2" : undefined,
                            }}
                            disabled={!isDisabled || view}
                            class="form-select"
                            aria-label="Default select example"
                            name="MandateLetter"
                            value={Policy?.MandateLetter}
                            onChange={(e) => {
                              onChangeSetValue(e);
                            }}
                          >
                            <option value="">
                              -- Select Mandate Letter --
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                        <div className="row" style={{ marginTop: "15px" }}>
                          {insurance && insurance?.cubicCapacity === "yes" && (
                            <div className="col-md-4 align-items-center">
                              <label
                                required
                                style={{ color: isDisabled ? "#808080" : "" }}
                              >
                                CC/watt (1KW=1000Watt)
                              </label>
                              <input
                                style={{
                                  backgroundColor: isDisabled
                                    ? "#7b7b7b"
                                    : undefined,
                                  color: isDisabled ? "#ffffff" : undefined,
                                }}
                                disabled={isDisabled || view}
                                className="form-control text-uppercase "
                                type="number"
                                name="Engine"
                                value={Policy?.Engine}
                                onChange={(e) => onChangeSetValue(e)}
                                placeholder="Enter Cubic Capacity"
                                required
                              />
                            </div>
                          )}

                          {insurance && insurance?.GVW === "yes" && (
                            <div className="col-md-4 align-items-center">
                              <label
                                required
                                style={{ color: isDisabled ? "#808080" : "" }}
                              >
                                GVW Kg. (1 Ton = 1000 Kg.)
                              </label>
                              <input
                                style={{
                                  backgroundColor: isDisabled
                                    ? "#7b7b7b"
                                    : undefined,
                                  color: isDisabled ? "#ffffff" : undefined,
                                }}
                                disabled={isDisabled || view}
                                className="form-control text-uppercase "
                                type="number"
                                name="GVW"
                                value={Policy?.GVW}
                                onChange={(e) => onChangeSetValue(e)}
                                // placeholder="Enter  Insuree Name"
                                required
                              />
                            </div>
                          )}

                          {insurance &&
                            insurance?.seatingCapacity === "yes" && (
                              <div className="col-md-4 align-items-center">
                                <label
                                  style={{ color: isDisabled ? "#808080" : "" }}
                                  required
                                >
                                  Seating Capacity
                                </label>
                                <input
                                  style={{
                                    backgroundColor: isDisabled
                                      ? "#7b7b7b"
                                      : undefined,
                                    color: isDisabled ? "#ffffff" : undefined,
                                  }}
                                  disabled={isDisabled || view}
                                  className="form-control text-uppercase "
                                  type="number"
                                  name="Seater"
                                  value={Policy?.Seater}
                                  onChange={(e) => onChangeSetValue(e)}
                                  placeholder="Enter Seating Capacity"
                                />
                              </div>
                            )}

                          <div className="col-md-4 align-items-center">
                            <label>IDV/Sum Insured</label>
                            <input
                              // style={{ backgroundColor: "#F5F5F6" }}
                              disabled={view}
                              className="form-control text-uppercase "
                              type="number"
                              name="IDV_Sum"
                              value={Policy?.IDV_Sum}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="Enter IDV/Sum Insured"
                            />
                          </div>

                          <div className="col-md-4 align-items-center">
                            <label
                              style={{ color: isDisabled ? "#808080" : "" }}
                              required
                            >
                              Nil Dep
                            </label>
                            <select
                              style={{
                                backgroundColor: isDisabled
                                  ? "#7b7b7b"
                                  : undefined,
                                color: isDisabled ? "#a9a2a2" : undefined,
                              }}
                              class="form-select"
                              disabled={isDisabled || view}
                              aria-label="Default select example"
                              name="nillDepth"
                              value={Policy?.nillDepth}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="Nil Dep"
                            >
                              <option value="">---- Select Nil Dep ---</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "15px" }}>
                      {insurance &&
                        insurance?.IMTType &&
                        insurance?.IMTType === "yes" && (
                          <div className="col-md-4 align-items-center">
                            <label>IMT Type</label>
                            <select
                              // style={{ backgroundColor: "#F5F5F6" }}
                              class="form-select"
                              aria-label="Default select example"
                              name="IMTType"
                              value={Policy?.IMTType}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="IMTType"
                            >
                              <option value="" selected>
                                -- Select IMT Type --
                              </option>

                              {IMT_TYPE.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}

                      {
                        <div className="col-md-4 align-items-center">
                          <label
                            style={{ color: isDisabled ? "#808080" : "" }}
                            required
                          >
                            NCB %
                          </label>

                          <input
                            style={{
                              backgroundColor: isDisabled
                                ? "#7b7b7b"
                                : undefined,
                              color: isDisabled ? "#ffffff" : undefined,
                            }}
                            disabled={isDisabled || view}
                            className="form-control text-uppercase"
                            aria-label="Default text example"
                            name="NCB"
                            value={Policy?.NCB}
                            onChange={(e) => {
                              if (e.target.value <= 100) {
                                onChangeSetValue(e);
                              }
                            }}
                            placeholder="ENTER NCB PERCENTAGE"
                          />
                        </div>
                      }

                      <div className="col-md-4 align-items-center">
                        <label>ODD %</label>
                        <input
                          className="form-control text-uppercase"
                          type="number"
                          name="ODDiscount"
                          value={Policy?.ODDiscount}
                          onChange={(e) => {
                            if (e.target.value <= 100) {
                              onChangeSetValue(e);
                            }
                          }}
                          placeholder="Enter  OD Discount"
                        />
                        {/* <CustomMultiSelect /> */}
                      </div>
                      <div className="col-md-3 align-items-center">
                        <label
                          required
                          style={{ color: isDisabled ? "#808080" : "" }}
                        >
                          Branch
                        </label>

                        <select
                          style={{
                            backgroundColor:
                              user?.Branch?.BranchCode !== "0"
                                ? "#7b7b7b"
                                : undefined,
                            color:
                              user?.Branch?.BranchCode !== "0"
                                ? "#ffffff"
                                : undefined,
                          }}
                          disabled={
                            view || PolicyId || user?.Branch?.BranchCode !== "0"
                          }
                          name="Branch"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setPOS(null);
                            onChangeSetValue(e);
                          }}
                          value={Policy?.Branch}
                          required
                        >
                          <option value="">-- Select Branch --</option>
                          {branch &&
                            branch?.length > 0 &&
                            branch.map((item) => (
                              <option key={item?._id} value={item?._id}>
                                {item?.BranchName}
                              </option>
                            ))}
                        </select>
                      </div>
                      {
                        <div
                          className="col-md-4 align-items-center"
                          // onClick={() => getPos()}
                        >
                          <label required>POS Name/Code</label>
                          <AutocompleteComponent
                            placeholder="Select POS"
                            value={POS || null}
                            disabled={
                              user?.userType === "Pos" &&
                              user?.Branch?.BranchCode !== "0"
                            }
                            options={POSOpt || []}
                            getOptionLabel={(option) => option.Name}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value?._id
                            }
                            onChange={(event, newValue) => {
                              setPOS(newValue);
                            }}
                          />
                          {/* <Select
                          // style={{ backgroundColor: "#F5F5F6" }}
                          className="basic-single"
                          classNamePrefix="select"
                          options={POSOpt}
                          noOptionsMessage={() => "No POS Found"}
                          isLoading={POSLoading}
                          // defaultValue={{
                          //   _id:"-- Please Select Variant --"
                          // }}
                          getOptionValue={(option) => option["_id"]}
                          getOptionLabel={(option) => option["Name"]}
                          onChange={setPOS}
                          value={POS}
                        /> */}
                        </div>
                      }

                      <div className="col-md-4 align-items-center">
                        <label>Remarks</label>
                        <input
                          // style={{ backgroundColor: "#F5F5F6" }}
                          disabled={view}
                          className="form-control "
                          type="text"
                          name="Remark"
                          value={Policy?.Remark}
                          onChange={(e) => onChangeSetValue(e)}
                          placeholder="Enter Remark"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="CreateCard p-4">
                <div className="row mx-4 ">
                  <h5>Premium Details</h5>
                  {/* Pos Information */}
                  <hr className="mt-1 " />
                  <div>
                    <div className="row">
                      <div
                        className="col-lg-10"
                        style={{ marginTop: "15px", float: "left" }}
                      >
                        <div
                          className="col-md-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label required>OD Premium</label>
                            <input
                              style={{
                                backgroundColor:
                                  selectedPolicy?.ODApplicable === "No"
                                    ? "#90cbf9"
                                    : "",
                              }}
                              disabled={
                                selectedPolicy?.ODApplicable === "No" || view
                              }
                              className="form-control "
                              type="Number"
                              name="ODPremium"
                              value={Policy?.ODPremium}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="Enter OD Premium "
                              required //done
                              min={0}
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label required>Add-on Premium</label>
                            <input
                              disabled={view || isDisabled}
                              className="form-control "
                              type="Number"
                              name="AddOnPremium"
                              style={{
                                backgroundColor:
                                  isDisabled === true ? "#808080" : "",
                              }}
                              value={Policy?.AddOnPremium}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="Enter Add-on Premium "
                              required //done
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label required>TP Premium</label>
                            <input
                              style={{
                                backgroundColor:
                                  selectedPolicy?.TPApplicable === "No"
                                    ? "#90cbf9"
                                    : "",

                                backgroundColor:
                                  isDisabled === true ? "#808080" : "",
                              }}
                              disabled={
                                selectedPolicy?.TPApplicable === "No" ||
                                view ||
                                isDisabled
                              }
                              className="form-control "
                              type="Number"
                              name="TPPremium"
                              value={Policy?.TPPremium}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="Enter TP Premium"
                              required //done
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label required>PA Premium</label>
                            <input
                              disabled={view || isDisabled}
                              className="form-control "
                              type="Number"
                              name="PAPremium"
                              style={{
                                backgroundColor:
                                  isDisabled === true ? "#808080" : "",
                              }}
                              value={Policy?.PAPremium}
                              onChange={(e) => onChangeSetValue(e)}
                              onBlur={handleBlur}
                              placeholder="Enter PA Premium"
                              required //done
                            />
                            {error && (
                              <div
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "12px",
                                }}
                              >
                                {error}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="col-md-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label required>
                              {" "}
                              {Policy?.LOB === "Motor"
                                ? "LL"
                                : "LL/Terrorism"}{" "}
                              Premium
                            </label>
                            <input
                              style={{
                                backgroundColor:
                                  selectedPolicy?.LLApplicable === "No"
                                    ? "#90cbf9"
                                    : "",
                              }}
                              disabled={
                                selectedPolicy?.LLApplicable === "No" || view
                              }
                              className="form-control "
                              type="Number"
                              name="LLPremium"
                              value={Policy?.LLPremium}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder={`Enter ${
                                Policy?.LOB === "Motor" ? "LL" : "LL/Terrorism"
                              } Premium`}
                              required //done
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label
                              style={{
                                color: isDisabled ? "#808080" : "",
                                fontSize: "15.6px",
                              }}
                            >
                              NET Premium (OD + TP)
                            </label>
                            <input
                              style={{
                                backgroundColor: "#7b7b7b",
                                color: "#ffffff",
                              }}
                              className="form-control "
                              type="Number"
                              name="NETPremium"
                              value={Policy?.NETPremium}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="Enter NET Premium"
                              required //done
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-2 mt-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label
                              style={{ color: isDisabled ? "#808080" : "" }}
                            >
                              GST on OD Prem.{" "}
                            </label>
                            <input
                              style={{
                                backgroundColor: "#7b7b7b",
                                color: "#ffffff",
                              }}
                              className="form-control "
                              type="Number"
                              name="ODPremiumGST"
                              value={Policy?.ODPremiumGST}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="GST Amount"
                              required //done
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-2 mt-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label
                              style={{ color: isDisabled ? "#808080" : "" }}
                            >
                              GST Add-on Prem.
                            </label>
                            <input
                              style={{
                                backgroundColor: "#7b7b7b",
                                color: "#ffffff",
                              }}
                              className="form-control "
                              type="Number"
                              name="AddOnPremiumGST"
                              value={Policy?.AddOnPremiumGST}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="GST Amount"
                              required //done
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-2 mt-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label
                              style={{ color: isDisabled ? "#808080" : "" }}
                            >
                              GST on TP Prem.
                            </label>
                            <input
                              style={{
                                backgroundColor: "#7b7b7b",
                                color: "#ffffff",
                              }}
                              className="form-control "
                              type="Number"
                              name="TPPremiumGST"
                              value={Policy?.TPPremiumGST}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="GST Amount"
                              required //done
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-2 mt-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label
                              style={{ color: isDisabled ? "#808080" : "" }}
                            >
                              GST on PA Prem.
                            </label>
                            <input
                              style={{
                                backgroundColor: "#7b7b7b",
                                color: "#ffffff",
                              }}
                              className="form-control "
                              type="Number"
                              name="PAPremiumGST"
                              value={Policy?.PAPremiumGST}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="GST Amount"
                              required //done
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-2 mt-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label
                              style={{ color: isDisabled ? "#808080" : "" }}
                            >
                              GST on{" "}
                              {Policy?.LOB === "Motor" ? "LL" : "LL/Terrorism"}{" "}
                              Prem.
                            </label>
                            <input
                              style={{
                                backgroundColor: "#7b7b7b",
                                color: "#ffffff",
                              }}
                              className="form-control "
                              type="Number"
                              name="LLPremiumGST"
                              value={Policy?.LLPremiumGST}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="GST Amount"
                              required //done
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-2 mt-2 align-items-center"
                          style={{ float: "left" }}
                        >
                          <div className="col-md-11">
                            <label
                              style={{ color: isDisabled ? "#808080" : "" }}
                            >
                              Total GST Amount
                            </label>
                            <input
                              style={{
                                backgroundColor: "#7b7b7b",
                                color: "#ffffff",
                              }}
                              className="form-control "
                              type="Number"
                              name="TotalGSTAmount"
                              value={Policy?.TotalGSTAmount}
                              onChange={(e) => onChangeSetValue(e)}
                              placeholder="GST Amount"
                              required //done
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 mt-2" style={{ float: "left" }}>
                        <div
                          className="col-md-12 align-items-center"
                          style={{ height: "78px" }}
                        >
                          &nbsp;
                        </div>
                        <div className="col-md-12 align-items-center">
                          <label style={{ color: isDisabled ? "#808080" : "" }}>
                            Gross Premium (with GST)
                          </label>
                          <input
                            style={{
                              backgroundColor: "#7b7b7b",
                              color: "#ffffff",
                            }}
                            className="form-control "
                            type="Number"
                            name="GrossPremium"
                            value={Policy?.GrossPremium}
                            onChange={(e) => onChangeSetValue(e)}
                            placeholder="Gross Premium"
                            required //done
                            disabled
                          />
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* // payment mode */}
              <div className="row mx-5">
                <div className="col-md-2 align-items-center">
                  <label>Payment Mode</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="PaymentMode"
                    value={Policy?.PaymentMode}
                    onChange={(e) => onChangeSetValue(e)}
                    placeholder="Payment Mode"
                  >
                    <option value="">---- Payment Mode ---</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Online">Online</option>
                    <option value="Cash">Cash</option>
                    <option value="CDT">CDT</option>
                  </select>
                </div>

                {Policy?.PaymentMode === "Cheque" && (
                  <>
                    <div className="col-md-3 align-items-center">
                      <label>Bank Name</label>
                      <input
                        // style={{ backgroundColor: "#F5F5F6" }}
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Bank"
                        value={Policy?.Bank}
                        onChange={(e) => onChangeSetValue(e)}
                        placeholder="Enter Bank Name"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Branch</label>
                      <input
                        // style={{ backgroundColor: "#F5F5F6" }}
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="BankBranch"
                        value={Policy?.BankBranch}
                        onChange={(e) => onChangeSetValue(e)}
                        placeholder="Enter Branch"
                      />
                    </div>
                    <div className="col-md-2 align-items-center">
                      <label>Cheque Date</label>
                      <input
                        // style={{ backgroundColor: "#F5F5F6" }}
                        disabled={view}
                        className="form-control"
                        type="date"
                        name="ChequeDate"
                        value={Policy?.ChequeDate}
                        onChange={(e) => onChangeSetValue(e)}
                        placeholder="Enter ChequeDate"
                      />
                    </div>
                  </>
                )}

                {(Policy?.PaymentMode === "Cheque" ||
                  Policy?.PaymentMode === "Online" ||
                  Policy?.PaymentMode === "Cash" ||
                  Policy?.PaymentMode === "CDT") && (
                  <div className="col-md-2 align-items-center">
                    <label>Amount</label>
                    <input
                      type="number"
                      name="Amount"
                      value={Policy.Amount}
                      onChange={(e) => onChangeSetValue(e)}
                      className="form-control"
                      placeholder="Enter Amount"
                    />
                  </div>
                )}
              </div>

              <div
                className="CreateCard p-4"
                style={{
                  zoom: "90%",
                }}
              >
                <div className="row mx-4 ">
                  <h5>Policy Document Upload </h5>
                  {/* Pos Information */}
                  <hr className="mt-1 " />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <FileUploadModal
                      filedName="NewPolicyCopy"
                      id={value?._id}
                      deleteUrl={DELETE_URL}
                      index={"1"}
                      Mode={Mode}
                      Url={value?.NewPolicyCopy ?? ""}
                      setModalData={setModalData}
                      handleOpen={handleOpen}
                      view={view}
                      setChangeHandler={handleUploadPolicyCopy}
                      Name={
                        <span>
                          Policy Copy <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      accept=".pdf"
                      required
                    />
                    <FileUploadModal
                      filedName="RC1"
                      id={value?._id}
                      deleteUrl={DELETE_URL}
                      index={"2"}
                      Mode={Mode}
                      Url={value?.RC1 ?? ""}
                      setModalData={setModalData}
                      handleOpen={handleOpen}
                      view={view}
                      setChangeHandler={setRC1}
                      Name={
                        Policy?.LOB === "Motor" || Policy?.LOB === "" ? (
                          "First RC"
                        ) : (
                          <>
                            Mandate Letter
                            {Policy?.MandateLetter === "Yes" && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </>
                        )
                      }
                    />
                    <FileUploadModal
                      filedName="RC2"
                      id={value?._id}
                      deleteUrl={DELETE_URL}
                      index={"3"}
                      Mode={Mode}
                      Url={value?.RC2 ?? ""}
                      setModalData={setModalData}
                      handleOpen={handleOpen}
                      view={view}
                      setChangeHandler={setRC2}
                      Name={
                        Policy?.LOB === "Motor" || Policy?.LOB === ""
                          ? "Second RC"
                          : "Other Docs."
                      }
                    />
                    {!isDisabled && (
                      <FileUploadModal
                        filedName="PreviousPolicy1"
                        id={value?._id}
                        deleteUrl={DELETE_URL}
                        index={"4"}
                        Mode={Mode}
                        Url={value?.PreviousPolicy1 ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setPreviousPolicy1}
                        Name="Previous Policy 1"
                      />
                    )}
                    {!isDisabled && (
                      <FileUploadModal
                        filedName="PreviousPolicy2"
                        id={value?._id}
                        deleteUrl={DELETE_URL}
                        index={"6"}
                        Mode={Mode}
                        Url={value?.PreviousPolicy2 ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setPreviousPolicy2}
                        Name="Previous Policy 2"
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>

            <div
              className="d-flex gap-3 my-2 px-6"
              style={{ justifyContent: "space-between" }}
            >
              <div>
                <button
                  className="btn mybtn"
                  style={{ width: "100px" }}
                  onClick={() => navigate("/admin/Policy/data")}
                >
                  Back
                </button>
              </div>

              <div>
                {!view && (
                  <button
                    // style={{ marginLeft: "40%" }}
                    type="submit"
                    className="btn mybtn"
                    // style={{ marginTop: 28 }}
                    onClick={(e) => {
                      PolicyId ? handleEdit(e) : handleCreate(e);
                    }}
                    disabled={error !== "" || BtnDisable !== ""}
                  >
                    Submit
                  </button>
                )}
              </div>

              <div style={{ marginRight: "20px" }}>
                {PolicyId && (
                  <Link
                    className="btn "
                    style={{
                      backgroundColor: "rgb(227 192 0)",
                      color: "#FFF",
                      // marginLeft: "40%",
                    }}
                    to={`/admin/view/Policy/${PolicyId}`}
                  >
                    View
                  </Link>
                )}
                &nbsp; &nbsp;
                {PolicyId && (
                  <button
                    className="btn mybtn"
                    style={{
                      backgroundColor: "#d93535",
                      color: "#FFF",
                    }}
                    onClick={() => {
                      const isTrue = window.confirm("Do you want to cancel!");
                      if (isTrue) {
                        cancelPolicy(PolicyId, "CANCELLED");
                      }
                    }}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>{" "}
      <Footer />
    </>
  );
};

export default CreatePolicy;
