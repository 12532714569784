import React, { useState, useEffect } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import * as XLSX from "xlsx";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  validateHeadersExcelPayout,
} from "../../lib/validateExcel";
import "./styles.css";
import { axiosInstance, REACT_APP_FileURL } from "../../config";

const BackgroundImportPayoutGrid = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [headerPass, setHeaderPass] = useState(false);
  const [jobId, setJobId] = useState();
  const [headers, setHeaders] = useState([]);
  const [file, setFile] = useState(null)
  const [insertedCount, setInsertedCount] = useState(null);
  const [bgColor, setBgColor] = useState("");

  const parseExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = jsonData[0];
      const response = validateHeadersExcelPayout(headers);

      if (!response?.valid) {
        setError(response);

        return;
      } else {
        setError(null)
        setHeaderPass(true);
      }
    };
    reader.readAsArrayBuffer(file);
    setFile(file)
  };

  const handleFileUpload = (event) => {
    try {
      setLoading(true);
      const file = event.target.files[0];
      const fileType = file ? file.name.split(".").pop().toLowerCase() : "";

      if (fileType === "xls" || fileType === "xlsx") {
        parseExcel(file);
      } else {
        alert("Please upload a XLSX, or XLS file.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const savedJobId = localStorage.getItem("currentJobId");
    if (savedJobId) {
      setJobId(savedJobId);
      fetchCurrentProgress(savedJobId);
      startProgressPolling(savedJobId);
    }

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const fetchCurrentProgress = async (jobId) => {
    try {
      const { data: progressData } = await axiosInstance.get(`/Common/job-progress/${jobId}`);
      const progress = progressData.progress;
      const progressBar = document.getElementById('progressBar');
      const progressText = document.getElementById('progressText');
      if (progressBar) {
        progressBar.style.width = `${progress}%`;
        progressBar.innerText = `${progress}%`;
      }
      if (progressText) {
        progressText.innerText = `${progress}%`;
      }
    } catch (error) {
      localStorage.removeItem("currentJobId");
      console.error("Error fetching current progress:", error);
    }
  };

  const startProgressPolling = (jobId) => {
    const progressBar = document.getElementById('progressBar');
    const progressContainer = document.getElementById('progressContainer');
    const progressText = document.getElementById('progressText');
    const progressHeading = document.getElementById('heading');
    const interval = setInterval(async () => {
      try {
        const { data: progressData } = await axiosInstance.get(`/Common/job-progress/${jobId}`);
        const progress = progressData.progress;
        if (progress === 0) {

          progressHeading.innerText = "Progress";
        } else if (progress === 100) {
          progressHeading.innerText = "Completed";
        } else if (progress > 0 && progress < 100) {

          progressHeading.innerText = "Validating";
        }
        if (progressBar) {
          progressBar.style.width = `${progress}%`;
          progressBar.innerText = `${progress}%`;
        }
        if (progressText) {
          progressText.innerText = `${progress}%`;
        }

        const { data: errorData } = await axiosInstance.get(`/Common/Common/job-error/${jobId}`);

        const errorMessage = errorData.error;

        if (errorMessage) {

          clearInterval(interval);
          setError({ message: errorMessage });
          progressContainer.style.backgroundColor = 'red';
          return;
        }

        if (progress >= 100) {
          clearInterval(interval);
          ToastSusses(`Job ${jobId} complete!`);
          setFile(null);
          localStorage.removeItem("currentJobId");
        }
      } catch (error) {
        console.error("Error fetching job progress:", error);
        localStorage.removeItem("currentJobId");
        clearInterval(interval);
      }
    }, 5000);
  };

  const importPayoutGrid = async () => {
    try {
      setLoading(true);
      if (!file) {
        ToastError("File not found!");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
 
      const { data: { success, message, jobId, errors } } = await axiosInstance.post("/PayoutGrid/importPayoutGrid", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (success) {
        setJobId(jobId);
        localStorage.setItem("currentJobId", jobId);
        startProgressPolling(jobId);
        ToastSusses(`${message}, Job ID: ${jobId}`);
      } else {

        setError({ message, errors });
        ToastError(`Validation failed: ${message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      setError({ message: error?.response?.data?.message || "An unexpected error occurred." });
      localStorage.removeItem("currentJobId");
      ToastError("An error occurred while processing the file.");
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateDownload = (event) => {
    event.preventDefault();

    const fileUr = `${REACT_APP_FileURL}/images/Template/PayoutGrid-Template.xlsx`;
    window.open(fileUr, "_blank");
  };

  return (
    <>
      <Header />
      <div className="MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="ListDesigncol">
                        <h4 className="card-title">Import Payout Grid</h4>
                      </div>
                      <button
                        onClick={handleTemplateDownload}
                        className="btn mybtn"
                      >
                        Download Template
                      </button>
                    </div>
                  </div>

                  {error && error?.data && error?.data?.length !== 0 && (
                    <div>
                      <h3>Preview Error Data</h3>
                      <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <thead>
                          <tr>
                            {headers &&
                              headers.length > 0 &&
                              headers.map((header, index) => (
                                <th key={index}>{header}</th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {error?.data.map((data, rowIndex) => (
                            <tr key={rowIndex}>
                              {headers.map((item, colIndex) => {
                                let hasError;
                                if (error && error?.errorList) {
                                  hasError = error?.errorList.find(
                                    (err) => err?.fieldValue === data?.row[item]
                                  );
                                }
                                return (
                                  <td key={colIndex}>
                                    {data?.row[item]}
                                    {hasError && hasError?.fieldValue === data?.row[item] && (
                                      <span style={{ color: "red" }}> ({hasError?.row?.errMsg})</span>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {insertedCount !== null && (
                    <div
                      style={{
                        backgroundColor: bgColor,
                        width: "80vw",
                        height: "auto",
                        padding: "20px",
                        fontSize: "1rem",
                        margin: "20px auto",

                      }}
                    >
                      {insertedCount} Records Inserted
                    </div>
                  )}
                  <div className="container">
                    <div>
                      <div className="upload-container">
                        <input
                          type="file"
                          id="file_upload"
                          accept=".xlsx, .xls"

                          onChange={handleFileUpload}
                        />
                      </div>
                      <div className="mt-4" style={{ textAlign: "center" }}>
                        <button
                          className="btn btn-primary"
                          color="warning"
                          type="button"
                          disabled={loading || error || !file || !headerPass}
                          // disabled={loading || fileData.length === 0}
                          onClick={importPayoutGrid}
                        >
                          {loading ? "In Progress..." : "Import"}
                        </button>
                      </div>
                    </div>
                    {/* Progress Bar */}


                    <div style={{ marginTop: '10px' }}>
                      <h5 id="heading" style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: '10px' }}>Progress</h5>
                      <div id="progressContainer" style={{
                        width: '100%',
                        backgroundColor: '#e0e0e0',
                        borderRadius: '20px',
                        height: '20px',
                        overflow: 'hidden',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      }}>
                        <div
                          id="progressBar"
                          style={{
                            width: '0%',
                            height: '100%',
                            backgroundColor: '#4caf50',
                            borderRadius: '20px',
                            transition: 'width 0.3s ease, background-color 0.3s ease',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '14px',
                          }}
                        >
                          0%
                        </div>
                      </div>
                      <p id="progressText" style={{ textAlign: 'center', marginTop: '10px', display: "none", fontWeight: 'bold', color: '#333' }}>0%</p>
                    </div>

                    {/* error message */}

                    {error && (
                      <div style={{
                        backgroundColor: "#f8d7da",
                        marginTop: "10px",
                        border: "1px solid #f5c6cb",
                        color: "#721c24",
                        padding: "5px",
                        borderRadius: "5px",
                        fontFamily: "Arial, sans-serif",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                      }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginRight: "10px"
                          }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 14h-2v-2h2v2zm0-4h-2V7h2v5z" fill="red" />
                            </svg>
                          </span>

                          <h4 style={{
                            margin: "0",
                            fontSize: "20px",
                            fontWeight: "bold"
                          }}>Error:</h4>
                        </div>
                        <p style={{
                          marginTop: "10px",
                          fontSize: "16px"

                        }}>{error.message}</p>
                        {/* Missing Headers Section */}
                        {error.missingHeaders && error.missingHeaders.length > 0 && (
                          <div>
                            <ul style={{ paddingLeft: "2px", marginTop: "5px" }}>
                              {error.missingHeaders.map((header, index) => (
                                <li key={index} style={{ marginBottom: "5px", fontWeight: "bold" }}>
                                  {header}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {error.data && error.data.length > 0 && (
                          <ul style={{ paddingLeft: "20px", marginTop: "10px" }}>
                            {error.data.map((err, index) => (
                              <li key={index} style={{ marginBottom: "5px" }}>
                                <strong>Row {err.id}:</strong> {err.errMsg}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default BackgroundImportPayoutGrid;
