import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import { TablePagination } from "@mui/material";
import AutocompleteComponent from "../../Utils/AutoCompleteComponent";
import Header from "../Header/Header";
import { FiRefreshCcw } from "react-icons/fi";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import {
  convertDateStringToDate,
  coverAmountIntoCurrent,
} from "../../Utils/usefullFunction";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import { FaEye } from "react-icons/fa";
import { MdOutlineRefresh } from "react-icons/md";
import Test from "../../Utils/Test";
import Tooltip from "../utils/Tooltip/Tooltip";
import DateRange from "../DateRangeModule/DateRange";
import useGettingData from "../Hooks/useGettingData";
import { useDispatch, useSelector } from "react-redux";
import PolicyTab from "../../components/PolicyTab";
import MultiInsuranceTypeFilter from "../../Utils/MultiInsuranceTypeFilter";
import { ToastError } from "../../Utils/Tost";

const dateFilterCombo = [
  { label: "Issue Date", value: "IssueDate" },
  { label: "Start Date (RSD)", value: "RSD" },
];

const Policy = ({ showBtn = true }) => {
  const dispatch = useDispatch();
  const {
    Policy,
    TabsValue,
    PayoutCycle,
    InsuranceCompany,
    Broker,
    POS,
    EnteredBy,
    Branch,
    pagination,
    rowsPerPage,
    SavedData,
    SavedTotalDocs,
    Status,
    Premium,
    SelectedInsuranceType,
  } = useSelector((store) => store.PolicyFilters);

  const { user } = useSelector((store) => store.user);
  const today = new Date();
  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [data, setData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const {
    getData: getInsuranceCompany,
    Loading: CompanyLoading,
    Options: InsuranceCompanyOpt,
  } = useGettingData("/InsuranceCompany", [
    {
      _id: "",
      AliasName: "-- Select Company --",
    },
  ]);

  let {
    getData: getBranch,
    Loading: BranchLoading,
    Options: BranchOpt = [
      {
        _id: "",
        BranchName: "-- Select Branch --",
      },
      user?.Branch,
    ],
  } = useGettingData("/Policy/getPolicyBranch", [
    {
      _id: "",
      BranchName: "-- Select Branch --",
    },
  ]);
  const [LOB, setLOB] = useState("");
  const [inputData, setInput] = useState("");
  const [POSOpt, setPOSOpt] = useState([]);
  const [EnteredByOpt, setEnteredByOpt] = useState([]);
  const [loading, setLoading] = useState(false);
  const [IsFirstRendering, setIsFirstRendering] = useState(true);
  const [totalDocs, setTotalDocs] = useState(0);
  const totalPages = Math.ceil(totalDocs / rowsPerPage);
  const pageNumbersToShow = 6;

  const calculatePageNumbers = () => {
    let start = Math.max(0, pagination - Math.floor(pageNumbersToShow / 2));
    let end = Math.min(totalPages, start + pageNumbersToShow);

    if (pagination >= totalPages - 2) {
      start = Math.max(0, totalPages - pageNumbersToShow);
      end = totalPages;
    }

    if (end - start < pageNumbersToShow) {
      start = Math.max(0, end - pageNumbersToShow);
    }

    let pages = Array.from({ length: end - start }, (_, index) => start + index);

    if (pages[0] !== 0) pages.unshift(0);

    if (pages[pages.length - 1] !== totalPages - 1) pages.push(totalPages - 1);

    const middlePage = Math.floor(totalPages / 2);
    if (!pages.includes(middlePage)) pages.push(middlePage);

    pages.sort((a, b) => a - b);

    let finalPages = [];
    for (let i = 0; i < pages.length; i++) {
      finalPages.push(pages[i]);
      if (i < pages.length - 1 && pages[i] + 1 !== pages[i + 1]) {
        finalPages.push('........');
      }
    }

    if (!finalPages.includes(0)) finalPages.unshift(0);
    if (!finalPages.includes(totalPages - 1)) finalPages.push(totalPages - 1);

    return finalPages;
  };

  const pageNumbers = calculatePageNumbers();

  const handlePageClick = (pageNumber) => {
    if (pageNumber !== '........') {
      handleChangePage(null, pageNumber);
    }
  };

  const handleRowsPerPageChange = (event) => {
    handleChangeRowsPerPage(event);
  };

  const handleChangePage = (event, newPage) => {
    onSetPolicyFilter("pagination", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    onSetPolicyFilter("rowsPerPage", parseInt(event.target.value));
    onSetPolicyFilter("pagination", 0);
  };

  const filterDataHandler = async ({
    EraserHandler = false,
    StopLoading = false,
  }) => {
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");

    try {
      !StopLoading && setLoading(true);
      if (!user) return;

      let que = {};
      if (InsuranceCompany["_id"]) {
        que["InsuranceCompany"] = InsuranceCompany["_id"];
      }
      if (POS && POS["_id"]) {
        que["POS"] = POS["_id"];
      }

      if (EnteredBy && EnteredBy["_id"]) {
        que["EnteredBy"] = EnteredBy["_id"];
      }

      if (PayoutCycle) {
        que["PayoutCycle"] = PayoutCycle;
      }
      if (Branch["_id"]) {
        que["Branch"] = Branch["_id"];
      }

      if (POS && POS["_id"]) {
        que["POS"] = POS["_id"];
      } else if (user?.userType === "Pos" && user?.Branch?.BranchCode !== "0") {
        que["POS"] = user["_id"];
      }

      if (!EraserHandler) {
        que = {
          ...que,
          ...Policy,
          InsuranceType: SelectedInsuranceTypeData,
        };
      } else {
        que = {};
        que["startDate"] = firstDay;
        que["endDate"] = lastDay;
        que["PayoutCycle"] = "";
        que["InsuranceType"] = [];
      }
      const {
        data: { data, totalDocs },
      } = await axiosInstance.post("/Policy/getPolicyByFilter", {
        page: pagination + 1,
        limit: rowsPerPage,
        TabsValue: TabsValue,
        inputData,
        ...que,
      });
      setTotalDocs(totalDocs);
      setData(data);
      onSetPolicyFilter("SavedData", data);
      onSetPolicyFilter("SavedTotalDocs", totalDocs);
      getPremium({ que });
      getStatus({ que });

      !StopLoading && setLoading(false);
    } catch (error) {
      ToastError(error.response.data.message)
      setLoading(false);
    }
  };

  const getPremium = async ({ que = {} }) => {
    try {
      const {
        data: { Premium },
      } = await axiosInstance.post("/Policy/getPolicyPremium", {
        page: pagination + 1,
        limit: rowsPerPage,
        TabsValue: TabsValue,
        inputData,
        ...que,
      });
      onSetPolicyFilter("Premium", Premium[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getStatus = async ({ que = {} }) => {
    try {
      const {
        data: { StatusCount },
      } = await axiosInstance.post("/Policy/getPolicyStatus", {
        page: pagination + 1,
        limit: rowsPerPage,
        TabsValue: "ALL",
        inputData,
        ...que,
      });
      onSetPolicyFilter("Status", StatusCount);
    } catch (error) { }
  };

  const getPolicyPos = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Pos/getPosByField", {
        branch: Branch?._id,
        showFiled: {
          Code: 1,
          AliasName: 1,
          Name: 1,
          Salutation: 1,
          MiddleName: 1,
          LastName: 1,
          _id: 1,
        },
      });
      const POS = data.map(
        ({
          Code,
          AliasName,
          Name = "",
          Salutation = "",
          MiddleName = "",
          LastName = "",
          _id,
        }) => {
          return {
            Name: `${Code}- ${AliasName || ""} (${Name || ""} ${MiddleName || ""
              } ${LastName || ""})`,
            _id,
          };
        }
      );
      setPOSOpt(POS);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getEmployeesByBranch = async (branchId) => {
    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Policy/getEmployeesByBranch", {
        branchId,
      });

      const Employee = data.map(
        ({ Name = "", MiddleName = "", LastName = "", _id }) => {
          return {
            Name: `${Name || ""} ${MiddleName || ""} ${LastName || ""}`,
            _id,
          };
        }
      );
      setEnteredByOpt(Employee);
    } catch (error) {
      console.log("Error fetching employees by branch:", error);
    }
  };
  useEffect(() => {
    if (Branch?._id) {
      getPolicyPos();
      getEmployeesByBranch(Branch?._id);
    }
  }, [Branch]);

  const filterDataHandlerForExcel = async (page, pageSize) => {
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");
    if (isChecked && !InsuranceCompany._id) {
      return;
    }
    try {
      let que = {};
      if (InsuranceCompany["_id"]) {
        que["InsuranceCompany"] = InsuranceCompany["_id"];
      }
      if (POS && POS["_id"]) {
        que["POS"] = POS["_id"];
      }
      if (EnteredBy && EnteredBy["_id"]) {
        que["EnteredBy"] = EnteredBy["_id"];
      }
      if (Broker["_id"]) {
        que["Broker"] = Broker["_id"];
      }
      if (Branch["_id"]) {
        que["Branch"] = Branch["_id"];
      }
      if (PayoutCycle) {
        que["PayoutCycle"] = PayoutCycle;
      }
      if (SelectedInsuranceTypeData.length > 0) {
        que["InsuranceType"] = SelectedInsuranceTypeData;
      }
      que["ClusterCalculations"] = isChecked ? "Yes" : "No";

      const {
        data: { data },
      } = await axiosInstance.post("/Policy/getPolicyByFilterForExcel", {
        ...Policy,
        filterOn: Policy.filterOn,
        LOB: Policy?.LOB || "",
        EnteredBy: EnteredBy || "",
        TabsValue: TabsValue,
        pageSize,
        page,
        inputData,
        user: { _id: user?._id, branchCode: user?.Branch?.BranchCode },
        ...que,
      });
      return data;
    } catch (error) {
      setLoading(false);
    }
  };

  const EraserHandler = async () => {
    dispatch({
      type: "setPolicyFilterDefaultValue",
    });
    setInput("");
    filterDataHandler({ EraserHandler: true });
    const reSetData = { data: SelectedInsuranceType[0]["data"], value: "" };
    onSetPolicyFilter("SelectedInsuranceType", [reSetData]);
  };

  useEffect(() => {
    if (IsFirstRendering) {
      setIsFirstRendering(false);
      if (SavedData.length > 0) {
        setData(SavedData);
        setTotalDocs(SavedTotalDocs);
      } else {
        filterDataHandler({});
      }
    } else {
      filterDataHandler({
        notCallOther: false,
      });
    }
  }, [pagination, rowsPerPage, TabsValue]);

  useEffect(() => {
    if (SelectedInsuranceType.length === 0 || LOB) {
      callInsuranceData();
    }
  }, [LOB]);

  const onChangeSetValue = (e) => {
    onSetPolicyFilter("Policy", {
      ...Policy,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (IsFirstRendering) {
      return;
    }
    const timer = setTimeout(
      () => filterDataHandler({ EraserHandler: false, StopLoading: true }),
      1000
    );
    return () => clearTimeout(timer);
  }, [inputData]);

  const setDateHandler = (ranges) => {
    onSetPolicyFilter("Policy", {
      ...Policy,
      startDate: convertDateStringToDate(ranges.startDate),
      endDate: convertDateStringToDate(ranges.endDate),
    });
  };

  const onSetPolicyFilter = (key, value) => {
    dispatch({
      type: "updatePolicyFilters",
      key: key,
      value: value,
    });
  };

  useEffect(() => {
    if (
      user &&
      user?.userType &&
      user?.Branch?.BranchCode !== "0" &&
      user?.userType === "Pos"
    ) {
      onSetPolicyFilter("POS", user);
    }
  }, [user]);

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get(
          `/InsuranceType?Root=true&InsuranceCategory=${LOB}`
        );
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) { }
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]
            }&InsuranceCategory=${LOB}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) { }
      }

      return onSetPolicyFilter("SelectedInsuranceType", [
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/InsuranceType?Root=true&InsuranceCategory=${LOB}`
      );
      onSetPolicyFilter("SelectedInsuranceType", [{ data, value: "" }]);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    getBranch();
    getInsuranceCompany();
  }, []);

  return (
    <div>
      <Header />
      <div
        className="container-fluid"
        style={{ marginTop: "20px", outline: "none" }}>
        <div className="row CreateCard p-4">
          <div className=" p-2 container filters" style={{}}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "baseline",
                flexDirection: "row",
                gap: "10px",
              }}>
              <SearchInputFilter
                setInput={setInput}
                inputData={inputData}
                PlaceHolder="Policy No.,Vehicle No.,Insuree Name*"
              />

              <div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={InsuranceCompanyOpt}
                  noOptionsMessage={() => "No Company Found"}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["AliasName"]}
                  onChange={(ele) => {
                    onSetPolicyFilter("InsuranceCompany", ele);
                  }}
                  value={InsuranceCompany}
                  isLoading={CompanyLoading}
                  styles={{ height: "42px" }}
                />
              </div>
              <div
                className=""
              >
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={BranchOpt || []}
                  isLoading={BranchLoading}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["BranchName"]}
                  value={Branch}
                  onChange={(ele) => {
                    onSetPolicyFilter("Branch", ele);
                  }}
                  styles={{ height: "42px" }}
                />
              </div>
              <div style={{ position: "relative", top: "-9px" }}>
                <AutocompleteComponent
                  placeholder="------------ Select POS -----------"
                  styles={{ width: "400px" }}
                  disabled={
                    user?.userType === "Pos" && user?.Branch?.BranchCode !== "0"
                  }
                  value={POS || null}
                  options={POSOpt || []}
                  getOptionLabel={(option) => option.Name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  }
                  onChange={(event, newValue) =>
                    onSetPolicyFilter("POS", newValue)
                  }
                />
              </div>

              <div style={{ position: "relative", top: "-9px" }}>
                <AutocompleteComponent
                  placeholder="-- Select EnteredBy --"
                  styles={{ width: "230px" }}
                  disabled={
                    user?.userType === "Pos" && user?.Branch?.BranchCode !== "0"
                  }
                  value={EnteredBy || null}
                  options={EnteredByOpt || []}
                  getOptionLabel={(option) => option.Name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  }
                  onChange={(event, newValue) =>
                    onSetPolicyFilter("EnteredBy", newValue)
                  }
                />
              </div>
            </div>
            <div className="d-flex justify-content-between pt-2 align-items-center">
              <div className="d-flex gap-2 ">
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div>
                    <input
                      className="form-control"
                      style={{ width: "120px" }}
                      value="Create Date"
                      readonly
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="date"
                      name="startCreatedAtDate"
                      style={{
                        height: "42px",
                        maxWidth: "140px",
                      }}
                      value={Policy?.startCreatedAtDate}
                      onChange={(e) => onChangeSetValue(e)}
                      max={new Date().toISOString().split("T")[0]}
                      required //done
                    />
                  </div>
                  <p>-</p>
                  <div>
                    <input
                      className="form-control "
                      type="date"
                      name="endCreatedAtDate"
                      style={{
                        height: "42px",
                        maxWidth: "140px",
                      }}
                      value={Policy?.endCreatedAtDate}
                      onChange={(e) => onChangeSetValue(e)}
                      max={new Date().toISOString().split("T")[0]}
                      required //done
                    />
                  </div>

                  <div className="">
                    <select
                      class="form-select SelectBoxWith"
                      aria-label="Default select example"
                      name="filterOn"
                      value={Policy?.filterOn}
                      onChange={(e) => onChangeSetValue(e)}
                      required
                      styles={{ height: "42px" }}
                    >
                      {dateFilterCombo &&
                        dateFilterCombo.map((data) => (
                          <option key={data.value} value={data.value}>
                            {data.label}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <input
                      className="form-control "
                      type="date"
                      name="startDate"
                      style={{ height: "42px" }}
                      value={Policy?.startDate}
                      onChange={(e) => onChangeSetValue(e)}
                      required
                    />
                  </div>
                  <p>-</p>
                  <div>
                    <input
                      className="form-control "
                      type="date"
                      name="endDate"
                      style={{ height: "42px" }}
                      value={Policy?.endDate}
                      onChange={(e) => onChangeSetValue(e)}
                      required
                    />
                  </div>
                </div>
                <div>
                  <DateRange setDateHandler={setDateHandler} />
                </div>
              </div>
              <div>
                <Tooltip content="Refresh">
                  <FiRefreshCcw
                    size={32}
                    onClick={() => filterDataHandler({})}
                    style={{
                      backgroundColor: "#174379",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "5px",
                      marginTop: "-5px",
                    }}
                  />{" "}
                </Tooltip>
                &nbsp;
                <Link>
                  <Tooltip content="Reset">
                    <MdOutlineRefresh
                      onClick={() => EraserHandler()}
                      size={32}
                      style={{
                        backgroundColor: "#f99d36",
                        color: "#fff",
                        padding: "5px",
                        borderRadius: "5px",
                        marginTop: "-5px",
                      }}
                    />
                  </Tooltip>
                </Link>
              </div>
            </div>
            <div className="d-flex pt-2 align-items-center gap-2">
              <div className="">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="LOB"
                  value={LOB}
                  onChange={(e) => {
                    onSetPolicyFilter("SelectedInsuranceType", [
                      { data: [], value: "" },
                    ]);
                    onChangeSetValue(e);
                    setLOB(e.target.value);
                  }}
                  required
                >
                  <option value="">All</option>
                  <option value="Motor">Motor</option>
                  <option value="Non-Motor">Non-Motor</option>
                </select>
              </div>
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                {SelectedInsuranceType &&
                  SelectedInsuranceType?.length > 0 &&
                  SelectedInsuranceType.map(
                    (data, index) =>
                      data &&
                      data?.data?.length > 0 && (
                        <MultiInsuranceTypeFilter
                          key={data.value}
                          Options={data}
                          setSelectedInsuranceType={onSetPolicyFilter}
                          SelectedInsuranceType={SelectedInsuranceType}
                          index={index}
                          OptionsValue={data.value}
                        />
                      )
                  )}
              </div>
            </div>
          </div>
          <div className="p-2 container d-flex justify-content-between align-items-right mt-0">

            <div style={{ flex: 1, display: "flex", justifyContent: "right" }}>
              <ul className="nav nav-pills shadow-sm" style={{ display: "flex", alignItems: "right" }}>
                <li className="nav-item nav-link" style={{ color: "#295394" , fontWeight: 700 }}>
                  {`${coverAmountIntoCurrent(Premium?.GrossPremium)}`}
                  <span> Gross Premium</span>
                </li>

                <li className="nav-item nav-link" style={{ color: "#295394" , fontWeight: 700}}>
                  {`${coverAmountIntoCurrent(Premium?.NetPremium)} `}
                  <span>Net Premium</span>
                </li>
              </ul>
            </div>

            <div
              className="flex-right-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "auto",
              }}
            >
              {user?.Branch?.BranchCode === "0" && showBtn && (
                <>
                  <label
                    style={{
                      marginRight: "10px",
                      textAlign: "center",
                      fontSize: "small",
                      marginLeft: "15px",
                    }}
                    htmlFor="checkbox"
                  >
                    Rate Calculation
                  </label>
                  <div
                    style={{
                      marginRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </>
              )}

              <Test
                fileName={`Policy-${Policy?.startDate}-To-${Policy?.endDate}`}
                filterDataHandlerForExcel={filterDataHandlerForExcel}
              />
              &nbsp;
              {showBtn && (
                <Link
                  className="btn mybtn"
                  to="/admin/Policy"
                  style={{ fontSize: "14px" }}
                >
                  Enter Policy Details
                </Link>
              )}
              &nbsp;
              {showBtn && (
                <Link
                  to="/admin/import-policy/data"
                  className="btn mybtn"
                  style={{ fontSize: "14px" }}
                >
                  Import Policy
                </Link>
              )}
            </div>
          </div>

          <div
            className="container-fluid"
            style={{ borderTop: "1px solid #ddd" }}
          >
            <div className="row">
              <div
                className=" p-2 container d-flex justify-content-center align-items-center"
                style={{
                  height: "1px",
                }}
              >
                <div className="col-lg-12" style={{ float: "left" }}>
                  <PolicyTab
                    TabsValue={TabsValue}
                    Status={Status}
                    onChangeFilter={onSetPolicyFilter}
                  />
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div
              className=" p-2 container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <TableSkeleton />
            </div>
          ) : (
            <div
              className=" p-2 container"
              style={{
                zoom: "80%",
              }}
            >
              {data && (
                <table class="table table-striped table-bordered table-responsive overflow-x mt-0">
                  <thead>
                    <tr style={{ background: "#174379", color: "#fff" }}>
                      <th style={{ textAlign: "right" }}>SNo.</th>

                      <th style={{ textAlign: "left", width: "120px" }}>
                        Insurer
                      </th>
                      <th style={{ textAlign: "left" }}>Control No.</th>
                      <th style={{ textAlign: "left" }}>Policy Number</th>
                      <th style={{ textAlign: "left" }}>Insured Name</th>
                      <th style={{ textAlign: "left" }}>POS</th>
                      <th style={{ textAlign: "left", width: "105px" }}>
                        Vehicle No.
                      </th>
                      <th style={{ textAlign: "right", width: "115px" }}>
                        Net Premium
                      </th>
                      <th style={{ textAlign: "right", width: "130px" }}>
                        Gross Premium
                      </th>
                      <th style={{ textAlign: "left" }}>Branch</th>
                      <th style={{ textAlign: "left", width: "105px" }}>
                        Issue Date
                      </th>
                      <th style={{ textAlign: "left", width: "105px" }}>
                        Created At
                      </th>
                      {showBtn && (
                        <th style={{ textAlign: "center" }}>Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "15px" }}>
                    {data &&
                      data?.length !== 0 &&
                      data.map((data, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center" }} scope="row">
                            {index + 1}
                          </td>
                          <td>
                            {" "}
                            <tr className="d-flex">
                              {" "}
                              <img
                                src={`${REACT_APP_FileURL}${data?.InsuranceCompany?.Logo}`}
                                style={{ width: "3vw", borderRadius: "10px" }}
                                loading="lazy"
                                alt=""
                              />
                              &nbsp;&nbsp;{" "}
                              <div>
                                <div> {data?.InsuranceCompany?.AliasName}</div>
                              </div>
                            </tr>
                          </td>
                          <td>

                            {data?.ControlNo || ""}

                          </td>
                          <td>
                            <Link
                              to={`${REACT_APP_FileURL}${data.NewPolicyCopy}`}
                              target="_blank"
                              rel="noreferrer"
                              state={{
                                color: "#2424d1",
                              }}
                            >
                              {data?.PolicyNumber}
                            </Link>
                          </td>
                          <td>{data?.InsureeName}</td>
                          <td>
                            {` ${data?.POS?.AliasName ?? ""
                              } `}{" "}
                          </td>

                          <td>{data?.VehicleNumber}</td>
                          <td className="text-end">
                            {" "}
                            {`${coverAmountIntoCurrent(data?.NETPremium)}`}
                          </td>
                          <td className="text-end">
                            {" "}
                            {`${coverAmountIntoCurrent(data?.GrossPremium)}`}
                          </td>
                          <td className="text-start">
                            {data?.Branch?.BranchName}
                          </td>
                          <td className="text-start">
                            {data?.IssueDate.split("-").reverse().join("-")}
                          </td>
                          <td className="text-start">
                            {data?.createdAt
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("-")}
                          </td>

                          {showBtn && (
                            <th scope="row">
                              {" "}
                              <div className="col-md-4">
                                <div className="">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-center",
                                    }}
                                  >
                                    <Link
                                      className=""
                                      to={`/admin/view/Policy/${data._id}`}
                                    >
                                      <FaEye
                                        size={35}
                                        style={{
                                          backgroundColor: "#f99d36",
                                          color: "#fff",
                                          padding: "7px",
                                          borderRadius: "5px",
                                        }}
                                      />{" "}
                                    </Link>
                                    &nbsp;&nbsp;
                                    {user &&
                                      user?.Branch?.BranchCode === "0" &&
                                      data?.LOB === "Motor" ? (
                                      <Link
                                        to={`/admin/data/cluster/${data?._id}`}
                                        className="btn mybtn"
                                      >
                                        Click
                                      </Link>
                                    ) : user &&
                                      user?.Branch?.BranchCode === "0" ? (
                                      <Link
                                        to={`/admin/data/cluster/NonMotor/${data?._id}`}
                                        className="btn mybtn"
                                      >
                                        Click
                                      </Link>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </th>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {
                <div
                  style={{
                    backgroundColor: "#1a2e45d7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    marginTop: "10px",
                  }}
                >
                  <TablePagination
                    component="div"
                    className="text-white"
                    rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                    count={totalDocs ?? 0}
                    page={pagination}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "10px" }}>
                    {pageNumbers.map((pageNumber, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageClick(pageNumber)}
                        style={{
                          margin: "5px",
                          padding: "5px 10px",
                          backgroundColor: pagination === pageNumber ? "#5c6bc0" : "#37474f",
                          color: "white",
                          border: "none",
                          cursor: pageNumber === '........' ? "default" : "pointer",
                          borderRadius: "4px",
                        }}
                      >
                        {pageNumber === '........' ? '........' : pageNumber + 1}
                      </button>
                    ))}
                  </div>
                </div>
              }
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Policy;

