import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../config";

import { reversDate } from "../../Utils/usefullFunction";

const clusterColSpan = 11;
const payoutColSpan = 18;

const ClusterMatch = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [payoutData, setPayoutData] = useState({
    data1: [],
    data2: [],
    data3: [],
    data4: [],
    data5: [],
    data6: [],
    data7: [],
    data8: [],
    data9: [],
    data10: [],
    data11: [],
    data12: [],
    data13: [],
    data14: [],
    data15: [],
    data16: [],
    data17: [],
    data18: [],
    data19: [],
    data20: [],
    data21: [],
    data22: [],
  });
  const [policy, setPolicy] = useState();

  useEffect(() => {
    const getCluster = async () => {
      try {
        const response = await axiosInstance.post(`/Policy/getCluster/${id}`);

        if (response && response?.data) {
          setData1(response?.data?.data);
          setData2(response?.data?.data2);
          setData3(response?.data?.data3);
          setData4(response?.data?.data4);
          setData5(response?.data?.data5);
          setData6(response?.data?.data6);
          setPolicy(response?.data?.policy);
          setPayoutData({
            data1: response?.data?.payoutData1,
            data2: response?.data?.payoutData2,
            data3: response?.data?.payoutData3,
            data4: response?.data?.payoutData4,
            data5: response?.data?.payoutData5,
            data6: response?.data?.payoutData6,
            data7: response?.data?.payoutData7,
            data8: response?.data?.payoutData8,
            data9: response?.data?.payoutData9,
            data10: response?.data?.payoutData10,
            data11: response?.data?.payoutData11,
            data12: response?.data?.payoutData12,
            data13: response?.data?.payoutData13,
            data14: response?.data?.payoutData14,
            data15: response?.data?.payoutData15,
            data16: response?.data?.payoutData16,
            data17: response?.data?.payoutData17,
            data18: response?.data?.payoutData18,
            data19: response?.data?.payoutData19,
            data20: response?.data?.payoutData20,
            data21: response?.data?.payoutData21,
            data22: response?.data?.payoutData22,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      getCluster();
    }
  }, [id]);

  return (
    <div>
      <h2>{policy?.PolicyNumber}</h2>
      <div
        class="table-responsive"
        style={{
          zoom: "70%",
        }}
      >
        <table
          class="table table-striped   table-bordered  overflow-x mt-3"
          id="table-to-xls"
        >
          <thead>
            <tr>
              <th scope="col">SNo.</th>
              <th scope="col">Id</th>
              <th scope="col">Company Name </th>
              <th scope="col">DEF Date</th>
              <th scope="col">DET Date</th>
              <th scope="col">Cluster Name</th>
              <th scope="col">Products</th>
              <th scope="col">Policy Type</th>
              <th scope="col">GVW</th>
              <th scope="col">Seater</th>
              <th scope="col">Total RTO</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                colSpan={clusterColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "#174379",
                  color: "#FFFFFF",
                }}
              >
                Step - 1 (Company & DEF and DET)
              </td>
            </tr>
            {data1 &&
              data1.length !== 0 &&
              data1.map((value, index) => {
                let myInsuranceType =
                  value?.InsuranceType?.InsuranceUnderFlow?.map(
                    (ele) => ele?.InsuranceType
                  );
                myInsuranceType?.push(value?.InsuranceType?.InsuranceType);

                return (
                  <tr key={index + 1}>
                    <th scope="row">{index + 1}</th>
                    <td>{`${value?._id ?? ""}`}</td>
                    <td>{`${value?.InsuranceCompany?.AliasName ?? ""}`}</td>
                    <td>{`${reversDate(value?.DEF) ?? ""}`}</td>
                    <td>{`${reversDate(value?.DET) ?? ""}`}</td>
                    <td>{`${value?.GroupName ?? ""}`}</td>
                    <td>{`${myInsuranceType ?? ""}`}</td>
                    <td>{`${value?.PolicyType?.PolicyTypeName ?? ""}`}</td>
                    <td>{`${value?.GVWFrom ?? ""}-${value?.GVWTo ?? ""}`}</td>
                    <td>{`${value?.SeaterFrom ?? ""}-${
                      value?.SeaterTo ?? ""
                    }`}</td>
                    <td style={{ position: "relative" }}>
                      <center>
                        <span
                          class="badge m-2 p-2"
                          style={{ backgroundColor: "#174379" }}
                          title={value?.ListOfRto?.map(
                            (ele) => `${ele?.RTOName} (${ele?.RTOCode})`
                          ).join(", ")}
                        >
                          {`${value?.ListOfRto?.length}`}
                        </span>
                      </center>
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td
                colSpan={clusterColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "#174379",
                  color: "#FFFFFF",
                }}
              >
                Step - 2 (Product)
              </td>
            </tr>
            {data2 &&
              data2.length !== 0 &&
              data2.map((value, index) => {
                let myInsuranceType =
                  value?.InsuranceType?.InsuranceUnderFlow?.map(
                    (ele) => ele?.InsuranceType
                  );
                myInsuranceType?.push(value?.InsuranceType?.InsuranceType);

                return (
                  <tr key={index + 1}>
                    <th scope="row">
                      {index + 1}
                      {/* {(rowsPerPage * pagination) - rowsPerPage + index + 1} */}
                    </th>
                    <td>{`${value?._id ?? ""}`}</td>
                    <td>{`${value?.InsuranceCompany?.AliasName ?? ""}`}</td>
                    <td>{`${reversDate(value?.DEF) ?? ""}`}</td>
                    <td>{`${reversDate(value?.DET) ?? ""}`}</td>
                    <td>{`${value?.GroupName ?? ""}`}</td>
                    <td>{`${myInsuranceType ?? ""}`}</td>
                    <td>{`${value?.PolicyType?.PolicyTypeName ?? ""}`}</td>
                    <td>{`${value?.GVWFrom ?? ""}-${value?.GVWTo ?? ""}`}</td>
                    <td>{`${value?.SeaterFrom ?? ""}-${
                      value?.SeaterTo ?? ""
                    }`}</td>
                    <td style={{ position: "relative" }}>
                      <center>
                        <span
                          class="badge m-2 p-2"
                          style={{ backgroundColor: "#174379" }}
                          title={value?.ListOfRto?.map(
                            (ele) => `${ele?.RTOName} (${ele?.RTOCode})`
                          ).join(", ")}
                        >
                          {`${value?.ListOfRto?.length}`}
                        </span>
                      </center>
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td
                colSpan={clusterColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "#174379",
                  color: "#FFFFFF",
                }}
              >
                Step - 3 (Policy)
              </td>
            </tr>
            {data3 &&
              data3.length !== 0 &&
              data3.map((value, index) => {
                let myInsuranceType =
                  value?.InsuranceType?.InsuranceUnderFlow?.map(
                    (ele) => ele?.InsuranceType
                  );
                myInsuranceType?.push(value?.InsuranceType?.InsuranceType);

                return (
                  <tr key={index + 1}>
                    <th scope="row">
                      {index + 1}
                      {/* {(rowsPerPage * pagination) - rowsPerPage + index + 1} */}
                    </th>
                    <td>{`${value?._id ?? ""}`}</td>
                    <td>{`${value?.InsuranceCompany?.AliasName ?? ""}`}</td>
                    <td>{`${reversDate(value?.DEF) ?? ""}`}</td>
                    <td>{`${reversDate(value?.DET) ?? ""}`}</td>
                    <td>{`${value?.GroupName ?? ""}`}</td>
                    <td>{`${myInsuranceType ?? ""}`}</td>
                    <td>{`${value?.PolicyType?.PolicyTypeName ?? ""}`}</td>
                    <td>{`${value?.GVWFrom ?? ""}-${value?.GVWTo ?? ""}`}</td>
                    <td>{`${value?.SeaterFrom ?? ""}-${
                      value?.SeaterTo ?? ""
                    }`}</td>
                    <td style={{ position: "relative" }}>
                      <center>
                        <span
                          class="badge m-2 p-2"
                          style={{ backgroundColor: "#174379" }}
                          title={value?.ListOfRto?.map(
                            (ele) => `${ele?.RTOName} (${ele?.RTOCode})`
                          ).join(", ")}
                        >
                          {`${value?.ListOfRto?.length}`}
                        </span>
                      </center>
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td
                colSpan={clusterColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "#174379",
                  color: "#FFFFFF",
                }}
              >
                Step - 4 (Fuel type)
              </td>
            </tr>
            {data4 &&
              data4.length !== 0 &&
              data4.map((value, index) => {
                let myInsuranceType =
                  value?.InsuranceType?.InsuranceUnderFlow?.map(
                    (ele) => ele?.InsuranceType
                  );
                myInsuranceType?.push(value?.InsuranceType?.InsuranceType);

                return (
                  <tr key={index + 1}>
                    <th scope="row">
                      {index + 1}
                      {/* {(rowsPerPage * pagination) - rowsPerPage + index + 1} */}
                    </th>
                    <td>{`${value?._id ?? ""}`}</td>
                    <td>{`${value?.InsuranceCompany?.AliasName ?? ""}`}</td>
                    <td>{`${reversDate(value?.DEF) ?? ""}`}</td>
                    <td>{`${reversDate(value?.DET) ?? ""}`}</td>
                    <td>{`${value?.GroupName ?? ""}`}</td>
                    <td>{`${myInsuranceType ?? ""}`}</td>
                    <td>{`${value?.PolicyType?.PolicyTypeName ?? ""}`}</td>
                    <td>{`${value?.GVWFrom ?? ""}-${value?.GVWTo ?? ""}`}</td>
                    <td>{`${value?.SeaterFrom ?? ""}-${
                      value?.SeaterTo ?? ""
                    }`}</td>
                    <td style={{ position: "relative" }}>
                      <center>
                        <span
                          class="badge m-2 p-2"
                          style={{ backgroundColor: "#174379" }}
                          title={value?.ListOfRto?.map(
                            (ele) => `${ele?.RTOName} (${ele?.RTOCode})`
                          ).join(", ")}
                        >
                          {`${value?.ListOfRto?.length}`}
                        </span>
                      </center>
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td
                colSpan={clusterColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "#174379",
                  color: "#FFFFFF",
                }}
              >
                Step - 5 (Seater)
              </td>
            </tr>
            {data5 &&
              data5.length !== 0 &&
              data5.map((value, index) => {
                let myInsuranceType =
                  value?.InsuranceType?.InsuranceUnderFlow?.map(
                    (ele) => ele?.InsuranceType
                  );
                myInsuranceType?.push(value?.InsuranceType?.InsuranceType);

                return (
                  <tr key={index + 1}>
                    <th scope="row">
                      {index + 1}
                      {/* {(rowsPerPage * pagination) - rowsPerPage + index + 1} */}
                    </th>
                    <td>{`${value?._id ?? ""}`}</td>
                    <td>{`${value?.InsuranceCompany?.AliasName ?? ""}`}</td>
                    <td>{`${reversDate(value?.DEF) ?? ""}`}</td>
                    <td>{`${reversDate(value?.DET) ?? ""}`}</td>
                    <td>{`${value?.GroupName ?? ""}`}</td>
                    <td>{`${myInsuranceType ?? ""}`}</td>
                    <td>{`${value?.PolicyType?.PolicyTypeName ?? ""}`}</td>
                    <td>{`${value?.GVWFrom ?? ""}-${value?.GVWTo ?? ""}`}</td>
                    <td>{`${value?.SeaterFrom ?? ""}-${
                      value?.SeaterTo ?? ""
                    }`}</td>
                    <td style={{ position: "relative" }}>
                      <center>
                        <span
                          class="badge m-2 p-2"
                          style={{ backgroundColor: "#174379" }}
                          title={value?.ListOfRto?.map(
                            (ele) => `${ele?.RTOName} (${ele?.RTOCode})`
                          ).join(", ")}
                        >
                          {`${value?.ListOfRto?.length}`}
                        </span>
                      </center>
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td
                colSpan={clusterColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "#174379",
                  color: "#FFFFFF",
                }}
              >
                Step - 6 (GVW)
              </td>
            </tr>
            {data6 &&
              data6.length !== 0 &&
              data6.map((value, index) => {
                let myInsuranceType =
                  value?.InsuranceType?.InsuranceUnderFlow?.map(
                    (ele) => ele?.InsuranceType
                  );
                myInsuranceType?.push(value?.InsuranceType?.InsuranceType);

                return (
                  <tr key={index + 1}>
                    <th scope="row">
                      {index + 1}
                      {/* {(rowsPerPage * pagination) - rowsPerPage + index + 1} */}
                    </th>
                    <td>{`${value?._id ?? ""}`}</td>
                    <td>{`${value?.InsuranceCompany?.AliasName ?? ""}`}</td>
                    <td>{`${reversDate(value?.DEF) ?? ""}`}</td>
                    <td>{`${reversDate(value?.DET) ?? ""}`}</td>
                    <td>{`${value?.GroupName ?? ""}`}</td>
                    <td>{`${myInsuranceType ?? ""}`}</td>
                    <td>{`${value?.PolicyType?.PolicyTypeName ?? ""}`}</td>
                    <td>{`${value?.GVWFrom ?? ""}-${value?.GVWTo ?? ""}`}</td>
                    <td>{`${value?.SeaterFrom ?? ""}-${
                      value?.SeaterTo ?? ""
                    }`}</td>
                    <td style={{ position: "relative" }}>
                      <center>
                        <span
                          class="badge m-2 p-2"
                          style={{ backgroundColor: "#174379" }}
                          title={value?.ListOfRto?.map(
                            (ele) => `${ele?.RTOName} (${ele?.RTOCode})`
                          ).join(", ")}
                        >
                          {`${value?.ListOfRto?.length}`}
                        </span>
                      </center>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          zoom: "70%",
        }}
      >
        <center>
          <h3>Matched Payout Grid</h3>
        </center>
        <table className="table table-striped">
          <thead className="table">
            <tr>
              <th scope="col">Id</th>
              <th scope="col">DEF</th>
              <th scope="col">DET</th>
              <th scope="col">Insurance Company</th>
              <th scope="col">Policy Type</th>
              <th scope="col">Product</th>
              <th scope="col">Fuel Type</th>
              <th scope="col">Model</th>
              <th scope="col">GVW</th>
              <th scope="col">Seater</th>
              <th scope="col">RTO Cluster</th>
              <th scope="col"> NCB </th>
              <th scope="col">based on </th>
              <th scope="col">Business Type</th>
              <th scope="col">CC</th>
              <th scope="col">Age</th>
              <th scope="col">Inflow </th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 1 (Cluster)
              </td>
            </tr>
            {payoutData?.data6 &&
              payoutData?.data6.length > 0 &&
              payoutData?.data6?.slice(0, 10)?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))} */}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 1 (Company & DEF and DET)
              </td>
            </tr>
            {payoutData?.data1 &&
              payoutData?.data1.length > 0 &&
              payoutData?.data1?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 2 (Product)
              </td>
            </tr>
            {payoutData?.data2 &&
              payoutData?.data2.length > 0 &&
              payoutData?.data2?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 3 (Policy Type)
              </td>
            </tr>
            {payoutData?.data3 &&
              payoutData?.data3.length > 0 &&
              payoutData?.data3?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 4 (Policy Paying Term)
              </td>
            </tr>
            {payoutData?.data4 &&
              payoutData?.data4.length > 0 &&
              payoutData?.data4?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 5 (Seater)
              </td>
            </tr>
            {payoutData?.data5 &&
              payoutData?.data5.length > 0 &&
              payoutData?.data5?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 6 (Nil Depth)
              </td>
            </tr>
            {payoutData?.data6 &&
              payoutData?.data6.length > 0 &&
              payoutData?.data6?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 7 (GVW)
              </td>
            </tr>
            {payoutData?.data7 &&
              payoutData?.data7.length > 0 &&
              payoutData?.data7?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 8 (PA Premium)
              </td>
            </tr>
            {payoutData?.data8 &&
              payoutData?.data8.length > 0 &&
              payoutData?.data8?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 9 (CC)
              </td>
            </tr>
            {payoutData?.data9 &&
              payoutData?.data9.length > 0 &&
              payoutData?.data9?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 10 (Make)
              </td>
            </tr>
            {payoutData?.data10 &&
              payoutData?.data10.length > 0 &&
              payoutData?.data10?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 11 (Model)
              </td>
            </tr>
            {payoutData?.data11 &&
              payoutData?.data11.length > 0 &&
              payoutData?.data11?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 12 (Variant)
              </td>
            </tr>
            {payoutData?.data12 &&
              payoutData?.data12.length > 0 &&
              payoutData?.data12?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 13 (ODD)
              </td>
            </tr>
            {payoutData?.data13 &&
              payoutData?.data13.length > 0 &&
              payoutData?.data13?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 14 (PA Type)
              </td>
            </tr>
            {payoutData?.data14 &&
              payoutData?.data14.length > 0 &&
              payoutData?.data14?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 15 (fuelType)
              </td>
            </tr>
            {payoutData?.data15 &&
              payoutData?.data15.length > 0 &&
              payoutData?.data15?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 16 (NCB)
              </td>
            </tr>
            {payoutData?.data16 &&
              payoutData?.data16.length > 0 &&
              payoutData?.data16?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 17 (Business Type)
              </td>
            </tr>
            {payoutData?.data17 &&
              payoutData?.data17.length > 0 &&
              payoutData?.data17?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 18 (IMTType)
              </td>
            </tr>
            {payoutData?.data18 &&
              payoutData?.data18.length > 0 &&
              payoutData?.data18?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 19 (Customer Type)
              </td>
            </tr>
            {payoutData?.data19 &&
              payoutData?.data19.length > 0 &&
              payoutData?.data19?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 20 (Branch)
              </td>
            </tr>
            {payoutData?.data20 &&
              payoutData?.data20.length > 0 &&
              payoutData?.data20?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 21 (Age)
              </td>
            </tr>
            {payoutData?.data21 &&
              payoutData?.data21.length > 0 &&
              payoutData?.data21?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
            <tr>
              <td
                colSpan={payoutColSpan}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  backgroundColor: "magenta",
                }}
              >
                Step - 22 (Insurer Branch)
              </td>
            </tr>
            {payoutData?.data22 &&
              payoutData?.data22.length > 0 &&
              payoutData?.data22?.map((data, index) => (
                <tr key={index}>
                  <th scope="row">{data?._id}</th>
                  <td>{data?.DEF || ""}</td>
                  <td>{data?.DET || ""}</td>
                  <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                  <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                  <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                  <td>{data?.FuelType?.FuelType || ""}</td>
                  <td>{data?.Model?.Model || ""}</td>
                  <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo ?? ""}`}</td>
                  <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo ?? ""}`}</td>
                  <td>{data?.RTOGroup?.GroupName || ""}</td>
                  <td>{data?.NCBSTATUS || ""}</td>
                  <td>{data?.BasedOn || ""}</td>
                  <td>{data?.BusinessType || ""}</td>
                  <td>{`${data?.CCFrom ?? ""}-${data?.CCTo ?? ""}`}</td>
                  <td>{`${data?.FromAge ?? ""}-${data?.ToAge ?? ""}`}</td>
                  <td>{`${data?.Reciveable} ${
                    data?.ReciveableTP ? ` + ${data?.ReciveableTP}` : ""
                  }`}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClusterMatch;
