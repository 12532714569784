import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";

import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import MultiInsuranceTypeFilter from "../../Utils/MultiInsuranceTypeFilter";

import { useDispatch, useSelector } from "react-redux";
const ClubException = () => {
  const dispatch = useDispatch();
  const { SelectedInsuranceType } = useSelector(
    (store) => store.ClubExceptionFilters
  );

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [inputData, setInput] = useState("");
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };

  const getDataByPageNation = async () => {
    try {
      setLoading(true);

      const SelectedInsuranceTypeData = SelectedInsuranceType.map(
        (ele) => ele.value
      ).filter((ele) => ele !== "");

      const insuranceType = SelectedInsuranceTypeData.length - 1;
      const que = {
        InsuranceUnderFlow: SelectedInsuranceTypeData[insuranceType],
      };
      const {
        data: { data, totalDocs },
      } = await axiosInstance.post(
        `/ClubException?page=${
          pagination + 1
        }&limit=${rowsPerPage}&inputData=${inputData} `,
        que
      );

      if (data) {
        setTotalDocs(totalDocs);
        setData(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataByPageNation();
  }, [pagination, rowsPerPage]);

  const deleteClubException = async (_id) => {
    try {
      const isTrue = window.confirm("Do you want to delete!");

      if (!isTrue) {
        return;
      }

      const {
        data: { success, message },
      } = await axiosInstance.delete(`/ClubException/${_id}`);

      if (success) {
        getDataByPageNation();
        ToastSusses(message);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const onSetClubExceptionFilter = (key, value) => {
    dispatch({
      type: "updateClubExceptionFilters",

      key: key,
      value: value,
    });
  };
  useEffect(() => {
    if (SelectedInsuranceType.length === 0) {
      callInsuranceData();
    }
  }, []);

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get(`/InsuranceType?Root=true`);
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }

      return onSetClubExceptionFilter("SelectedInsuranceType", [
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      const {
        data: { data },
      } = await axiosInstance.get(`/InsuranceType?Root=true`);
      onSetClubExceptionFilter("SelectedInsuranceType", [{ data, value: "" }]);
    }
  };

  const resetFilters = () => {
    dispatch({
      type: "updateClubExceptionFilters",
      key: "SelectedInsuranceType",
      value: [],
    });

    setInput("");
    setPagination(0);
    setRowsPerPage(10);
  };

  useEffect(() => {
    if (SelectedInsuranceType.length === 0) {
      getDataByPageNation();
      callInsuranceData({});
    }
  }, [SelectedInsuranceType]);

  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by, ClubName..."
      />
      <div
        className="container-fluid"
        style={{ marginTop: "70px", zoom: "85%" }}
      >
        <div className="row CreateCard">
          <div className="col-lg-12">
            <div className="">
              <div className="card-body">
                <div className="row">
                  <div className="col-10" style={{ float: "left" }}>
                    <h4 className="card-title">Club Exception</h4>
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ width: "100%" }}
                  >
                    <Link
                      className="btn mybtn"
                      to="/admin/masters/club-exception"
                      state={{
                        Mode: false,
                        value: "",
                      }}
                    >
                      Add More
                    </Link>
                  </div>

                  <div
                    className="d-flex align-items-center mt-2 mb-2"
                    style={{
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {/* Dropdown */}
                    <div
                      style={{
                        flex: "0 1 auto",
                        width: "250px",
                        minWidth: "150px",
                      }}
                    >
                      <select
                        className="form-control"
                        value={inputData}
                        onChange={(e) => setInput(e.target.value)}
                      >
                        <option value="">
                          ----------- Select based on -----------
                        </option>
                        <option value="CPMargin">CP Margin</option>
                        <option value="ClubMargin">Club Margin</option>
                        <option value="CPPMargin">
                          Channel Partner POS Margin
                        </option>
                      </select>
                    </div>

                    {/* Filters */}
                    <div
                      className="d-flex"
                      style={{
                        gap: "10px",
                        flexWrap: "wrap",
                        flex: "1 0 auto",
                      }}
                    >
                      {SelectedInsuranceType &&
                        SelectedInsuranceType?.length > 0 &&
                        SelectedInsuranceType.map(
                          (data, index) =>
                            data &&
                            data?.data?.length > 0 && (
                              <MultiInsuranceTypeFilter
                                key={data.value}
                                Options={data}
                                setSelectedInsuranceType={
                                  onSetClubExceptionFilter
                                }
                                SelectedInsuranceType={SelectedInsuranceType}
                                index={index}
                                OptionsValue={data.value}
                              />
                            )
                        )}
                    </div>

                    {/* Buttons */}
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <div
                        className="searchBox d-flex align-items-center justify-content-center"
                        onClick={getDataByPageNation}
                        style={{
                          cursor: "pointer",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <MdSend size={20} />
                      </div>
                      <div
                        className="searchBox d-flex align-items-center justify-content-center"
                        onClick={resetFilters}
                        style={{
                          cursor: "pointer",
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <BsEraser size={20} />
                      </div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div
                    className=" p-2 container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <>
                    <div
                      class="table-responsive"
                      style={{
                        zoom: "70%",
                      }}
                    >
                      <table class="table">
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "rgb(23, 67, 121)",
                              color: "rgb(255, 255, 255)",
                            }}
                          >
                            <th scope="col">#</th>
                            <th scope="col">Code</th>
                            <th scope="col">Name</th>
                            <th scope="col">Alias Name</th>
                            <th scope="col">Based On</th>
                            <th scope="col">Branch</th>
                            <th scope="col">Club</th>
                            <th scope="col">Product</th>
                            <th scope="col">DEF</th>
                            <th scope="col">DET</th>
                            <th scope="col">Old HOM</th>
                            <th scope="col">New HOM</th>
                            <th scope="col">Old CPM</th>
                            <th scope="col">New CPM</th>
                            <th scope="col">POS Rate</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.length !== 0 &&
                            data.map((ele, index) => {
                              const POS_Name = `${ele?.POS?.Salutation || ""} ${
                                ele?.POS?.Name || ""
                              } ${ele?.POS?.MiddleName || ""} ${
                                ele?.POS?.LastName || ""
                              }`;
                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <th scope="row">{ele?.POS?.Code}</th>
                                  <th scope="row">{POS_Name}</th>
                                  <th scope="row">{ele?.POS?.AliasName}</th>
                                  <th scope="row">{ele?.basedOn}</th>
                                  <th scope="row">
                                    {ele?.POS?.Branch?.BranchName}
                                  </th>
                                  <th scope="row">{ele?.POS?.Club?.Name}</th>
                                  <th scope="row">
                                    {ele?.InsuranceType?.InsuranceType || ""}
                                  </th>
                                  <th scope="row">
                                    {ele?.DEF?.split("-").reverse().join("-")}
                                  </th>
                                  <th scope="row">
                                    {ele?.DET?.split("-").reverse().join("-")}
                                  </th>
                                  <th scope="row">
                                    {ele?.POS?.Club?.HOMargin}
                                  </th>
                                  <th scope="row">{ele?.HOMargin}</th>
                                  <th scope="row">
                                    {ele?.POS?.Club?.CPMargin}
                                  </th>
                                  <th scope="row">{ele?.CPMargin}</th>
                                  <th scope="row">{ele?.POSRate}</th>

                                  <th scope="row">
                                    {" "}
                                    <div className="col-md-2">
                                      <div className="d-flex gap-3">
                                        <div>
                                          <Link
                                            className=""
                                            state={{
                                              value: ele,
                                              view: true,
                                            }}
                                            to="/admin/masters/club-exception"
                                          >
                                            <FaEye size={25} color="" />{" "}
                                          </Link>
                                        </div>
                                        <div className="">
                                          <Link
                                            className=""
                                            state={{
                                              value: ele,
                                              Mode: true,
                                            }}
                                            to="/admin/masters/club-exception"
                                          >
                                            <BiEdit size={25} color="blue" />{" "}
                                          </Link>
                                        </div>
                                        <div className="">
                                          <AiFillDelete
                                            color="red"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            size={25}
                                            onClick={deleteClubException.bind(
                                              null,
                                              ele?._id
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      >
                        <TablePagination
                          component="div"
                          className="text-white"
                          rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                          count={totalDocs ?? 0}
                          page={pagination}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    }
                  </>
                )}
              </div>
            </div>

            {/* end col */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ClubException;
