import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import ExcelExport from "../utils/ExcelExport/ExcelExport";
import { axiosInstance } from "../../config";
import Select from "react-select";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  convertDateStringToDate,
  removeEmptyValues,
} from "../../Utils/usefullFunction";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import DateRange from "../DateRangeModule/DateRange";
import useGettingData from "../Hooks/useGettingData";
import { useDispatch, useSelector } from "react-redux";
import MultiInsuranceTypeFilter from "../../Utils/MultiInsuranceTypeFilter";

const PayoutGrid = () => {
  const dispatch = useDispatch();
  const {
    PayoutGrid,
    InsuranceCompany,
    PolicyType,
    pagination,
    rowsPerPage,
    SavedData,
    SavedTotalDocs,
    SelectedInsuranceType,
    RTOGroup,
  } = useSelector((store) => store.PayoutGridFilters);

  const {
    getData: getInsuranceCompany,
    Loading: CompanyLoading,
    Options: InsuranceCompanyOpt,
  } = useGettingData("/InsuranceCompany", [
    {
      _id: "",
      AliasName: "-- Select Company --",
    },
  ]);
  const {
    getData: getPolicyType,
    Loading: PolicyTypeLoading,
    Options: PolicyTypeOpt,
  } = useGettingData("/PolicyType", [
    {
      _id: "",
      PolicyTypeName: "-- Select PolicyType --",
    },
  ]);
  const [RTOGroupOpt, setRTOGroupOpt] = useState([]);
  const [IsFirstRendering, setIsFirstRendering] = useState(true);
  const [loading, setLoading] = useState(false);
  const [InsuranceCompanyid, setInsuranceCompanyid] = useState();
  const [LOB, setLOB] = useState("Motor");

  const [inputData, setInput] = useState("");
  const handleChangePage = (event, newPage) => {
    onSetPolicyFilter("pagination", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    onSetPolicyFilter("rowsPerPage", parseInt(event.target.value));
    onSetPolicyFilter("pagination", 0);
  };
  const onSetPolicyFilter = (key, value) => {
    dispatch({
      type: "updatePayoutGridFilters",
      key: key,
      value: value,
    });
  };

  const deletePayoutGrid = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/PayoutGrid/${_id}`);

      if (success) {
        filterDataHandler();
        ToastSusses(message);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getRTOGroupOpt = async (insid, policyTypeid) => {
    try {
      const url =
        `/RtoGroupChecker/getRtoGroupSelectDrpDown` +
        (insid !== "" ? `?InsuranceCompany=${insid}` : "") +
        `&DEF=${PayoutGrid.startDate}&DET=${PayoutGrid.endDate}` +
        (policyTypeid !== "" ? `&PolicyType=${policyTypeid}` : "");

      const {
        data: { data },
      } = await axiosInstance.get(url);
      setRTOGroupOpt(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (InsuranceCompanyid) {
      getRTOGroupOpt(InsuranceCompanyid, "");
    }
  }, [PayoutGrid?.startDate, PayoutGrid?.endDate]);

  const onChangeSetValueStart = (e) => {
    onSetPolicyFilter("PayoutGrid", {
      ...PayoutGrid,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeSetValueEnd = (e) => {
    onSetPolicyFilter("PayoutGrid", {
      ...PayoutGrid,
      [e.target.name]: e.target.value,
    });
  };

  const filterDataHandler = async (isEraser) => {
    try {
      setLoading(true);

      const SelectedInsuranceTypeData = SelectedInsuranceType.map(
        (ele) => ele.value
      ).filter((ele) => ele !== "");

      const insuranceType = SelectedInsuranceTypeData.length - 1;
      let que = {};
      if (isEraser) {
        // que["startDate"] = firstDay;
        // que["endDate"] = lastDay;
      } else {
        que = {
          ...PayoutGrid,
          InsuranceType: SelectedInsuranceTypeData[insuranceType],
        };
        if (InsuranceCompany._id) {
          que["InsuranceCompany"] = InsuranceCompany._id;
        }

        if (PolicyType._id) {
          que["PolicyType"] = PolicyType._id;
        }
        if (inputData && inputData.trim() !== "") {
          que["inputData"] = inputData;
        }

        if (RTOGroup._id) {
          que["RTOGroup"] = RTOGroup._id;
        }
        if (LOB) {
          que["LOB"] = LOB;
        }
      }
      removeEmptyValues(que);

      const {
        data: { data, totalDocs },
      } = await axiosInstance.post(`/PayoutGrid/getPayoutGridByFilter`, {
        page: pagination + 1,
        limit: rowsPerPage,
        ...que,
      });
      onSetPolicyFilter("SavedData", data);
      onSetPolicyFilter("SavedTotalDocs", totalDocs);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const EraserHandler = async () => {
    setInput("");
    filterDataHandler(true);
    dispatch({
      type: "setPayoutGridFilterDefaultValue",
    });
    const reSetData = { data: SelectedInsuranceType[0]["data"], value: "" };
    onSetPolicyFilter("SelectedInsuranceType", [reSetData]);
  };

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get(
          `/InsuranceType?Root=true&InsuranceCategory=${LOB}`
        );
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) { }
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]
            }&InsuranceCategory=${LOB}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) { }
      }

      return onSetPolicyFilter("SelectedInsuranceType", [
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/InsuranceType?Root=true&InsuranceCategory=${LOB}`
      );
      onSetPolicyFilter("SelectedInsuranceType", [{ data, value: "" }]);
    }
  };

  const setDateHandler = (ranges) => {
    onSetPolicyFilter("PayoutGrid", {
      ...PayoutGrid,
      startDate: convertDateStringToDate(ranges.startDate),
      endDate: convertDateStringToDate(ranges.endDate),
    });
  };
  useEffect(() => {
    if (IsFirstRendering) {
      setIsFirstRendering(false);
      if (SavedData.length === 0) {
        filterDataHandler();
      }
    } else {
      filterDataHandler();
    }
  }, [pagination, rowsPerPage]);

  useEffect(() => {
    if (LOB && SelectedInsuranceType.length === 0) {
      callInsuranceData();
    }
    getInsuranceCompany();
    getPolicyType();
  }, [LOB, SelectedInsuranceType]);

  useEffect(() => {
    if (LOB) {
      callInsuranceData();
    }
  }, [LOB]);
  //excel export

  const getExcelData = async () => {
    try {
      let rows = [];
      const SelectedInsuranceTypeData = SelectedInsuranceType.map(
        (ele) => ele.value
      ).filter((ele) => ele !== "");
      const insuranceType = SelectedInsuranceTypeData.length - 1;
      let que = {};

      que = {
        ...PayoutGrid,
        InsuranceType: SelectedInsuranceTypeData[insuranceType],
      };
      if (InsuranceCompany._id) {
        que["InsuranceCompany"] = InsuranceCompany._id;
      }

      if (inputData && inputData.trim() !== "") {
        que["inputData"] = inputData;
      }

      if (RTOGroup._id) {
        que["RTOGroup"] = RTOGroup._id;
      }
      if (LOB) {
        que["LOB"] = LOB;
      }
      const {
        data: { data },
      } = await axiosInstance.post("/PayoutGrid/getPayoutGridByFilterExport", {
        ...que,
      });

      if (data && data.length > 0) {
        const responseData = data;

        const updatedData = responseData.map((item) => ({
          ...item,
          InsuranceCompany: item?.InsuranceCompany?.AliasName || "",
          DEF: item?.DEF?.split("-").reverse().join("-") || "",
          DET: item?.DET?.split("-").reverse().join("-") || "",
          PolicyType: item?.PolicyType?.PolicyTypeName || "",
          Branch: item?.Branch?.BranchName || "",
          RTOGroup: item?.RTOGroup?.GroupName || "",
          MakeModal: item?.MakeModal?.Make || "",

          Variant: item?.Variant?.VariantName || "",
          Model: item?.Model?.Model || "",
          FuelType: item?.FuelType?.FuelType || "",

          Product:
            item?.InsuranceType?.InsuranceUnderFlow?.length === 0
              ? item?.InsuranceType?.InsuranceType || ""
              : item?.InsuranceType?.InsuranceUnderFlow[0]?.InsuranceType || "",

          SubProduct:
            item?.InsuranceType?.InsuranceUnderFlow?.length === 2
              ? item?.InsuranceType?.InsuranceUnderFlow[1]?.InsuranceType || ""
              : item?.InsuranceType?.InsuranceUnderFlow?.length === 0
                ? ""
                : item?.InsuranceType?.InsuranceType,

          VehicleClass:
            item?.InsuranceType?.InsuranceUnderFlow?.length === 2
              ? item?.InsuranceType?.InsuranceType || ""
              : "",
        }));

        rows = updatedData;
      }

      // Define columns
      const columns = [
        { header: "Id", key: "_id", width: 25 },
        { header: "Product", key: "Product", width: 25 },
        { header: "Sub Product", key: "SubProduct", width: 25 },
        { header: "Vehicle Class", key: "VehicleClass", width: 25 },
        { header: "Insurance Company", key: "InsuranceCompany", width: 25 },
        { header: "Policy Type", key: "PolicyType", width: 20 },
        { header: "Branch", key: "Branch", width: 20 },
        { header: "RTO Group", key: "RTOGroup", width: 20 },
        { header: "DEF Date", key: "DEF", width: 20 },
        { header: "DET Date", key: "DET", width: 20 },
        { header: "GVW From", key: "GVWFrom", width: 10 },
        { header: "GVW To", key: "GVWTo", width: 10 },
        { header: "IDV From", key: "IDVFrom", width: 10 },
        { header: "IDV To", key: "IDVTo", width: 10 },
        { header: "Seater From", key: "seaterFrom", width: 10 },
        { header: "Seater To", key: "seaterTo", width: 10 },
        { header: "CC From", key: "CCFrom", width: 10 },
        { header: "CC To", key: "CCTo", width: 10 },
        { header: "Fuel Type", key: "FuelType", width: 15 },
        { header: "NCB Status", key: "NCBSTATUS", width: 15 },
        { header: "Make", key: "MakeModal", width: 15 },
        { header: "Model", key: "Model", width: 15 },
        { header: "Variant", key: "Variant", width: 15 },
        { header: "ODD From", key: "ODDFrom", width: 10 },
        { header: "ODD To", key: "ODDTo", width: 10 },
        { header: "Based On", key: "BasedOn", width: 15 },
        { header: "Business Type", key: "BusinessType", width: 15 },
        { header: "PA", key: "PA", width: 15 },
        { header: "Organization Type", key: "OrganizationType", width: 15 },
        { header: "Nill Dep", key: "nillDepth", width: 15 },
        { header: "Customer Type", key: "CustomerType", width: 15 },
        { header: "IMT Type", key: "IMTType", width: 15 },
        { header: "Policy Paying Term", key: "policyPayingTerm", width: 20 },
        { header: "Reciveable", key: "Reciveable", width: 15 },
        { header: "Age From", key: "FromAge", width: 15 },
        { header: "Age To", key: "ToAge", width: 15 },
        { header: "PAPremium From", key: "PAPremiumFrom", width: 10 },
        { header: "PAPremium To", key: "PAPremiumTo", width: 10 },
        { header: "Inflow (Spl. Apvl)", key: "InflowSpecial", width: 15 },
      ];

      return { columns, rows, fileName: "Payout Grid List" };
    } catch (error) {
      console.error("Error:", error);
      return { columns: [], rows: [], fileName: "Payout Grid List" };
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="row mt-4">
              <div className="col-1" style={{ float: "left" }}>
                <div className="ListDesigncol">
                  <h4 className="card-title">PayoutGrid</h4>
                 
                </div>
              </div>
              <div
                className="col-11 d-flex justify-content-end align-items-center"
                style={{ gap: "5px" }}
              >

                <Link
                  className="btn mybtn"
                  to="/admin/masters/import-payoutgrid/data"
                  state={{
                    Mode: false,
                    value: "",
                  }}
                >
                  Import Payout Grid
                </Link>

                <Link
                  className="btn mybtn"
                  to="/admin/masters/backgroundimport-payoutgrid/data"
                  state={{
                    Mode: false,
                    value: "",
                  }}
                >
                  Background Import Payout Grid
                </Link>

                <Link
                  className="btn mybtn"
                  to="/admin/masters/BulkDeletePayoutGrid/data"
                  state={{
                    Mode: false,
                    value: "",
                  }}
                >
                  Bulk Delete PayoutGrid
                </Link>

                <Link
                  className="btn mybtn"
                  to="/admin/masters/BulkUpdatePayoutGrid/data"
                  state={{
                    Mode: false,
                    value: "",
                  }}
                >
                  Bulk Update PayoutGrid
                </Link>
              </div>
            </div>

            <div
              className=" p-2 container mt-2 filters"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "unset",
                flexDirection: "row",
                gap: "10px",
                zoom: "75%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <SearchInputFilter
                  setInput={setInput}
                  inputData={inputData}
                  style={{ width: "250px" }}
                  PlaceHolder="Search by, id..."
                />
                <div
                  onClick={() => {
                    getInsuranceCompany();
                  }}
                >
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={InsuranceCompanyOpt}
                    noOptionsMessage={() => "No Company Found"}
                    isLoading={CompanyLoading}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["AliasName"]}
                    onChange={(ele) => {
                      onSetPolicyFilter("InsuranceCompany", ele);

                      if (ele._id) {
                        setRTOGroupOpt([]);
                        onSetPolicyFilter("InsuranceCompany", ele);
                        onSetPolicyFilter("RTOGroup", {
                          _id: "",
                          GroupName: "-- Select RTO Group --",
                        });
                        setInsuranceCompanyid(ele._id);
                        getRTOGroupOpt(ele._id, "");
                      }
                    }}
                    value={InsuranceCompany}
                  />
                </div>

                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <input
                    className="form-control "
                    type="date"
                    name="startDate"
                    value={PayoutGrid?.startDate}
                    onChange={(e) => onChangeSetValueStart(e)}
                    required //done
                  />

                  <p>TO</p>
                  <input
                    className="form-control "
                    type="date"
                    name="endDate"
                    value={PayoutGrid?.endDate}
                    onChange={(e) => onChangeSetValueEnd(e)}
                    required //done
                  />
                </div>
                <DateRange setDateHandler={setDateHandler} />

                <div
                  onClick={() => {
                    getPolicyType();
                  }}
                >
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={PolicyTypeOpt}
                    noOptionsMessage={() => "No Company Found"}
                    isLoading={PolicyTypeLoading}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["PolicyTypeName"]}
                    onChange={(ele) => {
                      onSetPolicyFilter("PolicyType", ele);
                      if (ele._id) {
                        onSetPolicyFilter("RTOGroup", {
                          _id: "",
                          GroupName: "-- Select RTO Group --",
                        });
                        setRTOGroupOpt([]);
                        onSetPolicyFilter("PolicyType", ele);

                        getRTOGroupOpt(InsuranceCompanyid, ele._id);
                      }
                    }}
                    value={PolicyType}
                  />
                </div>

                <div className="">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={[
                      {
                        _id: "",
                        GroupName: "-- Select RTO Group --",
                      },
                      ...RTOGroupOpt,
                    ]}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => option["GroupName"]}
                    value={RTOGroup}
                    onChange={(ele) => {
                      onSetPolicyFilter("RTOGroup", ele);
                    }}
                  />
                </div>

              </div>
              <div className="d-flex gap-2">
                <div
                  className="searchBox"
                  style={{
                    width: "77px",
                    cursor: "pointer",
                  }}
                  onClick={() => filterDataHandler()}
                >
                  <MdSend />
                </div>
                <div
                  className="searchBox"
                  style={{
                    width: "77px",
                    cursor: "pointer",
                  }}
                  onClick={() => EraserHandler()}
                >
                  <BsEraser />
                </div>
              </div>
            </div>
            <div
              className=" p-2 container mt-1 filters"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "unset",
                flexDirection: "row",
                gap: "10px",
                zoom: "85%",
              }}
            >
              <div className="">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="LOB"
                  value={LOB}
                  // style={{ backgroundColor: "#f1e5ac" }}
                  onChange={(e) => {
                    onSetPolicyFilter("SelectedInsuranceType", [
                      { data: [], value: "" },
                    ]);

                    setLOB(e.target.value);
                  }}
                  required
                >
                  <option value="Motor">Motor</option>
                  <option value="Non-Motor">Non-Motor</option>
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                {SelectedInsuranceType &&
                  SelectedInsuranceType?.length > 0 &&
                  SelectedInsuranceType.map(
                    (data, index) =>
                      data &&
                      data?.data?.length > 0 && (
                        <MultiInsuranceTypeFilter
                          key={data.value}
                          Options={data}
                          setSelectedInsuranceType={onSetPolicyFilter}
                          SelectedInsuranceType={SelectedInsuranceType}
                          index={index}
                          OptionsValue={data.value}
                        />
                      )
                  )}
              </div>
              <div className="col d-flex justify-content-end align-items-center" style={{ gap: "10px" }}>
            <Link
                  className="btn mybtn"
                  to="/admin/masters/PayoutGrid"
                  state={{
                    Mode: false,
                    value: "",
                  }}
                >
                  Add PayoutGrid
                </Link>
              <ExcelExport getData={getExcelData} />
              <Link
                className="btn mybtn"
                to="/admin/masters/payout-grid/outflow"
                state={{
                  Mode: false,
                  value: "",
                }}
              >
                Outflow Grid
              </Link>
            </div>
            </div>
            
         
            {loading ? (
              <div
                className="container mt-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TableSkeleton />
              </div>
            ) : (
              <div
                className=" p-2 container mt-1"
                style={{
                  zoom: "65%",
                }}
              >
                {SavedData && (
                  <table class="table table-striped   table-bordered  overflow-x mt-3">
                    <thead>
                      <tr style={{ background: "#174379", color: "#fff" }}>
                        <th scope="col">S No.</th>
                        <th scope="col">DEF</th>
                        <th scope="col">DET</th>
                        <th scope="col">Insurance Company</th>
                        <th scope="col">Policy Type</th>
                        <th scope="col">Product</th>
                        <th scope="col">Fuel Type</th>
                        <th scope="col">Make</th>
                        <th scope="col">Model</th>
                        <th scope="col">GVW</th>
                        <th scope="col">Seater</th>
                        <th scope="col">RTO Cluster</th>
                        {/* <th scope="col">fuel type</th> */}

                        <th scope="col">Age </th>
                        <th scope="col">Business Type </th>
                        <th scope="col">Based On </th>
                        {/* <th scope="col">IMTType </th>
                        <th scope="col">NCBSTATUS </th> */}
                        <th scope="col">Inflow </th>
                        <th scope="col">EXTRA_OPTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {SavedData &&
                        SavedData.length > 0 &&
                        SavedData.map((data, index) => (
                          <tr key={data._id}>
                            <th scope="row">{index + 1}</th>
                            <td>{data?.DEF || ""}</td>
                            <td>{data?.DET || ""}</td>
                            <td>{data?.InsuranceCompany?.AliasName || ""}</td>
                            <td>{data?.PolicyType?.PolicyTypeName || ""}</td>
                            <td>{data?.InsuranceType?.InsuranceType || ""}</td>
                            <td>{data?.FuelType?.FuelType || ""}</td>
                            <td>{data?.MakeModal?.Make || ""}</td>
                            <td>{data?.Model?.Model ?? ""}</td>
                            <td>{`${data?.GVWFrom ?? ""}-${data?.GVWTo || ""
                              }`}</td>
                            <td>{`${data?.seaterFrom ?? ""}-${data?.seaterTo || ""
                              }`}</td>
                            <td>{data?.RTOGroup?.GroupName ?? ""}</td>
                            <td>{`${data?.FromAge ?? ""}-${data?.ToAge || ""
                              }`}</td>
                            <td>{data?.BusinessType ?? ""}</td>
                            <td>{data?.BasedOn ?? ""}</td>
                            <td>
                              <td>{data?.Reciveable ?? ""}</td>
                              {/* <table className="table table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <th
                                      scope="col"
                                      colSpan={
                                        data?.BasedOn
                                          ? data.BasedOn.split("+").length
                                          : 1
                                      }
                                    >
                                      Receivable
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row"></th>
                                    <th scope="row"></th>
                                    {data?.BasedOn?.split("+").map(
                                      (ele, idx) => (
                                        <td key={idx}>{ele}</td>
                                      )
                                    )}
                                  </tr>

                                  {data?.AddDetails.map((ele, idx) => (
                                    <tr key={idx}>
                                      <th scope="row">{idx + 1}</th>
                                      <th scope="row">
                                        {ele?.DEF?.split("T")[0]
                                          .split("-")
                                          .reverse()
                                          .join("-") || "N/A"}
                                      </th>
                                      {data?.BasedOn?.split("+").map(
                                        (based, idx) => (
                                          <td key={idx}>
                                            {ele[`Reciveable${based}`] || "N/A"}
                                          </td>
                                        )
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table> */}
                            </td>
                            <td>
                              <div className="col-md-2">
                                <div className="d-flex gap-3">
                                  <div>
                                    <Link
                                      state={{ view: true }}
                                      to={`/admin/masters/PayoutGrid/edit/${data._id}`}
                                    >
                                      <FaEye size={25} />
                                    </Link>
                                  </div>
                                  <div>
                                    <Link
                                      state={{ Mode: true }}
                                      to={`/admin/masters/PayoutGrid/edit/${data._id}`}
                                    >
                                      <BiEdit size={25} color="blue" />
                                    </Link>
                                  </div>
                                  <div>
                                    <AiFillDelete
                                      color="red"
                                      style={{ cursor: "pointer" }}
                                      size={25}
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            "Do you want to delete!"
                                          )
                                        ) {
                                          deletePayoutGrid(data?._id);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                {
                  <div
                    style={{
                      backgroundColor: "#1a2e45d7",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      marginTop: "5px",
                    }}
                  >
                    <TablePagination
                      component="div"
                      className="text-white"
                      rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                      count={SavedTotalDocs ?? 0}
                      page={pagination}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                }
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PayoutGrid;

//  <TableTransition
// AllKey={AllKey}
// data={data}
// editUrl={"/admin/PayoutGrid/edit"}
// Mode={true}
// deleteFunction={deletePayoutGrid}
// EditId={true}
// isPayoutGrid={true}
// />
