import React, { useEffect, useState } from "react";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import Select from "react-select";
import "../Purchase/Purchase.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { convertDateStringToDate, reversDate } from "../../Utils/usefullFunction";
import NewSalesExcel from "./NewSalesExcel";
import { AiFillFilePdf } from "react-icons/ai";
import axios from "axios";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import DateRange from "../DateRangeModule/DateRange";
const ViewSale = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const [EnterDate, setEnterDate] = useState(lastDay);
  const [inputData, setInput] = useState();
  const [TotalAmountOfSelectPolicy] = useState(0);
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();
  const [PolicySaved, setPolicySaved] = useState([]);
  // const [TDSAccount, setTDSAccount] = useState({
  //   _id: "",
  //   Name: "-- Select TDS Account --",
  // });
  const [loading, setLoading] = useState(false);
  const [Remark, setRemark] = useState();
  // const [TDSPercent, setTDSPercent] = useState(0);
  const [Data, setData] = useState([]);
  const [DebitAccount, setDebitAccount] = useState({
    _id: "",
    Name: "-- Select Debit Account",
  });

  // const [TotalAmount, setTotalAmount] = useState(0);
  const [InsuranceCompany, setInsuranceCompany] = useState({});
  console.log("InsuranceCompany",InsuranceCompany)
  const [NetAmount, setNetAmount] = useState(0);

  const getSingleSale = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await axiosInstance.get(`/Sale/single/${id}`);
      setEnterDate(data?.EnterDate.split("T")[0]);
      setRemark(data?.Remark);
      // setTDSPercent(data?.TDSPercent);
      // setTotalAmountOfSelectPolicy(data?.TotalAmount);
      setNetAmount(data?.NetAmount);
      setInsuranceCompany(data?.CreditAccount);
      setDebitAccount(data?.DebitAccount);


      data?.Policy?.sort((a, b) => new Date(a.IssueDate) - new Date(b.IssueDate));
      setData(data?.Policy);
      setPolicySaved(data?.Policy);
      setStartDate(data?.Policy[0]["IssueDate"]);
      setEndDate(data?.Policy[data?.Policy.length - 1]["IssueDate"]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  let FilteredPolicy;

  if (inputData) {
    FilteredPolicy = Data?.filter(
      (e) =>
        e?.VehicleNumber?.toLowerCase()?.includes(inputData?.toLowerCase()) ||
        e?.InsureeName?.toLowerCase()?.includes(inputData?.toLowerCase()) ||
        e?.PolicyNumber?.toLowerCase()?.includes(inputData?.toLowerCase())
    );
  } else {
    FilteredPolicy = Data;
  }

  useEffect(() => {
    getSingleSale();
  }, []);
  const setDateHandler = (ranges) => {
    setEndDate(convertDateStringToDate(ranges.endDate));
    setStartDate(convertDateStringToDate(ranges.startDate));
  };

  const filterDataByDate =()=>{
    const Start = new Date(StartDate);
    const End = new Date(EndDate);
    const filteredDates = PolicySaved.filter((date) => {
      const currentDate = new Date(date.IssueDate);
      return currentDate >= Start && currentDate <=End;
    });

    setData(JSON.parse(JSON.stringify(filteredDates)));
  }

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          {
            <div className="row">
              <div className="card-body CreateCard">
                <h4 className="card-title">View Sale</h4>
                <hr />
                <br />
                <div className="row mx-2 ">
                  <div className="col-md-4 align-items-center">
                    <label>Debit Account</label>
                    <input
                      disabled={true}
                      className="form-control "
                      type="input"
                      value={`${InsuranceCompany["AliasName"] }`?? ""}
                      placeholder="Enter Receipt"
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>EnterDate</label>
                    <input
                      className="form-control "
                      type="date"
                      name="EnterDate"
                      value={EnterDate}
                      placeholder="Enter Receipt "
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Credit Account</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"

                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => {
                        const Name = `${option?.Name}`;
                        return Name;
                      }}
                      value={DebitAccount}
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Remark</label>
                    <input
                      className="form-control "
                      type="text"
                      name="Remark"
                      value={Remark}
                      placeholder="Remark"
                      required //done
                    />
                  </div>
                  {/* <div className="col-md-4 align-items-center">
                    <label>TDS Account</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          Name: "-- Select TDS Account --",
                        },
                        ...TDSOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => {
                        const Name = `${option?.Name}`;
                        return Name;
                      }}
                      onChange={setTDSAccount}
                      value={TDSAccount}
                    />
                  </div> */}
                  {/* <div className="col-md-4 align-items-center">
                    <label>TDS Percent</label>
                    <input
                      className="form-control "
                      type="number"
                      name="TDSPercent"
                      value={TDSPercent}
                      onChange={(e) => setTDSPercent(e.target.value)}
                      placeholder="Enter TDS Percent "
                      required //done
                    />
                  </div> */}
                </div>
              </div>
              <div className="card-body CreateCard">
                <div
                  className="p-2 filters"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "unset",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <div className="d-flex gap-3">
                      <SearchInputFilter
                        setInput={setInput}
                        inputData={inputData}
                        PlaceHolder="Policy No.,Vehicle No.,Insuree Name*"
                      />
                         <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          // alignItems: "baseline",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <input
                          className="form-control "
                          type="date"
                          name="StartDate"
                          value={StartDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required //done
                        />

                        <p>-</p>
                        <input
                          className="form-control "
                          type="date"
                          name="EndDate"
                          value={EndDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required //done
                        />
                        <DateRange setDateHandler={setDateHandler} />
                      </div>
                       <DownloadPdf
                        id={id}
                        Name={`${InsuranceCompany["AliasName"]}.pdf`}
                      />
                      <NewSalesExcel
                        _id={id}
                        Name={`${InsuranceCompany["AliasName"]}.pdf`}
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="searchBox"
                      style={{
                        width: "77px",
                        cursor: "pointer",
                      }}
                      onClick={() => filterDataByDate()}
                    >
                      <MdSend />
                    </div>
                    <div
                      className="searchBox"
                      style={{
                        width: "77px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setStartDate(PolicySaved[0]["IssueDate"]);
                        setEndDate(PolicySaved[PolicySaved.length - 1]["IssueDate"]);
                        setData(JSON.parse(JSON.stringify(PolicySaved)))
                      }}
                    >
                      <BsEraser />
                    </div>
                  </div>
                </div>

                <div className="PolicyCounts">
                  <div className="PolicyCounts-Item">
                    <b>Total Net Amount :-</b> {NetAmount}
                  </div>

                  <div className="PolicyCounts-Item">
                    {" "}
                    <b>Total Amount :-</b> {TotalAmountOfSelectPolicy}
                  </div>
                </div>

                {loading ? (
                  <div
                    className=" p-2 "
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <div
                    className=" p-2 "
                    style={{
                      zoom: "70%",
                    }}
                  >
                    {Data && (
                      <table
                        class="table table-striped   table-bordered  overflow-x mt-3"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy Details</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>

                            <th scope="col">Vehicle No.</th>

                            <th scope="col">Amount Receive</th>
                          </tr>
                        </thead>

                        <tbody>
                          {FilteredPolicy &&
                            FilteredPolicy?.length !== 0 &&
                            FilteredPolicy.map((data, index) => (
                              <tr key={data._id}>
                                <th>{index + 1}</th>
                                <th>{reversDate(data?.IssueDate)}</th>
                                <th>
                                  <tr> {data?.InsuranceCompany?.Name}</tr>
                                  <tr> {data?.InsuranceType?.InsuranceType}</tr>
                                  <tr> RSD:-{reversDate(data?.RSD)}</tr>
                                  <tr> {data?.InsureeName}</tr>
                                  <tr> {data?.InsuranceCompany?.AliasName}</tr>

                                </th>

                                <th>
                                  <tr>
                                    <Link
                                      to={`${REACT_APP_FileURL}${data.NewPolicyCopy}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {" "}
                                      {data?.PolicyNumber}
                                    </Link>{" "}
                                  </tr>
                                  <tr> {data?.MakeModal?.Name}</tr>
                                  <tr> {data?.Variant}</tr>
                                  <tr> YOM- {data?.YearOfManufacture}</tr>
                                  <tr> {data?.PolicyType?.PolicyTypeName}</tr>

                                </th>

                                <th>
                                  <tr>Gross </tr>
                                  <tr>Net </tr>
                                  <tr>TP</tr>
                                  <tr>OD</tr>
                                  <tr>Flat</tr>
                                </th>

                                <th>
                                  <tr> ₹ {Math.round(data?.GrossPremium)}</tr>
                                  <tr> ₹ {data?.NETPremium}</tr>
                                  <tr> ₹ {data?.TPPremium}</tr>
                                  <tr> ₹ {data?.ODPremium}</tr>
                                  {/* <tr> ₹ {data?.ODPremium}</tr> */}
                                </th>
                                <th>
                                  <tr>
                                    <br />
                                  </tr>
                                  {data?.BasedOn.includes("NET") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableNET / 10 +
                                        "X NET"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("TP") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableTP / 10 +
                                        "X TP"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("OD") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableOD / 10 +
                                        "X OD"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("FLAT") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableFLAT +
                                        "FLAT"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                </th>
                                <th>
                                  <tr> {data?.PaymentMode}</tr>
                                  <tr> {data?.PolicyType?.Name}</tr>
                                  <tr> {data?.VehicleNumber}</tr>
                                  {/* <tr> {data?.ODPremium}</tr> */}
                                </th>
                                <th>
                                  <tr>
                                    {" "}
                                    ₹ {data?.Commission?.TotalPolicyProfit}
                                  </tr>
                                </th>
                              </tr>
                            ))}

                        </tbody>
                      </table>
                    )}
                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      ></div>
                    }
                  </div>
                )}

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                  <Link className="btn mybtn" to="/admin/Sale">
                    Go Back
                  </Link>
                  <Link
                    className="btn mybtn"
                    to={`/admin/Sale/${id}`}
                  >
                    Edit
                  </Link>
                </div>
                
              </div>
            </div>
          }
        </div>
      </div>
      <Footer />
    </>
  );
};
const DownloadPdf = ({ id, Name }) => {
  const [loading, setLoading] = useState(false);

  const getSingleSale = async () => {
    setLoading(true);
    try {
      axios({
        url: `${REACT_APP_FileURL}/api/v1/Sale/getGenerateReport/${id}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = Name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
                       <img src={require("../utils/UiImages/Download.gif")} alt="My GIF" style={{
                        width: "35px",
                        height: "34px",
                    }} />
      ) : (
        <AiFillFilePdf
          onClick={() => {
            getSingleSale();
          }}
          size={35}
          style={{
            backgroundColor: "green",
            color: "#fff",
            padding: "7px",
            borderRadius: "5px",
          }}
        />
      )}
    </>
  );
};

export default ViewSale;
