import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <></>
    // <div data-topbar="dark" data-layout="horizontal" data-layout-size="boxed">
    //   <footer className="Adminfooter">
    //     <div className="container-fluid">
    //       <div className="row">
    //         <div
    //           className="col-12"
    //           style={{ fontWeight: 800, letterSpacing: 1 }}
    //         >
    //           Designed &amp; Developed By Innovate X
    //         </div>
    //       </div>
    //     </div>
    //   </footer>
    // </div>
  );
};

export default Footer;
