import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Select from "react-select";
import "./Employee.css";
import FileUploadModal from "../Modals/FileUploadModal";
import ViewFileModal from "../Modals/ViewFileModal";
import { DESIGNATION } from "../utils/database";
import PortalBankDetails from "../../Utils/PortalBankDetails";

const CreateEmployee = () => {
  const DELETE_URL = "/Employee/delete/Image";
  const navigate = useNavigate();
  const { state } = useLocation();
  const { value, Mode, view } = state || {};
  const [Name, setName] = useState(value?.Name || "");
  const [OldReportingTo, setOldReportingTo] = useState("");
  const [MiddleName, setMiddleName] = useState(value?.MiddleName || "");
  const [LastName, setLastName] = useState(value?.LastName || "");
  const [ModalData, setModalData] = useState({});
  const [MobileNumber, setMobileNumber] = useState(value?.MobileNumber || "");
  const [Address, setAddress] = useState(value?.Address || "");
  const [ReferralBy, setReferralBy] = useState(value?.ReferralBy || "");
  const [NomineeName, setNomineeName] = useState(value?.NomineeName || "");
  const [BankDetails, setBankDetails] = useState([]);
  const [BankName, setBankName] = useState(value?.BankName || "");
  const [IFSC, setIFSC] = useState(value?.IFSC || "");
  const [BankAccountNumber, setBankAccountNumber] = useState(value?.BankAccountNumber || "");
  const [NomineeRelation, setNomineeRelation] = useState(
    value?.NomineeRelation || ""
  );

  const [BranchData, setBranchData] = useState([]);
  const [Branch, setBranch] = useState(
    value?.Branch ?? {
      _id: "",
      BranchName: "-- Select Branch --",
    }
  );
  const [PFNumberCheckbox, setPFNumberCheckbox] = useState(
    value?.PFNumberCheckbox || false
  );
  const [PFNumber, setPFNumber] = useState(value?.PFNumber || "");
  const [EmployeeStateInsurance, setEmployeeStateInsurance] = useState(
    value?.EmployeeStateInsurance || ""
  );
  const [ESICheckbox, setESICheckbox] = useState(value?.ESICheckbox || false);
  const [Photo, setPhoto] = useState();
  const [ReportingTo, setReportingTo] = useState(
    value?.ReportingTo ?? {
      _id: "",
      Name: "-- Select Reporting To --",
    }
  );

  const [Data, setData] = useState([]);
  const [Email, setEmail] = useState(value?.Email || "");
  const [SpokeLocation, setSpokeLocation] = useState(
    value?.SpokeLocation || ""
  );
  const [EmployeeCode] = useState(value?.EmployeeCode || "");

  // const [Password, setPassword] = useState(value?.Password || "");
  const [FatherName, setFatherName] = useState(value?.FatherName || "");
  const [HusbandName, setHusbandName] = useState(value?.HusbandName || "");
  const [State, setState] = useState(value?.State || "");
  const [EmergencyContactNumber, setEmergencyContactNumber] = useState(
    value?.EmergencyContactNumber || ""
  );
  const [WeekendDay, setWeekendDay] = useState(value?.WeekendDay || "");
  const [Qualification, setQualification] = useState(
    value?.Qualification || ""
  );
  const [Religion, setReligion] = useState(value?.Religion || "");
  const [BloodGroup, setBloodGroup] = useState(value?.BloodGroup || "");
  const [Digination, setDigination] = useState(value?.Digination || "");
  const [Department, setDepartment] = useState(value?.Department || "Sales");
  const [City, setCity] = useState(value?.City || "");
  const [Pincode, setPincode] = useState(value?.Pincode || "");
  const [Permission, setPermission] = useState(value?.Permission?._id || "");
  const [PermissionOpt, setPermissionOpt] = useState([]);
  const [DOB, setDOB] = useState(value?.DOB || "");
  const [MaritalStatus, setMaritalStatus] = useState(
    value?.MaritalStatus || "No"
  );
  const [DOA, setDOA] = useState(value?.DOA || "");
  const [JoiningDate, setJoiningDate] = useState(value?.JoiningDate || "");
  const [Gender, setGender] = useState(value?.Gender || "male");
  const [PanNumber, setPanNumber] = useState(value?.PanNumber || "");
  const [AadhaarNumber, setAadhaarNumber] = useState(
    value?.AadhaarNumber || ""
  );
  const [MarkSheet, setMarkSheet] = useState();
  // IMAGES;
  const [panCard, setpanCard] = useState();
  const [adharcardFrontImage, setAdharcardFrontImage] = useState();
  const [adharcardBackImage, setAdharcardBackImage] = useState();
  const [Cheque, setCheque] = useState();
  const [Contract, setContract] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const EMAIL_VALIDATED = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      // const PAN_VALIDATED = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (!EMAIL_VALIDATED.test(Email)) {
        return ToastError("Invalid Email Address");
      }
      if (String(MobileNumber).length !== 10) {
        return ToastError("Invalid Mobile Number");
      }

      const EmpData = new FormData();
      EmpData.append("Name", Name);
      // EmpData.append("Salutation", Salutation);
      EmpData.append("MiddleName", MiddleName);
      EmpData.append("LastName", LastName);
      EmpData.append("JoiningDate", JoiningDate);
      EmpData.append("MobileNumber", MobileNumber);
      EmpData.append("ReportingTo", ReportingTo._id);
      EmpData.append("EmployeeCode", EmployeeCode);
      EmpData.append("Email", Email);
      // EmpData.append("Password", Password);
      EmpData.append("BankName", BankName);
      EmpData.append("IFSC", IFSC);
      EmpData.append("BankAccountNumber", BankAccountNumber);

      EmpData.append("DOB", DOB);
      EmpData.append("DOA", DOA);
      EmpData.append("FatherName", FatherName);
      EmpData.append("HusbandName", HusbandName);
      EmpData.append("Gender", Gender);
      EmpData.append("Permission", Permission);
      EmpData.append("MaritalStatus", MaritalStatus);
      EmpData.append("Digination", Digination);
      EmpData.append("Department", Department);
      EmpData.append("AadhaarNumber", AadhaarNumber);
      EmpData.append("PanNumber", PanNumber.toUpperCase());
      EmpData.append("ReferralBy", ReferralBy);
      EmpData.append("NomineeName", NomineeName);
      EmpData.append("NomineeRelation", NomineeRelation);
      EmpData.append("Branch", Branch._id);
      EmpData.append("PFNumber", PFNumber);
      EmpData.append("SpokeLocation", SpokeLocation);
      EmpData.append("PFNumberCheckbox", PFNumberCheckbox);
      EmpData.append("ESICheckbox", ESICheckbox);
      EmpData.append("EmployeeStateInsurance", EmployeeStateInsurance);
      EmpData.append("Address", Address);
      EmpData.append("State", State);
      EmpData.append("City", City);
      EmpData.append("Pincode", Pincode);
      EmpData.append("EmergencyContactNumber", EmergencyContactNumber);
      EmpData.append("WeekendDay", WeekendDay);
      EmpData.append("Qualification", Qualification);
      EmpData.append("Religion", Religion);
      EmpData.append("BloodGroup", BloodGroup);

      // image
      EmpData.append("profilePic", Photo);
      EmpData.append("adharcardFrontImage", adharcardFrontImage);
      EmpData.append("adharcardBackImage", adharcardBackImage);
      EmpData.append("MarkSheet", MarkSheet);
      EmpData.append("Cheque", Cheque);
      EmpData.append("panCard", panCard);
      EmpData.append("Contract", Contract);

      // bank details
      const myData = BankDetails.map((ele) => ele._id);
      EmpData.append("BankDetails", JSON.stringify(myData));
      const {
        data: { success, message },
      } = await axiosInstance.post("/Employee", EmpData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Employee/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const EMAIL_VALIDATED = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!EMAIL_VALIDATED.test(Email)) {
      return ToastWarn("Please Enter Valid Email Address");
    }
    // if (String(MobileNumber).length !== 10) {
    //   return ToastWarn("Please Enter valid Mobile Number");
    // }
    // if (PanNumber.length !== 10) {
    //   return ToastWarn("Please Enter valid Pan Number");
    // }
    // if (String(AadhaarNumber).length !== 12) {
    //   return ToastWarn("Please Enter valid Aadhaar Number");
    // }
    // if (!ReportingTo) {
    //   return ToastWarn("Please Provide ReportingTo");
    // }
    // if (ESICheckbox && !EmployeeStateInsurance) {
    //   return ToastWarn("Please Provide Employee State Insurance Number");
    // }
    // if (PFNumberCheckbox && !PFNumber) {
    //   return ToastWarn("Please Provide Employee PF Number");
    // }
    // if (!Permission) {
    //   return ToastWarn("Permission is Required");
    // }
    const EmpData = new FormData();
    EmpData.append("Name", Name);
    // EmpData.append("Salutation", Salutation);
    EmpData.append("MiddleName", MiddleName);
    EmpData.append("LastName", LastName);
    EmpData.append("JoiningDate", JoiningDate);
    EmpData.append("MobileNumber", MobileNumber);
    EmpData.append("ReportingTo", ReportingTo._id);
    EmpData.append("OldReportingTo", OldReportingTo);
    EmpData.append("EmployeeCode", EmployeeCode);
    EmpData.append("Email", Email);
    EmpData.append("BankName", BankName);
    EmpData.append("IFSC", IFSC);
    EmpData.append("BankAccountNumber", BankAccountNumber);
    // EmpData.append("Password", Password);
    EmpData.append("SpokeLocation", SpokeLocation);
    EmpData.append("DOB", DOB);
    EmpData.append("DOA", DOA);
    EmpData.append("FatherName", FatherName);
    EmpData.append("HusbandName", HusbandName);
    EmpData.append("Gender", Gender);
    EmpData.append("Permission", Permission);
    EmpData.append("MaritalStatus", MaritalStatus);
    EmpData.append("Digination", Digination);
    EmpData.append("Department", Department);
    EmpData.append("AadhaarNumber", AadhaarNumber);
    EmpData.append("PanNumber", PanNumber.toUpperCase());
    EmpData.append("ReferralBy", ReferralBy);
    EmpData.append("NomineeName", NomineeName);
    EmpData.append("NomineeRelation", NomineeRelation);
    EmpData.append("Branch", Branch._id);
    EmpData.append("PFNumber", PFNumber);
    EmpData.append("PFNumberCheckbox", PFNumberCheckbox);
    EmpData.append("ESICheckbox", ESICheckbox);
    EmpData.append("EmployeeStateInsurance", EmployeeStateInsurance);
    EmpData.append("Address", Address);
    EmpData.append("State", State);
    EmpData.append("City", City);
    EmpData.append("Pincode", Pincode);
    EmpData.append("EmergencyContactNumber", EmergencyContactNumber);
    EmpData.append("WeekendDay", WeekendDay);
    EmpData.append("Qualification", Qualification);
    EmpData.append("Religion", Religion);
    EmpData.append("BloodGroup", BloodGroup);

    // image
    EmpData.append("profilePic", Photo);
    EmpData.append("adharcardFrontImage", adharcardFrontImage);
    EmpData.append("adharcardBackImage", adharcardBackImage);
    EmpData.append("MarkSheet", MarkSheet);
    EmpData.append("Cheque", Cheque);
    EmpData.append("panCard", panCard);
    EmpData.append("Contract", Contract);

    try {
      const {
        data: { success, message },
      } = await axiosInstance.put(`/Employee/${value?._id}`, EmpData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Employee/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getData = async () => {
    try {

      const {
        data: { data },
      } = await axiosInstance.get("/Employee/selectBoxDropdown", {

      });
      const Emp = data.map(
        ({ Name = "", MiddleName = "", LastName = "", _id }) => {
          return {
            Name: `${Name} ${MiddleName} ${LastName}`,
            _id,
          };
        }
      );
      setData(Emp);
    } catch (error) { }
  };

  useEffect(() => {
    if (ReportingTo) {
      setOldReportingTo(ReportingTo._id || "");
    }
  }, []);

  const getBranch = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/Branch");

      setBranchData(data);
    } catch (error) { }
  };
  useEffect(() => {
    getBranch();
  }, []);

  const getPermission = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/RBAC");
      setPermissionOpt(data);
    } catch (error) { }
  };
  useEffect(() => {
    getData();
    getPermission();
  }, []);

  return (
    <>
      <Header />

      <ViewFileModal
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        ModalData={ModalData}
      />

      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <form onSubmit={(e) => (Mode ? handleEdit(e) : handleCreate(e))}>
            <div className="row">
              <section
                className="push "
                style={{
                  position: "relative",
                  transition: " margin-left .5s",
                  zoom: "90%",
                }}
              >
                <div className="CreateCard  p-4">
                  <div className="row mx-2 ">
                    <h5>
                      {view
                        ? "View Employee"
                        : Mode
                          ? "Edit Employee"
                          : "Create Employee"}
                    </h5>
                    <hr className="mt-3" />
                    {
                      <div className="col-md-1 align-items-center">
                        <label>Emp-Id</label>
                        <input
                          disabled={true}
                          className="form-control "
                          type="text"
                          name="EmployeeCode"
                          value={EmployeeCode}
                          // onChange={(e) => setEmployeeCode(e.target.value)}
                          placeholder="Emp-Id"
                        />
                      </div>
                    }

                    <div className="col-md-8 align-items-center">
                      <label required>Name</label>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        {/* <select
                          class="form-select"
                          style={{
                            width: "20%",
                          }}
                          aria-label="Default select example"
                          name="Salutation"
                          value={Salutation}
                          onChange={(e) => setSalutation(e.target.value)}
                          placeholder="Salutation"
                          disabled={view}
                        >
                          <option value="Mr">Mr.</option>
                          <option value="Mrs">Mrs.</option>
                          <option value="Miss">Miss.</option>
                          <option value="Ms">Ms.</option>
                        </select> */}
                        <input
                          disabled={view}
                          className="form-control myName"
                          type="text"
                          name="Name"
                          value={Name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder=" First Name"
                          aria-label="Default select example"
                          required
                        />
                        <input
                          disabled={view}
                          className="form-control myName "
                          type="text"
                          name="MiddleName"
                          value={MiddleName}
                          onChange={(e) => setMiddleName(e.target.value)}
                          placeholder=" Middle Name"
                        />
                        <input
                          disabled={view}
                          className="form-control myName"
                          type="text"
                          name="LastName"
                          value={LastName}
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label required>Branch</label>
                      {/* <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        name="Branch"
                        value={Branch}
                        onChange={(e) => setBranch(e.target.value)}
                        placeholder="Nominee Relation"
                      >
                        <option value="">Select Branch </option>
                        {BranchData &&
                          BranchData.map((data) => (
                            <option value={data._id}>{data.BranchName}</option>
                          ))}
                      </select> */}
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={[
                          {
                            _id: "",
                            BranchName: "-- Select Broker --",
                          },
                          ...BranchData,
                        ]}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["BranchName"]}
                        value={Branch}
                        onChange={setBranch}
                      />
                    </div>

                    <div className="col-md-3 align-items-center">
                      <label>Reporting To</label>
                      {/* <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setReportingTo(e.target.value)}
                        value={ReportingTo}
                      >
                        <option value="">Select Reporting To</option>
                        {Data &&
                          Data.map((data) => (
                            <option value={data._id}>{data.Name}</option>
                          ))}
                      </select> */}
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={[
                          {
                            _id: "",
                            Name: "-- Select Reporting To --",
                          },
                          ...Data,
                        ]}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["Name"]}
                        value={ReportingTo}
                        onChange={setReportingTo}
                      />
                    </div>

                    <div className="col-md-3 align-items-center">
                      <label required>Email</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Email"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Employee Email"
                        required
                      />
                    </div>

                    {/* <div className="col-md-3 align-items-center">
                    <label>Password</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="text"
                      name="Password"
                      value={Password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter Employee Password"
                      
                    />
                  </div> */}
                    <div className="col-md-3 align-items-center">
                      <label required>Mobile Number</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="Number"
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 10))
                        }
                        name="MobileNumber"
                        value={MobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        placeholder="MobileNumber"
                        required
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>DOB</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="date"
                        name="DOB"
                        value={DOB}
                        onChange={(e) => setDOB(e.target.value)}
                        placeholder="Enter Pos DOB"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Father Name</label>
                      <input
                        disabled={view}
                        className="form-control"
                        type="text"
                        name="FatherName"
                        value={FatherName}
                        onChange={(e) => setFatherName(e.target.value)}
                        placeholder="Enter FatherName "
                      />
                    </div>
                    {/* {Salutation === "Mrs" && (
                      <div className="col-md-3 align-items-center">
                        <label>Husband Name</label>
                        <input
                          disabled={view}
                          className="form-control"
                          type="text"
                          name="HusbandName"
                          value={HusbandName}
                          onChange={(e) => setHusbandName(e.target.value)}
                          placeholder="Enter Husband Name "
                        />
                      </div>
                    )} */}
                    <div className="col-md-3 align-items-center">
                      <label>Gender</label>
                      <select
                        disabled={view}
                        name="Gender"
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setGender(e.target.value)}
                        value={Gender}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>

                    <div className="col-md-3 align-items-center">
                      <label>Marital Status</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setMaritalStatus(e.target.value)}
                        value={MaritalStatus}
                      >
                        <option value="yes">Yes</option>
                        <option selected value="No">
                          No
                        </option>
                      </select>
                    </div>
                    {MaritalStatus === "yes" && (
                      <div className="col-md-3 align-items-center">
                        <label>DOA</label>
                        <input
                          disabled={view}
                          className="form-control "
                          type="date"
                          name="DOA"
                          value={DOA}
                          onChange={(e) => setDOA(e.target.value)}
                          placeholder="Enter Pos DOA"
                        />
                      </div>
                    )}

                    <div className="col-md-3 align-items-center">
                      <label>Joining Date</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="date"
                        name="JoiningDate"
                        value={JoiningDate}
                        onChange={(e) => setJoiningDate(e.target.value)}
                        placeholder="Joining Date"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Aadhaar Number</label>
                      <input
                        disabled={view}
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 12))
                        }
                        className="form-control "
                        type="Number"
                        name="AadhaarNumber"
                        value={AadhaarNumber}
                        onChange={(e) => setAadhaarNumber(e.target.value)}
                        placeholder="Enter AadhaarNumber"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Pan Number</label>
                      <input
                        disabled={view}
                        className="form-control text-uppercase "
                        type="text"
                        name="PanNumber"
                        minLength={10}
                        maxLength={10}
                        value={PanNumber}
                        onChange={(e) => setPanNumber(e.target.value)}
                        placeholder="Enter PanNumber"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Department</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        name="Department"
                        value={Department}
                        onChange={(e) => setDepartment(e.target.value)}
                        placeholder="Department"
                      >
                        <option value="">Select Department </option>
                        <option value="Sales">Sales</option>
                        <option value="Accounts">Accounts</option>
                        <option value="Hr">Hr</option>
                        <option value="Operation">Operation</option>
                        <option value="TeamLeader">Team Leader</option>
                        <option value="QC">QC</option>
                      </select>
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Designation</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        name="Digination"
                        value={Digination}
                        onChange={(e) => setDigination(e.target.value)}
                        placeholder="Enter Designation"
                      >
                        <option value="">Select Designation</option>
                        {DESIGNATION.get(Department) &&
                          DESIGNATION.get(Department).length !== 0 ? (
                          DESIGNATION.get(Department).map((ele) => (
                            <option key={ele} value={ele}>
                              {ele}
                            </option>
                          ))
                        ) : (
                          <option value="">No option</option>
                        )}
                      </select>
                      {/* <input
                      disabled={view}
                      className="form-control "
                      type="text"
                      name="Digination"
                      value={Digination}
                      onChange={(e) => setDigination(e.target.value)}
                      placeholder="Enter Designation"
                      
                    /> */}
                    </div>

                    <div className="col-md-3 align-items-center">
                      <label>SPOC</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="SpokeLocation"
                        value={SpokeLocation}
                        onChange={(e) => setSpokeLocation(e.target.value)}
                        placeholder="SPOC"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Referral by</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Referral by"
                        value={ReferralBy}
                        onChange={(e) => setReferralBy(e.target.value)}
                        placeholder="Referral By"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Nominee Name</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="NomineeName"
                        value={NomineeName}
                        onChange={(e) => setNomineeName(e.target.value)}
                        placeholder="Nominee Name"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Nominee Relation</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        name="NomineeRelation"
                        value={NomineeRelation}
                        onChange={(e) => setNomineeRelation(e.target.value)}
                        placeholder="Nominee Relation"
                      >
                        <option value="">Select Nominee Relation </option>
                        <option value="Father">Father</option>
                        <option value="Spouse">Spouse</option>
                      </select>
                    </div>

                    <div className="col-md-3 align-items-center">
                      <label>
                        {" "}
                        <input
                          disabled={view}
                          type="checkbox"
                          name="PFNumberCheckbox"
                          checked={PFNumberCheckbox}
                          onClick={(e) => setPFNumberCheckbox(e.target.checked)}
                        />{" "}
                        &nbsp; PF Number
                      </label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="PFNumber"
                        value={PFNumber}
                        onChange={(e) => setPFNumber(e.target.value)}
                        placeholder="IF Yes Enter PF Number Account Number"
                      />
                      <label
                        style={{
                          color: "gray",
                          fontSize: "small",
                        }}
                      >
                        &nbsp; 15000 limit is applicable{" "}
                      </label>
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>
                        <input
                          disabled={view}
                          type="checkbox"
                          name="ESICheckbox"
                          id="ESICheckbox"
                          checked={ESICheckbox}
                          onClick={(e) => setESICheckbox(e.target.checked)}
                        />
                        &nbsp;Employee State Insurance
                      </label>
                      <input
                        disabled={view}
                        className="form-control"
                        type="text"
                        name="EmployeeStateInsurance"
                        value={EmployeeStateInsurance}
                        onChange={(e) =>
                          setEmployeeStateInsurance(e.target.value)
                        }
                        placeholder="IF Yes Enter Employee State Insurance Account Number"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label required>Permission</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setPermission(e.target.value)}
                        value={Permission}
                      >
                        <option value="">-----Select Permission------</option>
                        {PermissionOpt &&
                          PermissionOpt.map((data) => (
                            <option value={data._id}>{data?.Roll}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="CreateCard p-4">
                  <div className="row mx-4 ">
                    <h5>
                      {Mode
                        ? "Edit Additional details"
                        : "Add Additional details"}
                    </h5>
                    {/* Pos Information */}
                    <hr className="mt-1 " />
                    <div className="col-md-3 align-items-center">
                      <label>Address</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Address"
                        value={Address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Address"
                      />
                    </div>

                    <div className="col-md-3 align-items-center">
                      <label>State</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="State"
                        value={State}
                        onChange={(e) => setState(e.target.value)}
                        placeholder="Enter State"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>City</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="City"
                        value={City}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Enter City"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Pin code</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="Pincode"
                        value={Pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        placeholder="Enter Pincode"
                      />
                    </div>

                    <div className="col-md-3 align-items-center">
                      <label>Emergency Contact Number</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="EmergencyContactNumber"
                        value={EmergencyContactNumber}
                        onChange={(e) =>
                          setEmergencyContactNumber(e.target.value)
                        }
                        placeholder="Emergency Contact Number"
                      />
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Week of Day</label>
                      <select
                        disabled={view}
                        name="WeekendDay"
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setWeekendDay(e.target.value)}
                        value={WeekendDay}
                      >
                        <option value="">Select Week Of Day </option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                      </select>
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Qualification</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setQualification(e.target.value)}
                        value={Qualification}
                        name="Qualification"
                      >
                        <option value="">Select Qualification </option>
                        <option value="high_school">High School</option>
                        <option value="diploma">Diploma</option>
                        <option value="bachelor">Bachelor's Degree</option>
                        <option value="master">Master's Degree</option>
                        <option value="phd">PhD</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Religion</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setReligion(e.target.value)}
                        value={Religion}
                        name="Religion"
                      >
                        <option value="">Select Religion </option>
                        <option value="christianity">Christianity</option>
                        <option value="islam">Islam</option>
                        <option value="hinduism">Hinduism</option>
                        <option value="buddhism">Buddhism</option>
                        <option value="judaism">Judaism</option>
                        <option value="sikhism">Sikhism</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className="col-md-3 align-items-center">
                      <label>Blood group</label>
                      <select
                        disabled={view}
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setBloodGroup(e.target.value)}
                        value={BloodGroup}
                      >
                        <option value="">Select Blood Group</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="CreateCard p-4">
                  <div className="row mx-4 ">
                    <h5>{Mode ? "Edit Bank Details" : "Fill Bank Details"}</h5>
                    {/* Pos Information */}
                    <hr className="mt-1 " />

                    {/* <PortalBankDetails
                      view={view}
                      Name={Name}
                      MiddleName
                      LastName={MiddleName}
                      value={value}
                      Mode={Mode}
                      BankDetails={BankDetails}
                      setBankDetails={setBankDetails}
                      For="Employee"
                    /> */}

                    <div className="col-md-4 align-items-center">
                      <label>Bank Name</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="BankName"
                        value={BankName}
                        onChange={(e) => setBankName(e.target.value)}
                        placeholder="Enter Bank Name"
                      />
                    </div>

                    <div className="col-md-4 align-items-center">
                      <label>IFSC Code</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="IFSC"
                        value={IFSC}
                        onChange={(e) => setIFSC(e.target.value)}
                        placeholder="Enter IFSC"
                      />
                    </div>

                    <div className="col-md-4 align-items-center">
                      <label>Bank Account Number</label>
                      <input
                        disabled={view}
                        className="form-control "
                        type="text"
                        name="BankAccountNumber"
                        value={BankAccountNumber}
                        onChange={(e) => setBankAccountNumber(e.target.value)}
                        placeholder="Enter BankAccountNumber"
                      />
                    </div>


                  </div>
                </div>
                <div
                  className="CreateCard p-4"
                  style={{
                    zoom: "90%",
                  }}
                >
                  <div className="row mx-4 ">
                    <h5> Document Upload </h5>
                    {/* Pos Information */}

                    <hr className="mt-1 " />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <FileUploadModal
                        filedName="profilePic"
                        loadModal
                        id={value?._id}
                        deleteUrl={DELETE_URL}
                        index={"1"}
                        Mode={Mode}
                        Url={value?.profilePic ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setPhoto}
                        Name="Profile Pic"
                      />
                      <FileUploadModal
                        filedName="adharcardFrontImage"
                        dModal
                        id={value?._id}
                        deleteUrl={DELETE_URL}
                        index={"2"}
                        Mode={Mode}
                        Url={value?.adharcardFrontImage ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setAdharcardFrontImage}
                        Name="Aadharcard Front"
                      />
                      {/* <div className="col-md-3 align-items-center">
                    <label>Aadharcard Back Image*</label>
                    {Mode && value?.adharcardBackImage && (
                      <>
                        <FaSearch
                          onClick={() => {
                            setModalData({
                              MyLink: `${REACT_APP_FileURL}${value.adharcardBackImage}`,
                              LinkType: "Image",
                              Name: "Adharcard Back Image",
                            });

                            handleOpen();
                          }}
                          style={{
                            color: "blue",
                          }}
                        />
                      </>
                    )}
                    <input
                      disabled={view}
                      className="form-control"
                      type="file"
                      onChange={(e) => setAdharcardBackImage(e.target.files[0])}
                      
                    />
                  </div> */}
                      <FileUploadModal
                        filedName="adharcardBackImage"
                        id={value?._id}
                        deleteUrl={DELETE_URL}
                        index={"3"}
                        Mode={Mode}
                        Url={value?.adharcardBackImage ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setAdharcardBackImage}
                        Name="Adharcard Back"
                      />

                      {/* <div className="col-md-3 align-items-center">
                    <label>Pan Card*</label>
                    {Mode && value?.panCard && (
                      <>
                        <FaSearch
                          onClick={() => {
                            setModalData({
                              MyLink: `${REACT_APP_FileURL}${value.panCard}`,
                              LinkType: "Image",
                              Name: "Pan Card Image",
                            });

                            handleOpen();
                          }}
                          style={{
                            color: "blue",
                          }}
                        />
                      </>
                    )}
                    <input
                      disabled={view}
                      className="form-control"
                      type="file"
                      onChange={(e) => setpanCard(e.target.files[0])}
                      
                    />
                  </div> */}
                      <FileUploadModal
                        filedName="panCard"
                        id={value?._id}
                        deleteUrl={DELETE_URL}
                        index={"4"}
                        Mode={Mode}
                        Url={value?.panCard ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setpanCard}
                        Name="Pan Card Image"
                      />
                      {/* <div className="col-md-3 alicgn-items-center">
                    <label>Resume/CV*</label>
                    {Mode && value?.MarkSheet && (
                      <>
                        <FaSearch
                          onClick={() => {
                            setModalData({
                              MyLink: `${REACT_APP_FileURL}${value.MarkSheet}`,
                              LinkType: "Image",
                              Name: "Resume/CV* Image",
                            });

                            handleOpen();
                          }}
                          style={{
                            color: "blue",
                          }}
                        />
                      </>
                    )}
                    <input
                      disabled={view}
                      className="form-control"
                      type="file"
                      onChange={(e) => setMarkSheet(e.target.files[0])}
                      
                    />
                  </div> */}
                      <FileUploadModal
                        filedName="MarkSheet"
                        id={value?._id}
                        deleteUrl={DELETE_URL}
                        index={"5"}
                        Mode={Mode}
                        Url={value?.MarkSheet ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setMarkSheet}
                        Name="Resume/CV*"
                      />
                      {/* <div className="col-md-3 align-items-center">
                    <label>Contract Pdf*</label>
                    {Mode && value?.Contract && (
                      <>
                        <FaSearch
                          onClick={() => {
                            setModalData({
                              MyLink: `${REACT_APP_FileURL}${value.Contract}`,
                              LinkType: "PDF",
                              Name: "Contract Pdf",
                            });
                            handleOpen();
                          }}
                          style={{
                            color: "blue",
                          }}
                        />
                      </>
                    )}
                    <input
                      disabled={view}
                      className="form-control"
                      type="file"
                      onChange={(e) => setContract(e.target.files[0])}
                      
                    />
                  </div> */}
                      <FileUploadModal
                        filedName="Contract"
                        id={value?._id}
                        deleteUrl={DELETE_URL}
                        index={"6"}
                        Mode={Mode}
                        Url={value?.Contract ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setContract}
                        Name="Contract Pdf"
                      />
                      {/* <div className="col-md-3 align-items-center">
                    <label>Cancel Cheque*</label>
                    {Mode && value?.Cheque && (
                      <>
                        <FaSearch
                          onClick={() => {
                            setModalData({
                              MyLink: `${REACT_APP_FileURL}${value.Cheque}`,
                              LinkType: "Image",
                              Name: "Cancel Cheque",
                            });

                            handleOpen();
                          }}
                          style={{
                            color: "blue",
                          }}
                        />
                      </>
                    )}
                    <input
                      disabled={view}
                      className="form-control"
                      type="file"
                      onChange={(e) => setCheque(e.target.files[0])}
                      
                    />
                  </div> */}
                      <FileUploadModal
                        filedName="Cheque"
                        id={value?._id}
                        deleteUrl={DELETE_URL}
                        index={"7"}
                        Mode={Mode}
                        Url={value?.Cheque ?? ""}
                        setModalData={setModalData}
                        handleOpen={handleOpen}
                        view={view}
                        setChangeHandler={setCheque}
                        Name="Cancel Cheque"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="d-flex gap-3 ">
              <button
                type="button"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => navigate("/admin/masters/Employee/data")}
              >
                Back
              </button>
              {!view && (
                <button
                  type="submit"
                  className="btn mybtn"
                // style={{ marginTop: 28 }}
                // onClick={() => {
                //   Mode ? handleEdit() : handleCreate();
                // }}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CreateEmployee;

{
  /*
<div className="col-md-6 align-items-center">
<label>Photo</label>
<input
disabled={view}
  className="form-control"
  type="file"
  onChange={(e) => setPhoto(e.target.files[0])}
  
/>
</div> 





*/
}
