import React, { useEffect, useState } from "react";
import { ToastError, ToastInfo, ToastSusses } from "../../Utils/Tost";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import Select from "react-select";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import SearchInputFilter from "../../Utils/SearchInputFilter";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Purchase/Purchase.css";
import {
  convertDateStringToDate,
  reversDate,
} from "../../Utils/usefullFunction";
import DateRange from "../DateRangeModule/DateRange";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const getApprovedPolicy = async (InsuranceCompany, inputData, page, pageSize) => {
  let que = { InsuranceCompany, page, pageSize };

  if (inputData) {
    que["inputData"] = inputData;
  }

  try {
    const {
      data: { data = [], TotalAmount = {}, totalRecords = 0 },
    } = await axiosInstance.post("/Sale/getIsSale", {
      ...que,
    });

    return { data, TotalAmount, totalRecords };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const CreateSaleReturn = () => {
  const today = new Date();
  const navigate = useNavigate();
  const lastDay = today.toISOString().substr(0, 10);
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();

  const { state } = useLocation();
  const { value = "", view } = state || {};
  const [EnterDate, setEnterDate] = useState(lastDay);

  const [InsuranceCompanyId, setInsuranceCompanyId] = useState(null);
  const [inputData, setInput] = useState();
  const [loading, setLoading] = useState(false);
  const [Remark, setRemark] = useState("");
  const [TDSPercent, setTDSPercent] = useState(0);
  const [TDSAccount, setTDSAccount] = useState({
    _id: "",
    Name: "-- Select TDS Account --",
  });
  const [DebitAccount, setDebitAccount] = useState({
    _id: "",
    Name: "-- Select Debit Account --",
  });
  const [GSTPercent, setGSTPercent] = useState(0);
  const [GSTAccount, setGSTAccount] = useState({
    _id: "",
    Name: "-- Select GST Account --",
  });

  const [DebitAccountOpt, setDebitAccountOpt] = useState([]);
  const [TDSOpt, setTDSOpt] = useState([]);
  const [Data, setData] = useState([]);
  const [PolicySaved, setPolicySaved] = useState([]);
  const [InsuranceCompanyOpt, setInsuranceCompanyOpt] = useState([]);
  const [SelectedPolicyOpt, setSelectedPolicyOpt] = useState([]);

  const [InsuranceCompany, setInsuranceCompany] = useState({
    _id: "",
    Name: "-- Select InsuranceCompany --",
  });

  const [TotalAmount, setTotalAmount] = useState(0);
  const [TotalAmountOfSelectPolicy, setTotalAmountOfSelectPolicy] = useState(0);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  useEffect(() => {
    const fetchData = async () => {
      let page = 1;
      const pageSize = 100;
      let totalRecords = 0;

      const Allarr = [];
      do {
        const {
          data,
          TotalAmount,
          totalRecords: newTotalRecords,
        } = await getApprovedPolicy(InsuranceCompanyId, inputData, page, pageSize);

        totalRecords = newTotalRecords;

        // Update state only if new data is received
        Allarr.push(...data);

        page++;

        const progressCounter = (Allarr?.length / totalRecords) * 100;
        setProgress(progressCounter);
        setBuffer(progressCounter + Math.floor(Math.random() * 10) + 1);

        if (Allarr.length > 0) {
          if (Allarr.length === totalRecords) {
            Allarr.sort(
              (a, b) => new Date(a.IssueDate) - new Date(b.IssueDate)
            );
          }

          setData(Allarr);

          setPolicySaved(Allarr);
          setStartDate(Allarr[0]?.IssueDate);
          setEndDate(Allarr[Allarr.length - 1]?.IssueDate);
          setTotalAmount(TotalAmount);
        }
      } while (Allarr?.length !== totalRecords);
    };

    if (InsuranceCompanyId) {
      fetchData();
    }
  }, [InsuranceCompanyId]);

  const getInsuranceCompany = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceCompany");
      setInsuranceCompanyOpt([
        {
          _id: "",
          Name: "-- Select InsuranceCompany --",
        },
        ...data,
      ]);
    } catch (error) {}
  };

  useEffect(() => {
    getInsuranceCompany();
  }, []);

  const selectPolicyHandler = (isChecked, _id, Amount = 0) => {
    if (isChecked) {
      setSelectedPolicyOpt((pre) => [...pre, _id]);
      setTotalAmountOfSelectPolicy((pre) => pre + Amount);
    } else {
      const SelectedPolicy = SelectedPolicyOpt.findIndex((ele) => ele === _id);
      SelectedPolicyOpt.splice(SelectedPolicy, 1);
      setSelectedPolicyOpt([...SelectedPolicyOpt]);
      setTotalAmountOfSelectPolicy((pre) => pre - Amount);
    }
  };

  const checkIsPresentOrNot = (_id) => {
    const SelectedPolicy = SelectedPolicyOpt.findIndex((ele) => ele === _id);

    return SelectedPolicy === -1 ? false : true;
  };

  const SelectAllDeleteAll = (isChecked) => {
    if (!isChecked) {
      setSelectedPolicyOpt([]);
      setTotalAmountOfSelectPolicy(0);
    } else {
      let Total = 0;
      const selectALL = Data.map((ele) => {
        let amount = parseInt(ele?.Commission?.TotalToGetAmount )?? 0;
        Total = Total + amount;
        return ele?._id;
      });
      setSelectedPolicyOpt([...selectALL]);
      setTotalAmountOfSelectPolicy(Total);
    }
  };

  const setDateHandler = (ranges) => {
    setEndDate(convertDateStringToDate(ranges.endDate));
    setStartDate(convertDateStringToDate(ranges.startDate));
  };

  const handleCreate = async () => {
    if (!DebitAccount._id) {
      return ToastInfo("Credit Account is Required");
    }
    const AmountToGive = TotalAmountOfSelectPolicy;

    let GSTAmount = 0; // + add On Original Cost
    let TDSAmount = 0; // -

    if (TDSAccount._id) {
      TDSAmount = Math.round((AmountToGive * TDSPercent) / 100);
    }
    if (GSTAccount._id) {
      GSTAmount = (AmountToGive * GSTPercent) / 100;
      GSTAmount = Number(GSTAmount.toFixed(2));
    }
    const NetAmount = AmountToGive + GSTAmount - TDSAmount;
    //
    //   {
    //     TDSAccount: TDSAccount._id,
    //     GSTAccount: GSTAccount._id,

    //     TDSAmount,
    //     GSTAmount,
    //     TDSPercent,
    //     GSTPercent,
    //   },
    //   "check this one"
    // );
    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/SaleReturn", {
        CreditAccount:InsuranceCompany._id,
        DebitAccount: DebitAccount._id,
        TDSAccount: TDSAccount._id,
        GSTAccount: GSTAccount._id,
        TDSAmount,
        GSTAmount,
        TDSPercent,
        GSTPercent,
        NetAmount,
        EnterDate,
        Remark,
        TotalAmount: AmountToGive,
        SelectedPolicyOpt,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/Sale/Return");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  let FilteredPolicy;

  if (inputData) {
    FilteredPolicy = Data?.filter(
      (e) =>
        e?.VehicleNumber?.toLowerCase()?.includes(inputData?.toLowerCase()) ||
        e?.InsureeName?.toLowerCase()?.includes(inputData?.toLowerCase()) ||
        e?.PolicyNumber?.toLowerCase()?.includes(inputData?.toLowerCase())
    );
  } else {
    FilteredPolicy = Data;

  }

  const getLedgerMaster = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/LedgerEntry/getDebitAccounts`);

      setDebitAccountOpt(data);
    } catch (error) {}
  };

  const getLedgerMasterForTds = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/LedgerEntry?Group=648ac8ca7df926cfdd2ddccb`
      ); // 	Duties and Tax
      setTDSOpt(data);
    } catch (error) {}
  };

  useEffect(() => {
    getLedgerMaster();
    getLedgerMasterForTds();
  }, []);

  const filterDataByDate = () => {
    const Start = new Date(StartDate);
    const End = new Date(EndDate);
    const filteredDates = PolicySaved.filter((date) => {
      const currentDate = new Date(date.IssueDate);
      return currentDate >= Start && currentDate <= End;
    });

    setData(JSON.parse(JSON.stringify(filteredDates)));
  };

  
  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          {!InsuranceCompany._id ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="selectPosDiv">
                <Select
                  className="select-BoxUi"
                  classNamePrefix="select"
                  options={InsuranceCompanyOpt}
                  getOptionValue={(option) => option["_id"]}
                  getOptionLabel={(option) => option["Name"]}
                  onChange={(ele) => {
                    setInsuranceCompanyId(ele._id);
                    setInsuranceCompany(ele);
                  }}
                  value={InsuranceCompany}
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <div
                className="card-body CreateCard"
                // style={{
                //   height: "30vh",
                // }}
              >
                <h4 className="card-title">
                  {/* {Mode ? "Edit Receipt" : "Create Receipt"} */}
                  Create Sale
                </h4>
                <hr />
                <br />
                <div className="row mx-2 ">
                  <div className="col-md-4 align-items-center">
                    <label>Credit Account</label>
                    <input
                      disabled={true}
                      className="form-control "
                      type="input"
                      value={`${InsuranceCompany["AliasName"] ?? ""}`}
                      placeholder="Enter Receipt"
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>EnterDate</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="date"
                      name="EnterDate"
                      value={EnterDate}
                      onChange={(e) => setEnterDate(e.target.value)}
                      placeholder="Enter Receipt "
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Debit Account</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          Name: "-- Select Debit Account --",
                        },
                        ...DebitAccountOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => {
                        const Name = `${option?.Name}`;
                        return Name;
                      }}
                      onChange={setDebitAccount}
                      value={DebitAccount}
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>Remark</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="text"
                      name="Remark"
                      value={Remark}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Remark"
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>GST Account</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          Name: "-- Select GST Account --",
                        },
                        ...TDSOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => {
                        const Name = `${option?.Name}`;
                        return Name;
                      }}
                      onChange={setGSTAccount}
                      value={GSTAccount}
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>GST Percent</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Number"
                      name="GSTPercent"
                      value={GSTPercent}
                      onChange={(e) => setGSTPercent(e.target.value)}
                      placeholder="GST Percent"
                      required //done
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>TDS Account</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          Name: "-- Select TDS Account --",
                        },
                        ...TDSOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => {
                        const Name = `${option?.Name}`;
                        return Name;
                      }}
                      onChange={setTDSAccount}
                      value={TDSAccount}
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>TDS Percent</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Number"
                      name="TDSPercent"
                      value={TDSPercent}
                      onChange={(e) => setTDSPercent(e.target.value)}
                      placeholder="TDS Percent"
                      required //done
                    />
                  </div>
                </div>
              </div>
              <div className="card-body CreateCard">
                <div
                  className="p-2 filters"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "unset",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          // alignItems: "baseline",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <input
                          className="form-control "
                          type="date"
                          name="StartDate"
                          value={StartDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required //done
                        />

                        <p>-</p>
                        <input
                          className="form-control "
                          type="date"
                          name="EndDate"
                          value={EndDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required //done
                        />
                        <DateRange setDateHandler={setDateHandler} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="searchBox"
                      style={{
                        width: "77px",
                        cursor: "pointer",
                      }}
                      onClick={() => filterDataByDate()}
                    >
                      <MdSend />
                    </div>
                    <div
                      className="searchBox"
                      style={{
                        width: "77px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setStartDate(PolicySaved[0]["IssueDate"]);
                        setEndDate(
                          PolicySaved[PolicySaved.length - 1]["IssueDate"]
                        );
                        setData(JSON.parse(JSON.stringify(PolicySaved)));
                      }}
                    >
                      <BsEraser />
                    </div>
                  </div>
                </div>

                <div className="PolicyCounts">
                  <div className="PolicyCounts-Item">
                    <b>Selected Policy :-</b> {SelectedPolicyOpt.length}
                  </div>

                  <div className="PolicyCounts-Item">
                    {" "}
                    <b>Total Amount :-</b> {TotalAmountOfSelectPolicy}
                  </div>
                </div>

                <LinearProgressWithLabel value={progress} />

                {loading ? (
                  <div
                    className=" p-2 "
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <div
                    className=" p-2 "
                    style={{
                      zoom: "70%",
                    }}
                  >
                    {Data && (
                      <table
                        class="table table-striped   table-bordered  overflow-x mt-3"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="selectBoxForTable">
                              <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                                value="Car"
                                checked={
                                  SelectedPolicyOpt.length === Data.length
                                }
                                style={{
                                  width: "23px",
                                  height: "23px",
                                }}
                                onClick={(e) => {
                                  SelectAllDeleteAll(e.target.checked);
                                }}
                              />
                            </th>
                            <th scope="col">S/N </th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy Details</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>

                            <th scope="col">Vehicle No.</th>

                            <th scope="col">Amount Receive</th>
                          </tr>
                          {/* <tr>
                            <th scope="col" className="selectBoxForTable">
                              <p>#</p>
                              <input
                                type="checkbox"
                                id="vehicle2"
                                name="vehicle2"
                                value="Car"
                                checked={
                                  SelectedPolicyOpt.length === Data.length
                                }
                                style={{
                                  width: "23px",
                                  height: "23px",
                                }}
                                onClick={(e) => {
                                  SelectAllDeleteAll(e.target.checked);
                                }}
                              />
                            </th>
                            <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy Details</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>

                            <th scope="col">Vehicle No.</th>

                            <th scope="col">Amount To Pay</th>
                          </tr>
                          </tr> */}
                        </thead>

                        <tbody>
                          {Data &&
                            Data?.length !== 0 &&
                            Data.map((data, index) => (
                              <tr key={data._id}>
                                <th scope="row" className="selectBoxForTable">
                                  <input
                                    type="checkbox"
                                    id="vehicle2"
                                    name="vehicle2"
                                    value="Car"
                                    key={data._id}
                                    checked={checkIsPresentOrNot(data._id)}
                                    style={{
                                      width: "23px",
                                      height: "23px",
                                    }}
                                    onClick={(e) => {
                                      selectPolicyHandler(
                                        e.target.checked,
                                        data._id,
                                        parseInt(data?.Commission?.TotalToGetAmount) || 0
                                      );
                                    }}
                                  />
                                </th>
                                <th scope="row">
                                  <p>{index + 1}</p>
                                </th>
                                <th>{reversDate(data?.IssueDate)}</th>
                                <th>
                                  <tr> {data?.InsuranceCompany?.Name}</tr>
                                  <tr> {data?.InsuranceType?.InsuranceType}</tr>
                                  <tr> RSD:-{reversDate(data?.RSD)}</tr>
                                  <tr> {data?.InsureeName}</tr>
                                  <tr> {data?.InsuranceCompany?.AliasName}</tr>
                                </th>

                                <th>
                                  <tr>
                                    <Link
                                      to={`${REACT_APP_FileURL}${data.NewPolicyCopy}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {" "}
                                      {data?.PolicyNumber}
                                    </Link>{" "}
                                  </tr>
                                  <tr> {data?.MakeModal?.Name}</tr>
                                  <tr> {data?.Variant}</tr>
                                  <tr> YOM- {data?.YearOfManufacture}</tr>
                                  <tr> {data?.PolicyType?.PolicyTypeName}</tr>
                                </th>

                                <th>
                                  <tr>Gross </tr>
                                  <tr>Net </tr>
                                  <tr>TP</tr>
                                  <tr>OD</tr>
                                  <tr>Flat</tr>
                                </th>

                                <th>
                                  <tr> ₹ {Math.round(data?.GrossPremium)}</tr>
                                  <tr> ₹ {data?.NETPremium}</tr>
                                  <tr> ₹ {data?.TPPremium}</tr>
                                  <tr> ₹ {data?.ODPremium}</tr>
                                </th>
                                <th>
                                  <tr>
                                    <br />
                                  </tr>
                                  {data?.BasedOn.includes("NET") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableNET / 10 +
                                        "X NET"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("TP") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableTP / 10 +
                                        "X TP"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("OD") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableOD / 10 +
                                        "X OD"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                  {data?.BasedOn.includes("FLAT") ? (
                                    <tr>
                                      {data?.Commission?.ReciveableFLAT +
                                        "FLAT"}{" "}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <br />
                                    </tr>
                                  )}
                                </th>

                                <th>
                                  <tr>
                                    {" "}
                                    {data?.PaymentMode?.TypeOfPaymentMode ??
                                      data?.PaymentMode}
                                  </tr>
                                  <tr> {data?.PolicyType?.Name}</tr>
                                  <tr> {data?.VehicleNumber}</tr>
                                  {/* <tr> {data?.ODPremium}</tr> */}
                                </th>
                                <th>
                                  <tr>
                                    {" "}
                                    ₹ {data?.Commission?.TotalToGetAmount || 0}
                                  </tr>
                                </th>
                              </tr>
                            ))}
                          <tr>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row"></th>

                            <th scope="row">
                              ₹ {TotalAmount?.TotalPolicyProfit ?? 0}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      ></div>
                    }
                  </div>
                )}

                <div className="d-flex gap-3 ">
                  <button
                    type="submit"
                    className="btn mybtn"
                    // style={{ marginTop: 28 }}
                    onClick={() => {
                      setTotalAmount(0);
                      setData([]);
                      setTotalAmountOfSelectPolicy(0);
                      setSelectedPolicyOpt([]);
                      setInsuranceCompany({
                        _id: "",
                        Name: "-- Select InsuranceCompany --",
                      });
                    }}
                  >
                    Go Back
                  </button>

                  {!view && (
                    <button
                      type="submit"
                      className="btn mybtn"
                      // style={{ marginTop: 28 }}
                      onClick={() => {
                        handleCreate();
                      }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateSaleReturn;
