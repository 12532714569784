import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

import { AiOutlineFileExcel } from "react-icons/ai";
import { reversDate } from "./usefullFunction";
const PaymentExvelNew = ({ _id, value, Name }) => {
  const createDownloadData = () => {
    handleExport().then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        `${Name}-${value?.EntryDate}`
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wboutput = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wboutput)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    const KeyArray = [
      "Client_Code",
      "Product_Code",
      "Payment_Type",
      "Payment_Type",
      "Payment_Date",
      "Instrument Date",
      "Dr_Ac_No",
      "Amount",
      "Bank_Code_Indicator",
      "Beneficiary_Code",
      "Beneficiary_Name",
      "Beneficiary_Bank",
      "Beneficiary_Branch / IFSC Code",
      "Beneficiary_Acc_No",
      "Location",
      "Print_Location",
      "Instrument_Number",
      "Ben_Add1",
      "Ben_Add2",
      "Ben_Add3",
      "Ben_Add4",
      "Beneficiary_Email",
      "Beneficiary_Mobile",
      "Debit_Narration",
      "Credit_Narration",
      "Payment Details 1",
      "Payment Details 2",
      "Payment Details 3",
      "Payment Details 4",
      "Enrichment_1",
      "Enrichment_2",
      "Enrichment_3",
      "Enrichment_4",
      "Enrichment_5",
      "Enrichment_6",
      "Enrichment_7",
      "Enrichment_8",
      "Enrichment_9",
      "Enrichment_10",
      "Enrichment_11",
      "Enrichment_12",
      "Enrichment_13",
      "Enrichment_14",
      "Enrichment_15",
      "Enrichment_16",
      "Enrichment_17",
      "Enrichment_18",
      "Enrichment_19",
      "Enrichment_20",

    ];

    let table = [
      {
        A: "Client_Code",
        B: "Product_Code",
        C: "Payment_Type",
        D: "Payment_Type",
        E: "Payment_Date",
        F: "Instrument Date",
        G: "Dr_Ac_No",
        H: "Amount",
        I: "Bank_Code_Indicator",
        J: "Beneficiary_Code",
        K: "Beneficiary_Name",
        L: "Beneficiary_Bank",
        M: "Beneficiary_Branch / IFSC Code",
        N: "Beneficiary_Acc_No",
        O: "Location",
        P: "Print_Location",
        Q: "Instrument_Number",
        R: "Ben_Add1",
        S: "Ben_Add2",
        T: "Ben_Add3",
        U: "Ben_Add4",
        V: "Beneficiary_Email",
        W: "Beneficiary_Mobile",
        X: "Debit_Narration",
        Y: "Credit_Narration",
        Z: "Payment Details 1",
        AA: "Payment Details 2",
        AB: "Payment Details 3",
        AC: "Payment Details 4",
        AD: "Enrichment_1",
        AE: "Enrichment_2",
        AF: "Enrichment_3",
        AG: "Enrichment_4",
        AH: "Enrichment_5",
        AI: "Enrichment_6",
        AJ: "Enrichment_7",
        AK: "Enrichment_8",
        AL: "Enrichment_9",
        AM: "Enrichment_10",
        AN: "Enrichment_11",
        AO: "Enrichment_12",
        AP: "Enrichment_13",
        AQ: "Enrichment_14",
        AR: "Enrichment_15",
        AS: "Enrichment_16",
        AT: "Enrichment_17",
        AU: "Enrichment_18",
        AV: "Enrichment_19",
        AW: "Enrichment_20",
      },
    ];

    value?.PaymentList.forEach((data, index) => {
      const myObj = {};

      for (const key of KeyArray) {
        if (key === "Client_Code") {
          myObj["A"] = "GIBPL23" || "";
          continue;
        }
        if (key === "Product_Code") {
          myObj["B"] = "SALPAY";
          continue;
        }

        if (key === "Payment_Type") {
          myObj["C"] = "";
          continue;
        }

        if (key === "Payment_Ref_No.") {
          myObj["D"] = "";
          continue;
        }

        if (key === "Payment_Date") {
          myObj["E"] = "";
          continue;
        }

        if (key === "Instrument Date") {
          myObj["F"] = "";
          continue;
        }

        if (key === "Dr_Ac_No") {
          myObj["G"] = "'3546972046";
          continue;
        }

        if (key === "Amount") {
          myObj["H"] = data.Account || 0;
          continue;
        }

        if (key === "Bank_Code_Indicator") {
          myObj["I"] = "M";
          continue;
        }

        if (key === "Beneficiary_Code") {
          myObj["J"] = "";
          continue;
        }

        if (key === "Beneficiary_Name") {
          myObj["K"] = data.PartyAccount.AliasName || "";
          continue;
        }
        if (key === "Beneficiary_Bank") {
          myObj["L"] = "";
          continue;
        }

        if (key === "Beneficiary_Branch / IFSC Code") {
          myObj["M"] = data.PartyAccount.IFSC || "";
          continue;
        }

        if (key === "Beneficiary_Acc_No") {
          myObj["N"] = data.PartyAccount.AccountNumber || "";
          continue;
        }
        if (key === "Location") {
          myObj["O"] = "";
          continue;
        }
        if (key === "Print_Location") {
          myObj["P"] = "";
          continue;
        }
        if (key === "Instrument_Number") {
          myObj["Q"] = "";
          continue;
        }
        if (key === "Ben_Add1") {
          myObj["R"] = "";
          continue;
        }
        if (key === "Ben_Add2") {
          myObj["S"] = "";
          continue;
        }
        if (key === "Ben_Add3") {
          myObj["T"] = "";
          continue;
        }
        if (key === "Ben_Add4") {
          myObj["U"] = "";
          continue;
        }
        if (key === "Beneficiary_Email") {
          myObj["V"] = "";
          continue;
        }
        if (key === "Beneficiary_Mobile") {
          myObj["W"] = "";
          continue;
        }
        if (key === "Debit_Narration") {
          myObj["X"] = "";
          continue;
        }
        if (key === "Credit_Narration") {
          myObj["Y"] = `${data.PartyAccount.AliasName} & GOOD INSURNACE` || "";
          continue;
        }
        if (key === "Payment Details 1") {
          myObj["Z"] = "";
          continue;
        }
        if (key === "Payment Details 2") {
          myObj["AA"] = "";
          continue;
        }
        if (key === "Payment Details 3") {
          myObj["AB"] = "";
          continue;
        }
        if (key === "Payment Details 4") {
          myObj["AC"] = "";
          continue;
        }
        if (key === "Enrichment_1") {
          myObj["AD"] = "";
          continue;
        }
        if (key === "Enrichment_2") {
          myObj["AE"] = "";
          continue;
        }
        if (key === "Enrichment_3") {
          myObj["AF"] = "";
          continue;
        }
        if (key === "Enrichment_4") {
          myObj["AG"] = "";
          continue;
        }
        if (key === "Enrichment_5") {
          myObj["AH"] = "";
          continue;
        }
        if (key === "Enrichment_6") {
          myObj["AI"] = "";
          continue;
        }
        if (key === "Enrichment_7") {
          myObj["AJ"] = "";
          continue;
        }
        if (key === "Enrichment_8") {
          myObj["AK"] = "";
          continue;
        }
        if (key === "Enrichment_9") {
          myObj["AL"] = "";
          continue;
        }
        if (key === "Enrichment_10") {
          myObj["AM"] = "";
          continue;
        }
        if (key === "Enrichment_11") {
          myObj["AN"] = "";
          continue;
        }
        if (key === "Enrichment_12") {
          myObj["AO"] = "";
          continue;
        }
        if (key === "Enrichment_13") {
          myObj["AP"] = "";
          continue;
        }
        if (key === "Enrichment_14") {
          myObj["AQ"] = "";
          continue;
        }
        if (key === "Enrichment_15") {
          myObj["AR"] = "";
          continue;
        }
        if (key === "Enrichment_16") {
          myObj["AS"] = "";
          continue;
        }
        if (key === "Enrichment_17") {
          myObj["AT"] = "";
          continue;
        }
        if (key === "Enrichment_18") {
          myObj["AU"] = "";
          continue;
        }
        if (key === "Enrichment_19") {
          myObj["AV"] = "";
          continue;
        }
        if (key === "Enrichment_20") {
          myObj["AW"] = "";
          continue;
        }
      }
      table.push(myObj);
    });

    // table = [{ A: "Student Details" }].concat(table);
    const finalData = [...table];
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "Payment_List");

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Sl No." ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A1",
      //   titleRange: "A1:AG2",
      tbodyRange: `A1:AW${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:AW${headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        sheet.column("A").width(15);
        sheet.column("B").width(15);
        sheet.column("C").width(15);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(20);
        sheet.column("G").width(20);
        sheet.column("H").width(20);
        sheet.column("I").width(25);
        sheet.column("J").width(15);
        sheet.column("K").width(35);
        sheet.column("L").width(15);
        sheet.column("M").width(15);
        sheet.column("N").width(15);
        sheet.column("O").width(20);
        sheet.column("P").width(15);
        sheet.column("Q").width(25);
        sheet.column("R").width(25);
        sheet.column("S").width(20);
        sheet.column("T").width(15);
        sheet.column("U").width(15);
        sheet.column("V").width(25);
        sheet.column("W").width(25);
        sheet.column("X").width(20);
        sheet.column("Y").width(60);
        sheet.column("Z").width(25);
        sheet.column("AA").width(20);
        sheet.column("AB").width(15);
        sheet.column("AC").width(15);
        sheet.column("AD").width(15);
        sheet.column("AE").width(15);
        sheet.column("AF").width(15);
        sheet.column("AG").width(20);
        sheet.column("AH").width(15);
        sheet.column("AI").width(15);
        sheet.column("AJ").width(25);
        sheet.column("AK").width(20);
        sheet.column("AL").width(15);
        sheet.column("AM").width(15);
        sheet.column("AN").width(15);
        sheet.column("AO").width(20);
        sheet.column("AP").width(15);
        sheet.column("AQ").width(15);
        sheet.column("AR").width(25);
        sheet.column("AS").width(20);
        sheet.column("AT").width(15);
        sheet.column("AU").width(15);
        sheet.column("AV").width(15);
        sheet.column("AW").width(15);
        

        // sheet.range(dataInfo.titleRange).merged(true).style({
        //   bold: true,
        //   horizontalAlignment: "center",
        //   verticalAlignment: "center",
        // });

        // if (dataInfo.tbodyRange) {
        //   sheet.range(dataInfo.tbodyRange).style({
        //     horizontalAlignment: "center",
        //   });
        // }

        // sheet.range(dataInfo.theadRange).style({
        //   bold: true,
        //   horizontalAlignment: "center",
        // });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <div>
      <AiOutlineFileExcel
        onClick={createDownloadData}
        size={35}
        style={{
          backgroundColor: "green",
          color: "#fff",
          padding: "7px",
          borderRadius: "5px",
        }}
      />
    </div>
  );
};

export default PaymentExvelNew;
