import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { AiOutlineFileExcel } from "react-icons/ai";
import ExcelJS from "exceljs";
import { REACT_APP_FileURL } from "../config";
import { useSelector } from "react-redux";
import { reversDate } from "./usefullFunction";
import {
  calculateCPMargin,
  calculateOutflow,
  calculateTotalAmount,
  calculateTPOutflow,
} from "../lib/calculateMargin";
import { calculateVehicleAge } from "../lib/calculateVehicleAge";

const stateRTO = {
  AP: "Andhra Pradesh",
  AR: "Arunachal Pradesh",
  AS: "Assam",
  BR: "Bihar",
  CG: "Chhattisgarh",
  GA: "Goa",
  GJ: "Gujarat",
  HR: "Haryana",
  HP: "Himachal Pradesh",
  JH: "Jharkhand",
  KA: "Karnataka",
  KL: "Kerala",
  MP: "Madhya Pradesh",
  MH: "Maharashtra",
  MN: "Manipur",
  ML: "Meghalaya",
  MZ: "Mizoram",
  NL: "Nagaland",
  OD: "Odisha",
  OR: "Odisha",
  PB: "Punjab",
  RJ: "Rajasthan",
  SK: "Sikkim",
  TN: "Tamil Nadu",
  TS: "Telangana",
  TR: "Tripura",
  UP: "Uttar Pradesh",
  UK: "Uttarakhand",
  WB: "West Bengal",
  UA: "Uttarakhand",
  // union Territories
  AN: "Andaman and Nicobar Islands",
  CH: "Chandigarh",
  DN: "Dadra and Nagar Haveli and Daman and Diu",
  DL: "Delhi",
  JK: "Jammu and Kashmir",
  LA: "Ladakh",
  LD: "Lakshadweep",
  PY: "Puducherry",
};
const KeyArray = [
  "Sr.No.",
  "Control Number",
  "Code",
  "Insurance Company",
  "Insurer Branch",
  "Policy Number",
  "Alternate Policy Number",
  "Insured Name",
  "Business Prop. Date",
  "Issue Date",
  "Start Date (RSD)",
  "Expiry Date (RED)",
  "Customer Type",
  "Vehicle Number",
  "RTO State",
  "RTO City",
  "RTO Code",
  "DoRegn.",
  "YoMfg.",
  "Vehicle Age", // pending
  "Product",
  "Sub Product",
  "Vehicle Class",
  "Policy Type",
  "Business Type",
  "Make",
  "Model",
  "Variant",
  "Fuel Type",
  "CC/Watt",
  "GVW",
  "Seater",
  "IDV/Sum insured",
  "Agent Name",
  "POS Name",
  "RM Name",
  "Gross Premium",
  "Total GST Amount",
  "NET Premium",
  "OD Premium",
  "Add-On Premium",
  "TP Premium",
  "CPA",
  "Legal Liability",
  "Commisionable Premium",
  "Based On",
  "NCB",
  "ODD %",
  "Nil Dep",
  "IMT Type",
  "Policy Paying Term",
  "Status",
  "Entered By",
  "Branch",
  "Branch/DEC",
  "Approved By",
  "Remarks",
  "Created At",
  "Updated At",
  "clusterId",
  "cluster Name",
  "Payout Grid Id",
  "Inflow %",
  "Inflow Amount",
  "CP Margin %",
  "CP Amount",
  "Outflow %",
  "Outflow Amount",
  "Policy Copy",
];

const headerStyle = {
  fill: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "0f4890" },
  },
  font: {
    color: { argb: "FFFFFFFF" },
    bold: true,
  },
};

const ReturnCharCode = (startingIndex, addOn) => {
  if (addOn === 0) {
    return String.fromCharCode(startingIndex);
  }

  return `${String.fromCharCode(64 + addOn)}${String.fromCharCode(
    startingIndex
  )}`;
};

const Test = ({ filterDataHandlerForExcel, fileName }) => {
  const [loading, setLoading] = useState();
  const { user } = useSelector((store) => store.user);

  const handleExportToExcel = async () => {
    try {
      setLoading(true);
      const excelData = await filterDataHandlerForExcel();

      let newArr = [];

      let MaxCountLengthOfInsuranceType = 0;
      excelData.forEach((value) => {
        const { InsuranceUnderFlow = [] } = value;
        MaxCountLengthOfInsuranceType = Math.max(
          MaxCountLengthOfInsuranceType,
          InsuranceUnderFlow.length
        );
      });

      const createInitialKey = () => {
        let startingIndex = 65;
        let addon = 0;
      
        KeyArray.forEach((ele) => {
          if (startingIndex > 90) {
            startingIndex = 65;
            addon++;
          }
      
          newArr.push({
            header: ele,
            key: ReturnCharCode(startingIndex, addon),
            type: "string",
          });
      
          startingIndex++;
        });
      };

      createInitialKey();

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");

      worksheet.columns = newArr;

      worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
        cell.style = headerStyle;
      });

      const createValues = async () => {
        let slNo = 1;
        for (const value of excelData) {
          const obj = {};
          let startingIndex = 65;
          let addOn = 0;

          const issueDate = new Date(value?.IssueDate);
          let foundPOSNewMargin;
          let foundCPNewMargin;

          if (
            !value?.POSRate &&
            value?.POS &&
            value?.POS?.ClubException &&
            value?.POS?.ClubException.length > 0
          ) {
            const clubException = value?.POS?.ClubException || [];
            const insUnderType = value?.InsuranceUnderFlow || [];

            foundPOSNewMargin = clubException.find((item) => {
              const defDate = new Date(item.DEF);
              const detDate = new Date(item.DET);
              const insType = item?.InsuranceType;

              const insUnderflowIds = insUnderType.map((item) => item?._id);

              const isIssueDateInRange =
                issueDate >= defDate && issueDate <= detDate;

              const isProductPresent = insType
                ? insUnderflowIds.includes(insType)
                : true;

              return isIssueDateInRange && isProductPresent;
            });
          } else if (
            value?.POSRate &&
            value?.POSRate > 0 &&
            value?.POS &&
            value?.POS?.ClubException &&
            value?.POS?.ClubException.length > 0
          ) {
            const insIds = value.InsuranceUnderFlow.map((item) => item._id);

            foundCPNewMargin = value?.POS?.ClubException.find((item) =>
              insIds.includes(item.InsuranceType)
            );
          }

          const cpMargin = calculateCPMargin(
            foundCPNewMargin,
            foundPOSNewMargin,
            value
          );

          let marginOffset =
            calculateOutflow(foundCPNewMargin, foundPOSNewMargin, value) ?? 0;

          const basedOn = value.BasedOn ? value.BasedOn?.split("+") : "";
          const [OD, other] = basedOn;
          let totalAmount = calculateTotalAmount(value, value.Inflow);
          let totalAmountCP = calculateTotalAmount(value, cpMargin);
          let totalAmountPOS = calculateTotalAmount(value, marginOffset);

          if (basedOn.length === 1) {
            // Single value case: Round marginOffset to 2 decimal places
            marginOffset = parseFloat(marginOffset).toFixed(2);
          } else if (basedOn.length === 2) {
            // Two values case: Calculate total amounts and round each part to 2 decimal places
            totalAmount = calculateTotalAmount(value, value.Inflow, value.InflowTP);
          
            const TPOutFlowPercentage =
              calculateTPOutflow(foundCPNewMargin, foundPOSNewMargin, value) ?? 0;
          
            totalAmountCP = calculateTotalAmount(value, cpMargin, cpMargin);
            totalAmountPOS = calculateTotalAmount(
              value,
              marginOffset,
              TPOutFlowPercentage
            );
          
            // Round both parts of marginOffset to 2 decimal places
            const roundedMarginOffset = parseFloat(marginOffset).toFixed(2);
            const roundedTPOutFlowPercentage = parseFloat(TPOutFlowPercentage).toFixed(2);
          
            // Concatenate the rounded values with a "+" sign
            marginOffset = `${roundedMarginOffset}+${roundedTPOutFlowPercentage}`;
          }

          //   marginOffset = `${marginOffset} + ${TPOutFlowPercentage}`;

          //   const ODAmount = totalAmount[`${basedOn[0]}Amount`];
          //   const ODHOAmount = calculateHOAmount(ODAmount, hoMargin);
          //   const ODCPAmount = calculateMarginAmount(ODHOAmount, cpMargin);

          //   const TPAmount = totalAmount[`${basedOn[1]}Amount`];
          //   const TPHOAmount = calculateHOAmount(TPAmount, hoMargin);
          //   const TPCPAmount = calculateCPAmount(TPHOAmount, cpMargin);

          //   totalAmountHo = (ODHOAmount + TPHOAmount).toFixed(0);
          //   totalAmountCP = (ODCPAmount + TPCPAmount).toFixed(0);
          // }

          for (const key of KeyArray) {
            if (startingIndex > 90) {
              startingIndex = 65;
              addOn++;
            }

            if (key === "Sr.No.") {
              obj[ReturnCharCode(startingIndex, addOn)] = slNo;
              startingIndex++;
              continue;
            }
            if (key === "Control Number") {
              obj[ReturnCharCode(startingIndex, addOn)] = `${value?.ControlNo ?? ""
                }`;
              startingIndex++;
              continue;
            }

            if (key === "Policy Copy") {
              obj[ReturnCharCode(startingIndex, addOn)] = {
                text: `${REACT_APP_FileURL}${value?.NewPolicyCopy}`,
                hyperlink: `${REACT_APP_FileURL}${value?.NewPolicyCopy}`,
                tooltip: "Policy Copy Download",
              };
              startingIndex++;
              continue;
            }

            if (key === "CP Amount") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                basedOn !== "-"
                  ? basedOn.length > 1
                    ? +totalAmountCP[`${OD}Amount`] ??
                    value?.Commission[`CP${OD}Amount`] +
                    +totalAmountCP[`${other}Amount`] ??
                    value?.Commission[`CP${other}Amount`]
                    : value?.BasedOn && value.RateMatched === "Manual"
                      ? value?.Commission[`CP${value?.BasedOn}Amount`] ?? 0
                      : Number(totalAmountCP) ?? 0
                  : "";
              startingIndex++;
              continue;
            }
            // if (key === "Outflow Amount") {
            //   obj[ReturnCharCode(startingIndex, addOn)] =
            //     basedOn !== "-"
            //       ? basedOn.length > 1
            //         ? +totalAmountPOS[`${OD}Amount`] ??
            //         value?.Commission[`POSRate${OD}Amount`] +
            //         +(+totalAmountPOS[`${other}Amount`]) ??
            //         value?.Commission[`POSRate${other}Amount`]
            //         : value?.BasedOn && value.RateMatched === "Manual"
            //           ? value?.Commission[`POSRate${value?.BasedOn}Amount`] ?? 0
            //           : Number(totalAmountPOS) ?? 0
            //       : "";
            //   startingIndex++;
            //   continue;
            // }

            if (key === "Insured Name") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value?.InsureeName
              );
              startingIndex++;
              continue;
            }
            if (key === "Start Date (RSD)") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value?.RSD
              );
              startingIndex++;
              continue;
            }
            if (key === "Fuel Type") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.FuelType?.FuelType ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "CP Margin %") {
              const branchCode = user?.Branch?.BranchCode;
              const value =
                branchCode === "0"
                  ? +Number(cpMargin ?? value?.Commission["CPMargin"] ?? 0)
                  : "-";

              obj[ReturnCharCode(startingIndex, addOn)] = value;
              startingIndex++;
              continue;
            }

            if (key === "Outflow %") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value.RateMatched === "Manual" && value?.BasedOn
                  ? value?.Commission[`POSRate${value?.BasedOn}`] ?? 0
                  : marginOffset;
              startingIndex++;
              continue;
            }

            if (key === "Model") {
              obj[ReturnCharCode(startingIndex, addOn)] = `${value?.Model?.Model ?? "-"
                }`;
              startingIndex++;
              continue;
            }

            if (key === "Variant") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.Variant?.VariantName ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "CC/Watt") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.Engine ?? 0;
              startingIndex++;
              continue;
            }
            if (key === "GVW") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.GVW ?? 0;
              startingIndex++;
              continue;
            }
            if (key === "Seater") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.Seater ?? 0;
              startingIndex++;
              continue;
            }
            if (key === "IDV/Sum insured") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.IDV_Sum ?? 0;
              startingIndex++;
              continue;
            }

            if (key === "Customer Type") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.CustomerType ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "Policy Paying Term") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.policyPayingTerm ?? "-";
              startingIndex++;
              continue;
            }

            if (key === "Status") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.Status ?? "-";
              startingIndex++;
              continue;
            }

            if (key === "Vehicle Number") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.VehicleNumber ?? "-";
              startingIndex++;
              continue;
            }

            if (key === "RTO Code") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.VehicleNumber?.slice(0, 4) ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "RTO City") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.RTO?.RTOName ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "RTO State") {
              const RTOCode = value?.VehicleNumber?.slice(
                0,
                2
              )?.toLocaleUpperCase();

              obj[ReturnCharCode(startingIndex, addOn)] =
                stateRTO[RTOCode] ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "Expiry Date (RED)") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value?.RED
              );
              startingIndex++;
              continue;
            }
            if (key === "Policy Type") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.PolicyType?.PolicyTypeName;
              startingIndex++;
              continue;
            }
            if (key === "Commisionable Premium") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.CommissionablePremium ?? 0;
              startingIndex++;
              continue;
            }
            if (key === "Business Type") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.BusinessType;
              startingIndex++;
              continue;
            }
            if (key === "Policy Number") {
              obj[ReturnCharCode(startingIndex, addOn)] = {
                text: value?.PolicyNumber,
                hyperlink: `${REACT_APP_FileURL}${value?.NewPolicyCopy}`,
                tooltip: "Click here to download Pdf",
              };
              startingIndex++;
              continue;
            }
            if (key === "Alternate Policy Number") {
              if (value["InsuranceCompany"]?.alternatePolicy === "Yes") {
                const policyNumber = value?.PolicyNumber;
                const extractedValues = policyNumber?.split("/");
                let longestValue = extractedValues?.reduce(
                  (longest, current) => {
                    return current.length > longest.length ? current : longest;
                  },
                  ""
                );

                longestValue = longestValue?.replace(/^0+/, "");
                obj[ReturnCharCode(startingIndex, addOn)] = longestValue;
                startingIndex++;
                continue;
              }
            }

            if (key === "Product") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.InsuranceUnderFlow[0]?.InsuranceType;
              startingIndex++;
              continue;
            }
            if (key === "DoRegn.") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value?.RegistrationDate ?? "-"
              );
              startingIndex++;
              continue;
            }
            if (key === "YoMfg.") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.YearOfManufacture ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "CPA") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.PAPremium;
              startingIndex++;
              continue;
            }
            if (key === "Inflow %") {
              let inflowValue = "";
              let inflowValuesingle = "";

              if (value?.Inflow) {
                inflowValuesingle = `${value.Inflow}`;
              } else {
                inflowValuesingle = value?.Commission
                  ? value.Commission[`Inflow${value.BasedOn}`]
                  : "";
              }

              if (value?.Inflow && value?.InflowTP) {
                inflowValue = `${value.Inflow}+${value.InflowTP}`;
              } else {
                inflowValue = value?.Commission
                  ? `${value.Commission[`Inflow${OD?.trim()}`] || 0}+${value.Commission[`Inflow${other?.trim()}`] || 0
                  }`
                  : `${value?.Inflow || 0}+${value?.InflowTP || 0}`;
              }

              obj[ReturnCharCode(startingIndex, addOn)] =
                user?.Branch?.BranchCode === "0" && basedOn?.length === 1
                  ? inflowValuesingle
                  : basedOn?.length !== 0
                    ? inflowValue
                    : "";

              startingIndex++;
              continue;
            }
            if (key === "Outflow Amount") {
              if (user?.Branch?.BranchCode === "0") {
                let outflowAmount = 0;

                if (basedOn.length > 1) {
                  const odAmount = +totalAmountPOS?.[`${OD}Amount`] ?? 0;
                  const otherAmount = +totalAmountPOS?.[`${other}Amount`] ?? 0;
                  const odCommission =
                    +value?.Commission?.[`POSRate${OD}Amount`] ?? 0;
                  const otherCommission =
                    +value?.Commission?.[`POSRate${other}Amount`] ?? 0;

                  if (odAmount != null && otherAmount != null) {
                    outflowAmount = odAmount + otherAmount;
                  } else {
                    outflowAmount = odCommission + otherCommission;
                  }
                } else {
                  if (value?.BasedOn && value.RateMatched === "Manual") {
                    outflowAmount =
                      value?.Commission[`POSRate${value?.BasedOn}Amount`] || 0;
                  } else {
                    outflowAmount = +totalAmountPOS || 0;
                  }
                }

                obj[ReturnCharCode(startingIndex, addOn)] = basedOn
                  ? outflowAmount
                  : "";
              } else {
                obj[ReturnCharCode(startingIndex, addOn)] = "";
              }

              startingIndex++;
              continue;
            }

            if (key === "Inflow Amount") {
              if (user?.Branch?.BranchCode === "0") {
                let inflowAmount = 0;

                if (basedOn.length > 1) {
                  const odAmount = +totalAmount?.[`${OD}Amount`] ?? 0;
                  const otherAmount = +totalAmount?.[`${other}Amount`] ?? 0;
                  const odCommission =
                    +value?.Commission?.[`Inflow${OD?.trim()}Amount`] ?? 0;
                  const otherCommission =
                    +value?.Commission?.[`Inflow${other?.trim()}Amount`] ?? 0;

                  if (odAmount != null && otherAmount != null) {
                    inflowAmount = odAmount + otherAmount;
                  } else {
                    inflowAmount = odCommission + otherCommission;
                  }
                } else {
                  if (value?.BasedOn && value.RateMatched === "Manual") {
                    inflowAmount =
                      value?.Commission[`Inflow${value?.BasedOn}Amount`] || 0;
                  } else {
                    inflowAmount = +totalAmount || 0;
                  }
                }
                obj[ReturnCharCode(startingIndex, addOn)] = inflowAmount;
              } else {
                obj[ReturnCharCode(startingIndex, addOn)] = "";
              }

              startingIndex++;
              continue;
            }

            if (key === "Legal Liability") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.LLPremium;
              startingIndex++;
              continue;
            }
            if (key === "Gross Premium") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.GrossPremium;
              startingIndex++;
              continue;
            }
            if (key === "NET Premium") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.NETPremium;
              startingIndex++;
              continue;
            }
            if (key === "TP Premium") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.TPPremium;
              startingIndex++;
              continue;
            }
            if (key === "OD Premium") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.ODPremium;
              startingIndex++;
              continue;
            }
            if (key === "Add-On Premium") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.AddOnPremium;
              startingIndex++;
              continue;
            }
            if (key === "Total GST Amount") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                +value?.ODPremiumGST +
                +value?.AddOnPremiumGST +
                +value?.TPPremiumGST +
                +value?.TerrorismPremiumGST +
                +value?.PAPremiumGST +
                +value?.LLPremiumGST;
              startingIndex++;
              continue;
            }

            if (key === "Agent Name") {
              if (value["POS"]) {
                const { Name = "" } = value["POS"];
                obj[ReturnCharCode(startingIndex, addOn)] = Name;
                startingIndex++;
                continue;
              }
            }
            if (key === "POS Name") {
              if (value["POS"]) {
                const { AliasName = "" } = value["POS"];
                obj[ReturnCharCode(startingIndex, addOn)] = AliasName;
                startingIndex++;
                continue;
              }
            }
            if (key === "RM Name") {
              if (value["POS"]) {
                const { ReportingToMotor, ReportingToNonMotor } = value["POS"];

                if (ReportingToMotor || ReportingToNonMotor) {
                  let { Name = "" } = value?.IsNonMotorPolicy
                    ? ReportingToNonMotor || {
                      Name: "",
                    }
                    : ReportingToMotor || {};
                  obj[ReturnCharCode(startingIndex, addOn)] = Name ?? "-";
                  startingIndex++;
                  continue;
                }
              }
            }
            if (key === "Insurance Company") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value["InsuranceCompany"]?.AliasName;
              startingIndex++;
              continue;
            }
            if (key === "Code") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value["POS"]?.Code;
              startingIndex++;
              continue;
            }
            if (key === "Insurer Branch") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value["CompanyBranch"]?.BranchName || "-";
              startingIndex++;
              continue;
            }
            if (key === "Branch") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value["Branch"]?.BranchName;
              startingIndex++;
              continue;
            }
            if (key === "Branch/DEC") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value["Branch"]?.BranchType;
              startingIndex++;
              continue;
            }
            if (key === "Remarks") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value["Remark"] ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "clusterId") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.["clusterId"] ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "cluster Name") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.["clusterName"] ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "Payout Grid Id") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.[
                "matchedPayout"
              ]
                ? value?.["matchedPayout"].map((item) => item._id).join(", ")
                : "-";
              startingIndex++;
              continue;
            }
            if (key === "Make") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value.MakeModal?.Make ?? "-";
              startingIndex++;
              continue;
            }
            if (key === "Approved By") {
              if (value["ApprovedBy"]) {
                let {
                  Name = "",
                  Salutation = "",
                  MiddleName = "",
                  LastName = "",
                } = value["ApprovedBy"];
                obj[ReturnCharCode(startingIndex, addOn)] =
                  `${Salutation} ${Name} ${MiddleName} ${LastName}` ?? "-";
                startingIndex++;
                continue;
              }
            }
            if (key === "Entered By") {
              if (value["EnteredBy"]) {
                let {
                  Name = "",
                  Salutation = "",
                  MiddleName = "",
                  LastName = "",
                } = value["EnteredBy"];
                obj[
                  ReturnCharCode(startingIndex, addOn)
                ] = `${Salutation} ${Name} ${MiddleName} ${LastName}`;
                startingIndex++;
                continue;
              }
            }
            if (key === "Created At") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value["createdAt"].split("T")[0]
              );
              startingIndex++;
              continue;
            }
            if (key === "Updated At") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value["updatedAt"].split("T")[0]
              );
              startingIndex++;
              continue;
            }
            if (key === "Business Prop. Date" || key === "Issue Date") {
              obj[ReturnCharCode(startingIndex, addOn)] = reversDate(
                value["IssueDate"]
              );
              startingIndex++;
              continue;
            }
            if (key === "Vehicle Age") {
              const issueDateYear = value["IssueDate"] ?? new Date();
              const regDateYear = value?.RegistrationDate ?? "";
              const InsuranceCompany = value?.InsuranceCompany;

              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.IsNonMotorPolicy
                  ? 0
                  : calculateVehicleAge(
                    issueDateYear,
                    regDateYear,
                    InsuranceCompany
                  );
              // value?.IsNonMotorPolicy ? 0 : issueDateYear - regDateYear;
              startingIndex++;
              continue;
            }
            // if (key === "Commisionable Premium") {

            //   obj[ReturnCharCode(startingIndex, addOn)] =
            //     value?.CommisionablePremium.toFixed(2)?? 0;

            //   startingIndex++;
            //   continue;
            // }
            if (key === "Based On") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.BasedOn || "-";

              startingIndex++;
              continue;
            }

            if (key === "Nil Dep") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.nillDepth ?? "-";

              startingIndex++;
              continue;
            }
            if (key === "IMT Type") {
              obj[ReturnCharCode(startingIndex, addOn)] = value?.IMTType ?? "-";

              startingIndex++;
              continue;
            }
      
            if (key === "ODD %") {
              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.ODDiscount ?? 0;
              startingIndex++;
              continue;
            }

            if (key === "Sub Product") {
              obj[ReturnCharCode(startingIndex, addOn)] =
              value?.InsuranceUnderFlow?.[1]?.InsuranceType ?? "-";
            startingIndex++;
            continue;
            }
            if (key === "Vehicle Class") {

              obj[ReturnCharCode(startingIndex, addOn)] =
                value?.InsuranceUnderFlow?.[2]?.InsuranceType ?? "-";
              startingIndex++;
              continue;
            }
            obj[ReturnCharCode(startingIndex, addOn)] = value[key];
            startingIndex++;
          }

          slNo++;

          worksheet.addRow(obj);
        }
      };

      createValues();
      setLoading(false);

      const blob = await workbook.xlsx.writeBuffer();

      // Create a Blob and URL for the download link
      const blobData = new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blobData);

      // Create a download link and click it
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <img
          src={require("../backend/utils/UiImages/Download.gif")}
          alt="My GIF"
          style={{
            width: "35px",
            height: "34px",
            cursor: "not-allowed",
          }}
        />
      ) : (
        <Tooltip content="Download Excel">
          <AiOutlineFileExcel
            onClick={handleExportToExcel}
            size={35}
            style={{
              backgroundColor: "green",
              color: "#fff",
              padding: "7px",
              borderRadius: "5px",
              width: "35px",
              height: "34px",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

export default Test;
