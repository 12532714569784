import React, { useState } from "react";
import ExcelJS from "exceljs";
import { AiOutlineFileExcel } from "react-icons/ai";

const ExcelExportMultiSheet = ({ getData, style }) => {
    const [loading, setLoading] = useState(false);

    const handleExcelDownload = async () => {
        setLoading(true);
        try {
            const excelData = await getData();
            await createExcelFile(excelData);
        } catch (error) {
            console.error("Error exporting Excel:", error);
        } finally {
            setLoading(false);
        }
    };

    return (

        <>
            {loading ? (
                <AiOutlineFileExcel
                    // onClick={downloadFile}
                    size={35}
                    style={{
                        backgroundColor: "green",
                        color: "#fff",
                        padding: "7px",
                        borderRadius: "5px",
                        cursor: "not-allowed"
                    }}
                />
            ) : (
                <AiOutlineFileExcel
                    onClick={handleExcelDownload}
                    size={35}
                    style={{
                        backgroundColor: "green",
                        color: "#fff",
                        padding: "7px",
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                />
            )}
        </>
    );
};

function highlightColumn(row, columnLength) {
    for (let i = 1; i <= columnLength; i++) {
        row.getCell(i).alignment = { vertical: "middle", horizontal: "center" };
        row.getCell(i).font = { bold: true, color: { argb: "FFFFFFFF" } };
        row.getCell(i).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "0f4890" },
        };
    }
}

function createRows(workbook, data) {
    for (let row of data) {
        workbook.addRow(row);
    }
}

async function createExcelFile(excelData) {
    const { fileName = "data", ReportLedgerColumns,
        ledgerColumns, ReportLedgerSheet,
        ledgerSheet, invoiceData } = excelData;

    const workbook = new ExcelJS.Workbook();

    // ---------------- Invoice ------------------------
    const InvoiceWorksheet = workbook.addWorksheet("Invoice", {
        pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 }
    });

    InvoiceWorksheet.pageSetup.orientation = 'portrait'
    InvoiceWorksheet.pageSetup.margins = {
        left: 0.7, right: 0.7,
        top: 0.75, bottom: 0.75,
        header: 0.3, footer: 0.3
    };

    InvoiceWorksheet.pageSetup.fitToWidth = 1;
    InvoiceWorksheet.pageSetup.fitToHeight = 0;
    InvoiceWorksheet.pageSetup.paperSize = 9;
    InvoiceWorksheet.pageSetup.header = {
        center: { text: '&24&"Arial,Bold"&UCompany Name', margin: 0.3 },
    };
    InvoiceWorksheet.pageSetup.footer = {
        center: { text: '&8&"Arial"&P/&N', margin: 0.3 },
    };

    // Hide Gridlines
    InvoiceWorksheet.views = [{ showGridLines: false }];
    InvoiceWorksheet.pageSetup.gridlines = false;

    for (let row = 6; row <= 55; row++) {
        for (let col = 2; col <= 11; col++) {
            const cell = InvoiceWorksheet.getCell(row, col);

            if (row === 6) {
                // Top row (B6:K6)
                cell.border = {
                    top: { style: 'medium' },
                    left: col === 2 ? { style: 'medium' } : undefined,
                    right: col === 11 ? { style: 'medium' } : undefined,
                    bottom: undefined
                };
            }
            else if (row === 55) {
                // Bottom row (B55:K55)
                cell.border = {
                    bottom: { style: 'medium' },
                    left: col === 2 ? { style: 'medium' } : undefined,
                    right: col === 11 ? { style: 'medium' } : undefined,
                    top: undefined
                };
            }
            else if (row === 7) {
                // Horizontal line for row 7 (B7:K7)
                cell.border = {
                    bottom: { style: 'medium' }, // Adds horizontal line
                    left: col === 2 ? { style: 'medium' } : undefined, // Left vertical border
                    right: col === 11 ? { style: 'medium' } : undefined // Right vertical border
                };
            }
            else {
                // Middle rows (B to K) - vertical borders only
                cell.border = {
                    left: col === 2 ? { style: 'medium' } : undefined,
                    right: col === 11 ? { style: 'medium' } : undefined
                };
            }
        }
    }

    // Add "Tax Invoice" text to cell F6
    const taxInvoiceCell = InvoiceWorksheet.getCell('F6');
    taxInvoiceCell.value = 'Tax Invoice';
    taxInvoiceCell.font = { name: 'Arial', size: 12, bold: true };
    taxInvoiceCell.alignment = { horizontal: 'center' };

    // Merge B8:F8 and add "Name PosName"
    InvoiceWorksheet.mergeCells('B8:F8');
    const namePosCell = InvoiceWorksheet.getCell('B8');
    namePosCell.value = `Name :- ${invoiceData?.POSAliasName}`;
    namePosCell.font = { name: 'Arial', size: 10, };
    namePosCell.alignment = { horizontal: 'left' };

    // Vertical line from F8 to F15 (includes F9)
    for (let row = 8; row <= 15; row++) {
        const cellF = InvoiceWorksheet.getCell(row, 6);
        cellF.border = {
            ...cellF.border,
            right: { style: 'medium' }
        };
    }

    // Address (B9:F9) - Merged range includes column F
    InvoiceWorksheet.mergeCells('B9:F9');
    const addressCell = InvoiceWorksheet.getCell('B9');
    addressCell.value = `Address :- ${invoiceData?.Address}`;
    addressCell.font = { name: 'Arial', size: 10 };
    addressCell.alignment = { horizontal: 'left' };
    addressCell.border = {
        left: { style: 'medium' },
        right: { style: 'medium' }
    };
    InvoiceWorksheet.getRow(9).height = 20;

    // Mobile Number (B10:E10)
    InvoiceWorksheet.mergeCells('B10:E10');
    const mobCell = InvoiceWorksheet.getCell('B10');
    mobCell.value = `Mob No:- ${invoiceData?.Mobile}`;
    mobCell.font = { name: 'Arial', size: 10 };
    mobCell.alignment = { horizontal: 'left' };

    // Email (B11:E11)
    InvoiceWorksheet.mergeCells('B11:E11');
    const emailCell = InvoiceWorksheet.getCell('B11');
    emailCell.value = `Email ID:- ${invoiceData?.Email}`;
    emailCell.font = { name: 'Arial', size: 10 };
    emailCell.alignment = { horizontal: 'left' };

    const invoiceNoCellH = InvoiceWorksheet.getCell(8, 8);
    invoiceNoCellH.border = { ...invoiceNoCellH.border, right: { style: 'medium' } };
    // Add GSTIN (B13:C13)
    const gstinLabelCell = InvoiceWorksheet.getCell('B13');
    gstinLabelCell.value = 'GSTIN No-';
    gstinLabelCell.font = { name: 'Arial', size: 10, };

    const gstinValueCell = InvoiceWorksheet.getCell('C13');
    gstinValueCell.value = `${invoiceData?.GSTINNumber}`;
    gstinValueCell.font = { name: 'Arial', size: 10 };

    // Add PAN (B14:C14)
    const panLabelCell = InvoiceWorksheet.getCell('B14');
    panLabelCell.value = `PAN No. : `;
    panLabelCell.font = { name: 'Arial', size: 10, };

    const panValueCell = InvoiceWorksheet.getCell('C14');
    panValueCell.value = `${invoiceData?.PanNumber}`;
    panValueCell.font = { name: 'Arial', size: 10 };

    // Merge G8:H8 for Invoice Number
    InvoiceWorksheet.mergeCells('G8:H8');
    const invoiceNoCell = InvoiceWorksheet.getCell('G8');
    invoiceNoCell.value = 'Invoice NO -';
    invoiceNoCell.font = { name: 'Arial', size: 10, };
    invoiceNoCell.alignment = { horizontal: 'left' };

    // Add vertical line to H8:H9
    for (let row = 8; row <= 9; row++) {
        const cellH = InvoiceWorksheet.getCell(row, 8);
        cellH.border = { ...cellH.border, right: { style: 'medium' } };
    }

    // Add "Date - " in I8

    const date = new Date(invoiceData?.EntryDate);

    // Format the date as DD-MM-YYYY (07-03-2025)
    const formattedDate = date.toLocaleDateString('en-GB');
    const dateCell = InvoiceWorksheet.getCell('I8');
    dateCell.value = `Date - ${formattedDate}`;
    dateCell.font = { name: 'Arial', size: 10 };
    dateCell.alignment = { horizontal: 'left' };


    // Add horizontal line G9:K9
    for (let col = 7; col <= 11; col++) {
        const cell = InvoiceWorksheet.getCell(9, col);
        cell.border = { ...cell.border, bottom: { style: 'medium' } };
    }

    // Add "Delivery note" in G10
    const deliveryNoteCell = InvoiceWorksheet.getCell('G10');
    deliveryNoteCell.value = 'Delivery note';
    deliveryNoteCell.font = { name: 'Arial', size: 10 };
    deliveryNoteCell.alignment = { horizontal: 'left' };

    // Add vertical line H10:H15 (right border of column H)
    for (let row = 10; row <= 15; row++) {
        const cellH = InvoiceWorksheet.getCell(row, 8);
        cellH.border = {
            ...cellH.border,
            right: { style: 'medium' }
        };
    }

    // Add "Mode/Term of Payment" in I10
    const paymentTermsCell = InvoiceWorksheet.getCell('I10');
    paymentTermsCell.value = 'Mode/Term of Payment';
    paymentTermsCell.font = { name: 'Arial', size: 10 };
    paymentTermsCell.alignment = { horizontal: 'left' };

    // Add "Cheque/NEFT/RTGS" in I15
    const paymentMethodCell = InvoiceWorksheet.getCell('I15');
    paymentMethodCell.value = 'Cheque/NEFT/RTGS';
    paymentMethodCell.font = { name: 'Arial', size: 10 };
    paymentMethodCell.alignment = { horizontal: 'left' };

    for (let col = 2; col <= 11; col++) {
        const cell = InvoiceWorksheet.getCell(15, col);
        cell.border = {
            top: undefined,
            left: col === 2 ? { style: 'medium' } : undefined,
            right: col === 11 ? { style: 'medium' } : undefined,
            bottom: { style: 'medium' },
            ...cell.border
        };
    }

    for (let row = 10; row <= 15; row++) {
        const cellH = InvoiceWorksheet.getCell(row, 8);
        cellH.border = {
            ...cellH.border,
            right: { style: 'medium' }
        };
    }

    // BILL TO Section

    // Row 16: BILL TO label
    const billToCell = InvoiceWorksheet.getCell('B16');
    billToCell.value = 'BILL TO:-';
    billToCell.font = { name: 'Arial', size: 10, bold: true };
    billToCell.alignment = { horizontal: 'left' };

    // Row 17: Company Name
    InvoiceWorksheet.mergeCells('B17:F17');
    const companyNameCell = InvoiceWorksheet.getCell('B17');
    companyNameCell.value = 'GOOD INSURANCE BROKERS PRIVATE LIMITED';
    companyNameCell.font = { name: 'Arial', size: 10 };
    companyNameCell.alignment = { horizontal: 'left' };
    InvoiceWorksheet.getRow(17).height = 20;

    // Row 18: Address Line 1
    InvoiceWorksheet.mergeCells('B18:F18');
    const address1Cell = InvoiceWorksheet.getCell('B18');
    address1Cell.value = 'Vijay Building,807, Barakhamba Road,';
    address1Cell.font = { name: 'Arial', size: 10 };
    address1Cell.alignment = { horizontal: 'left' };

    // Row 19: Address Line 2
    InvoiceWorksheet.mergeCells('B19:F19');
    const address2Cell = InvoiceWorksheet.getCell('B19');
    address2Cell.value = 'New Delhi, Central Delhi, Delhi, 110001';
    address2Cell.font = { name: 'Arial', size: 10 };
    address2Cell.alignment = { horizontal: 'left' };

    // Row 22: GSTIN
    InvoiceWorksheet.mergeCells('B22:F22');
    const gstinCell = InvoiceWorksheet.getCell('B22');
    gstinCell.value = 'GSTIN No- 07AADCH4702N1ZI';
    gstinCell.font = { name: 'Arial', size: 10 };
    gstinCell.alignment = { horizontal: 'left' };

    // Row 23: PAN
    InvoiceWorksheet.mergeCells('B23:F23');
    const panCell = InvoiceWorksheet.getCell('B23');
    panCell.value = 'PAN No. : AADCH4702N';
    panCell.font = { name: 'Arial', size: 10 };
    panCell.alignment = { horizontal: 'left' };

    // Row 24: State Info
    InvoiceWorksheet.mergeCells('B24:F24');
    const stateCell = InvoiceWorksheet.getCell('B24');
    stateCell.value = 'State Name : DELHI,    Code : 07';
    stateCell.font = { name: 'Arial', size: 10 };
    stateCell.alignment = { horizontal: 'left' };

    // Add vertical lines to column F for rows 16-24
    for (let row = 16; row <= 24; row++) {
        const cellF = InvoiceWorksheet.getCell(row, 6);
        cellF.border = {
            ...cellF.border,
            right: { style: 'medium' }
        };
    }

    // Add horizontal line B25:K25
    for (let col = 2; col <= 11; col++) {
        const cell = InvoiceWorksheet.getCell(25, col);
        cell.border = {
            ...cell.border,
            bottom: { style: 'medium' },
            left: col === 2 ? { style: 'medium' } : undefined,
            right: col === 11 ? { style: 'medium' } : undefined
        };
    }

    // Extend vertical line in column F to row 25
    for (let row = 8; row <= 25; row++) {
        const cellF = InvoiceWorksheet.getCell(row, 6);
        cellF.border = {
            ...cellF.border,
            right: { style: 'medium' }
        };
    }
    // Merge G16:H17 and add "Supplier's Ref."
    InvoiceWorksheet.mergeCells('G16:H17');
    const supplierRefCell = InvoiceWorksheet.getCell('G16');
    supplierRefCell.value = "Supplier's Ref.";
    supplierRefCell.font = { name: 'Arial', size: 10, };
    supplierRefCell.alignment = { horizontal: 'left' };

    // Extend vertical line in column H to row 17
    for (let row = 10; row <= 19; row++) {
        const cellH = InvoiceWorksheet.getCell(row, 8);
        cellH.border = {
            ...cellH.border,
            right: { style: 'medium' }
        };
    }

    // Merge I16:K17 and add "Other Reference"
    InvoiceWorksheet.mergeCells('I16:K17');
    const otherRefCell = InvoiceWorksheet.getCell('I16');
    otherRefCell.value = "Other Reference";
    otherRefCell.font = { name: 'Arial', size: 10, };
    otherRefCell.alignment = { horizontal: 'left' };

    // Ensure vertical line continuity in column K
    for (let row = 16; row <= 17; row++) {
        const cellK = InvoiceWorksheet.getCell(row, 11);
        cellK.border = {
            ...cellK.border,
            right: { style: 'medium' }
        };
    }

    // Add horizontal line G19:K19
    for (let col = 7; col <= 11; col++) {
        const cell = InvoiceWorksheet.getCell(19, col);
        cell.border = {
            ...cell.border,
            bottom: { style: 'medium' }
        };
    }

    // Merge G20:K25 and add "Terms of Delivery"
    InvoiceWorksheet.mergeCells('G20:K25');
    const termsCell = InvoiceWorksheet.getCell('G20');
    termsCell.value = 'Terms of Delivery';
    termsCell.font = { name: 'Arial', size: 10, };
    termsCell.alignment = { horizontal: 'left', vertical: "top" };

    // Ensure vertical line continuity in column K (existing border)
    for (let row = 20; row <= 25; row++) {
        const cellK = InvoiceWorksheet.getCell(row, 11);
        cellK.border = {
            ...cellK.border,
            right: { style: 'medium' }
        };
    }

    // Add bottom border to G25:K25
    for (let col = 7; col <= 11; col++) {
        const cell = InvoiceWorksheet.getCell(25, col);
        cell.border = {
            ...cell.border,
            bottom: { style: 'medium' }
        };
    }

    // Merge cells and add headers (Rows 26-27)

    // SR. NO. (B26:B27)
    InvoiceWorksheet.mergeCells('B26:B27');
    const srNoCell = InvoiceWorksheet.getCell('B26');
    srNoCell.value = 'SR. NO.';
    srNoCell.font = { name: 'Arial', size: 10, };
    srNoCell.alignment = { horizontal: 'left', vertical: 'top' };

    // PARTICULARS (C26:G27)
    InvoiceWorksheet.mergeCells('C26:G27');
    const particularsCell = InvoiceWorksheet.getCell('C26');
    particularsCell.value = 'PARTICULARS';
    particularsCell.font = { name: 'Arial', size: 10, };
    particularsCell.alignment = { horizontal: 'left', vertical: 'top' };

    // HSN/SAC (H26:I27)
    InvoiceWorksheet.mergeCells('H26:I27');
    const hsnCell = InvoiceWorksheet.getCell('H26');
    hsnCell.value = 'HSN/SAC';
    hsnCell.font = { name: 'Arial', size: 10, };
    hsnCell.alignment = { horizontal: 'left', vertical: 'top' };

    // AMOUNT (J26:K27)
    InvoiceWorksheet.mergeCells('J26:K27');
    const amountCell = InvoiceWorksheet.getCell('J26');
    amountCell.value = 'AMOUNT(Rs.)';
    amountCell.font = { name: 'Arial', size: 10, };
    amountCell.alignment = { horizontal: 'left', vertical: 'top' };

    // Add horizontal line with left/right borders (B27:K27)
    for (let col = 2; col <= 11; col++) {
        const cell = InvoiceWorksheet.getCell(27, col);
        cell.border = {
            ...cell.border,
            bottom: { style: 'medium' },
            left: col === 2 ? { style: 'medium' } : undefined,
            right: col === 11 ? { style: 'medium' } : undefined
        };
    }

    // Add vertical lines to column B for rows 26-40
    for (let row = 26; row <= 40; row++) {
        const cellF = InvoiceWorksheet.getCell(row, 2);
        cellF.border = {
            ...cellF.border,
            right: { style: 'medium' }
        };
    }

    // Add vertical lines to column G for rows 26-40
    for (let row = 26; row <= 40; row++) {
        const cellF = InvoiceWorksheet.getCell(row, 7);
        cellF.border = {
            ...cellF.border,
            right: { style: 'medium' }
        };
    }

    // Add vertical lines to column I for rows 26-40
    for (let row = 26; row <= 40; row++) {
        const cellF = InvoiceWorksheet.getCell(row, 9);
        cellF.border = {
            ...cellF.border,
            right: { style: 'medium' }
        };
    }

    // Set row 28 height to half of default
    InvoiceWorksheet.getRow(28).height = 7;

    // Add TEXT 1 in B29 with right alignment
    const textCell = InvoiceWorksheet.getCell('B29');
    textCell.value = '1';
    textCell.font = { name: 'Arial', size: 10 };
    textCell.alignment = { horizontal: 'right' };

    // Ensure vertical lines in columns B and C
    for (let row = 29; row <= 29; row++) {
        const cellB = InvoiceWorksheet.getCell(row, 2);
        cellB.border = {
            ...cellB.border,
            left: { style: 'medium' }
        };

        // Column C (vertical line from previous code)
        const cellC = InvoiceWorksheet.getCell(row, 3);
        cellC.border = {
            ...cellC.border,
            right: { style: 'medium' }
        };
    }

    // Merge C29:G29 and add "Insurance Commission"
    InvoiceWorksheet.mergeCells('C29:G29');
    const insuranceCell = InvoiceWorksheet.getCell('C29');
    insuranceCell.value = 'Insurance Commission';
    insuranceCell.font = { name: 'Arial', size: 10 };
    insuranceCell.alignment = { horizontal: 'left' };

    // Add right border to G29 (end of merged range)
    const cellG29 = InvoiceWorksheet.getCell('G29');
    cellG29.border = {
        ...cellG29.border,
        right: { style: 'medium' }
    };

    // Merge H29:I29 and add '997161'
    InvoiceWorksheet.mergeCells('H29:I29');
    const hsnCell1 = InvoiceWorksheet.getCell('H29');
    hsnCell1.value = '997161';
    hsnCell1.font = { name: 'Arial', size: 10 };
    hsnCell1.alignment = { horizontal: 'left' };

    // Add right border to I29 (end of merged range)
    const cellI29 = InvoiceWorksheet.getCell('I29');
    cellI29.border = {
        ...cellI29.border,
        right: { style: 'medium' }
    };

    // Merge J29:K29 and add '1000'
    InvoiceWorksheet.mergeCells('J29:K29');
    const amountCell1 = InvoiceWorksheet.getCell('J29');
    amountCell1.value = `${invoiceData.TotalPurchaseAmount}`;
    amountCell1.font = { name: 'Arial', size: 10 };
    amountCell1.alignment = { horizontal: 'right' };

    // Ensure vertical line in column K (existing border)
    const cellK29 = InvoiceWorksheet.getCell('K29');
    cellK29.border = {
        ...cellK29.border,
        right: { style: 'medium' }
    };

    // CGST @ 9% (Row 37)
    InvoiceWorksheet.mergeCells('C37:G37');
    const cgstCell = InvoiceWorksheet.getCell('C37');
    cgstCell.value = 'CGST @ 9%';
    cgstCell.font = { name: 'Arial', size: 10 };
    cgstCell.alignment = { horizontal: 'left' };

    // SGST @ 9% (Row 38)
    InvoiceWorksheet.mergeCells('C38:G38');
    const sgstCell = InvoiceWorksheet.getCell('C38');
    sgstCell.value = 'SGST @ 9%';
    sgstCell.font = { name: 'Arial', size: 10 };
    sgstCell.alignment = { horizontal: 'left' };

    // IGST @ 18% (Row 39)
    InvoiceWorksheet.mergeCells('C39:G39');
    const igstCell = InvoiceWorksheet.getCell('C39');
    igstCell.value = 'IGST @ 18%';
    igstCell.font = { name: 'Arial', size: 10 };
    igstCell.alignment = { horizontal: 'left' };

    // Add right borders to merged ranges and column H
    [37, 38, 39].forEach(row => {
        const topLeftCell = InvoiceWorksheet.getCell(`C${row}`);
        topLeftCell.border = {
            ...topLeftCell.border,
            left: { style: 'medium' }
        };

        const cellH = InvoiceWorksheet.getCell(row, 8);
        cellH.border = {
            ...cellH.border,
            left: { style: 'medium' }
        };
    });

    // Merge cells J39:K39
    InvoiceWorksheet.mergeCells('J39:K39');

    // Set IGST amount in cell J39 (leave it empty or set to another value)
    const igstAmountCell = InvoiceWorksheet.getCell('J39');
    igstAmountCell.value = '';
    igstAmountCell.font = { name: 'Arial', size: 10 };
    igstAmountCell.alignment = { horizontal: 'right' };

    // Ensure vertical lines in columns H and K
    for (let row = 37; row <= 39; row++) {
        const cellK = InvoiceWorksheet.getCell(row, 11);
        cellK.border = {
            ...cellK.border,
            right: { style: 'medium' }
        };

        // Set value of cells K37, K38, and K39 to 0
        if (row >= 37 && row <= 39) {
            cellK.value = 0;
            cellK.font = { name: 'Arial', size: 10 };
            cellK.alignment = { horizontal: 'right' };
        }
    }

    // Horizontal line B39:K39 (full-width under IGST row)
    for (let col = 2; col <= 11; col++) {
        const cell = InvoiceWorksheet.getCell(39, col);
        cell.border = {
            ...cell.border,
            bottom: { style: 'medium' }
        };
    }

    // Horizontal line B40:K40 (full-width divider)
    for (let col = 2; col <= 11; col++) {
        const cell = InvoiceWorksheet.getCell(40, col);
        cell.border = {
            ...cell.border,
            bottom: { style: 'medium' },
            left: col === 2 ? { style: 'medium' } : undefined,
            right: col === 11 ? { style: 'medium' } : undefined
        };
    }

    // Merge B41:I42 for "Total" label
    InvoiceWorksheet.mergeCells('B41:I42');
    const totalLabelCell = InvoiceWorksheet.getCell('B41');
    totalLabelCell.value = 'Total';
    totalLabelCell.font = { name: 'Arial', size: 10, bold: true };
    totalLabelCell.alignment = {
        horizontal: 'left',
        vertical: 'middle'
    };

    [41].forEach(row => {
        const totalLabelCell = InvoiceWorksheet.getCell(`C${row}`);
        totalLabelCell.border = {
            ...totalLabelCell.border,
            right: { style: 'medium' }
        };
    });

    // Merge J41:K42 for amount
    InvoiceWorksheet.mergeCells('J41:K42');
    const totalAmountCell = InvoiceWorksheet.getCell('J41');
    totalAmountCell.value = `${invoiceData.TotalPurchaseAmount}`;
    totalAmountCell.font = { name: 'Arial', size: 10, bold: true };
    totalAmountCell.alignment = {
        horizontal: 'right',
        vertical: 'middle'
    };

    // Add borders to merged ranges and vertical lines
    for (let row = 41; row <= 42; row++) {
        for (let col = 2; col <= 11; col++) {
            const cell = InvoiceWorksheet.getCell(row, col);

            if (row === 42) {
                cell.border = {
                    ...cell.border,
                    bottom: { style: 'medium' }
                };
            }

            if (col === 2) {
                cell.border = {
                    ...cell.border,
                    left: { style: 'medium' }
                };
            }

            if (col === 11) {
                cell.border = {
                    ...cell.border,
                    right: { style: 'medium' }
                };
            }
        }
    }
    // --- AMOUNT IN WORDS LABEL (B44) ---
    const amountLabelCell = InvoiceWorksheet.getCell('B44');
    amountLabelCell.value = 'AMOUNT(IN WORDS)';
    amountLabelCell.font = {
        name: 'Arial',
        size: 8,

    };
    amountLabelCell.alignment = {
        horizontal: 'left',
        vertical: 'middle'
    };

    // --- RUPEES ONLY (D44) ---
    const rupeesCell = InvoiceWorksheet.getCell('D44');
    rupeesCell.value = 'Rupees  Only';
    rupeesCell.font = {
        name: 'Arial',
        size: 8
    };
    rupeesCell.alignment = {
        horizontal: 'left',
        vertical: 'middle'
    };

    // --- FOR POS NAME (J46) ---
    const posNameCell = InvoiceWorksheet.getCell('J46');
    posNameCell.value = `POS NAME : ${invoiceData?.POSAliasName}`;
    posNameCell.font = {
        name: 'Arial',
        size: 8,
        bold: true
    };
    posNameCell.alignment = {
        horizontal: 'right',
        vertical: 'middle'
    };

    // --- BANK ACCOUNT DETAILS HEADER (B49) ---
    const bankHeaderCell = InvoiceWorksheet.getCell('B49');
    bankHeaderCell.value = 'BANK ACCOUNT DETAILS';
    bankHeaderCell.font = {
        name: 'Arial',
        size: 8,
        bold: true
    };
    bankHeaderCell.alignment = {
        horizontal: 'left',
        vertical: 'middle'
    };

    // --- NAME LABEL (B50) ---
    const nameLabelCell = InvoiceWorksheet.getCell('B50');
    nameLabelCell.value = `Name:-`;
    nameLabelCell.font = {
        name: 'Arial',
        size: 8
    };
    nameLabelCell.alignment = {
        horizontal: 'left',
        vertical: 'middle'
    };
    // --- C50 (POS NAME) ---
    const posNameValueCell = InvoiceWorksheet.getCell('C50');
    posNameValueCell.value = `${invoiceData?.POSAliasName}`;
    posNameValueCell.font = {
        name: 'Arial',
        size: 8
    };
    posNameValueCell.alignment = {
        horizontal: 'left',
        vertical: 'middle'
    };

    // --- B51 (Bank Name:-) ---
    const bankNameLabelCell = InvoiceWorksheet.getCell('B51');
    bankNameLabelCell.value = 'Bank Name:-';
    bankNameLabelCell.font = {
        name: 'Arial',
        size: 8
    };
    bankNameLabelCell.alignment = {
        horizontal: 'right',
        vertical: 'middle'
    };

    // --- C51 (BANK NAME) ---
    const bankNameValueCell = InvoiceWorksheet.getCell('C51');
    bankNameValueCell.value = `${invoiceData?.BankName}`;
    bankNameValueCell.font = {
        name: 'Arial',
        size: 8
    };
    bankNameValueCell.alignment = {
        horizontal: 'left',
        vertical: 'middle'
    };

    // --- B52 (Account No:-) ---
    const accountLabelCell = InvoiceWorksheet.getCell('B52');
    accountLabelCell.value = 'Account No:-';
    accountLabelCell.font = {
        name: 'Arial',
        size: 8
    };
    accountLabelCell.alignment = {
        horizontal: 'right',
        vertical: 'middle'
    };

    // --- B53 (IFSC Code:-) ---
    const ifscLabelCell = InvoiceWorksheet.getCell('B53');
    ifscLabelCell.value = 'IFSC Code:-';
    ifscLabelCell.font = {
        name: 'Arial',
        size: 8
    };
    ifscLabelCell.alignment = {
        horizontal: 'right',
        vertical: 'middle'
    };

    // --- C52 (Account Number) ---
    const accountValueCell = InvoiceWorksheet.getCell('C52');
    accountValueCell.value = `${invoiceData?.BankAccountNumber}`;
    accountValueCell.font = {
        name: 'Arial',
        size: 8
    };
    accountValueCell.alignment = {
        horizontal: 'left',
        vertical: 'middle'
    };

    // --- C53 (IFSC Code) ---
    const ifscValueCell = InvoiceWorksheet.getCell('C53');
    ifscValueCell.value = `${invoiceData?.IFSC}`;
    ifscValueCell.font = {
        name: 'Arial',
        size: 8
    };
    ifscValueCell.alignment = {
        horizontal: 'left',
        vertical: 'middle'
    };

    // --- AUTHORIZED SIGNATORY (I51) ---
    const signatoryCell = InvoiceWorksheet.getCell('I51');
    signatoryCell.value = 'Authorized Signatory';
    signatoryCell.font = {
        name: 'Arial',
        size: 8,
        bold: true
    };
    signatoryCell.alignment = {
        horizontal: 'right',
        vertical: 'bottom'
    };

    // ---------------- Rport Ledger ------------------------
    const ReportLedgerWorksheet = workbook.addWorksheet("Policy Details");

    const ReportLedgerRolLength = ReportLedgerColumns?.length;
    ReportLedgerWorksheet.columns = ReportLedgerColumns;

    const ReportLedgerRow = ReportLedgerWorksheet.getRow(1);

    highlightColumn(ReportLedgerRow, ReportLedgerRolLength)
    createRows(ReportLedgerWorksheet, ReportLedgerSheet)

    // ---------------- Ledger ------------------------
    const LedgerWorksheet = workbook.addWorksheet("Summary");

    const LedgerRolLength = ledgerColumns?.length;
    LedgerWorksheet.columns = ledgerColumns;

    const LedgerRow = LedgerWorksheet.getRow(1);

    highlightColumn(LedgerRow, LedgerRolLength)
    createRows(LedgerWorksheet, ledgerSheet)


    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

export default ExcelExportMultiSheet;