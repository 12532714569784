import React, { useState, useEffect } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";

import { Alert, AlertTitle } from "@mui/material";
import Papa from "papaparse";
import * as XLSX from "xlsx";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  validateHeadersExcel,
  validateAndUpdateField,
  updateInsuranceType,
} from "../../lib/validateExcel";
import { validateHeaders, createMapping } from "../../lib/validateData";
import "./styles.css";
import { axiosInstance, REACT_APP_FileURL } from "../../config";

const getLastAvailableProperty = (obj) => {
  return (
    obj?.["Vehicle Class"] || obj?.["Sub Product"] || obj?.["Product"] || null
  );
};

const dateRegex = /^\d{2}-\d{2}-\d{4}$/;

const transformData = (data) => {
  const result = data.reduce(
    (acc, curr) => {
      const insuranceCompany = curr["Insurance Company"];
      const RTOCode = curr["RTO Code"];
      const DEF = curr["Date (From)"];
      const DET = curr["Date (To)"];
      acc.DEF = DEF;
      acc.DET = DET;
      if (insuranceCompany) acc.insuranceCompany.add(insuranceCompany.trim());
      if (RTOCode) acc.RTOCode.add(RTOCode.trim());
      acc.insuranceType.add(getLastAvailableProperty(curr));
      return acc;
    },
    {
      insuranceCompany: new Set(),
      RTOCode: new Set(),
      insuranceType: new Set(),
      DEF: "",
      DET: "",
    }
  );

  return {
    insuranceCompany: Array.from(result.insuranceCompany),
    RTOCode: Array.from(result.RTOCode),
    insuranceType: Array.from(result.insuranceType),
    DEF: result.DEF,
    DET: result.DET,
  };
};

const ImportRtoGroup = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    insuranceCompany: [],
    RTOCode: [],
    insuranceType: [],
  });
  const [fileData, setFileData] = useState([]);
  const [headers, setHeaders] = useState([]);

  const [insertedCount, setInsertedCount] = useState(null);
  const [bgColor, setBgColor] = useState("");

  const parseExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = jsonData[0];
      const response = validateHeadersExcel(headers);

      if (!response?.valid) {
        setError(response);
        return;
      }
      const rows = jsonData.slice(1);

      const transformedData = rows.reduce((acc, row) => {
        if (row.length === 0) return acc;

        const transformedRow = Object.fromEntries(
          row
            .map((cell, index) => {
              const header = headers[index];

              if (
                typeof cell === "number" &&
                header.toLowerCase().includes("date")
              ) {
                const date = XLSX.SSF.parse_date_code(cell);
                const formattedDate = `${date.y}-${String(date.m).padStart(
                  2,
                  "0"
                )}-${String(date.d).padStart(2, "0")}`;
                return [header, formattedDate];
              }

              return [header, cell];
            })
            .filter(([key, value]) => key !== undefined && value !== undefined)
        );

        acc.push(transformedRow);
        return acc;
      }, []);

      setHeaders(headers);
      setFileData(transformedData);

      const resultData = transformData(transformedData);
      setData((prev) => ({
        ...prev,
        insuranceCompany: resultData.insuranceCompany,
        RTOCode: resultData.RTOCode,
        insuranceType: resultData.insuranceType,
        DEF: resultData.DEF,
        DET: resultData.DET,
      }));
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFileUpload = (event) => {
    try {
      setLoading(true);
      const file = event.target.files[0];
      const fileType = file ? file.name.split(".").pop().toLowerCase() : "";

      if (fileType === "csv") {
        Papa.parse(file, {
          complete: (result) => {
            if (result?.data && result?.data?.length > 0) {
              const headers = Object.keys(result?.data[0]);

              const response = validateHeaders(headers);

              if (!response?.valid) {
                setError(response);
                return;
              }
              setHeaders(headers);
              setFileData(result.data);
              const resultData = transformData(result.data);
              setData((prev) => ({
                ...prev,
                ...resultData,
              }));
            }
          },
          header: true,
        });
      } else if (fileType === "xls" || fileType === "xlsx") {
        parseExcel(file);
      } else {
        alert("Please upload a CSV, XLSX, or XLS file.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  function toProperFormat(str = "") {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const getDataForRtoGroupImport = async () => {
    try {
      setLoading(true);
      const { data: response } = await axiosInstance.post(
        "/RtoGroupChecker/getDataForRtoGroupImport",
        data
      );
      if (response && response?.data) {
        const {
          insuranceCompany,
          RTOCode,
          PolicyType,
          FuelType,
          insuranceType,
        } = response?.data;
        const validationError = [];

        const mappingsInsuranceType = createMapping(
          insuranceType,
          "InsuranceType",
          "_id"
        );

        // Create mappings
        const mappings = {
          "Insurance Company": createMapping(
            insuranceCompany,
            "AliasName",
            "_id"
          ),
          "Policy Type": createMapping(PolicyType, "PolicyTypeName", "_id"),

          "Fuel Type": createMapping(FuelType, "FuelType", "_id"),
          "RTO Code": createMapping(RTOCode, "RTOCode", "_id"),
        };

        const result = fileData.reduce((acc, curr) => {
          const RTOCode = curr["RTO Code"];

          const keyName = `${curr?.["Vehicle Class"] || ""}
            ${curr?.["Sub Product"] || ""}
            ${curr?.["Product"] || ""}
            ""`;

          const clsName = curr?.["Cluster Name"] || "";

          const clusterName = `${clsName?.toLowerCase()}
          ${curr?.["Date (From)"]}
          ${curr?.["Date (To)"]}
          ${curr?.["Insurance Company"]}
          ${keyName}
          ${curr?.["Policy Type"] || ""}
           ${curr?.["Fuel Type"] || ""}
          ${curr?.["Seating From"] || ""}
          ${curr?.["Seating To"] || ""}
          ${curr?.["GVW From"] || ""}
          ${curr?.["GVW To"] || ""}
          `;

          if (!acc[clusterName]) {
            acc[clusterName] = {
              ...curr,
              GroupName: toProperFormat(curr["Cluster Name"]),
              ListOfRto: [],
              GVWFrom: curr?.["GVW From"] != null ? curr?.["GVW From"] : "",
              GVWTo: curr?.["GVW To"] != null ? curr?.["GVW To"] : "",
              seaterFrom:
                curr?.["Seating From"] != null ? curr?.["Seating From"] : "",
              seaterTo:
                curr?.["Seating To"] != null ? curr?.["Seating To"] : "",
              PolicyType: curr?.["Policy Type"] ? curr?.["Policy Type"] : null,
              FuelType: curr?.["Fuel Type"] ? curr?.["Fuel Type"] : null,
              DEF: curr["Date (From)"],
              DET: curr["Date (To)"],
            };
          }
          acc[clusterName].ListOfRto.push(
            mappings["RTO Code"][RTOCode.trim().toLowerCase()] || null
          );

          if (RTOCode && dateRegex.test(curr["Date (From)"])) {
            validationError.push({
              valid: false,
              id: `${RTOCode}${curr?.["Cluster Name"]}`,
              fieldName: "Date (From)",
              fieldValue: curr["Date (From)"],
              row: { ...curr, errMsg: "Date must be in excel date formate!" },
            });
          }

          if (RTOCode && dateRegex.test(curr["Date (To)"])) {
            validationError.push({
              valid: false,
              id: `${RTOCode}${curr?.["Cluster Name"]}`,
              fieldName: "Date (To)",
              fieldValue: curr["Date (To)"],
              row: { ...curr, errMsg: "Date must be in excel date formate!" },
            });
          }

          if (RTOCode && !mappings["RTO Code"][RTOCode.trim().toLowerCase()]) {
            validationError.push({
              valid: false,
              id: `${RTOCode}${curr?.["Cluster Name"]}`,
              fieldName: "RTO Code",
              fieldValue: RTOCode,
              row: { ...curr, errMsg: "RTO code not found!" },
            });
          }

          return acc;
        }, {});

        const finalData = Object.values(result);

        const fieldsToValidate = [
          { excelField: "Insurance Company", dbField: "InsuranceCompany" },
          { excelField: "Policy Type", dbField: "PolicyType" },
          { excelField: "Fuel Type", dbField: "FuelType" },
        ];

        let updatedData = finalData;

        fieldsToValidate.forEach(({ excelField, dbField }) => {
          const { data: updatedFieldData, error } = validateAndUpdateField(
            updatedData,
            excelField,
            dbField,
            mappings[excelField]
          );

          updatedData = updatedFieldData;
          validationError.push(...error);
        });

        const { data: insTypeUpdate, error: insTypeErr } = updateInsuranceType(
          mappingsInsuranceType,
          updatedData
        );

        updatedData = insTypeUpdate;

        if (insTypeErr.length > 0) {
          validationError.push(...insTypeErr);
        }

        //new code
        const backendData = response.data.rtogroupcheckers;

        updatedData.forEach((item) => {
          const found = backendData.find((backendItem) => {
            return (
              (item["DEF"] || "") === (backendItem["DEF"] || "") &&
              (item["DET"] || "") === (backendItem["DET"] || "") &&
              (item["GroupName"]?.trim().toLowerCase() || "") ===
              (backendItem["GroupName"]?.trim().toLowerCase() || "") &&
              (item["InsuranceCompany"]?.trim().toLowerCase() || "") ===
              (backendItem["InsuranceCompany"]?.trim().toLowerCase() || "") &&
              (item["PolicyType"]?.trim().toLowerCase() || "") ===
              (backendItem["PolicyType"]?.trim().toLowerCase() || "") &&
              (item["FuelType"]?.trim().toLowerCase() || "") ===
              (backendItem["FuelType"]?.trim().toLowerCase() || "") &&
              (item["InsuranceType"] || "") ===
              (backendItem["InsuranceType"] || "") &&
              (item["GVWFrom"] ?? "") === (backendItem["GVWFrom"] ?? "") &&
              (item["GVWTo"] ?? "") === (backendItem["GVWTo"] ?? "") &&
              (item["seaterFrom"] ?? "") ===
              (backendItem["seaterFrom"] ?? "") &&
              (item["seaterTo"] ?? "") === (backendItem["seaterTo"] ?? "") &&
              (item["ListOfRto"]?.join(",") || "") ===
              (backendItem["ListOfRto"]?.join(",") || "")
            );
          });

          if (found) {
            const RTOCode = item["RTO Code"];

            validationError.push({
              valid: false,
              id: `${RTOCode}${item?.["Cluster Name"]}`,
              fieldName: "RTO Code",
              fieldValue: RTOCode,
              row: { ...item, errMsg: "Data already exist" },
            });
          }
        });

        if (validationError.length > 0) {
          const uniqueArray = [];
          const seen = new Map();

          for (const obj of validationError) {
            if (!seen.has(obj.id)) {
              seen.set(obj.id, true);
              uniqueArray.push(obj);
            }
          }

          setError({
            message: "Validation failed!",
            data: uniqueArray,
            errorList: validationError,
          });
          return;
        }

        const postResponse = await axiosInstance.post(
          "/RtoGroupChecker/postManyRtoGroupChecker",
          updatedData
        );

        if (postResponse?.data?.success) {
          setInsertedCount(postResponse?.data?.insertedCount);
          setBgColor("#d4edda");
        }

        const fileInput = document.getElementById("file_upload");
        if (fileInput) {
          fileInput.value = "";
        }
        setHeaders([]);
        setFileData([]);
        setData([]);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateDownload = (event) => {
    event.preventDefault();

    const fileUr = `${REACT_APP_FileURL}/images/Template/RTOGroup-Template.xlsx`;
    window.open(fileUr, "_blank");
  };

  return (
    <>
      <Header />
      <div className="MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="ListDesigncol">
                        <h4 className="card-title">Import RTO Cluster</h4>
                      </div>
                      <button
                        onClick={handleTemplateDownload}
                        className="btn mybtn"
                      >
                        Download Template
                      </button>
                    </div>
                  </div>
                  {error && (
                    <Alert severity="error">
                      {error?.message && (
                        <AlertTitle>Error : {error?.message}</AlertTitle>
                      )}
                      {error?.missingHeaders &&
                        error?.missingHeaders?.length > 0 && (
                          <AlertTitle>
                            {error?.missingHeaders.join(", ")}
                          </AlertTitle>
                        )}
                    </Alert>
                  )}
                  {insertedCount !== null && (
                    <div
                      style={{
                        backgroundColor: bgColor, // Green if success
                        width: "80vw", // 80% of the viewport width
                        height: "auto", // Automatically adjust height based on content
                        padding: "20px", // Optional padding for spacing
                        fontSize: "1rem", // Adjust font size
                        margin: "20px auto",
                      }}
                    >
                      {insertedCount} Records Inserted
                    </div>
                  )}
                  <div className="container">
                    <div>
                      <div className="upload-container">
                        <input
                          type="file"
                          id="file_upload"
                          accept=".xlsx, .xls"
                          onChange={handleFileUpload}
                        />
                      </div>
                      <div className="mt-4" style={{ textAlign: "center" }}>
                        <button
                          className="btn btn-primary"
                          color="warning"
                          type="button"
                          disabled={loading || error || fileData.length === 0}
                          onClick={getDataForRtoGroupImport}
                        >
                          {loading ? "In Progress..." : "Import"}
                        </button>
                      </div>
                    </div>
                    {error && error?.data && error?.data?.length !== 0 && (
                      <div className="mt-8">
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Preview Error Data
                        </div>
                        <div
                          // className=" p-2 container"
                          style={{
                            zoom: "80%",
                            height: "60vh",
                            border: "2px solid black",
                            resize: "vertical", // Allow vertical resizing only
                            overflow: "auto",
                          }}
                        >
                          <table class="table table-striped   table-bordered  overflow-x mt-3">
                            <thead>
                              <tr>
                                {headers &&
                                  headers.length > 0 &&
                                  headers.map((header) => {
                                    let hasError;
                                    if (error && error?.errorList) {
                                      hasError = error?.errorList.find(
                                        (error) => error.fieldName === header
                                      );
                                    }
                                    return (
                                      <th
                                        style={{
                                          backgroundColor: hasError
                                            ? "#f8d7da"
                                            : "",
                                        }}
                                        scope="col"
                                      >
                                        {header}
                                      </th>
                                    );
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              {error?.data.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    {headers.map((item, index) => {
                                      let hasError;
                                      if (error && error?.errorList) {
                                        hasError = error?.errorList.find(
                                          (err) =>
                                            err?.fieldValue === data?.row[item]
                                        );
                                      }

                                      return (
                                        <td
                                          style={{
                                            backgroundColor:
                                              hasError &&
                                                hasError?.fieldValue ===
                                                data?.row[item]
                                                ? "#f8d7da"
                                                : "",
                                          }}
                                          key={index}
                                        >
                                          {data?.row[item]}
                                          {hasError &&
                                            hasError?.fieldValue ===
                                            data?.row[item] && (
                                              <div
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {hasError?.row?.errMsg || ""}
                                              </div>
                                            )}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default ImportRtoGroup;
