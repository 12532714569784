import React, { useEffect, useState } from "react";
import { ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate } from "react-router-dom";
import useHttpErrorHandler from "../Hooks/useHttpErrorHandler";
import { axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { MultiSelect } from "react-multi-select-component";

const returnUnique = (arrOFComp = []) => {
  let jsonObject = arrOFComp.map(JSON.stringify);
  let uniqueSet = new Set(jsonObject);
  let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

  return uniqueArray;
};

const premiumOptions = [
  { label: "OD Premium", value: "ODPremium" },
  { label: "Net Premium", value: "NETPremium" },
  { label: "Addon Premium", value: "AddOnPremium" },
  { label: "TP Premium", value: "TPPremium" },
  { label: "PA Premium", value: "PAPremium" },
  { label: "LL Premium", value: "LLPremium" },
];

const AddCommissionablePremium = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};

  const [data, setData] = useState({
    PolicyTypeOpt: [],
    InsuranceCompanyOpt: [],
    InsuranceTypeOpt: [],
  });
  const [selectedData, setSelectedData] = useState({
    PolicyType: value?.PolicyType || null,
    InsuranceCompany: value?.POS?.InsuranceCompany || [],
    InsuranceType: value?.POS?.InsuranceType || [],
    Premium: value?.POS?.Premium || [],
  });

  const navigate = useNavigate();
  const httpErrorHandler = useHttpErrorHandler();

  const handleSubmit = async () => {
    try {
      if (!selectedData?.PolicyType || !selectedData?.InsuranceCompany) {
        return ToastWarn("All fields are required!");
      }
      const path = Mode
        ? `/CommissionablePremium/${value?._id}`
        : "/CommissionablePremium/create";
      const method = Mode ? "put" : "post";

      const {
        data: { success, message },
      } = await axiosInstance[method](path, {
        PolicyType: selectedData?.PolicyType || null,

        InsuranceCompany: selectedData?.InsuranceCompany?.length
          ? selectedData.InsuranceCompany.map((item) => item.value)
          : [],

        InsuranceType: selectedData?.InsuranceType?.length
          ? selectedData.InsuranceType.map((item) => item.value)
          : [],

        Premium: selectedData?.Premium?.length
          ? selectedData.Premium.map((item) => item.value)
          : [],
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/commissionable-premium/data");
      }
    } catch (error) {
      httpErrorHandler(error);
    }
  };

  const selectData = (e, filedName) => {
    if (selectedData?.[filedName].length === e.length) {
      setSelectedData((prev) => ({
        ...prev,
        [filedName]: returnUnique([...e, ...prev[filedName]]),
      }));
    } else {
      setSelectedData((prev) => ({
        ...prev,
        [filedName]: e,
      }));
    }
  };

  const selectPolicyTypeData = (value) => {
    setSelectedData((prev) => ({
      ...prev,
      PolicyType: value,
    }));
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const [incCompany, insType, policyType] = await Promise.all([
          axiosInstance.get("/InsuranceCompany"),
          axiosInstance.get("/InsuranceType?Root=true&InsuranceCategory=Motor"),
          axiosInstance.get("/PolicyType"),
        ]);

        // Transform Insurance Company data
        const updatedInsCompany =
          incCompany?.data?.data?.map((item) => ({
            label: item?.AliasName || item?.label,
            value: item?._id || item?.value,
          })) || [];

        // Transform Insurance Type data
        const updatedInsType =
          insType?.data?.data?.map((item) => ({
            label: item?.InsuranceType || item?.label,
            value: item?._id || item?.value,
          })) || [];

        if (value) {
          setSelectedData((prev) => ({
            ...prev,
            PolicyType: value?.PolicyType?._id,
            InsuranceCompany: value?.InsuranceCompany,
            InsuranceType: value?.InsuranceType,
            Premium: premiumOptions.filter((item) =>
              value?.Premium.includes(item.value)
            ),
          }));
        }

        setData((prev) => ({
          ...prev,
          PolicyTypeOpt: policyType?.data?.data,
          InsuranceCompanyOpt: updatedInsCompany,
          InsuranceTypeOpt: updatedInsType,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [value]);

  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        <div className="row mx-2 ">
          <div
            className="card-body CreateCard"
            style={{
              zoom: "90%",
            }}
          >
            <h5>{view ? "View Club" : Mode ? "Edit Data" : "Add Data"}</h5>
            <hr />
            <br />
            <div className="row">
              <div className="col-md-3 align-items-center">
                <label className="my-2" required>
                  Insurance Company
                </label>
                <MultiSelect
                  options={data?.InsuranceCompanyOpt || []}
                  value={selectedData?.InsuranceCompany}
                  onChange={(e) => {
                    selectData(e, "InsuranceCompany");
                  }}
                  labelledBy="Select"
                  disabled={view}
                />
              </div>

              <div className="col-md-3 align-items-center">
                <label className="my-2" required>
                  Product
                </label>
                <MultiSelect
                  options={data?.InsuranceTypeOpt || []}
                  value={selectedData?.InsuranceType}
                  onChange={(e) => {
                    selectData(e, "InsuranceType");
                  }}
                  labelledBy="Select"
                  disabled={view}
                />
              </div>

              <div className="col-md-3 align-items-center">
                <label className="my-2" required>
                  Policy Type
                </label>
                <select
                  className="form-select"
                  value={selectedData?.PolicyType || ""}
                  onChange={(e) => {
                    selectPolicyTypeData(e.target.value);
                  }}
                  disabled={view}
                >
                  <option value="">-- Select Policy Type --</option>
                  {data.PolicyTypeOpt &&
                    data.PolicyTypeOpt.map((data) => (
                      <option key={data?._id} value={data?._id}>
                        {data.PolicyTypeName}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-md-3 align-items-center">
                <label className="my-2" required>
                  Premium
                </label>
                <MultiSelect
                  options={premiumOptions}
                  value={selectedData?.Premium}
                  onChange={(e) => {
                    selectData(e, "Premium");
                  }}
                  labelledBy="Select"
                  disabled={view}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-12" style={{ float: "left" }}>
            <center>
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() =>
                  navigate("/admin/masters/commissionable-premium/data")
                }
              >
                Back
              </button>
              &nbsp;
              {!view && (
                <button
                  type="submit"
                  className="btn mybtn"
                  // style={{ marginTop: 28 }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
            </center>
          </div>
          <div className="col-lg-5" style={{ float: "left" }}></div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default AddCommissionablePremium;
