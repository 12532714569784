import { configureStore } from "@reduxjs/toolkit";
import { UserReducers } from "./Reducer/UserReducers";
import { PayoutGridFilter, PolicyFilter,CPPolicyFilter, RtoClusterFilter} from "./Reducer/FiltersReducers";
import {ClubExceptionFilters} from "./Reducer/ClubExceptionFiltersReducers";
const store = configureStore({
  reducer: {
    user: UserReducers,
    PolicyFilters: PolicyFilter,
    RtoClusterFilters: RtoClusterFilter,
    CPPolicyFilters: CPPolicyFilter,
    PayoutGridFilters: PayoutGridFilter,
    ClubExceptionFilters: ClubExceptionFilters,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
