import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses, ToastWarn } from "../../Utils/Tost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";

const CreateInsuranceType = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const { view } = state || {};
  const [InsuranceTypeName, setInsuranceTypeName] = useState("");
  const [GST, setGST] = useState(18);
  const [InsuranceCategory, setInsuranceCategory] = useState("Motor");
  const [formData, setFormData] = useState({
    seatingCapacity: "yes",
    cubicCapacity: "yes",
    GVW: "yes",
    IMTType: "no",
    DOB: "no",
    status: "active",
  });

  const [InsuranceCompany, setInsuranceCompany] = useState("");
  const [InsuranceCompanyOpt, setInsuranceCompanyOpt] = useState([]);
  const navigate = useNavigate();
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);

  const handleCreate = async () => {
    if (!InsuranceTypeName) {
      return ToastWarn("Insurance Type Name is required ");
    }
    try {
      const length = SelectedInsuranceType.length;
      const insuranceTypeFlow =
        length !== 0
          ? SelectedInsuranceType.filter((ele) => ele.value.trim() !== "").map(
            (ele) => ele.value
          )
          : [];

      const {
        data: { success, message },
      } = await axiosInstance.post("/InsuranceType", {
        ...formData,
        InsuranceType: InsuranceTypeName,
        InsuranceCompany: InsuranceCompany,
        GST: GST,
        InsuranceUnder: length === 0 ? "" : insuranceTypeFlow[length - 1],
        InsuranceUnderFlow: insuranceTypeFlow,
        InsuranceCategory,
       
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/InsuranceType/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  const handleEdit = async () => {
    try {
      if (!InsuranceTypeName) {
        return ToastWarn("Insurance Type Name is required ");
      }

      const length = SelectedInsuranceType.length;
      const insuranceTypeFlow =
        length !== 0
          ? SelectedInsuranceType.filter((ele) => ele.value !== "").map(
            (ele) => ele.value
          )
          : [];

      const {
        data: { success, message },
      } = await axiosInstance.put(`/InsuranceType/${id}`, {
        ...formData,
        InsuranceType: InsuranceTypeName,
        InsuranceCompany: InsuranceCompany,
        InsuranceUnder: length === 0 ? "" : insuranceTypeFlow[length - 1],
        InsuranceUnderFlow: insuranceTypeFlow,
        GST: GST,
        InsuranceCategory,
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/InsuranceType/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getInsuranceCompany = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceCompany");
      setInsuranceCompanyOpt(data);
    } catch (error) { }
  };

  const getDataInsuranceType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceType?Root=true");
      setSelectedInsuranceType([{ data, value: "" }]);
    } catch (error) { }
  };

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get("/InsuranceType?Root=true");
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) { }
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) { }
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      getDataInsuranceType();
    }
  };

  const getDataById = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/InsuranceType/getDataById/${id}`);
      setInsuranceTypeName(data.InsuranceType);
      setGST(data.GST);
      setInsuranceCategory(data?.InsuranceCategory);
      setInsuranceCompany(data.InsuranceCompany);
      callInsuranceData(data.InsuranceUnderFlow);
      setFormData(data);
    } catch (error) { }
  };
  useEffect(() => {
    if (id) {
      getDataById();
    } else {
      callInsuranceData([]);
    }
    getInsuranceCompany();
  }, []);

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="">
              <h4 className="card-title">
                {view
                  ? "View Insurance Type"
                  : id
                    ? "Edit Insurance Type"
                    : "Create Insurance Type"}
              </h4>
              <hr />
              <br />

              <div className="row">
                <div className="col-md-4 align-items-center">
                  <label required>Insurance Type Name</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="InsuranceTypeName"
                    value={InsuranceTypeName}
                    onChange={(e) => setInsuranceTypeName(e.target.value)}
                    placeholder="Enter InsuranceType Name"
                    required //done
                  />
                </div>
                <div className="col-md-4 align-items-center">
                  <label>Insurance Type</label>
                  <div className="">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="insuranceType"
                      value={InsuranceCategory}
                      onChange={(e) => {
                        setInsuranceCategory(e.target.value);
                        if (e.target.value === "Non-Motor") {
                          setFormData((prevState) => ({
                            ...prevState,
                            seatingCapacity: "no",
                            cubicCapacity: "no",
                            GVW: "no",
                            IMTType: "no",
                          }));
                        }
                      }}
                      disabled={view}
                    >
                      <option value="Motor">Motor</option>
                      <option value="Non-Motor">Non-Motor</option>
                    </select>
                  </div>
                </div>
                {SelectedInsuranceType &&
                  SelectedInsuranceType?.length > 0 &&
                  SelectedInsuranceType.map(
                    (data, index) =>
                      data &&
                      data?.data?.length > 0 && (
                        <MultiInsurance
                          key={index}
                          Options={data}
                          setSelectedInsuranceType={setSelectedInsuranceType}
                          // getMakeModal={getMakeModal}
                          view={view}
                          SelectedInsuranceType={SelectedInsuranceType}
                          index={index}
                          OptionsValue={data.value}
                        />
                      )
                  )}
                <div className="col-md-4 align-items-center">
                  <label>Insurance Company</label>
                  <select
                    disabled={view}
                    class="form-select"
                    aria-label="Default select example"
                    name="InsuranceCompany"
                    value={InsuranceCompany}
                    onChange={(e) => setInsuranceCompany(e.target.value)}
                    required
                  >
                    <option value="">
                      -------------Select Insurance Company-------------
                    </option>
                    {InsuranceCompanyOpt &&
                      InsuranceCompanyOpt.map((data) => (
                        <option key={data._id} value={data._id}>
                          {data.AliasName}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 align-items-center">
                  <label>GST on TP (in %)</label>
                  <div className="">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="GST"
                      value={GST}
                      onChange={(e) => setGST(e.target.value)}
                      // required

                      disabled={view}
                    >
                      <option value={12}>12</option>
                      <option value={18}>18</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 align-items-center">
                  <label>Is Date Of Birth Applicable</label>
                  <div className="">
                    <select
                      disabled={InsuranceCategory === "Motor" || view}
                      class="form-select"
                      aria-label="Default select example"
                      name="DOB"
                      value={formData?.DOB}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          DOB: e.target.value,
                        }))
                      }
                      required
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 align-items-center">
                  <label>Is Seating Capacity Applicable</label>
                  <div className="">
                    <select
                      disabled={InsuranceCategory === "Non-Motor" || view}
                      class="form-select"
                      aria-label="Default select example"
                      name="seatingCapacity"
                      value={formData?.seatingCapacity}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          seatingCapacity: e.target.value,
                        }))
                      }
                      required
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4 align-items-center">
                  <label>Is Cubic Capacity Applicable</label>
                  <div className="">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="cubicCapacity"
                      value={formData?.cubicCapacity}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          cubicCapacity: e.target.value,
                        }))
                      }
                      disabled={InsuranceCategory === "Non-Motor" || view}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 align-items-center">
                  <label>Is GVW Applicable</label>
                  <div className="">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="GVW"
                      value={formData?.GVW}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          GVW: e.target.value,
                        }))
                      }
                      disabled={InsuranceCategory === "Non-Motor" || view}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>


                <div className="col-md-4 align-items-center">
                  <label>Is IMT Type Applicable</label>
                  <div className="">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="IMTType"
                      value={formData?.IMTType}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          IMTType: e.target.value,
                        }))
                      }
                      disabled={InsuranceCategory === "Non-Motor" || view}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
              
                <div className="col-md-4 align-items-center">
                  <label>Status</label>
                  <div className="">
                    <select
                      className="form-select"
                      aria-label="Status select"
                      name="status"
                      value={formData?.status}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          status: e.target.value, // Ensure the value is a string
                        }))
                      }
                      required
                    >
                      <option value="active">active</option>
                      <option value="inactive">inactive</option>
                    </select>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/masters/InsuranceType/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  id ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default CreateInsuranceType;
