import React, { useState } from "react";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import ExcelExportMultiSheet from "../utils/ExcelExport/ExcelExportMultiSheet";
import { reversDate } from "../../Utils/usefullFunction";

const ReportLedgerColumns = [
  { header: "Sr.No.", key: "SNo", width: 10 },
  { header: "Insurance Company", key: "InsuranceCompany", width: 10 },
  { header: "Policy Number", key: "PolicyNumber", width: 25 },
  { header: "Insured Name", key: "InsureeName", width: 30 },
  { header: "Business Prop. Date", key: "BusinessPropDate", width: 20 },
  { header: "Issue Date", key: "IssueDate", width: 20 },
  { header: "Start Date (RSD)", key: "RSD", width: 20 },
  { header: "Expiry Date (RED)", key: "RED", width: 20 },
  { header: "Customer Type", key: "CustomerType", width: 20 },
  { header: "Vehicle Number", key: "VehicleNumber", width: 20 },
  { header: "RTO State", key: "RTOState", width: 20 },
  { header: "RTO City", key: "RTOCity", width: 20 },
  { header: "RTO Code", key: "RTOCode", width: 20 },
  { header: "DoRegn.", key: "RegistrationDate", width: 20 },
  { header: "YoMfg.", key: "YearOfManufacture", width: 20 },
  { header: "Vehicle Age", key: "Vehicle Age", width: 20 },
  { header: "Product", key: "product", width: 25 },
  { header: "Sub Product", key: "subProduct", width: 20 },
  { header: "Vehicle Class", key: "vehicleClass", width: 20 },
  { header: "Policy Type", key: "PolicyType", width: 20 },
  { header: "Business Type", key: "BusinessType", width: 20 },
  { header: "Make", key: "Make", width: 20 },
  { header: "Model", key: "Model", width: 20 },
  { header: "Variant", key: "Variant", width: 20 },
  { header: "Fuel Type", key: "FuelType", width: 20 },
  { header: "CC/Watt", key: "Engine", width: 20 },
  { header: "GVW", key: "GVW", width: 20 },
  { header: "Seater", key: "Seater", width: 20 },
  { header: "IDV/Sum insured", key: "IDV_Sum", width: 20 },
  { header: "POS Name", key: "POSName", width: 20 },
  { header: "Agent Code", key: "POSName2", width: 20 },
  { header: "RM Name", key: "RMName", width: 20 },
  { header: "Gross Premium", key: "GrossPremium", width: 20 },
  { header: "Total GST Amount", key: "TotalGSTAmount", width: 20 },
  { header: "NET Premium", key: "NETPremium", width: 20 },
  { header: "OD Premium", key: "ODPremium", width: 20 },
  { header: "Add-On Premium", key: "AddOnPremium", width: 20 },
  { header: "TP Premium", key: "TPPremium", width: 20 },
  { header: "CPA", key: "PAPremium", width: 20 },
  { header: "Legal Liability", key: "LLPremium", width: 20 },
  { header: "Commisionable Premium", key: "CommissionablePremium", width: 20 },
  { header: "Based On", key: "BasedOn", width: 20 },
  { header: "NCB", key: "NCB", width: 25 },
  { header: "ODD %", key: "ODDiscount", width: 20 },
  { header: "Nil Dep", key: "nillDepth", width: 20 },
  { header: "IMT Type", key: "IMTType", width: 20 },
  { header: "Entered By", key: "EnteredBy", width: 20 },
  { header: "Branch", key: "Branch", width: 20 },
  { header: "Approved By", key: "ApprovedBy", width: 20 },
  { header: "QC Remarks", key: "Remarks", width: 20 },
  { header: "Created At", key: "CreatedAt", width: 20 },
  { header: "Updated At", key: "UpdatedAt", width: 20 },
  { header: "Outflow %", key: "Outflow", width: 20 },
  { header: "Outflow Amount", key: "OutflowAmount", width: 20 },
  { header: "Billed At", key: "EnterDate", width: 20 },
  { header: "Billed By", key: "BilledBy", width: 20 },
  { header: "Billed Amount", key: "BilledAmount", width: 20 },
  { header: "Policy Copy", key: "policyCopy", width: 100 },
];

const transformData = async (data, EnterDate, BilledBy) => {
  const enteredDate = EnterDate.split("T")[0]
  const updatedData = data.map((item, index) => {
    const basedOn = (item.BasedOn ?? "").split("+");
    const { ReportingToMotor, ReportingToNonMotor } = item["POS"];

    let { Name = "", LastName = "" } = item?.IsNonMotorPolicy
      ? ReportingToNonMotor || {
        Name: "",
        LastName: "",
      }
      : ReportingToMotor || {};
    return {
      ...item,
      SNo: index + 1,
      InsuranceCompany: item?.["InsuranceCompany"]?.AliasName || "-",
      BusinessPropDate: reversDate(item["IssueDate"]),
      IssueDate: reversDate(item["IssueDate"]),
      RSD: reversDate(item?.RSD),
      RED: reversDate(item?.RED),
      RTOState: item?.VehicleNumber?.slice(0, 2)?.toLocaleUpperCase() ?? "-",
      RTOCity: item.RTO?.RTOName ?? "-",
      RTOCode: item.VehicleNumber?.slice(0, 4) ?? "-",
      product: item.InsuranceUnderFlow[0]?.InsuranceType || "-",
      subProduct: item.InsuranceUnderFlow[1]?.InsuranceType || "-",
      vehicleClass: item.InsuranceUnderFlow[2]?.InsuranceType || "-",
      PolicyType: item?.PolicyType?.PolicyTypeName ?? "-",
      Make: item?.MakeModal?.Make ?? "-",
      Model: item?.Model?.Model ?? "-",
      Variant: item?.Variant?.VariantName ?? "-",
      FuelType: item?.FuelType?.FuelType ?? "-",
      POSName: item["POS"]?.Name ?? "-",
      POSName2: item["POS"]?.AliasName ?? "-",
      RMName: `${Name} ${LastName}` ?? "-",
      EnterDate: enteredDate ?? "-",
      TotalGSTAmount:
        +item?.ODPremiumGST +
        +item?.TPPremiumGST +
        +item?.TerrorismPremiumGST +
        +item?.PAPremiumGST +
        +item?.LLPremiumGST,
      EnteredBy:
        `${item?.EnteredBy?.Name} ${item?.EnteredBy?.MiddleName} ${item?.EnteredBy?.LastName}` ??
        "-",
      Branch: item?.Branch?.BranchName,
      ApprovedBy:
        `${item?.ApprovedBy?.Name || ""} ${item?.ApprovedBy?.MiddleName || ""
        } ${item?.ApprovedBy?.LastName || ""}` ?? "-",
      Remarks: item?.QCRemark,
      CreatedAt: reversDate(item["createdAt"].split("T")[0]),
      UpdatedAt: reversDate(item["updatedAt"].split("T")[0]),
      Outflow:
        item.BasedOn && basedOn.length === 1
          ? `${item.Commission?.[`POSRate${basedOn[0]}`]}`
          : item.BasedOn && basedOn.length === 2
            ? `${item.Commission?.[`POSRate${basedOn[0]}`]}+${item.Commission?.[`POSRate${basedOn[1]}`]}`
            : "-",

      OutflowAmount:
        item.BasedOn && basedOn.length === 1
          ? +item.Commission?.[`POSRate${basedOn[0]}Amount`]
          : item.BasedOn && basedOn.length === 2
            ? item.Commission?.[`POSRate${basedOn[0]}Amount`] + item.Commission?.[`POSRate${basedOn[1]}Amount`]
            : "-",

      BilledAmount:
        item.BasedOn && basedOn.length === 1
          ? +item.Commission?.[`POSRate${basedOn[0]}Amount`]
          : item.BasedOn && basedOn.length === 2
            ? item.Commission?.[`POSRate${basedOn[0]}Amount`] + item.Commission?.[`POSRate${basedOn[1]}Amount`]
            : "-",

      BilledBy: BilledBy.Name || "",

      policyCopy: {
        text: `${REACT_APP_FileURL}${item?.NewPolicyCopy}`,
        hyperlink: `${REACT_APP_FileURL}${item?.NewPolicyCopy}`,
      },
    };
  });

  return updatedData;
};

const NewPurchaseExcel = ({ fileName, _id }) => {

  const [loading, setLoading] = useState();

  const downloadFile = async () => {
    try {
      setLoading(true);

      const {
        data: {
          data: {
            Policy = [],
            EnterDate,
            BilledBy,
            CreditAccount: {
              Name = "",
              Salutation = "",
              MiddleName = "",
              LastName = "",
              AliasName="",
              Email = "", Mobile = "", Address = "", GSTINNumber = "", PanNumber = "", BankName = "", IFSC = "", BankAccountNumber = "",
            },
          },
        },
      } = await axiosInstance.get(`/Purchase/single/Excel/${_id}`);

      const ReportLedgerSheet = await transformData(Policy, EnterDate, BilledBy);

      const ledgerColumns = [
        { header: "Product", key: "product", width: 25 },
        { header: "Count", key: "count", width: 10 },
        { header: "Gross Premium", key: "GrossPremium", width: 20 },
        { header: "OD Premium", key: "ODPremium", width: 20 },
        { header: "NET Premium", key: "NETPremium", width: 20 },
        { header: "TP Premium", key: "TPPremium", width: 20 },
        { header: "Commission", key: "Commission", width: 20 },
      ];

      const productCounts = {};
      ReportLedgerSheet.forEach((item) => {
        const productValue =
          (item.vehicleClass !== "-" ? item.vehicleClass : null) ||
          (item.subProduct !== "-" ? item.subProduct : null) ||
          item.product ||
          "-";

        if (productValue === "-") return;

        if (!productCounts[productValue]) {
          productCounts[productValue] = {
            count: 0,
            GrossPremium: 0,
            ODPremium: 0,
            NETPremium: 0,
            TPPremium: 0,
            Commission: 0,
          };
        }
        productCounts[productValue].count += 1;
        productCounts[productValue].GrossPremium += +item.GrossPremium || 0;
        productCounts[productValue].ODPremium += +item.ODPremium || 0;
        productCounts[productValue].NETPremium += +item.NETPremium || 0;
        productCounts[productValue].TPPremium += +item.TPPremium || 0;
        productCounts[productValue].Commission += +item.OutflowAmount || 0;
      });


      const ledgerSheet = Object.keys(productCounts).map((product) => ({
        product: product,
        count: productCounts[product].count,
        GrossPremium: productCounts[product].GrossPremium,
        ODPremium: productCounts[product].ODPremium,
        NETPremium: productCounts[product].NETPremium,
        TPPremium: productCounts[product].TPPremium,
        Commission: productCounts[product].Commission,
      }));
      const totalBilledAmount = ReportLedgerSheet.reduce(
        (sum, item) => sum + (item.BilledAmount || 0),
        0
      );
      const totalCommission = ledgerSheet.reduce(
        (sum, row) => sum + (row.Commission || 0),
        0
      );

      ledgerSheet.push({
        TPPremium: "Total",
        Commission: totalCommission,
      });

      const invoiceData = {
        Name,
        Salutation,
        MiddleName,
        LastName,
        Email,              // Email field
        Mobile,             // Mobile field
        Address,            // Address field
        GSTINNumber,        // GSTINNumber field
        PanNumber,          // PanNumber field
        BankName,           // BankName field
        IFSC,               // IFSC field
        BankAccountNumber,  // BankAccountNumber field
        POSAliasName: AliasName || "", // Add POS alias name
        EntryDate: EnterDate.split("T")[0],      // Add entry date
        TotalPurchaseAmount: totalBilledAmount,
      };

      return {
        fileName: `Bill-${Salutation} ${Name} ${MiddleName} ${LastName}-${EnterDate.split("T")[0]}`,
        ReportLedgerColumns,
        ledgerColumns,
        ReportLedgerSheet,
        ledgerSheet, invoiceData
      };
    } catch (error) {
      console.error("Error fetching or transforming data:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <ExcelExportMultiSheet getData={downloadFile} />
    </>
  );
};

export default NewPurchaseExcel;
