import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import { axiosInstance } from "../../config";
import TableTransition from "../TableTransition/TableTransition";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ExcelExport from "../utils/ExcelExport/ExcelExport";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import Select from "react-select";
import { useSelector } from "react-redux";
import useGettingData from "../Hooks/useGettingData";

const Employee = () => {
  const { user } = useSelector((store) => store.user);

  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState([]); // Filtered data for display
  const [searchTerm, setSearchTerm] = useState("");
  const [AllKey, setAllKey] = useState();
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [firstRender, setFirstRender] = useState(false);
  const [inputData, setInput] = useState("");
  const [branch, setBranch] = useState({
    _id: "",
    BranchName: "-- Select Branch --",
  });

  let {
    getData: getBranch,
    Loading: BranchLoading,
    Options: BranchOpt = [
      {
        _id: "",
        BranchName: "-- Select Branch --",
      },
      user?.Branch,
    ],
  } = useGettingData("/Branch", [
    {
      _id: "",
      BranchName: "-- Select Branch --",
    },
  ]);

  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/Employee/GetPopulate?page=${
          pagination + 1
        }&limit=${rowsPerPage}&inputData=${inputData}`
      );

      const {
        data: { data: employeeData, totalDocs },
      } = response;

      setTotalDocs(totalDocs);
      setData(employeeData);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteEmployee = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/Employee/${_id}`);

      if (success) {
        getData();
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getDataByPageNation = async () => {
    try {
      const response = await axiosInstance.get(
        `/Employee/GetPopulate?page=${
          pagination + 1
        }&limit=${rowsPerPage}&inputData=${inputData}&branch=${branch?._id}`
      );
      setData(response.data.data);
      setFilteredData(response.data.data);
      setTotalDocs(response.data.totalDocs);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const handleSearch = (term) => {
  //   setSearchTerm(term);
  //   if (term === "") {
  //     setFilteredData(data);
  //   } else {
  //     const filtered = data.filter((item) =>
  //       [item.EmployeeCode, item.Email, item.Name].some((field) =>
  //         field?.toString().toLowerCase().includes(term.toLowerCase())
  //       )
  //     );
  //     setFilteredData(filtered);
  //   }
  // };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (user && user?.Branch) {
      if (user?.Branch?.BranchCode !== "0") setBranch(user?.Branch);
    }
  }, [user]);

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
    }
    const timeout = setTimeout(() => {
      getDataByPageNation();
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [pagination, rowsPerPage, inputData, branch]);

  const EraserHandler = async () => {
    setInput("");
    getData();
  };

  //excel export

  const getExcelData = async () => {
    let rows = [];
    try {
      const {
        data: { data, totalDocs },
      } = await axiosInstance.get(
        `/Employee/GetPopulateExport?inputData=${inputData}&branch=${branch?._id}`
      );

      // setData(data);
      if (data && data.length > 0) {
        const responseData = data;
        const updatedData = responseData.map((item) => ({
          ...item,

          Branch: item?.Branch?.BranchName || "",
          ReportingTo: item?.ReportingTo?.Name || "",
          Permission: item?.Permission?.Roll || "",
        }));

        rows = updatedData;
      }

      // Define columns
      const columns = [
        { header: "Code", key: "EmployeeCode", width: 20 },
        { header: "Name", key: "Name", width: 20 },
        { header: "Middle Name", key: "MiddleName", width: 15 },
        { header: "Last Name", key: "LastName", width: 15 },
        { header: "DOB", key: "DOB", width: 15 },
        { header: "DOA", key: "DOA", width: 15 },
        { header: "Gender", key: "Gender", width: 15 },
        { header: "Email", key: "Email", width: 25 },
        { header: "Mobile Number", key: "MobileNumber", width: 15 },
        { header: "Aadhar Number", key: "AadhaarNumber", width: 20 },
        { header: "Pan Number", key: "PanNumber", width: 20 },
        { header: "Father's Name", key: "FatherName", width: 20 },
        { header: "Branch Name", key: "Branch", width: 20 },
        { header: "Joining Date", key: "JoiningDate", width: 20 },
        { header: "Marital Status", key: "MaritalStatus", width: 15 },
        { header: "Permission", key: "Permission", width: 20 },
        { header: "Reporting To", key: "ReportingTo", width: 25 },
      ];

      return { columns, rows, fileName: "Employee List" };
    } catch (error) {
      console.error("Error:", error);
      return { columns: [], rows: [], fileName: "Employee List" };
    }
  };

  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by, Name"
      />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <div className="ListDesigncol">
                        <h4 className="card-title">Employee</h4>
                        <p className="card-title-desc">Employee List</p>
                      </div>
                    </div>
                    <div className="col-6 d-flex justify-content-end gap-3">
                      <Link className="btn mybtn" to="/admin/masters/Employee">
                        Add Employee
                      </Link>
                      <Link
                        className="btn mybtn"
                        to="/admin/masters/import-employee/data"
                      >
                        Import Employee
                      </Link>
                      <ExcelExport getData={getExcelData} />
                    </div>
                  </div>

                  {loading ? (
                    <div
                      className=" p-2 container"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                      }}
                    >
                      <TableSkeleton />
                    </div>
                  ) : (
                    <>
                      <TableTransition
                        AllKey={AllKey}
                        data={data}
                        editUrl={"/admin/masters/Employee"}
                        Mode={true}
                        deleteFunction={deleteEmployee}
                        isProfileImage={true}
                      />

                      <div>
                        <div
                          className="search-input-container"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <div style={{ display: "flex", gap: 3 }}>
                            <SearchInputFilter
                              setInput={setInput}
                              inputData={inputData}
                              PlaceHolder="Name, Email, Code, Mobile"
                              style={{
                                width: "300px",
                                height: "45px",
                                background: "#fff",
                              }}
                            />

                            <div
                              className=""
                              onClick={() => {
                                if (user?.Branch?.BranchCode === "0")
                                  getBranch();
                              }}
                            >
                              {/* <div className=""> */}
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isDisabled={user?.Branch?.BranchCode !== "0"}
                                options={
                                  BranchOpt.length === 0
                                    ? [user?.Branch]
                                    : BranchOpt
                                }
                                isLoading={BranchLoading}
                                // defaultValue={{
                                //   _id:"-- Please Select Variant --"
                                // }}
                                getOptionValue={(option) => option["_id"]}
                                getOptionLabel={(option) =>
                                  option["BranchName"]
                                }
                                value={branch}
                                onChange={(ele) => {
                                  setPagination(0);
                                  setBranch(ele);
                                }}
                                styles={{ height: "42px" }}
                              />
                            </div>
                          </div>
                          {/* 
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search By Employee Code, Email, or Name"
                            value={searchTerm}
                            onChange={(e) => handleSearch(e.target.value)}
                            style={{
                              borderRadius: "25px",
                              padding: "10px 20px",
                              fontSize: "16px",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                          /> */}

                          {/* <div style={{ display: "flex", gap: 2 }}>
                            <div
                              className="searchBox"
                              style={{
                                width: "50px",
                                cursor: "pointer",
                              }}
                              // onClick={() => filterDataHandler()}
                            >
                              <MdSend />
                            </div>
                            <div
                              className="searchBox"
                              style={{
                                width: "50px",
                                cursor: "pointer",
                              }}
                              onClick={() => EraserHandler()}
                            >
                              <BsEraser />
                            </div>
                          </div> */}
                        </div>

                        <div
                          class="table-responsive"
                          style={{
                            zoom: "70%",
                          }}
                        >
                          <table class="table table-striped   table-bordered  overflow-x mt-3">
                            <thead>
                              <tr
                                style={{ background: "#174379", color: "#fff" }}
                              >
                                <th scope="col">S.No.</th>
                                <th scope="col">Code</th>
                                <th scope="col">Employee Name</th>
                                <th scope="col">Branch</th>
                                <th scope="col">Reporting Person</th>
                                <th scope="col">Phone No.</th>
                                <th scope="col">Email Id</th>
                                <th scope="col">Department</th>
                                <th scope="col">Permission</th>
                                <th scope="col">EXTRA_OPTIONS</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredData &&
                                filteredData?.length !== 0 &&
                                filteredData.map((data, index) => (
                                  <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{data?.EmployeeCode}</td>
                                    <td>{`${data?.Name} ${
                                      data?.MiddleName ?? ""
                                    } ${data?.LastName ?? ""}`}</td>
                                    <td>{data?.Branch?.BranchName}</td>
                                    <td>
                                      {`${
                                        data?.ReportingTo?.Salutation ?? ""
                                      } ${data?.ReportingTo?.Name ?? ""} ${
                                        data?.ReportingTo?.MiddleName ?? ""
                                      } ${
                                        data?.ReportingTo?.LastName ?? ""
                                      }`}{" "}
                                    </td>
                                    <td>{data?.MobileNumber}</td>
                                    <td>{data?.Email}</td>
                                    <td>{data?.Department}</td>
                                    <td>{data?.Permission?.Roll ?? ""}</td>

                                    <th scope="row">
                                      {" "}
                                      <div className="col-md-2">
                                        <div className="d-flex gap-3">
                                          <div>
                                            <Link
                                              className=""
                                              state={{
                                                value: data,
                                                view: true,
                                              }}
                                              to="/admin/masters/Employee"
                                            >
                                              <FaEye size={25} color="" />{" "}
                                            </Link>
                                          </div>
                                          <div className="">
                                            <Link
                                              className=""
                                              state={{
                                                value: data,
                                                Mode: true,
                                              }}
                                              to="/admin/masters/Employee"
                                            >
                                              <BiEdit size={25} color="blue" />{" "}
                                            </Link>
                                          </div>
                                          <div className="">
                                            <AiFillDelete
                                              color="red"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              size={25}
                                              onClick={() => {
                                                const isTrue = window.confirm(
                                                  "Do you want to delete!"
                                                );
                                                if (isTrue) {
                                                  deleteEmployee(data?._id);
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {
                        <div
                          style={{
                            backgroundColor: "#1a2e45d7",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            marginTop: "5px",
                          }}
                        >
                          <TablePagination
                            component="div"
                            className="text-white"
                            rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                            count={totalDocs ?? 0}
                            page={pagination}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      }
                    </>
                  )}
                </div>
              </div>

              {/* end col */}
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default Employee;
