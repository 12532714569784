import React, { useEffect, useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
// import { MultiSelect } from "react-multi-select-component";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import { returnInsuranceTypeValue } from "../../Utils/usefullFunction";
import {
  policyPayingTermOpt,
  Business_TypeData,
  NCB_STATUS,
  PA_TYPE,
} from "../../Utils/CommonFields";
// const convertSelectBoxToValueData = (arr) => {
//   const myarrr = arr.map((ele) => ele.value);
//   const myUnique = new Set(myarrr);
//   return [...myUnique];
// };
// const convertInsuranceToId = (arr) => {
//   if (arr.length === 0) return [];

//   return arr.map((ele) => ele.value).filter((ele) => ele !== "");
// };

const EditPayoutGrid = () => {
  const { id } = useParams();

  const { state } = useLocation();
  const { view } = state || {};
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);

  const [insurance, setInsurance] = useState(null);
  const [PayoutGrid, setPayoutGrid] = useState({ DEF: lastDay, DET: lastDay });
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
  const [PolicyType, setPolicyType] = useState(null);
  const [PolicyTypeOpt, setPolicyTypeOpt] = useState([]);
  const [Branch, setBranch] = useState(null);
  const [BranchOpt, setBranchOpt] = useState([]);
  const [BusinessType, setBusinessType] = useState([]);

  const [IMTType, setIMTType] = useState("");
  const [nillDepth, setNillDepth] = useState("");
  const [InsuranceCompany, setInsuranceCompany] = useState({
    _id: "",
    AliasName: "-- Select Insurance Company --",
  });
  const [InsuranceCompanyOpt, setInsuranceCompanyOpt] = useState([]);
  const [CompanyBranch, setCompanyBranch] = useState({
    _id: null,
    BranchName: "-- Select Company Branch --",
  });
  const [CompanyBranchOpt, setCompanyBranchOpt] = useState([]);
  const [MakeModal, setMakeModal] = useState(null);
  const [Modal, setModal] = useState(null);
  const [Varient, setVarient] = useState(null);
  const [MakeModalOpt, setMakeModalOpt] = useState([]);
  const [ModalOpt, setModalOpt] = useState([]);
  const [VarientOpt, setVarientOpt] = useState([]);
  const [PA, setPA] = useState([]);

  const [CustomerType, setCustomerType] = useState("");
  const [BasedOn, setBasedOn] = useState("");
  const [RTOGroup, setRTOGroup] = useState(null);
  const [RTOGroupOpt, setRTOGroupOpt] = useState([]);
  const [AddDetails, setAddDetails] = useState([]);
  const [IsEditCheck, setIsEditCheck] = useState(false);
  const [odDiscount, setOdDiscount] = useState({
    ODDFrom: 0,
    ODDTo: 0,
  });
  const [ccFrom, setCcFrom] = useState("");
  const [ccTo, setCcTo] = useState("");
  const [PAPremiumFrom, setPAPremiumFrom] = useState("");
  const [PAPremiumTo, setPAPremiumTo] = useState("");
  const [gvwFrom, setGvwFrom] = useState("");
  const [gvwTo, setGvwTo] = useState("");
  const [seaterFrom, setSeaterFrom] = useState("");
  const [seaterTo, setSeaterTo] = useState("");
  const [NCBSTATUS, setNCBSTATUS] = useState([]);
  const [FuelTypeOpt, setFuelTypeOpt] = useState([]);
  const [FuelType, setFuelType] = useState([]);
  const [LOB, setLOB] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [riskType, setRiskType] = useState("");
  const [IDVFrom, setIDVFrom] = useState("");
  const [IDVTo, setIDVTo] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);

  const onChangeAddDetails = (e) => {
    setPayoutGrid({ ...PayoutGrid, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchCityOptions = async () => {
      try {
        const response = await axiosInstance.get("/RTO/City", {
          params: { state: selectedState?._id },
        });
        setCityOpt(response.data.data);
      } catch (error) {
        console.error("Error fetching RTO data:", error);
      }
    };

    if (selectedState?._id) {
      fetchCityOptions();
    }
  }, [selectedState]);

  const navigate = useNavigate();

  const handleEdit = async () => {
    try {
      if (IsEditCheck) {
        return ToastWarn("Add to list First");
      }
      if (!BasedOn) {
        return ToastWarn("Basedon is required");
      }

      setTimeout(async () => {
        const insType = SelectedInsuranceType.map((item) => item?.value).filter(
          (item) => item !== ""
        );

        const {
          data: { success, message },
        } = await axiosInstance.put(`/PayoutGrid/${id}`, {
          ...PayoutGrid,
          RTOGroup: RTOGroup ? RTOGroup : null,
          PolicyType: PolicyType ? PolicyType : null,
          Branch: Branch ? Branch : null,
          InsuranceType:
            insType.length > 0 ? insType[insType.length - 1] : null,
          InsuranceCompany: InsuranceCompany?._id ?? "",
          CompanyBranch: CompanyBranch?._id ?? null,
          MakeModal: MakeModal,
          Model: Modal,
          Variant: Varient,
          AddDetails: AddDetails,
          LOB: LOB || "",
          State: selectedState || null,
          City: selectedCity || null,
          RiskType: riskType || "",
          IDVFrom: IDVFrom ?? 0,
          IDVTo: IDVTo ?? 0,
          PA: PA,
          CustomerType: CustomerType,
          BasedOn,
          NCBSTATUS: NCBSTATUS,
          FuelType: FuelType,
          BusinessType: BusinessType,
          nillDepth: nillDepth,
          IMTType: IMTType,
          ...odDiscount,
          CCFrom: ccFrom,
          CCTo: ccTo,
          PAPremiumFrom: PAPremiumFrom,
          PAPremiumTo: PAPremiumTo,
          GVWFrom: gvwFrom,
          GVWTo: gvwTo,
          seaterFrom: seaterFrom,
          seaterTo: seaterTo,
        });

        if (success) {
          ToastSusses(message);
          navigate("/admin/masters/PayoutGrid/data");
        } else {
          ToastError(message);
        }
      }, 0); // Delay payload send to ensure state update
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleMotorTypeChange = (e) => {
    const selectedValue = e.target.value;
    setLOB(selectedValue);
  };

  const getFuelType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/MakeModal/getAllFuelTypewithname");

      const selectedFuelTypeId = FuelType;

      if (data && data.length > 0 && data[0].FuelType) {
        const fuelType = data.map((item) => ({
          value: item._id,
          label: item.FuelType,
        }));

        const selectedFuelType = fuelType.find(
          (item) => item._id === selectedFuelTypeId
        );

        // if (selectedFuelType) {
        //   console.log("Selected Fuel Type:", selectedFuelType.label);
        // }

        setFuelTypeOpt(fuelType);
      } else {
        console.log("FuelType not found or data is empty");
      }
    } catch (error) {
      console.error("Error fetching fuel types:", error);
    }
  };

  useEffect(() => {
    if (
      SelectedInsuranceType &&
      SelectedInsuranceType.length > 0 &&
      SelectedInsuranceType[0].value
    ) {
      const foundData = SelectedInsuranceType[0].data.find(
        (item) => item?._id === SelectedInsuranceType[0]?.value
      );

      if (foundData) {
        setInsurance(foundData);
      }
    }
  }, [SelectedInsuranceType]);

  const getPolicyType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/PolicyType");

      const PolicyType = await data.map((ele) => {
        return {
          label: ele.PolicyTypeName,
          value: ele._id,
        };
      });

      setPolicyTypeOpt(PolicyType);
    } catch (error) {}
  };
  const getBranch = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/Branch");

      const Branch = await data.map((ele) => {
        return {
          label: ele.BranchName,
          value: ele._id,
        };
      });

      setBranchOpt(Branch);
    } catch (error) {}
  };
  const getState = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/State");

      setStateOpt(data);
    } catch (error) {}
  };

  const getInsuranceCompany = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceCompany");

      setInsuranceCompanyOpt(data);
    } catch (error) {}
  };

  const getCompanyBranch = async () => {
    try {
      if (CompanyBranchOpt.length > 0) {
        return false;
      }

      const {
        data: { data },
      } = await axiosInstance.post(
        "/InsuranceCompany/CompanyBranch/getallcompanyBranch",
        {
          id: InsuranceCompany._id,
        }
      );
      setCompanyBranchOpt([
        { _id: null, BranchName: "-- Select Company Branch --" },
        ...data,
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const getMakeModal = async (InsuranceType, reset = false) => {
    if (!InsuranceType) {
      setMakeModalOpt([]);
      return;
    }

    if (!reset) {
      setMakeModal(null);
    }

    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Variant/getMake", { id: InsuranceType });
      const options = data.map((item) => ({
        label: item.Make,
        value: item._id,
      }));

      setMakeModalOpt(options);
    } catch (error) {
      console.error("Error fetching make modal options:", error);
    }
  };

  const getRTOGroupOpt = async (id) => {
    const SelectedInsuranceTypeData = SelectedInsuranceType.map(
      (ele) => ele.value
    ).filter((ele) => ele !== "");

    const SelectedInsuranceTypeIds = SelectedInsuranceType.map(
      (item) => item.value
    ).filter((item) => item !== "");

    // const insuranceType = SelectedInsuranceTypeData.length - 1;

    // let isInsuranceType = InsuranceType
    //   ? InsuranceType
    //   : SelectedInsuranceTypeData[insuranceType];

    let isInsuranceCompany = InsuranceCompany ? InsuranceCompany?._id : "";

    try {
      const {
        data: { data },
      } = await axiosInstance.post(
        `/PayoutGrid/getRtoGroupCheckerfilterpayout`,
        {
          InsuranceType: SelectedInsuranceTypeData ?? "",
          InsuranceCompany: InsuranceCompany?._id ,
          PolicyType: PolicyType,
          DEF: PayoutGrid?.DEF,
          DET: PayoutGrid?.DET,
          GVWFrom: gvwFrom,
          GVWTo: gvwTo,
          seaterFrom: seaterFrom,
          seaterTo: seaterTo,
        }
      );

      const RTOGroup = await data.map((ele) => {
        return {
          label: `${ele.GroupName} ( ${ele._id} )`,
          value: ele._id,
        };
      });

      setRTOGroupOpt(RTOGroup);
    } catch (error) {}
  };

 const getDataInsuranceType = async (InsuranceCategory) => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/InsuranceType?Root=true&InsuranceCategory=${InsuranceCategory}`
      );
      setSelectedInsuranceType([{ data, value: "" }]);
    } catch (error) {}
  };

  const getPayoutGridDataById = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/PayoutGrid/singleGrid/${id}`);
      const {
        InsuranceType,
        InsuranceCompany,
        CompanyBranch,
        // Manufacturer = [],
        MakeModal = "",
        Model = "",
        Variant = "",
        PolicyType = "",
        Branch = "",
        RTOGroup = "",
        // VehicleModal = [],
        BasedOn,
        PA,
        CustomerType,
        AddDetails = "",
        FuelType = "",
        NCBSTATUS = "",
        BusinessType = "",
        nillDepth = "",
        IMTType = "",
        ODDFrom = "",
        ODDTo = "",
        CCFrom = "",
        LOB = "",
        State = "",
        City = "",
        RiskType = "",
        IDVFrom = "",
        IDVTo = "",
        CCTo = "",
        PAPremiumFrom = "",
        PAPremiumTo = "",
        GVWFrom = "",
        GVWTo = "",
        seaterFrom = "",
        seaterTo = "",
      } = data || {};
     
      setLOB(LOB);
      setSelectedState(State);
      setSelectedCity(City);
      setRiskType(RiskType);
      setIDVFrom(IDVFrom);
      setIDVTo(IDVTo);
      setPayoutGrid(data);
      setInsuranceCompany(InsuranceCompany);
      setCompanyBranch(CompanyBranch);
      setBusinessType(BusinessType);
      setNillDepth(nillDepth);
      setIMTType(IMTType);
      setNCBSTATUS(NCBSTATUS);
      // setRTOGroup(RTOGroup?._id);
      setMakeModal(MakeModal?._id);
      setModal(Model?._id);
      setVarient(Variant?._id);
      setPolicyType(PolicyType?._id);
      setBranch(Branch?._id);
      setFuelType(FuelType?._id);
      setAddDetails(AddDetails);
      setBasedOn(BasedOn);
      setPA(PA);
      setCustomerType(CustomerType);
      setOdDiscount({
        ODDFrom,
        ODDTo,
      });
      setCcFrom(CCFrom);
      setCcTo(CCTo);
      setPAPremiumFrom(PAPremiumFrom);
      setPAPremiumTo(PAPremiumTo);
      setGvwFrom(GVWFrom);
      setGvwTo(GVWTo);
      setSeaterFrom(seaterFrom);
      setSeaterTo(seaterTo);
      getMakeModal(InsuranceType.InsuranceUnderFlow[0], true);
      if (InsuranceType) {
        callInsuranceData([
          ...InsuranceType.InsuranceUnderFlow,
          InsuranceType._id,
        ]);
      }

    } catch (error) {}
  };

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get(`/InsuranceType?Root=true`);
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );

          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    }
  };

  useEffect(() => {
    getPayoutGridDataById();
    getFuelType();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (insurance == null) {
        getDataInsuranceType(LOB);
      }
    }, 3000); 
  
    return () => clearTimeout(timer);
  }, [insurance]);
  
  useEffect(() => {
    getPolicyType();
    getBranch();
    getState();
    getInsuranceCompany();
  }, []);

  useEffect(() => {
    if (InsuranceCompany._id) {
      getRTOGroupOpt(InsuranceCompany._id);
    }
  }, [
    InsuranceCompany,
    SelectedInsuranceType,
    PolicyType,
    seaterFrom,
    seaterTo,
    gvwFrom,
    gvwTo,
    PayoutGrid?.DEF,
    PayoutGrid?.DET,
  ]);

  const addHandler = (e) => {
    e.preventDefault();
    setAddDetails([...AddDetails, PayoutGrid]);
    setPayoutGrid({ DEF: lastDay, DET: lastDay });
    if (IsEditCheck) {
      setIsEditCheck(false);
      return ToastSusses("Edit Successfully");
    }
    return ToastSusses("Add Successfully");
  };

  const editHandler = (index, IsEdit) => {
    if (IsEditCheck) {
      return ToastWarn("Please First Add Selected Data");
    }
    const AddDetailsData = AddDetails;
    const restData = AddDetailsData.splice(index, 1);
    setAddDetails([...AddDetailsData]);

    if (IsEdit) {
      setIsEditCheck(true);
      setPayoutGrid(...restData);
      return ToastInfo("Selected");
    }
    return ToastSusses("Delete Successfully");
  };

  // do not delete
  const MakeModalPromiseOptions = async (options, inputValue = "") => {
    try {
      if (!inputValue) {
        return options;
      }
      const id = returnInsuranceTypeValue(SelectedInsuranceType);

      if (!id) {
        return;
      }
      const {
        data: { data },
      } = await axiosInstance.post("/MakeModal/getDropDownData", {
        inputData: inputValue,
        InsuranceType: id,
      });
      return data;
    } catch (error) {}
  };

  useEffect(() => {
    const getModal = async (insuranceType, makeIds) => {
      try {
        const {
          data: { data },
        } = await axiosInstance.post("/Variant/getModelForMultiSelectBox", {
          insuranceType,
          makeIds,
        });
        const formattedData = data.map((item) => ({
          label: item.Model,
          value: item._id,
        }));
        setModalOpt(formattedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (
      SelectedInsuranceType &&
      SelectedInsuranceType.length > 0 &&
      SelectedInsuranceType[0].value &&
      MakeModal
    ) {
      const makeIds = [MakeModal];
      getModal(SelectedInsuranceType[0].value, makeIds);
    }
  }, [MakeModal, SelectedInsuranceType]);

  useEffect(() => {
    const getVariant = async (insuranceType, makeIds, modelIds) => {
      try {
        const {
          data: { data },
        } = await axiosInstance.post("/Variant/getVariantForMultiSelectBox", {
          insuranceType,
          makeIds,
          modelIds,
        });
        const formattedData = data.map((item) => ({
          label: item.VariantName,
          value: item._id,
        }));

        setVarientOpt(formattedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (
      SelectedInsuranceType &&
      SelectedInsuranceType.length > 0 &&
      SelectedInsuranceType[0].value &&
      MakeModal &&
      Modal
    ) {
      const makeIds = MakeModal;
      const modelIds = Modal;

      getVariant(SelectedInsuranceType[0].value, makeIds, modelIds);
    }
  }, [Modal, SelectedInsuranceType]);

  useEffect(() => {
    if (RTOGroupOpt && RTOGroupOpt.length > 0 && PayoutGrid?.RTOGroup) {
      setRTOGroup(PayoutGrid?.RTOGroup?._id);
    }
  }, [RTOGroupOpt, PayoutGrid?.RTOGroup]);
  
  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row ">
            <section
              className="push CreateCard p-3"
              style={{
                position: "relative",
                transition: " margin-left .5s",
                zoom: "90%",
              }}
            >
              <div className="">
                <div className="row mx-2 ">
                  <h5 className="my-3">
                    {view ? "View Payout Grid" : "Edit Payout Grid"}
                  </h5>

                  <hr className="my-1" />

                  <div className="col-md-4 align-items-center">
                    <label>DEF Date</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Date"
                      name="DEF"
                      value={PayoutGrid?.DEF}
                      onChange={(e) => onChangeAddDetails(e)}
                      placeholder="Enter From Age "
                      required
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label>DET Date</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Date"
                      name="DET"
                      value={PayoutGrid.DET}
                      onChange={(e) => onChangeAddDetails(e)}
                      placeholder="Enter From Age "
                      required //done
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="policyTypeSelect">Policy Type</label>
                    <select
                      id="policyTypeSelect"
                      className="form-select"
                      value={PolicyType}
                      onChange={(e) => {
                        setPolicyType(e.target.value);
                      }}
                      disabled={view}
                    >
                      <option value="">Select a Policy Type</option>
                      {PolicyTypeOpt.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="policyTypeSelect">Branch</label>
                    <select
                      id="policyTypeSelect"
                      className="form-select"
                      value={Branch}
                      onChange={(e) => {
                        setBranch(e.target.value);
                      }}
                      disabled={view}
                    >
                      <option value="">Select Branch</option>
                      {BranchOpt.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  {
                    <div className="col-md-4 align-items-center">
                      <label>
                        Insurance Company{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={[
                          {
                            _id: "",
                            AliasName: "-- Select Insurance Company --",
                          },
                          ...InsuranceCompanyOpt,
                        ]}
                        getOptionValue={(option) => option["_id"]}
                        getOptionLabel={(option) => option["AliasName"]}
                        onChange={(ele) => {
                          setInsuranceCompany(ele);
                          setRTOGroup(null);
                        }}
                        value={InsuranceCompany}
                      />
                    </div>
                  }

                  <div
                    className="col-md-4 align-items-center"
                    onClick={() => {
                      getCompanyBranch();
                    }}
                  >
                    <label>Insurer Branch</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={[...CompanyBranchOpt]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["BranchName"]}
                      onChange={(ele) => {
                        setCompanyBranch(ele);
                      }}
                      value={CompanyBranch}
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>LOB</label>
                    <select
                      className="form-select"
                      value={LOB}
                      onChange={handleMotorTypeChange}
                      disabled={isDisabled || view}
                      style={{
                        backgroundColor: isDisabled || view ? "#90cbf9" : "",
                      }}
                    >
                      <option value="Motor">Motor</option>
                      <option value="Non-Motor">Non-Motor</option>
                    </select>
                  </div>

                  {SelectedInsuranceType &&
                    SelectedInsuranceType?.length > 0 &&
                    SelectedInsuranceType.map(
                      (data, index) =>
                        data &&
                        data?.data?.length > 0 && (
                          <MultiInsurance
                            key={index}
                            Options={data}
                            setSelectedInsuranceType={setSelectedInsuranceType}
                            view={view}
                            SelectedInsuranceType={SelectedInsuranceType}
                            index={index}
                            OptionsValue={data.value}
                            InsuranceCompany={InsuranceCompany?._id}
                            getMakeModal={getMakeModal}
                            getRTOGroupOpt={getRTOGroupOpt}
                            setRTOGroup={setRTOGroup}
                          />
                        )
                    )}

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="gvwFromInput">GVW From</label>
                    <input
                      disabled={LOB === "Non-Motor" || view}
                      type="number"
                      id="gvwFromInput"
                      className="form-control"
                      value={gvwFrom} // Assuming you have a state for GVW From
                      onChange={(e) => setGvwFrom(e.target.value)} // Assuming setGvwFrom is your state updater function
                      placeholder="Enter GVW From"
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label htmlFor="gvwToInput">GVW To</label>
                    <input
                      type="number"
                      id="gvwToInput"
                      className="form-control"
                      value={gvwTo} // Assuming you have a state for GVW To
                      onChange={(e) => setGvwTo(e.target.value)} // Assuming setGvwTo is your state updater function
                      placeholder="Enter GVW To"
                      disabled={LOB === "Non-Motor" || view} // If view is a prop to disable editing
                    />
                  </div>

                  {/* Seater Fields */}
                  <div className="col-md-4 align-items-center">
                    <label htmlFor="seaterFromInput">Seater From</label>
                    <input
                      type="number"
                      id="seaterFromInput"
                      className="form-control"
                      value={seaterFrom} // Assuming you have a state for Seater From
                      onChange={(e) => setSeaterFrom(e.target.value)} // Assuming setSeaterFrom is your state updater function
                      placeholder="Enter Seater From"
                      disabled={LOB === "Non-Motor" || view} // If view is a prop to disable editing
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label htmlFor="seaterToInput">Seater To</label>
                    <input
                      type="number"
                      id="seaterToInput"
                      className="form-control"
                      value={seaterTo} // Assuming you have a state for Seater To
                      onChange={(e) => setSeaterTo(e.target.value)} // Assuming setSeaterTo is your state updater function
                      placeholder="Enter Seater To"
                      disabled={LOB === "Non-Motor" || view} // If view is a prop to disable editing
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="rtoGroupSelect">RTO Cluster</label>
                    <select
                      id="rtoGroupSelect"
                      className="form-select"
                      value={RTOGroup}
                      onChange={(e) => {
                        setRTOGroup(e.target.value);
                      }}
                      disabled={LOB === "Non-Motor" || view}
                    >
                      <option value="">Please select RTO Group</option>
                      {RTOGroupOpt.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="makeSelect">Make</label>
                    <select
                      id="makeSelect"
                      className="form-select"
                      value={MakeModal}
                      onChange={(e) => {
                        setMakeModal(e.target.value);
                        setModal(null);
                        setVarient(null);
                      }}
                      disabled={LOB === "Non-Motor" || view}
                    >
                      <option value="">Select Make</option>
                      {MakeModalOpt.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label htmlFor="modelSelect">Model</label>
                    <select
                      id="modelSelect"
                      className="form-select"
                      value={Modal}
                      onChange={(e) => {
                        setModal(e.target.value);
                        setVarient(null);
                      }}
                      disabled={LOB === "Non-Motor" || view}
                    >
                      <option value="">Select Model</option>
                      {ModalOpt &&
                        ModalOpt.length > 0 &&
                        ModalOpt.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="variantSelect">Variant</label>
                    <select
                      id="variantSelect"
                      className="form-select"
                      value={Varient}
                      onChange={(e) => {
                        setVarient(e.target.value);
                      }}
                      disabled={LOB === "Non-Motor" || view}
                    >
                      <option value="">Select Variant</option>
                      {VarientOpt.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="odDiscountInput">ODD % (From)</label>
                    <input
                      type="number"
                      id="odDiscountInput"
                      className="form-control"
                      value={odDiscount?.ODDFrom ?? ""}
                      onChange={(e) =>
                        setOdDiscount((prev) => ({
                          ...prev,
                          ODDFrom: e.target.value,
                        }))
                      }
                      placeholder="Enter ODD %"
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="odDiscountInput">ODD % (To)</label>
                    <input
                      type="number"
                      id="odDiscountInput"
                      className="form-control"
                      value={odDiscount?.ODDTo ?? ""}
                      onChange={(e) =>
                        setOdDiscount((prev) => ({
                          ...prev,
                          ODDTo: e.target.value,
                        }))
                      }
                      placeholder="Enter ODD %"
                      disabled={view}
                    />
                  </div>

                  {/* cc gvw seater */}
                  <div className="col-md-4 align-items-center">
                    <label htmlFor="ccFromInput">CC From</label>
                    <input
                      type="number"
                      id="ccFromInput"
                      className="form-control"
                      value={ccFrom}
                      onChange={(e) => setCcFrom(e.target.value)}
                      placeholder="Enter CC From"
                      disabled={LOB === "Non-Motor" || view}
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label htmlFor="ccToInput">CC To</label>
                    <input
                      type="number"
                      id="ccToInput"
                      className="form-control"
                      value={ccTo} // Assuming you have a state for CC To
                      onChange={(e) => setCcTo(e.target.value)} // Assuming setCcTo is your state updater function
                      placeholder="Enter CC To"
                      disabled={LOB === "Non-Motor" || view} // If view is a prop to disable editing
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label htmlFor="PAPremiumFromInput">PAPremium From</label>
                    <input
                      type="number"
                      id="PAPremiumFromInput"
                      className="form-control"
                      value={PAPremiumFrom}
                      onChange={(e) => setPAPremiumFrom(e.target.value)}
                      placeholder="Enter PAPremium From"
                      disabled={LOB === "Non-Motor" || view}
                    />
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label htmlFor="PAPremiumToInput">PAPremium To</label>
                    <input
                      type="number"
                      id="PAPremiumToInput"
                      className="form-control"
                      value={PAPremiumTo} // Assuming you have a state for CC To
                      onChange={(e) => setPAPremiumTo(e.target.value)} // Assuming setCcTo is your state updater function
                      placeholder="Enter PAPremium To"
                      disabled={LOB === "Non-Motor" || view} // If view is a prop to disable editing
                    />
                  </div>

                  {/* GVW Fields */}

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="paTypeSelect">PA Type</label>
                    <select
                      id="paTypeSelect"
                      className="form-select"
                      value={PA} // Assuming PA holds the selected value
                      onChange={(e) => {
                        setPA(e.target.value);
                      }}
                      disabled={LOB === "Non-Motor" || view} // Disable based on your view state
                    >
                      <option value="">Select PA Type</option>
                      {PA_TYPE.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="paTypeSelect">Customer Type</label>
                    <select
                      id="CustomerTypeSelect"
                      className="form-select"
                      value={CustomerType}
                      onChange={(e) => {
                        setCustomerType(e.target.value);
                      }}
                      disabled={LOB === "Non-Motor" || view}
                    >
                      <option value="">Select</option>
                      <option value="Individual">Individual</option>
                      <option value="Corporate">Corporate</option>
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="ncbStatusSelect">NCB STATUS</label>
                    <select
                      id="ncbStatusSelect"
                      className="form-select"
                      value={NCBSTATUS} // Assuming NCBSTATUS holds the selected value
                      onChange={(e) => {
                        setNCBSTATUS(e.target.value);
                      }}
                      disabled={LOB === "Non-Motor" || view} // Disable based on your view state
                    >
                      <option value="">Select NCB STATUS</option>
                      {NCB_STATUS.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="fuelTypeSelect">Fuel Type</label>
                    <select
                      id="fuelTypeSelect"
                      className="form-select"
                      value={FuelType} // Assuming FuelType holds the selected value
                      onChange={(e) => {
                        setFuelType(e.target.value);
                      }}
                      disabled={LOB === "Non-Motor" || view} // Disable based on your view state
                    >
                      <option value="">Select Fuel Type</option>
                      {FuelTypeOpt.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label htmlFor="businessTypeSelect">Business Type</label>
                    <select
                      id="businessTypeSelect"
                      className="form-select"
                      value={BusinessType} // Assuming BusinessType holds the selected value
                      onChange={(e) => {
                        setBusinessType(e.target.value); // Set the selected value
                      }}
                      disabled={view} // Disable based on your view state
                    >
                      <option value="">Select Business Type</option>
                      {Business_TypeData.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label required>Nil Dep</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="nillDepth"
                      value={nillDepth}
                      onChange={(e) => setNillDepth(e.target.value)}
                      placeholder="Nil Dep"
                      disabled={LOB === "Non-Motor" || view}
                    >
                      <option value="">---- Select Nil Dep ---</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {insurance &&
                    insurance.IMTType &&
                    insurance.IMTType.toLocaleLowerCase().includes("yes") && (
                      <div className="col-md-4 align-items-center">
                        <label required>IMT Type</label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          name="IMTType"
                          value={IMTType}
                          onChange={(e) => setIMTType(e.target.value)}
                          placeholder="IMT Type"
                          disabled={LOB === "Non-Motor" || view}
                        >
                          <option value="">-- Select IMT Type --</option>
                          <option value="IMT 23">IMT 23</option>
                          <option value="IMT 34">IMT 34</option>
                          <option value="Not Available">Not Available</option>
                        </select>
                      </div>
                    )}

                  <div className="col-md-2 align-items-center">
                    <label>IDV/Sum Insured From</label>
                    <input
                      disabled={LOB === "Motor" || view}
                      className="form-control"
                      type="number"
                      name="IDVFrom"
                      value={IDVFrom}
                      onChange={(e) => setIDVFrom(e.target.value)}
                      placeholder="Enter IDV From"
                      required
                    />
                  </div>
                  <div className="col-md-2 align-items-center">
                    <label>IDV/Sum Insured To</label>
                    <input
                      disabled={LOB === "Motor" || view}
                      className="form-control"
                      type="number"
                      name="IDVTo"
                      value={IDVTo}
                      onChange={(e) => setIDVTo(e.target.value)}
                      placeholder="Enter IDV To"
                      required
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>State</label>
                    <Select
                      isDisabled={LOB === "Motor" || view}
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          AliasName: "-- Select State --",
                        },
                        ...stateOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["StateName"]}
                      onChange={(ele) => {
                        setSelectedState(ele);
                        setSelectedCity(null);
                      }}
                      value={selectedState}
                    />
                  </div>

                  {/* City Dropdown */}
                  <div className="col-md-4 align-items-center">
                    <label>City</label>
                    <Select
                      isDisabled={LOB === "Motor" || view}
                      className="basic-single"
                      classNamePrefix="select"
                      options={[
                        {
                          _id: "",
                          RTOName: "-- Select City --",
                        },
                        ...cityOpt,
                      ]}
                      getOptionValue={(option) => option["_id"]}
                      getOptionLabel={(option) => option["RTOName"]}
                      onChange={(ele) => setSelectedCity(ele)}
                      value={selectedCity}
                    />
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>Risk Type</label>
                    <select
                      disabled={LOB === "Motor" || view}
                      className="form-select"
                      value={riskType}
                      onChange={(e) => setRiskType(e.target.value)}
                    >
                      <option value="">----- Select Risk Type ----</option>
                      <option value="Preferred">Preferred</option>
                      <option value="Non-Preferred">Non-Preferred</option>
                    </select>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <label>
                      BasedOn <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setBasedOn(e.target.value);
                      }}
                      value={BasedOn}
                      disabled={view || AddDetails.length > 0}
                    >
                      <option value="">----Select----</option>
                      <option value="FLAT">FLAT</option>
                      <option value="OD">OD</option>
                      <option value="NET">NET</option>
                      <option value="TP">TP</option>
                      <option value="OD+TP">OD + TP </option>
                    </select>
                  </div>
                  <div className="col-md-4 align-items-center">
                    <label htmlFor="policyPayingTerm">
                      PPT (Policy Paying Term)
                    </label>
                    <select
                      disabled={view}
                      id="policyPayingTerm"
                      class="form-select"
                      aria-label="Default select example"
                      name="policyPayingTerm"
                      value={PayoutGrid?.policyPayingTerm}
                      onChange={(e) => {
                        onChangeAddDetails(e);
                      }}
                    >
                      <option value="">-- Policy Paying Term --</option>
                      {policyPayingTermOpt.map((item) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mx-2 ">
                  <div className="col-md-3 align-items-center">
                    <label>From Age</label>
                    <input
                      disabled={view}
                      className="form-control "
                      type="Number"
                      name="FromAge"
                      value={PayoutGrid?.FromAge ?? ""}
                      onChange={(e) => onChangeAddDetails(e)}
                      placeholder="Enter From Age"
                      required //done
                      min={0}
                    />
                  </div>
                  <div className="col-md-3 align-items-center">
                    <label>To Age</label>
                    <input
                      disabled={view}
                      className="form-control"
                      type="Number"
                      name="ToAge"
                      value={PayoutGrid?.ToAge ?? ""}
                      onChange={(e) => onChangeAddDetails(e)}
                      placeholder="Enter To Age"
                      min={0}
                      required
                    />
                  </div>
                  <div className="col-md-3 align-items-center">
                    <label>Reciveable</label>
                    {
                      <div className="d-flex gap-3">
                        {BasedOn.split("+").map((ele, i) => (
                          <input
                            disabled={view}
                            className="form-control"
                            style={{
                              width: "125px",
                            }}
                            type="Number"
                            key={ele}
                            name={i === 0 ? `Reciveable` : `ReciveableTP`}
                            value={
                              i === 0
                                ? PayoutGrid[`Reciveable`] ?? ""
                                : PayoutGrid[`ReciveableTP`] ?? ""
                            }
                            onChange={(e) => onChangeAddDetails(e)}
                            placeholder={`${ele} `}
                            required //done
                            min={0}
                            step=".01"
                          />
                        ))}
                      </div>
                    }
                  </div>

                  <div className="col-md-2 mx-5 align-items-center">
                    <label>Inflow (Spl. Apvl)</label>
                    <div className="d-flex gap-3">
                      {BasedOn.split("+").map((ele, i) => (
                        <input
                          disabled={view}
                          className="form-control"
                          style={{
                            width: "125px",
                          }}
                          type="Number"
                          key={ele}
                          name={i === 0 ? `InflowSpecial` : `InflowSpecialTP`}
                          value={
                            i === 0
                              ? PayoutGrid[`InflowSpecial`] ?? ""
                              : PayoutGrid[`InflowSpecialTP`] ?? ""
                          }
                          onChange={(e) => onChangeAddDetails(e)}
                          placeholder={`${ele} `}
                          required
                          min={0}
                          step=".01"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/masters/PayoutGrid/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  handleEdit();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default EditPayoutGrid;
