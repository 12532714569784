import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { REACT_APP_FileURL, axiosInstance } from "../../config";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { AiFillFilePdf } from "react-icons/ai";
// import fs from "fs";
import {
  convertDateStringToDate,
  convertTimeToIndianTimeZone,
  coverAmountIntoCurrent,
} from "../../Utils/usefullFunction";
import axios from "axios";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import SaleReturnExcel from "./SaleReturnExcel";
import DateRange from "../DateRangeModule/DateRange";
import NewReturnSaleExcel from "./NewReturnSaleExcel";


const SaleReturnn = () => {
  const today = new Date();
  // 2023-05-05
  const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  const lastDay = today.toISOString().substr(0, 10);
  const [StartDate, setStartDate] = useState(firstDay);
  const [EndDate, setEndDate] = useState(lastDay);
  const [Data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [inputData, setInput] = useState("");
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };
  const getData = async (lastDay, firstDay) => {
    setLoading(true);
    try {
      const {
        data: { data, totalDocs },
      } = await axiosInstance.get(
        `/SaleReturn?page=${pagination + 1}&limit=${rowsPerPage}&StartDate=${
          firstDay ?? StartDate
        }&EndDate=${lastDay ?? EndDate}`
      );

      setTotalDocs(totalDocs);

      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteSale = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/SaleReturn/${_id}`);

      if (success) {
        getData();
        ToastSusses(message);
      } else {
        ToastError(message);
        // ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const setDateHandler = (ranges) => {
    setEndDate(convertDateStringToDate(ranges.endDate));
    setStartDate(convertDateStringToDate(ranges.startDate));
  };
  useEffect(() => {
    getData();
  }, [pagination, rowsPerPage]);

  return (
    <>
      <Header
        setInput={setInput}
        inputData={inputData}
        placeHolder="Search by, Control Number"
      />
      <div
        className="container-fluid"
        style={{ marginTop: "80px", zoom: "80%" }}
      >
        <div className="row CreateCard">
          <div className="col-lg-12">
            <div className="">
              <div className="card-body">
                <div className="row">
                  <div className="col-10" style={{ float: "left" }}>
                    <div className="ListDesigncol">
                      <h4 className="card-title">Sale Return</h4>
                      <p className="card-title-desc">
                        Sale Return is a report of cash and bank transactions during a
                        period.
                      </p>
                    </div>
                  </div>
                  <div className="col-2" style={{ float: "left" }}>
                    <Link
                      className="btn mybtn"
                      to="/admin/vouchers/Create/Sale/Return"
                      state={{
                        Mode: false,
                        value: "",
                      }}
                    >
                      Add SaleReturn
                    </Link>
                  </div>
                </div>
                <div
                  className=" p-2 filters"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "unset",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "baseline",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <input
                        className="form-control "
                        type="date"
                        name="StartDate"
                        value={StartDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required //done
                      />

                      <p>-</p>
                      <input
                        className="form-control "
                        type="date"
                        name="EndDate"
                        value={EndDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required //done
                      />
                      <DateRange setDateHandler={setDateHandler} />
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="searchBox"
                      style={{
                        width: "77px",
                        cursor: "pointer",
                      }}
                      onClick={() => getData()}
                    >
                      <MdSend />
                    </div>
                    <div
                      className="searchBox"
                      style={{
                        width: "77px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEndDate(lastDay);
                        setStartDate(firstDay);
                        getData(lastDay, firstDay);
                      }}
                    >
                      <BsEraser />
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div
                    className=" p-2 container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                ) : (
                  <>
                    {
                      <table class="table table-striped table-bordered overflow-x mt-3">
                        <thead>
                          <tr>
                            <th scope="col" style={{ textAlign: "center" }}>
                              #
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Control Number
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              EntryDate
                            </th>
                           
                            <th scope="col" style={{ textAlign: "center" }}>
                              Credit Account
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Debit Account
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Amount
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Net Amount
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Remark
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Created Date & Time
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Action
                            </th>
                            <th scope="col" style={{ textAlign: "center" }}>
                              Billing
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Data &&
                            Data?.length !== 0 &&
                            Data?.map((ele, index) => {
                              const POS_NAME = `${
                                ele?.CreditAccount?.Salutation ?? ""
                              } ${ele?.CreditAccount?.Name ?? ""} ${
                                ele?.CreditAccount?.MiddleName ?? ""
                              } ${ele?.CreditAccount?.LastName ?? ""}`;
                              return (
                                <tr>
                                  <td scope="row">{index + 1}</td>
                                  <td>{ele?.ControlNumber}</td>

                                  <td>
                                    {ele?.EnterDate.split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("-")}
                                  </td>
                                  <td>{POS_NAME}</td>
                                  <td>{ele?.DebitAccount?.Name}</td>

                                  <td style={{ textAlign: "right" }}>
                                    {coverAmountIntoCurrent(ele?.TotalAmount)}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {coverAmountIntoCurrent(ele?.NetAmount)}
                                  </td>
                                  <td>{ele?.Remark}</td>
                                  <td>
                                    {convertTimeToIndianTimeZone(
                                      ele?.createdAt
                                    )}
                                  </td>
                                  <th scope="row">
                                    {" "}
                                    <div className="col-md-2">
                                      <div className="d-flex gap-3">
                                        <div>
                                          <Link
                                            to={`/admin/viewSale/${ele._id}`}
                                          >
                                            <FaEye
                                              size={35}
                                              style={{
                                                backgroundColor: "#E8960F",
                                                color: "#fff",
                                                padding: "7px",
                                                borderRadius: "5px",
                                              }}
                                            />{" "}
                                          </Link>
                                        </div>
                                        <div className="col-md-4">
                                          <Link
                                            className="col-md-1"
                                            // state={{
                                            //   value: ele,
                                            //   Mode: true,
                                            // }}
                                            to={`/admin/vouchers/Sale/Return/${ele._id}`}
                                          >
                                            <BiEdit
                                              size={35}
                                              style={{
                                                backgroundColor: "green",
                                                color: "#fff",
                                                padding: "7px",
                                                borderRadius: "5px",
                                              }}
                                            />{" "}
                                          </Link>
                                        </div>
                                        &nbsp; &nbsp;
                                        <div className="col-md-4">
                                          {/* <AiFillDelete
                                            size={35}
                                            style={{
                                              backgroundColor: "#A50406",
                                              color: "#fff",
                                              padding: "7px",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              const isTrue = window.confirm(
                                                "Do you want to delete!"
                                              );
                                              if (isTrue) {
                                                deleteSale(ele?._id);
                                              }
                                            }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    <DownloadPdf
                                      id={ele._id}
                                      Name={`${POS_NAME}-${ele?.ControlNumber}.pdf`}
                                    />
                                    <NewReturnSaleExcel
                                      _id={ele._id}
                                      Name={`${POS_NAME}-${ele?.ControlNumber}.pdf`}
                                    />
                                  </td>
                                  <td></td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    }
                    {
                      <div
                        style={{
                          backgroundColor: "#1a2e45d7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          marginTop: "5px",
                        }}
                      >
                        <TablePagination
                          component="div"
                          className="text-white"
                          rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                          count={totalDocs ?? 0}
                          page={pagination}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    }
                  </>
                )}
              </div>
            </div>

            {/* end col */}
          </div>
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

const DownloadPdf = ({ id, Name }) => {
  const [loading, setLoading] = useState(false);

  const getSingleSale = async () => {
    setLoading(true);
    try {
      axios({
        url: `${REACT_APP_FileURL}/api/v1/SaleReturn/getGenerateReport/${id}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = Name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <img
          src={require("../utils/UiImages/Download.gif")}
          alt="My GIF"
          style={{
            width: "35px",
            height: "34px",
          }}
        />
      ) : (
        <AiFillFilePdf
          onClick={() => {
            getSingleSale();
          }}
          size={35}
          style={{
            backgroundColor: "green",
            color: "#fff",
            padding: "7px",
            borderRadius: "5px",
          }}
        />
      )}
    </>
  );
};

export default SaleReturnn;
