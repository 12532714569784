import React, { useState, useEffect } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { axiosInstance } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";

const CreateBranch = () => {
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [BranchName, setBranchName] = useState(value?.BranchName || "");
  const [IfscCode, setIfscCode] = useState(value?.IfscCode || "");
  const [BranchCode, setBranchCode] = useState(value?.BranchCode || "");
  const navigate = useNavigate();


  const fetchIfscDetails = async (ifscCode) => {
    try {
       if (value?._id) {
        return
      }
      const response = await axios.get(`https://ifsc.razorpay.com/${ifscCode}`);

      if (response.data) {

        setBranchName(response.data.BRANCH);
        setBranchCode(response.data.BANKCODE);
      }
    } catch (error) {
      console.error("Error fetching IFSC details:", error);
      ToastError("Invalid IFSC code or unable to fetch details.");
    }
  };


  useEffect(() => {
    if (IfscCode.length === 11) {
      fetchIfscDetails(IfscCode);
    }
  }, [IfscCode]);

  const handleCreate = async () => {
    if (!BranchName) {
      ToastError("Branch Name is required");
      return;
    }

    if (!IfscCode) {
      ToastError("IfscCode is required");
      return;
    }

    try {
      const { data: { success, message } } = await axiosInstance.post("/BankBranch", {
        BranchName,
        BranchCode,
        IfscCode,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/BankBranch/data");
      } else {
        ToastError(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    if (!BranchName) {
      ToastError("Branch Name is required");
      return;
    }

    if (!IfscCode) {
      ToastError("IfscCode is required");
      return;
    }

    try {
      const { data: { success, message } } = await axiosInstance.put(`/BankBranch/${value?._id}`, {
        BranchName,
        BranchCode,
        IfscCode,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/BankBranch/data");
      } else {
        ToastError(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="MainDiv">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="col-lg-12">
              <h4 className="card-title">
                {view ? "View Branch" : Mode ? "Edit Branch" : "Create New Branch"}
              </h4>
              <hr />
              <div className="outer">
                <div className="col-4" style={{ float: "left", padding: 15 }}>
                  <label className="form-label">IFSC Code</label>
                  <input
                    className="form-control"
                    type="text"
                    value={IfscCode}
                    onChange={(e) => setIfscCode(e.target.value)}
                    placeholder="IFSC Code"
                    required
                  />
                </div>
                <div className="col-4" style={{ float: "left", padding: 15 }}>
                  <label className="form-label">Branch Name</label>
                  <input
                    className="form-control"
                    type="text"
                    value={BranchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    placeholder="Enter Branch Name"
                    required
                    disabled={view}
                  />
                </div>
                <div className="col-4" style={{ float: "left", padding: 15 }}>
                  <label className="form-label">Branch Code</label>
                  <input
                    className="form-control"
                    type="text"
                    value={BranchCode}
                    onChange={(e) => setBranchCode(e.target.value)}
                    placeholder="Branch Code"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3">
            <button
              className="btn mybtn"
              onClick={() => navigate("/admin/masters/BankBranch/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                className="btn mybtn"
                onClick={() => (Mode ? handleEdit() : handleCreate())}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateBranch;
