import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import useHttpErrorHandler from "../Hooks/useHttpErrorHandler";

const initialState = {
  Model: "",
  Make: "",
  InsuranceType: "",
  VariantName: "",
  FuelType: "",
  Engine: "",
  Seater: "",
  GVW: "",
  Status: true,
};

const AddVariant = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const { view } = state || {};
  const navigate = useNavigate();

  const httpErrorHandler = useHttpErrorHandler();
  const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);

  const [fetchedData, setFetchedData] = useState({
    fuelTypes: [],
    Make: [],
    Model: [],
  });
  const [formData, setFormData] = useState(initialState);

  const handleCreate = async () => {
    try {
      const insuranceType = SelectedInsuranceType.filter(
        (ele) => ele.value !== "" && ele.value !== " "
      ).map((ele) => ele.value);
      if (!insuranceType[0]) {
        return ToastError("Insurance type Is Required");
      }

      const {
        data: { success, message },
      } = await axiosInstance.post("/Variant", {
        ...formData,
        InsuranceType: insuranceType[0],
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Variant/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      httpErrorHandler(error);
    }
  };
  const handleEdit = async () => {
    try {
      const insuranceType = SelectedInsuranceType.filter(
        (ele) => ele.value !== "" && ele.value !== " "
      ).map((ele) => ele.value);
      if (!insuranceType[0]) {
        return ToastError("Insurance type Is Required");
      }

      const {
        data: { success, message },
      } = await axiosInstance.put(`/Variant/${id}`, {
        ...formData,
        InsuranceType: insuranceType[0],
      });

      if (success) {
        ToastSusses(message);
        navigate("/admin/masters/Variant/data");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      httpErrorHandler(error);
    }
  };

  const getDataInsuranceType = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/InsuranceType?Root=true");
      setSelectedInsuranceType([{ data, value: "" }]);
    } catch (error) {
      httpErrorHandler(error);
    }
  };

  const callInsuranceData = async (InsuranceType) => {
    const length = InsuranceType?.length;
    if (length) {
      const myInsuranceType = [];

      try {
        const {
          data: { data },
        } = await axiosInstance.get("/InsuranceType?Root=true");
        myInsuranceType.push({ data, value: InsuranceType[0] });
      } catch (error) {}
      for (let index = 1; index < length; index++) {
        try {
          const {
            data: { data = [] },
          } = await axiosInstance.get(
            `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
          );
          await myInsuranceType.push({
            data,
            value: InsuranceType[index],
          });
        } catch (error) {}
      }

      return setSelectedInsuranceType([
        ...SelectedInsuranceType,
        ...myInsuranceType,
      ]);
    } else {
      getDataInsuranceType();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [FuelTypeResp, MakeTypeResp, ModelResp] = await Promise.all([
          axiosInstance.get("/FuelType"),
          axiosInstance.get("/Make"),
          axiosInstance.get("/Model/getModel"),
        ]);

        if (FuelTypeResp?.data?.success && MakeTypeResp?.data?.success) {
          setFetchedData({
            fuelTypes: FuelTypeResp.data?.data,
            Make: MakeTypeResp?.data?.data,
            Model: ModelResp?.data?.data,
          });
        }
      } catch (error) {
        httpErrorHandler(error);
      }
    };

    fetchData();
  }, [httpErrorHandler]);

  const getDataById = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/Variant/${id}`);

      if (data) {
        callInsuranceData(
          [
            ...data?.InsuranceType?.InsuranceUnderFlow,
            data?.InsuranceType?._id,
          ] ?? []
        );
        setFormData(data);
      }
    } catch (error) {
      console.log("err", error);
      httpErrorHandler(error);
    }
  };
  useEffect(() => {
    if (id) {
      getDataById();
    } else {
      callInsuranceData([]);
    }
  }, []);

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Header />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard p-3">
            <div className="">
              <h4 className="card-title">
                {view ? "View variant" : id ? "Edit variant" : "Create variant"}
              </h4>
              <hr />
              <br />

              <div className="row">
                {SelectedInsuranceType &&
                  SelectedInsuranceType?.length > 0 &&
                  SelectedInsuranceType.map(
                    (data, index) =>
                      data &&
                      data?.data?.length > 0 && (
                        <MultiInsurance
                          key={index}
                          Options={data}
                          setSelectedInsuranceType={setSelectedInsuranceType}
                          // getMakeModal={getMakeModal}
                          view={view}
                          SelectedInsuranceType={SelectedInsuranceType}
                          index={index}
                          OptionsValue={data.value}
                        />
                      )
                  )}
                <div className=" col-md-4 align-items-center">
                  <label>Make</label>
                  <select
                    disabled={view}
                    class="form-select"
                    aria-label="Default select example"
                    name="Make"
                    value={formData?.Make}
                    onChange={handleOnchange}
                    required
                  >
                    <option value={""}>Select Make</option>
                    {fetchedData?.Make &&
                      fetchedData?.Make?.length > 0 &&
                      fetchedData.Make.map((data) => (
                        <option key={data?._id} value={data?._id}>
                          {data?.Make}
                        </option>
                      ))}
                  </select>
                </div>
                <div className=" col-md-4 align-items-center">
                  <label>Model</label>
                  <select
                    disabled={view}
                    class="form-select"
                    aria-label="Default select example"
                    name="Model"
                    value={formData?.Model}
                    onChange={handleOnchange}
                    required
                  >
                    <option value={""}>Select Model</option>
                    {fetchedData?.Model &&
                      fetchedData?.Model?.length > 0 &&
                      fetchedData.Model.map((data) => (
                        <option key={data?._id} value={data?._id}>
                          {data?.Model}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 align-items-center">
                  <label>Variant Name</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="VariantName"
                    value={formData?.VariantName}
                    onChange={handleOnchange}
                    placeholder="Enter Variant Name"
                    required //done
                  />
                </div>
                <div className="col-md-4 align-items-center">
                  <label>Cubic Capacity (CC)</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="number"
                    name="Engine"
                    value={formData?.Engine}
                    onChange={handleOnchange}
                    placeholder="Enter CC"
                    required //done
                  />
                </div>
                <div className=" col-md-4 align-items-center">
                  <label>Select Fuel Type</label>
                  <select
                    disabled={view}
                    class="form-select"
                    aria-label="Default select example"
                    name="FuelType"
                    value={formData?.FuelType}
                    onChange={handleOnchange}
                    required
                  >
                    <option value={""}>Select Fuel Type</option>
                    {fetchedData?.fuelTypes &&
                      fetchedData?.fuelTypes?.length > 0 &&
                      fetchedData.fuelTypes.map((data) => (
                        <option key={data?._id} value={data?._id}>
                          {data?.FuelType}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 align-items-center">
                  <label>Seater</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="number"
                    name="Seater"
                    value={formData?.Seater}
                    onChange={handleOnchange}
                    placeholder="Enter Seater"
                    required //done
                  />
                </div>
                <div className="col-md-4 align-items-center">
                  <label>GVW</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="number"
                    name="GVW"
                    value={formData?.GVW}
                    onChange={handleOnchange}
                    placeholder="Enter GVW"
                    required //done
                  />
                </div>
                <div className=" col-md-4 align-items-center">
                  <label>Status</label>
                  <select
                    disabled={view}
                    class="form-select"
                    aria-label="Default select example"
                    name="Status"
                    value={formData?.Status}
                    onChange={handleOnchange}
                    required
                  >
                    <option value={1}>Enable</option>
                    <option value={0}>Disable</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex gap-3 ">
            <button
              type="submit"
              className="btn mybtn"
              // style={{ marginTop: 28 }}
              onClick={() => navigate("/admin/masters/Model/data")}
            >
              Go Back
            </button>
            {!view && (
              <button
                type="submit"
                className="btn mybtn"
                // style={{ marginTop: 28 }}
                onClick={() => {
                  id ? handleEdit() : handleCreate();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default AddVariant;
