import React from "react";
import ReactEcharts from "echarts-for-react";

const PieChart = ({ height, title, data, xAxisLabel }) => {

  const formatIndianNumber = (num) => {
    return new Intl.NumberFormat('en-IN').format(Math.round(num));
  };

  const sortedData = [...data].sort((a, b) => b.value - a.value);

  const formattedData = sortedData.map(item => ({
    ...item,
    formattedValue: formatIndianNumber(item.value),
  }));

  const option = {
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        const { name, value, percent } = params;
        const formattedValue = formatIndianNumber(value); 
        return `${name}: ${formattedValue} (${percent}%)`; 
      },
    },
    graphic: [
      {
        type: "text", 
        left: "27%", 
        top: "90%", 
        style: {
          text: xAxisLabel, 
          textAlign: "center",
          fill: "#666", 
          fontSize: 12, 
          fontWeight: "600",
        },
      },
    ],
    series: [
      {
        name: title,
        type: "pie",
        radius: ["20%", "40%"],
        center: ["43%", "50%"],  
        avoidLabelOverlap: true, 
        clockwise: false, 
        label: {
          show: true,
          position: "outside", // Place labels outside the pie chart
          formatter: (params) => {
            return `${params.name} (${params.percent}%)`;
          },
          fontSize: 10,
          fontWeight: "bold",
          alignTo: "edge", // Align labels to the edge of the chart
          margin: 5, // Add margin to prevent overlap
        },
        // labelLine: {
        //   show: true,
        //   length: 15, // Increase the length of the first segment
        //   length2: 30, // Increase the length of the second segment
        //   smooth: true,
        // },
        emphasis: {
          label: {
            show: true,
            fontSize: 12,
            fontWeight: "bold",
          },
        },
        data: formattedData.map(item => ({
          name: item.name,
          value: item.value, 
        })),
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      style={{ width: "100%", height: height }}
    />
  );
};

export default PieChart;