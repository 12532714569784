import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import ExcelExport from "../utils/ExcelExport/ExcelExport";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { BsEraser } from "react-icons/bs";
import { MdSend } from "react-icons/md";
import Select from "react-select";
import { useSelector } from "react-redux";

import { removeEmptyValues } from "../../Utils/usefullFunction";
import SearchInputFilter from "../../Utils/SearchInputFilter";
// import ImportPOSExcel from "../../Utils/ImportPOSExcel";
import useGettingData from "../Hooks/useGettingData";

function maskMobileNumber(mobileNo) {
  return mobileNo.replace(/^(\d{2})\d{6}(\d{2})$/, "$1******$2");
}

const POS = () => {
  // const today = new Date();
  // 2023-05-05
  // const firstDay = `${today.toISOString().substr(0, 7)}-01`;
  // const lastDay = today.toISOString().substr(0, 10);
  const [data, setData] = useState([]);
  const [branch, setBranch] = useState({
    _id: "",
    BranchName: "-- Select Branch --",
  });
  const [club, setClub] = useState({
    _id: "",
    Name: "-- Select Club --",
  });
  const { user } = useSelector((store) => store.user);
  const [loading, setLoading] = useState(false);
  const [POSDATA, setPOSDATA] = useState({
    // startDate: firstDay,
    // endDate: lastDay,
  });
  const [ReportingTo, setReportingTo] = useState({
    _id: "",
    Name: "-- Select Reporting Employee --",
  });

  const [totalDocs, setTotalDocs] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [Employee, setEmployee] = useState([]);
  const [inputData, setInput] = useState("");
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPagination(0);
  };

  let {
    getData: getBranch,
    Loading: BranchLoading,
    Options: BranchOpt = [
      {
        _id: "",
        BranchName: "-- Select Branch --",
      },
      user?.Branch,
    ],
  } = useGettingData("/Branch", [
    {
      _id: "",
      BranchName: "-- Select Branch --",
    },
  ]);

  let {
    getData: getClub,
    Loading: ClubLoading,
    Options: clubopt = [
      {
        _id: "",
        Name: "-- Select Club --",
      },
    ],
  } = useGettingData("/Club", [
    {
      _id: "",
      Name: "-- Select Club --",
    },
  ]);

  const getEmployee = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get("/Employee/selectBoxDropdown");
      const Emp = data.map(
        ({
          Name = "",
          Salutation = "",
          MiddleName = "",
          LastName = "",
          _id,
        }) => {
          return {
            Name: ` ${Salutation} ${Name} ${MiddleName} ${LastName}`,
            _id,
          };
        }
      );

      setEmployee(Emp);
    } catch (error) {}
  };

  const deletePos = async (_id) => {
    try {
      const {
        data: { success, message },
      } = await axiosInstance.delete(`/Pos/${_id}`);

      if (success) {
        filterDataHandler();
        ToastSusses(message);
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    // filterDataHandler();
    getEmployee();
  }, []);

  // const getDataExport = async (EraserHandler = false) => {
  //   let que = {};
  //   try {
  //     if (!EraserHandler) {
  //       que = POSDATA;
  //     } else {
  //       que["ExaminedStatus"] = "";
  //       que["ReportingTo"] = "";
  //       que["Club"] = "";
  //       que["inputData"] = "";
  //       que["Branch"] = "";
  //     }

  //     removeEmptyValues(que);

  //     if (!EraserHandler) {
  //       que["ReportingTo"] = ReportingTo?._id;
  //     }
  //     que["Branch"] = branch?._id;
  //     que["Club"] = club?._id;

  //     // Add query parameters using the `params` property
  //     const {
  //       data: { data },
  //     } = await axiosInstance.get("/Pos/getPosFilter", {
  //       params: {
  //         inputData,
  //         ...que,
  //       },
  //     });
  //     const POSbank = await axiosInstance.get("/PBD/getLatestBankAccounts");
  //     const res = POSbank.data.data || {};
  //     return { data, POSbank: res };
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };

  const getExcelData = async () => {
    let rows = [];
    let que = {};
    try {
      if (!EraserHandler) {
        que = POSDATA;
      } else {
        que["ExaminedStatus"] = "";
        que["ReportingTo"] = "";
        que["Club"] = "";
        que["inputData"] = "";
        que["Branch"] = "";
      }

      removeEmptyValues(que);

      if (!EraserHandler) {
        que["ReportingTo"] = ReportingTo?._id;
      }
      que["Branch"] = branch?._id;
      que["Club"] = club?._id;

      // Add query parameters using the `params` property
      const [POS] = await Promise.all([
        axiosInstance.get("/Pos/getPosFilter", {
          params: {
            inputData,
            ...que,
          },
        }),
        //axiosInstance.get("/PBD/getLatestBankAccounts"),
      ]);
      const data = POS.data.data || [];
   
      if (data && data.length > 0) {
        const responseData = data;
        const updatedData = responseData.map((item) => {
          const mob = item?.Mobile ? item?.Mobile.toString() : "";
          const aadhar = item?.AadhaarNumber
            ? item?.AadhaarNumber.toString()
            : "";
          const mobileNo = item?.Mobile ? maskMobileNumber(mob) : item?.Mobile;

          return {
            ...item,
            Mobile: mobileNo,
            AadhaarNumber: aadhar,
            Club: item?.Club?.Name || "",
            Branch: item?.Branch?.BranchName || "",
            RMMOTOR: item?.ReportingToMotor?.Name || "",
            RMNONMOTOR: item?.ReportingToNonMotor?.Name || "",
            AccountNumber: item?.BankAccountNumber || "",
            IFSCCode: item?.IFSC || "",
            BankName: item?.BankName || "",
        
          };
        });
        rows = updatedData;
      }

      // Define columns
      const columns = [
        { header: "Code", key: "Code", width: 20 },
        { header: "Name", key: "Name", width: 20 },
        { header: "Alias Name", key: "AliasName", width: 15 },
        { header: "Phone No.", key: "Mobile", width: 15 },
        { header: "Account Number", key: "AccountNumber", width: 15 },
        { header: "IFSC Code", key: "IFSCCode", width: 15 },
        { header: "Bank Name", key: "BankName", width: 15 },
      
        { header: "Aadhaar No.", key: "AadhaarNumber", width: 25 },
        { header: "Pan No", key: "PanNumber", width: 15 },
        { header: "Examined", key: "Examined", width: 15 },
        { header: "Club", key: "Club", width: 20 },
        { header: "Branch", key: "Branch", width: 20 },
        { header: "Reporting To Motor", key: "RMMOTOR", width: 20 },
        { header: "Reporting To Non Motor", key: "RMNONMOTOR", width: 20 },
      ];

      return { columns, rows, fileName: "POS List" };
    } catch (error) {
      console.error("Error:", error);
      return { columns: [], rows: [], fileName: "POS List" };
    }
  };

  const filterDataHandler = async (EraserHandler = false) => {
    let que = {};
    try {
      setLoading(true);

      if (!EraserHandler) {
        que = POSDATA;
      } else {
        que["ExaminedStatus"] = "";
        que["ReportingTo"] = "";
        que["Club"] = "";
        que["inputData"] = "";
        que["Branch"] = "";
      }

      removeEmptyValues(que);
      if (!EraserHandler) {
        que["ReportingTo"] = ReportingTo._id;
      }
      que["Branch"] = branch._id;

      que["Club"] = club._id;

      const {
        data: { data, totalDocs },
      } = await axiosInstance.post("/Pos/getPolicyByFilter", {
        page: pagination + 1,
        limit: rowsPerPage,
        inputData,
        ...que,
      });
      setTotalDocs(totalDocs);
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const EraserHandler = async () => {
    const newObj = {
      // startDate: firstDay,
      // endDate: lastDay,
      ExaminedStatus: "",
      ReportingTo: "",
      Club: "",
    };
    setPOSDATA(newObj);
    setReportingTo({
      _id: "",
      Name: "-- Select Reporting Employee --",
    });
    filterDataHandler(true);
  };

  const onChangeSetValue = (e) => {
    setPOSDATA({ ...POSDATA, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    filterDataHandler();
  }, [pagination, rowsPerPage]);

  // useEffect(() => {
  //   getDataExport();
  // }, []);

  useEffect(() => {}, [ReportingTo]);

  useEffect(() => {
    if (user && user?.Branch) {
      if (user?.Branch?.BranchCode !== "0") setBranch(user?.Branch);
    }
  }, [user]);

  return (
    <>
      <Header
      // setInput={setInput}
      // inputData={inputData}
      // placeHolder="Search by, PosName, PosCode"
      />
      <div className="MainDiv MainDivPadding">
        <div className="container-fluid">
          <div className="row CreateCard">
            <div className="col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="row">
                    <div className="col-9" style={{ float: "left" }}>
                      <div className="ListDesigncol">
                        <h4 className="card-title">POS</h4>
                        {/* <p className="card-title-desc">
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit.
                        </p> */}
                      </div>
                    </div>
                    <div className="col-4" style={{ float: "left" }}>
                      <div className="d-flex justify-content-start align-items-center">
                        <Link
                          className="btn mybtn"
                          to="/admin/masters/import-Bulk-Pos/data"
                        >
                          Update Bulk POS
                        </Link>
                        &nbsp;
                        <Link className="btn mybtn" to="/admin/masters/Pos">
                          Add POS
                        </Link>
                        &nbsp;
                        <Link
                          className="btn mybtn"
                          to="/admin/masters/import-Pos/data"
                        >
                          Import POS
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "unset",
                      flexDirection: "row",
                      gap: "10px",
                      zoom: "75%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      {/* <div className="app-search"> */}
                      <SearchInputFilter
                        setInput={setInput}
                        inputData={inputData}
                        PlaceHolder="Search by Name, Email, Code"
                        style={{ width: "300px" }}
                      />
                      {/* </div> */}
                      <div className="">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={[
                            {
                              _id: "",
                              Name: "-- Select Reporting Empolyee --",
                            },
                            ...Employee,
                          ]}
                          getOptionValue={(option) => option["_id"]}
                          getOptionLabel={(option) => option["Name"]}
                          value={ReportingTo}
                          onChange={setReportingTo}
                        />
                      </div>

                      <div
                        className=""
                        onClick={() => {
                          if (user?.Branch?.BranchCode === "0") getBranch();
                        }}
                      >
                        {/* <div className=""> */}
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={user?.Branch?.BranchCode !== "0"}
                          options={
                            BranchOpt.length === 0 ? [user?.Branch] : BranchOpt
                          }
                          isLoading={BranchLoading}
                          // defaultValue={{
                          //   _id:"-- Please Select Variant --"
                          // }}
                          getOptionValue={(option) => option["_id"]}
                          getOptionLabel={(option) => option["BranchName"]}
                          value={branch}
                          onChange={(ele) => {
                            setBranch(ele);
                          }}
                          styles={{ height: "42px" }}
                        />
                      </div>

                      <div className="">
                        <select
                          // disabled={view}
                          class="form-select"
                          aria-label="Default select example"
                          name="ExaminedStatus"
                          value={POSDATA.ExaminedStatus}
                          onChange={(e) => {
                            onChangeSetValue(e);
                          }}
                        >
                          <option value="">Select Status</option>

                          {[
                            "All",
                            "Profile pending",
                            "Registration requested",
                            "Duplicate",
                            "Docs invalid",
                            "Rejected",
                            "Gi- study material shared",
                            "Gi- exam pending",
                            "Gi- exam failed",
                            "Gi- exam cleared",
                            "Gi- Study material downloaded",
                            "Li- study material shared",
                            "Li- exam pending",
                            "Li- exam failed",
                            "Li- exam cleared",
                            "Li - study material downloaded",
                          ].map((ele, index) => (
                            <option key={index} value={ele}>
                              {ele}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* old club filter */}
                      {/* <div className="">
                        <select
                          class="form-select"
                          name="Club"
                          value={POSDATA.Club}
                          onChange={(e) => {
                            onChangeSetValue(e);
                          }}
                        >
                          <option value="Self">Select Club</option>
                          <option value="PlatinumClub">Platinum Club</option>
                          <option value="GoldClub">Gold Club</option>
                          <option value="SilverClub">Silver Club</option>
                        </select>
                      </div> */}

                      {/* newclub filter */}

                      <div
                        className=""
                        onClick={() => {
                          if (clubopt.length === 0) getClub();
                        }}
                      >
                        {/* <div className=""> */}
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={clubopt}
                          isLoading={ClubLoading}
                          // defaultValue={{
                          //   _id:"-- Please Select Variant --"
                          // }}
                          getOptionValue={(option) => option["_id"]}
                          getOptionLabel={(option) => option["Name"]}
                          value={club}
                          onChange={(ele) => {
                            setClub(ele);
                          }}
                          styles={{ height: "42px" }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      {/* <ImportPOSExcel
                        fileName="POS_DETAILS"
                        getDataExport={getDataExport}
                      /> */}
                      <ExcelExport getData={getExcelData} />
                      <div
                        className="searchBox"
                        style={{
                          width: "77px",
                          cursor: "pointer",
                        }}
                        onClick={() => filterDataHandler()}
                      >
                        <MdSend />
                      </div>
                      <div
                        className="searchBox"
                        style={{
                          width: "77px",
                          cursor: "pointer",
                        }}
                        onClick={() => EraserHandler()}
                      >
                        <BsEraser />
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <div
                      className="p-1 container"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                      }}
                    >
                      <TableSkeleton />
                    </div>
                  ) : (
                    <>
                      <div
                        class="table-responsive  mt-2"
                        style={{
                          zoom: "70%",
                        }}
                      >
                        <table class="table">
                          <thead>
                            <tr
                              style={{ background: "#174379", color: "#fff" }}
                            >
                              <th scope="col">SNo.</th>
                              <th scope="col">Code</th>
                              <th scope="col">Name</th>
                              <th scope="col">Alias Name</th>
                              <th scope="col">Permission</th>

                              <th scope="col">Email</th>
                              <th scope="col">Phone Number</th>
                              <th scope="col">Pan Number</th>
                              <th scope="col">Branch</th>
                              <th scope="col">Club</th>
                              <th scope="col">RM Name (MOTOR)</th>
                              <th scope="col">RM Name (Non-MOTOR)</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length !== 0 &&
                              data.map((ele, index) => {
                                const EMP_NAME = `${
                                  ele?.ReportingToMotor?.Salutation ?? ""
                                } ${ele?.ReportingToMotor?.Name ?? ""} ${
                                  ele?.ReportingToMotor?.MiddleName ?? ""
                                } ${ele?.ReportingToMotor?.LastName ?? ""}`;

                                const EMP_NAME2 = `${
                                  ele?.ReportingToNonMotor?.Salutation ?? ""
                                } ${ele?.ReportingToNonMotor?.Name ?? ""} ${
                                  ele?.ReportingToNonMotor?.MiddleName ?? ""
                                } ${ele?.ReportingToNonMotor?.LastName ?? ""}`;

                                const POS_Name = `${ele?.Salutation || ""} ${
                                  ele?.Name || ""
                                } ${ele?.MiddleName || ""} ${
                                  ele?.LastName || ""
                                }`;
                                return (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <th scope="row">{ele?.Code}</th>
                                    <th scope="row">{POS_Name}</th>
                                    <th scope="row">{ele?.AliasName}</th>
                                    <th scope="row">{ele?.Permission.Roll}</th>
                                    <th scope="row">{ele?.Email}</th>
                                    <th scope="row">{ele?.Mobile}</th>
                                    <th scope="row">{ele?.PanNumber || ""}</th>
                                    <th scope="row">
                                      {ele?.Branch?.BranchName}
                                    </th>
                                    <th scope="row">{ele?.Club?.Name}</th>
                                    <th scope="row">{EMP_NAME}</th>
                                    <th scope="row">{EMP_NAME2}</th>

                                    <th scope="row">
                                      {" "}
                                      <div className="col-md-2">
                                        <div className="d-flex gap-3">
                                          <div>
                                            <Link
                                              className=""
                                              state={{
                                                value: ele,
                                                view: true,
                                              }}
                                              to="/admin/masters/Pos"
                                            >
                                              <FaEye size={25} color="" />{" "}
                                            </Link>
                                          </div>
                                          <div className="">
                                            <Link
                                              className=""
                                              state={{
                                                value: ele,
                                                Mode: true,
                                              }}
                                              to="/admin/masters/Pos"
                                            >
                                              <BiEdit size={25} color="blue" />{" "}
                                            </Link>
                                          </div>
                                          <div className="">
                                            <AiFillDelete
                                              color="red"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              size={25}
                                              onClick={() => {
                                                const isTrue = window.confirm(
                                                  "Do you want to delete!"
                                                );
                                                if (isTrue) {
                                                  deletePos(ele?._id);
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      {/* <TableTransition
                        AllKey={AllKey}
                        data={data}
                        editUrl={"/admin/Pos"}
                        Mode={true}
                        deleteFunction={deletePos}
                        isProfileImage={true}
                        IsPos={true}
                      /> */}
                      {
                        <div
                          style={{
                            backgroundColor: "#1a2e45d7",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            marginTop: "5px",
                          }}
                        >
                          <TablePagination
                            component="div"
                            className="text-white"
                            rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                            count={totalDocs ?? 0}
                            page={pagination}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      }
                    </>
                  )}
                </div>
              </div>

              {/* end col */}
            </div>
          </div>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default POS;
