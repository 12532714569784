import React, { useEffect, useState } from "react";
import {
  ToastError,
  ToastSusses,
  ToastInfo,
  ToastWarn,
} from "../../Utils/Tost";
import { axiosInstance } from "../../config";
import Select from "react-select";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import SearchInputFilter from "../../Utils/SearchInputFilter";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useLocation, useNavigate } from "react-router-dom";
import "./Purchase.css";
import { reversDate } from "../../Utils/usefullFunction";

const CreatePurchaseReturn = () => {
  const navigate = useNavigate();
  const today = new Date();
  const lastDay = today.toISOString().substr(0, 10);
  const { state } = useLocation();
  const { value = "", Mode, view } = state || {};
  const [EnterDate, setEnterDate] = useState(lastDay);
  const [inputData, setInput] = useState();
  const [loading, setLoading] = useState(false);
  const [Remark, setRemark] = useState(value?.Remark || "");
  const [TDSPercent, setTDSPercent] = useState(value?.TDSPercent || 0);
  const [TDSAccount, setTDSAccount] = useState({
    _id: "",
    Name: "-- Select TDS Account --",
  });
  const [TDSOpt, setTDSOpt] = useState([]);
  const [Data, setData] = useState([]);
  const [POSOpt, setPOSOpt] = useState([]);
  const [SelectedPolicyOpt, setSelectedPolicyOpt] = useState([]);
  const [CreditAccount, setCreditAccount] = useState({
    _id: "",
    Name: "-- Select Credit Account",
  });
  const [CreditAccountOpt, setCreditAccountOpt] = useState([]);

  const [POS, setPOS] = useState({
    _id: "",
    Name: "-- Select POS --",
  });

  const [TotalAmount, setTotalAmount] = useState(0);
  const [TotalAmountOfSelectPolicy, setTotalAmountOfSelectPolicy] = useState(0);

  const getPos = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.post("/Pos/getPosByField", {
        showFiled: {
          Name: 1,
          AliasName: 1,
          Code:1,
          MiddleName: 1,
          LastName: 1,
          GSTINNumber: 1,
          _id: 1,
        },
      });
      setPOSOpt([
        {
          _id: "",
          Name: "-- Select POS --",
        },
        ...data,
      ]);
    } catch (error) {}
  };
  const getApprovedPolicy = async (POS) => {
    setLoading(true);
    let que = { POS };
    if (inputData) {
      que["inputData"] = inputData;
    }
    try {
      const {
        data: { data = [], TotalAmount = {} },
      } = await axiosInstance.post("/PurchaseReturn/getIsPurchaseReturn", {
        ...que,
      });
      const calculatedTotalAmount =
        data?.reduce((total, item) => {
          const commissionAmount =
            parseInt(item?.Commission?.[`POSRate${item.BasedOn}Amount`]) || 0;
          return total + commissionAmount;
        }, 0) || 0;

      setTotalAmount(calculatedTotalAmount);
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getLedgerMasterForTds = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/LedgerEntry?Group=648ac8ca7df926cfdd2ddccb`
      ); // 	Duties and Tax
      setTDSOpt(data);
    } catch (error) {}
  };
  useEffect(() => {
    getPos();
    getLedgerMasterForTds();
  }, []);

  const selectPolicyHandler = (isChecked, _id, Amount = 0) => {
    if (isChecked) {
      setSelectedPolicyOpt((pre) => [...pre, _id]);
      setTotalAmountOfSelectPolicy((pre) => pre + Amount);
    } else {
      const SelectedPolicy = SelectedPolicyOpt.findIndex((ele) => ele === _id);
      SelectedPolicyOpt.splice(SelectedPolicy, 1);
      setSelectedPolicyOpt([...SelectedPolicyOpt]);
      setTotalAmountOfSelectPolicy((pre) => pre - Amount);
    }
  };

  const checkIsPresentOrNot = (_id) => {
    const SelectedPolicy = SelectedPolicyOpt.findIndex((ele) => ele === _id);

    return SelectedPolicy === -1 ? false : true;
  };

  const SelectAllDeleteAll = (isChecked) => {
    if (!isChecked) {
      setSelectedPolicyOpt([]);
      setTotalAmountOfSelectPolicy(0);
    } else {
      let Total = 0;
      const selectALL = Data.map((ele) => {
        let amount = ele?.Commission?.[`POSRate${ele.BasedOn}Amount`] ?? 0;
        Total = Total + amount;
        return ele?._id;
      });
      setSelectedPolicyOpt([...selectALL]);
      setTotalAmountOfSelectPolicy(Total);
    }
  };

  const handleCreate = async () => {
    if (SelectedPolicyOpt.length === 0) {
      return ToastInfo("Please select Policy for Create Return");
    }

    if (!POS?._id) {
      return ToastInfo("Select Debit Account ");
    }
    if (!CreditAccount?._id) {
      return ToastInfo("Select Credit Account ");
    }
    const AmountToGive = TotalAmountOfSelectPolicy;
    const isGSTNumber =
      POS?.GSTINNumber && POS?.GSTINNumber.length === 16 ? true : false;

    let GstAmount = 0;
    let TDSAmount = Math.round((AmountToGive * TDSPercent) / 100);

    if (isGSTNumber) {
      GstAmount = Math.round(AmountToGive * (18 / 100));
    }
    const NetAmount = Math.round(AmountToGive + GstAmount - TDSAmount);

    try {
      const {
        data: { success, message },
      } = await axiosInstance.post("/PurchaseReturn", {
        CreditAccount: CreditAccount._id,
        DebitAccount: POS._id,
        EnterDate,
        Remark,
        // TDSPercent,
        // TDSAccount: TDSAccount?._id,
        TotalAmount: AmountToGive,
        NetAmount,
        SelectedPolicyOpt,
      });
      if (success) {
        ToastSusses(message);
        navigate("/admin/vouchers/Purchase/Return");
      } else {
        ToastSusses(message);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };
  let FilteredPolicy;

  if (inputData) {
    FilteredPolicy = Data?.filter(
      (e) =>
        e?.VehicleNumber?.toLowerCase()?.includes(
          inputData.trim()?.toLowerCase()
        ) ||
        e?.InsureeName?.toLowerCase()?.includes(
          inputData.trim()?.toLowerCase()
        ) ||
        e?.PolicyNumber?.toLowerCase()?.includes(
          inputData.trim()?.toLowerCase()
        )
    );
  } else {
    FilteredPolicy = Data;
  }

  const getLedgerMaster = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/LedgerEntry`);
      setCreditAccountOpt(data);
    } catch (error) {}
  };

  useEffect(() => {
    getLedgerMaster();
  }, []);
  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "70px" }}>
        {!POS._id ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="selectPosDiv" style={{ backgroundColor: "#fff" }}>
              <Select
                className="select-BoxUi"
                classNamePrefix="select"
                options={POSOpt}
                getOptionValue={(option) => option["_id"]}
                getOptionLabel={(option) => {
                  const Name = `${option["Code"] ?? ""} - ${option["Name"] ?? ""} ${option["AliasName"] ? `(${option["AliasName"]})` : ""}`.trim();
                  return Name;
                }}
                styles={{ backgroundColor: "#F5F5F6" }}
                onChange={(ele) => {
                  getApprovedPolicy(ele._id);
                  setPOS(ele);
                }}
                value={POS}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className="card-body CreateCard"
              style={{
                height: "38vh",
                zoom: "90%",
              }}
            >
              <h5>
                {/* {Mode ? "Edit Receipt" : "Create Receipt"} */}
                Create Purchase Return
              </h5>
              <hr />
              <br />
              <div className="row mx-2 ">
                <div className="col-md-4 align-items-center">
                  <label>Debit Account</label>
                  <input
                    disabled={true}
                    className="form-control "
                    type="input"
                    value={`${POS["Salutation"] ?? ""} ${
                      POS["MiddleName"] ?? ""
                    } ${POS["Name"] ?? ""} ${POS["LastName"] ?? ""}`}
                    placeholder="Enter Receipt"
                  />
                </div>
                <div className="col-md-4 align-items-center">
                  <label>EnterDate</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="date"
                    name="EnterDate"
                    value={EnterDate}
                    onChange={(e) => setEnterDate(e.target.value)}
                    placeholder="Enter Receipt "
                    required //done
                  />
                </div>
                <div className="col-md-4 align-items-center">
                  <label>Credit Account</label>

                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={[
                      {
                        _id: "",
                        Name: "-- Select Credit Account",
                      },
                      ...CreditAccountOpt,
                    ]}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => {
                      const Name = `${option?.Name}`;
                      return Name;
                    }}
                    onChange={setCreditAccount}
                    value={CreditAccount}
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label>Remark</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="text"
                    name="Remark"
                    value={Remark}
                    onChange={(e) => setRemark(e.target.value)}
                    placeholder="Remark"
                    required //done
                  />
                </div>
                {/* <div
                  className="col-md-4 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label>TDS Account</label>

                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={[
                      {
                        _id: "",
                        Name: "-- Select TDS Account --",
                      },
                      ...TDSOpt,
                    ]}
                    getOptionValue={(option) => option["_id"]}
                    getOptionLabel={(option) => {
                      const Name = `${option?.Name}`;
                      return Name;
                    }}
                    onChange={setTDSAccount}
                    value={TDSAccount}
                  />
                </div>
                <div
                  className="col-md-4 align-items-center"
                  style={{ marginTop: "15px" }}
                >
                  <label>TDS Percent</label>
                  <input
                    disabled={view}
                    className="form-control "
                    type="number"
                    name="TDSPercent"
                    value={TDSPercent}
                    onChange={(e) => setTDSPercent(e.target.value)}
                    placeholder="TDS Percent"
                    required //done
                  />
                </div> */}
              </div>
            </div>
            <div className="card-body CreateCard">
              <div
                className="p-2 filters"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "unset",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    flexDirection: "row",
                    gap: "10px",
                    zoom: "80%",
                  }}
                >
                  <div className="d-flex gap-3">
                    <SearchInputFilter
                      setInput={setInput}
                      inputData={inputData}
                      PlaceHolder="Policy No.,Vehicle No.,Insuree Name*"
                    />
                    <div className="col">
                      {/* <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn mybtn"
                        table="table-to-xls"
                        filename={`Billing-oF-${EnterDate}-${
                          POS["Salutation"] ?? ""
                        }${POS["MiddleName"] ?? ""}${POS["Name"] ?? ""}${
                          POS["LastName"] ?? ""
                        }`}
                        sheet="tablexls"
                        buttonText="Download as XLS"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="PolicyCounts">
                <div className="PolicyCounts-Item">
                  <b>Selected Policy : </b> {SelectedPolicyOpt.length}
                </div>

                <div className="PolicyCounts-Item">
                  {" "}
                  <b>Total Amount : </b> {TotalAmountOfSelectPolicy}
                </div>
              </div>

              {loading ? (
                <div
                  className=" p-2 "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                  }}
                >
                  <TableSkeleton />
                </div>
              ) : (
                <div
                  className=" p-2 "
                  style={{
                    zoom: "70%",
                  }}
                >
                  {Data && (
                    <table
                      class="table table-striped table-bordered overflow-x mt-3"
                      id="table-to-xls"
                    >
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{ textAlign: "center" }}
                            className="selectBoxForTable"
                          >
                            <p>#</p>
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              value="Car"
                              checked={SelectedPolicyOpt.length === Data.length}
                              style={{
                                width: "23px",
                                height: "23px",
                              }}
                              onClick={(e) => {
                                SelectAllDeleteAll(e.target.checked);
                              }}
                            />
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Date
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Insuree Name
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            PolicyNumber
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            VehicleNumber
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Payment Mode
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Insurance Type
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Insurance Company
                          </th>
                          {/* <th scope="col">POS</th> */}
                          {/* <th scope="col">Total Profit</th> */}
                          <th scope="col" style={{ textAlign: "center" }}>
                            Amount To Pay
                          </th>
                          {/* <th scope="col">Profit After Commission</th> */}

                          {/* <th scope="col">Approve</th> */}
                        </tr>
                      </thead>

                      <tbody>
                        {FilteredPolicy &&
                          FilteredPolicy?.length !== 0 &&
                          FilteredPolicy.map((data, index) => (
                            <tr key={data._id}>
                              <th scope="row" className="selectBoxForTable">
                                <p>{index + 1}</p>
                                <input
                                  type="checkbox"
                                  id="vehicle2"
                                  name="vehicle2"
                                  value="Car"
                                  key={data._id}
                                  checked={checkIsPresentOrNot(data._id)}
                                  style={{
                                    width: "23px",
                                    height: "23px",
                                  }}
                                  onClick={(e) => {
                                    selectPolicyHandler(
                                      e.target.checked,
                                      data._id,
                                      // data?.Commission?.TotalAmountToPay
                                      parseInt(
                                        data?.Commission?.[
                                          `POSRate${data.BasedOn}Amount`
                                        ]
                                      ) || 0
                                    );
                                  }}
                                />
                              </th>
                              <th>{reversDate(data?.IssueDate)}</th>
                              <th scope="row">{data?.InsureeName}</th>
                              <th scope="row">{data?.PolicyNumber}</th>
                              <th scope="row">{data?.VehicleNumber}</th>
                              <th scope="row">{data?.PaymentMode}</th>
                              <th scope="row">
                                {data?.InsuranceType?.InsuranceType}
                              </th>
                              <th scope="row">
                                {data?.InsuranceCompany?.Name}
                              </th>
                              {/* <th scope="row">{data?.POS?.Name}</th> */}
                              {/* <th scope="row">
                              ₹{" "}
                              {Math.round(data?.Commission?.TotalPolicyProfit)}
                            </th> */}
                              <th scope="row" style={{ textAlign: "right" }}>
                                ₹{" "}
                                {
                                  data?.Commission?.[
                                    `POSRate${data.BasedOn}Amount`
                                  ]
                                }
                              </th>
                              {/* <th scope="row">
                              ₹ {Math.round(data?.Commission?.PolicyProfit)}
                            </th> */}
                            </tr>
                          ))}
                        <tr>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>
                          <th scope="row"></th>

                          <th scope="row" style={{ textAlign: "right" }}>
                            ₹ {TotalAmount ?? 0}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {
                    <div
                      style={{
                        backgroundColor: "#1a2e45d7",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        marginTop: "5px",
                      }}
                    ></div>
                  }
                </div>
              )}
            </div>
            <div className="col-lg-5" style={{ float: "left" }}></div>
            <div className="col-lg-2" style={{ float: "left" }}>
              <div className="d-flex gap-3 ">
                <button
                  type="submit"
                  className="btn mybtn"
                  // style={{ marginTop: 28 }}
                  onClick={() => {
                    setTotalAmount(0);
                    setData([]);
                    setTotalAmountOfSelectPolicy(0);
                    setSelectedPolicyOpt([]);
                    setPOS({
                      _id: "",
                      Name: "-- Select POS --",
                    });
                  }}
                >
                  Back
                </button>

                {!view && (
                  <button
                    type="submit"
                    className="btn mybtn"
                    // style={{ marginTop: 28 }}
                    onClick={() => {
                      handleCreate();
                    }}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
            <div className="col-lg-5" style={{ float: "left" }}></div>
            <br></br>
            <br></br>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default CreatePurchaseReturn;
