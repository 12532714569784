import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const AutocompleteComponent = ({
  label,
  placeholder,
  styles,
  value,
  options,
  autoFocus = false,
  onChange,
  getOptionLabel,
  isOptionEqualToValue,
  error,
  helperText,
  onKeyDown = () => {},
  ...props
}) => (
  <Autocomplete
    id={label}
    name={label}
    size="small"
    style={styles}
    value={value}
    options={options}
    getOptionLabel={getOptionLabel}
    onChange={onChange}
    renderInput={(params) => (
      <TextField
        onKeyDown={onKeyDown}
        error={error}
        helperText={helperText}
        autoFocus={autoFocus}
        {...params}
        label={label}
        placeholder={placeholder}
      />
    )}
    {...props}
    isOptionEqualToValue={isOptionEqualToValue}
    renderOption={(props, option, { inputValue }) => {
      const matches = match(option.Name, inputValue, { insideWords: true });
      const parts = parse(option.Name, matches);

      return (
        <li {...props}>
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        </li>
      );
    }}
  />
);

export default AutocompleteComponent;
