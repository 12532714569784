import React, { useEffect, useState } from "react";
import { ToastError, ToastSusses } from "../../Utils/Tost";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config";
import { TablePagination } from "@mui/material";
import Header from "../Header/Header";

import ExcelExport from "../utils/ExcelExport/ExcelExport";
import Footer from "../Footer/Footer";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { MdSend } from "react-icons/md";
import { BsEraser } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import Select from "react-select";
import MultiInsurance from "../RtoGroupChecker/MultiInsurance";
import SearchInputFilter from "../../Utils/SearchInputFilter";
import { reversDate } from "../../Utils/usefullFunction";

const UpdateBulkRtoGroupChecker = () => {
    const [data, setData] = useState();
    const [AllKey, setAllKey] = useState();
    const [loading, setLoading] = useState(false);
    const [totalDocs, setTotalDocs] = useState(0);
    const [pagination, setPagination] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [updateDate, setupdateDate] = useState("");
    const [InsuranceTypeId, setInsuranceTypeId] = useState("");
    const [firstRender, setFirstRender] = useState(false);
    const [InsuranceCompanyOpt, setInsuranceCompanyOpt] = useState([]);
    const [SelectedInsuranceType, setSelectedInsuranceType] = useState([]);
    const [InsuranceCompany, setInsuranceCompany] = useState({
        _id: "",
        AliasName: "Select Company",
    });
    const [date, setDate] = useState({
        DEF: "",
        DET: "",
    });

    const [inputData, setInput] = useState("");
    const handleChangePage = (event, newPage) => {
        setPagination(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPagination(0);
    };
    const getData = async () => {
        setLoading(true);
        try {
            const {
                data: { data, totalDocs },
            } = await axiosInstance.get(
                `/RtoGroupChecker?page=${pagination + 1}&limit=${rowsPerPage}`
            );

            let Keys = [];
            if (data[0]) {
                Keys = Object.keys(data[0]);
            }

            setTotalDocs(totalDocs);
            setAllKey(
                Keys.filter(
                    (e) =>
                        e !== "_id" &&
                        e !== "createdAt" &&
                        e !== "__v" &&
                        e !== "ListOfRto" &&
                        e !== "InsuranceCompany" &&
                        e !== "updatedAt"
                )
            );

            setData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getDataByPageNation = async () => {
        if (inputData) {
            setInsuranceCompany("");
        }
        setLoading(true);
        const SelectedInsuranceTypeData = SelectedInsuranceType.map(
            (ele) => ele.value
        ).filter((ele) => ele !== "");

        const insuranceType = SelectedInsuranceTypeData.length - 1;
        setInsuranceTypeId(SelectedInsuranceTypeData[insuranceType])
        try {
            const {
                data: { data, totalDocs },
            } = await axiosInstance.get(
                `/RtoGroupChecker?page=${pagination + 1
                }&limit=${rowsPerPage}&inputData=${inputData}&InsuranceCompany=${InsuranceCompany?._id ?? ""
                }&InsuranceType=${SelectedInsuranceTypeData[insuranceType] ?? ""}&DEF=${date.DEF ?? ""
                }&DET=${date.DET ?? ""}`
            );

            setTotalDocs(totalDocs);
            setData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const deleteRTO = async (_id) => {
        try {
            const {
                data: { success, message },
            } = await axiosInstance.delete(`/RtoGroupChecker/${_id}`);

            if (success) {
                getData();
                ToastSusses(message);
                // ToastSusses(message);
            } else {
                ToastSusses(message);
            }
        } catch (error) {
            ToastError(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        } else {
            getDataByPageNation();
        }
    }, [pagination, rowsPerPage]);

    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        } else {
            const delayDebounceFn = setTimeout(getDataByPageNation, 500);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [inputData]);

    const getInsuranceCompany = async () => {
        try {
            const {
                data: { data },
            } = await axiosInstance.get("/InsuranceCompany");
            setInsuranceCompanyOpt(data);
        } catch (error) { }
    };

    const callInsuranceData = async (InsuranceType) => {
        const length = InsuranceType?.length;
        if (length) {
            const myInsuranceType = [];

            try {
                const {
                    data: { data },
                } = await axiosInstance.get("/InsuranceType?Root=true");
                myInsuranceType.push({ data, value: InsuranceType[0] });
            } catch (error) { }
            for (let index = 1; index < length; index++) {
                try {
                    const {
                        data: { data = [] },
                    } = await axiosInstance.get(
                        `/InsuranceType?InsuranceUnder=${InsuranceType[index - 1]}`
                    );
                    await myInsuranceType.push({
                        data,
                        value: InsuranceType[index],
                    });
                } catch (error) { }
            }

            return setSelectedInsuranceType([
                ...SelectedInsuranceType,
                ...myInsuranceType,
            ]);
        } else {
            getDataInsuranceType();
        }
    };
    const getDataInsuranceType = async () => {
        try {
            const {
                data: { data },
            } = await axiosInstance.get("/InsuranceType?Root=true");
            setSelectedInsuranceType([{ data, value: "" }]);
        } catch (error) { }
    };
    const EraserHandler = async () => {
        const reSetData = { data: SelectedInsuranceType[0]["data"], value: "" };
        setSelectedInsuranceType((pre) => [reSetData]);
        setInsuranceCompany({
            _id: "",
            Name: "-- Select Insurance Company --",
        });
        setInput("");
        getData();
        setDate({
            DEF: "",
            DET: "",
        });
    };
    useEffect(() => {
        callInsuranceData();
        getInsuranceCompany();
        getDataInsuranceType();
    }, []);

    const onChangeSetValue = (e) => {
        setDate({
            ...date,
            [e.target.name]: e.target.value,
        });
    };

    const updateRecordsHandler = async () => {
        if (InsuranceCompany._id === "") {
            ToastError("Insurance Company is required");
            return false;
        }
        if (!date.DEF) {
            ToastError("DEF is required");
            return false;
        }
        if (!date.DET) {
            ToastError("DET is required");
            return false;
        }

        const isConfirmed = window.confirm(
            "Are you sure you want to Update the records?"
        );
        if (!isConfirmed) {
            return;
        }
        const DEF = date.DEF;
        const DET = date.DET;
        const insuranceCompany = InsuranceCompany._id;
        try {
            setLoading(true);

            let url = `/RtoGroupChecker/UpdateBulk?DETUpdate=${updateDate}&DEF=${DEF}&DET=${DET}&InsuranceCompanyid=${insuranceCompany}`;

            if (InsuranceTypeId) {
                url += `&InsuranceType=${InsuranceTypeId}`;
            }

            const response = await axiosInstance.put(url);

            if (response.status === 200) {
                ToastSusses(response.data.message);

                // navigate("/admin/masters/PayoutGrid/data");
            }
        } catch (error) {
            setLoading(false);
            ToastError(error?.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header
                setInput={setInput}
                inputData={inputData}
                placeHolder="Search by, GroupName"
            />
            <div className="MainDiv MainDivPadding">
                <div className="container-fluid">
                    <div className="card-body CreateCard">
                        <div className="row">
                            <div className="col-8" style={{ float: "left" }}>
                                <div className="ListDesigncol">
                                    <h4 className="card-title">Update Bulk RTO Cluster</h4>
                                 
                                </div>
                            </div>

                        </div>



                        <div
                            className=""
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "unset",
                                flexDirection: "row",
                            }}
                        >
                            <div className="d-flex gap-3">
                                <div className="col-3">
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        options={[
                                            {
                                                _id: "",
                                                AliasName: "Select Company",
                                            },
                                            ...InsuranceCompanyOpt,
                                        ]}
                                        getOptionValue={(option) => option["_id"]}
                                        getOptionLabel={(option) => option["AliasName"]}
                                        onChange={(ele) => {
                                            setInsuranceCompany(ele);
                                        }}
                                        value={InsuranceCompany}
                                    />
                                </div>

                                {SelectedInsuranceType &&
                                    SelectedInsuranceType?.length > 0 &&
                                    SelectedInsuranceType.map(
                                        (data, index) =>
                                            data &&
                                            data?.data?.length > 0 && (
                                                <MultiInsurance
                                                    key={data.value}
                                                    Options={data}
                                                    setSelectedInsuranceType={setSelectedInsuranceType}
                                                    SelectedInsuranceType={SelectedInsuranceType}
                                                    index={index}
                                                    OptionsValue={data.value}
                                                    IsFilter={true}
                                                    ShowLabel={false}
                                                    col="col-md-4"
                                                />
                                            )
                                    )}

                                <div
                                    className=""
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "baseline",
                                        flexDirection: "row",
                                        gap: "10px",
                                    }}
                                >
                                    <input
                                        className="form-control "
                                        type="date"
                                        name="DEF"
                                        value={date.DEF}
                                        onChange={(e) => onChangeSetValue(e)}
                                        required //done
                                    />

                                    <p>TO</p>
                                    <input
                                        className="form-control "
                                        type="date"
                                        name="DET"
                                        value={date.DET}
                                        onChange={(e) => onChangeSetValue(e)}
                                        required //done
                                    />
                                </div>
                            </div>

                            <div className=" gap-3 " style={{ marginLeft: "50px" }} >
                                <div
                                    className="searchBox"
                                    style={{
                                        width: "77px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => getDataByPageNation()}
                                >
                                    <MdSend />
                                </div>
                                <div
                                    className="searchBox"
                                    style={{
                                        width: "77px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => EraserHandler()}
                                >
                                    <BsEraser />
                                </div>
                            </div>


                        </div>
                        <div
                            className="col-4"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",

                            }}
                        >
                            <span style={{ marginLeft: "10px" }}>
                                <label
                                    htmlFor="dateInp"
                                    style={{ marginRight: "10px", fontWeight: "bold" }}
                                >
                                    DET:
                                </label>
                                <input
                                    id="dateInp"
                                    type="date"
                                    name="Date"
                                    value={updateDate}
                                    onChange={(e) => setupdateDate(e.target.value)}
                                    required
                                    style={{
                                        padding: "6px 8px",
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        fontSize: "14px",
                                    }}
                                />
                            </span>{" "}
                            &nbsp;
                            <button
                                onClick={updateRecordsHandler}
                                className="btn mybtn"
                                disabled={loading}
                            >
                                Update
                            </button>
                        </div>
                        {loading ? (
                            <div
                                className=" p-2 container"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "80vh",
                                }}
                            >
                                <TableSkeleton />
                            </div>
                        ) : (
                            <>
                                <div
                                    class="table-responsive"
                                    style={{
                                        zoom: "70%",
                                    }}
                                >
                                    <table
                                        class="table table-striped   table-bordered  overflow-x mt-3"
                                        id="table-to-xls"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Id</th>
                                                <th scope="col">Company Name </th>
                                                <th scope="col">DEF Date</th>
                                                <th scope="col">DET Date</th>
                                                <th scope="col">Cluster Name</th>
                                                <th scope="col">Products</th>
                                                <th scope="col">Policy Type</th>
                                                <th scope="col">GVW</th>
                                                <th scope="col">Seater</th>
                                                <th scope="col">Total RTO</th>
                                                <th scope="col">EXTRA_OPTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.length !== 0 &&
                                                data.map((value, index) => {
                                                    let myInsuranceType =
                                                        value?.InsuranceType?.InsuranceUnderFlow?.map(
                                                            (ele) => ele?.InsuranceType
                                                        );
                                                    myInsuranceType?.push(
                                                        value?.InsuranceType?.InsuranceType
                                                    );

                                                    return (
                                                        <tr key={index + 1}>
                                                            <th scope="row">
                                                                {index + 1}
                                                                {/* {(rowsPerPage * pagination) - rowsPerPage + index + 1} */}
                                                            </th>
                                                            <td>{`${value?._id ?? ""}`}</td>
                                                            <td>{`${value?.InsuranceCompany?.AliasName ?? ""
                                                                }`}</td>
                                                            <td>{`${reversDate(value?.DEF) ?? ""}`}</td>
                                                            <td>{`${reversDate(value?.DET) ?? ""}`}</td>
                                                            <td>{`${value?.GroupName ?? ""}`}</td>
                                                            <td>{`${myInsuranceType ?? ""}`}</td>
                                                            <td>{`${value?.PolicyType?.PolicyTypeName ?? ""
                                                                }`}</td>
                                                            <td>{`${value?.GVWFrom ?? ""}-${value?.GVWTo ?? ""
                                                                }`}</td>
                                                            <td>{`${value?.SeaterFrom ?? ""}-${value?.SeaterTo ?? ""
                                                                }`}</td>
                                                            <td style={{ position: "relative" }}>
                                                                <center>
                                                                    <span
                                                                        class="badge m-2 p-2"
                                                                        style={{ backgroundColor: "#174379" }}
                                                                        title={value?.ListOfRto?.map(
                                                                            (ele) =>
                                                                                `${ele?.RTOName} (${ele?.RTOCode})`
                                                                        ).join(", ")}
                                                                    >
                                                                        {`${value?.ListOfRto?.length}`}
                                                                    </span>
                                                                </center>
                                                            </td>

                                                            <td>
                                                                {" "}
                                                                {value?.createdBy !== "By System" && (
                                                                    <div className="d-flex gap-3">

                                                                        <div>
                                                                            <Link
                                                                                className="col-md-1"
                                                                                state={{
                                                                                    value,
                                                                                    Mode: true,
                                                                                }}
                                                                                to={`/admin/masters/RtoGroupChecker?value=${value._id}`}
                                                                            >
                                                                                <BiEdit size={25} color="blue" />{" "}
                                                                            </Link>
                                                                        </div>

                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    <div
                                        style={{
                                            backgroundColor: "#1a2e45d7",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "start",
                                            marginTop: "5px",
                                        }}
                                    >
                                        <TablePagination
                                            component="div"
                                            className="text-white"
                                            rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                                            count={totalDocs ?? 0}
                                            page={pagination}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>{" "}
            </div>
            <Footer />
        </>
    );
};

export default UpdateBulkRtoGroupChecker;
