import React, { useEffect, useState } from "react";
import { ToastError } from "../../Utils/Tost";
import { axiosInstance } from "../../config";
import "./Dashboard.css";
import Header from "../Header/Header";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";
import BarLineChart from "../../components/BarLineChart";
import PieChart from "../../components/PieChart";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import BarChartT from "../../components/BarChartT";
import BarChartC from "../../components/BarChartC";

const colors = [
  "#b87333",
  "silver",
  "gold",
  "#e5e4e2",
  "#ff6347",
  "#ff4500",
  "#4682b4",
  "#32cd32",
];

const Dashboard = () => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [InsuranceTypes, setInsuranceTypes] = useState([]);
  const [InsuranceCompanies, setInsuranceCompanies] = useState([]);
  const [BranchCountData, setBranchCountData] = useState([]);
  const [BranchNWPData, setBranchNWPData] = useState([]);
  const [BusinessTypeCountData, setBusinessTypeCountData] = useState([]);
  const [BusinessTypeNWPData, setBusinessTypeNWPData] = useState([]);
  const [PolicyTypes, setPolicyTypes] = useState([]);
  const [NetPremium, setNetPremium] = useState(0);
  const [showDashboard, setShowDashboard] = useState(false);
  const [NetPremiumApproved, setNetPremiumApproved] = useState(0);
  const [TableData, setTableData] = useState([]);
  const [sortBy, setSortBy] = useState("count");
  const [sortOrder, setSortOrder] = useState("asc");
  const [DateStart, setDateStart] = useState("");
  const [DateEnd, setDateEnd] = useState("");
  const [CountOfPolicy, setCountOfPolicy] = useState(0);
  const [CountOfPolicyApproved, setCountOfPolicyApproved] = useState(0);
  const [Inflow, setInflow] = useState(0);
  const [Outflow, setOutflow] = useState(0);
  const [InflowApproved, setInflowApproved] = useState(0);
  const [OutflowApproved, setOutflowApproved] = useState(0);
  const [xAxisData, setXAxisData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [xAxisDataPolicyType, setXAxisDataPolicyType] = useState([]);
  const [seriesDataPolicyType, setSeriesDataPolicyType] = useState([]);
  const [dataForLOB, setDataForLOB] = useState({
    NOP: [],
    GWP: [],
  });
  const [dataForPolicyType, setDataForPolicyType] = useState({
    NOP: [],
    GWP: [],
  });
  const [product, setProduct] = useState({
    motor: [],
    nonMotor: [],
  });
  //const [subProduct, setSubProduct] = useState();
  const [dataForEmployeesPolicy, setDataForEmployeesPolicy] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((store) => store.user);

  const userPermission = user?.Permission?.Roll || "";
  const userBranchCode = user?.Branch?.BranchCode;
  const userBranchId = user?.Branch?._id;

  const formatNumberCrore = (num) => {
    return (num / 10000000).toFixed(2) ?? 0;
  };

  const formatNumberThousand = (num) => {
    return (num / 1000).toFixed(2) ?? 0;
  };

  useEffect(() => {
    if (userPermission === "Administrator" || userPermission === "HOS") {
      setShowDashboard(true);
    }
  }, [userPermission]);

  const getPolicyTypeCount = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/groupByPolicyType`, {
        params: { branchId: branchIdParam },
      });

      if (data) {
        const NOP = [];
        const GWP = [];
        data.forEach((item) => {
          GWP.push({
            value: Math.floor(item.GWP) ?? 0,
            name: `${item.policyTypeName} (${item.NOP ?? 0})`,
          });
        });
        setDataForPolicyType({ NOP, GWP });
      }
    } catch (error) {
      ToastError(error?.response?.data?.message || error.message);
    }
  };

  const getDataForEmployeesPolicy = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;
      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/groupByEnteredBy`, {
        params: {
          branchId: branchIdParam,
        },
      });
      setDataForEmployeesPolicy(data);
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedData = [...TableData].sort((a, b) => {
    let comparison = 0;

    if (sortBy === "count") {
      comparison = a.count - b.count;
    } else if (sortBy === "nwp") {
      comparison = a.totalNetPremium - b.totalNetPremium;
    }

    return sortOrder === "asc" ? comparison : -comparison;
  });

  const formattedData = [
    [
      "Element",
      "No of Policies",
      "Total Gross Premium",
      { role: "style" },
      { role: "tooltip", type: "string", p: { html: true } },
    ], // Added custom tooltip column
  ];

  dataForEmployeesPolicy.forEach((item, index) => {
    const tooltip = `
    Name - ${item.employeeName}
    Count -  ${formatNumberThousand(item.count)} 
    Total Gross Premium - ${formatNumberCrore(item.totalGrossPremium)}
    `;

    formattedData.push([
      item.employeeName,
      item.count,
      item.totalGrossPremium,
      colors[index % colors.length],
      tooltip,
    ]);
  });

  const groupByProduct = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const [motorResp, nonMotorResp] = await Promise.all([
        axiosInstance.post("/Policy/groupByProduct", {
          LOB: "Motor",
          branchId: branchIdParam,
        }),
        axiosInstance.post("/Policy/groupByProduct", {
          LOB: "Non-Motor",
          branchId: branchIdParam,
        }),
      ]);

      if (motorResp || nonMotorResp) {
        const formattedData = [];
        motorResp.data.data.forEach((item) => {
          formattedData.push({
            value: Math.floor(item.GWP) ?? 0,
            count: item.NOP ?? 0,
            name: `${item.Products}`,
          });
        });

        const formatedNonMotorData = [];
        nonMotorResp.data.data.forEach((item) => {
          formatedNonMotorData.push({
            value: Math.floor(item.GWP) ?? 0,
            count: item.NOP ?? 0,
            name: `${item.Products}`,
          });
        });

        setProduct({
          motor: formattedData,
          nonMotor: formatedNonMotorData,
        });
      }
    } catch (error) {
      ToastError(error?.response?.data?.message || error.message);
    }
  };

  // useEffect(() => {
  //   if (subProduct.GWP && subProduct.GWP.length > 0) {
  //     setOpenPopup(true);
  //   }
  // }, [subProduct]);

  // useEffect(() => {
  //   if (openPopup) {
  //     const timeout = setTimeout(() => {
  //       setRenderKey((prevKey) => prevKey + 1);
  //     }, 500);

  //     return () => clearTimeout(timeout);
  //   }
  // }, [openPopup]);

  useEffect(() => {
    Promise.all([
      getPremium(),
      FetchDate(),
      getPremiumApproved(),
      getNetPremiumByStatus(),
      getInflow(),
      getInflowApproved(),
      getBranches(),
      getOutflow(),
      getOutflowApproved(),
      getInsuranceTypes(),
      getInsurancecompanies(),
      getPolicyTypes(),
      getStatus(),
      getStatusApproved(),
      getDataForEmployeesPolicy(),
      getLobCount(),
      getPolicyTypeCount(),
      getBusinessTypeCount(),
      groupByProduct(),
    ]);
  }, []);

  // new controllers
  const getInsuranceTypes = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(
        `/Policy/getPolicyReportChartInsuranceType?dbName=insurancetypes&localFieldName=InsuranceType&asToSave=InsuranceType&groupUpKey=InsuranceType.InsuranceType`
      );
      setInsuranceTypes([["", ""], ...data]);
    } catch (error) {
      ToastError(error?.response?.data?.message);
    }
  };

  const getPremium = async () => {
    try {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endDate = new Date();

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const {
        data: { Premium },
      } = await axiosInstance.post("/Policy/getPolicyPremiumChart", {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        TabsValue: "ALL",
        filterOn: "IssueDate",
      });
      const formattedNetPremium = formatNumberCrore(
        Premium[0]?.NetPremium ?? 0
      );
      setNetPremium(formattedNetPremium);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getPremiumApproved = async () => {
    try {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endDate = new Date();

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const {
        data: { Premium },
      } = await axiosInstance.post("/Policy/getPolicyPremiumChart", {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        TabsValue: "APPROVED",
        filterOn: "IssueDate",
      });
      const formattedNetPremium = formatNumberCrore(
        Premium[0]?.NetPremium ?? 0
      );
      setNetPremiumApproved(formattedNetPremium);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getStatus = async () => {
    try {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endDate = new Date();

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const {
        data: { StatusCount },
      } = await axiosInstance.post("/Policy/getPolicyStatusChart", {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        TabsValue: "ALL",
        filterOn: "IssueDate",
      });
      const formattedCount = formatIndianNumber(StatusCount?.ALL);

      setCountOfPolicy(formattedCount);
    } catch (error) {
      console.error("Error fetching policy status:", error);
    }
  };

  const getStatusApproved = async () => {
    try {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endDate = new Date();

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const {
        data: { StatusCount },
      } = await axiosInstance.post("/Policy/getPolicyStatusChart", {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        TabsValue: "APPROVED",
        filterOn: "IssueDate",
      });
      const formattedCount = formatIndianNumber(StatusCount?.ALL);

      setCountOfPolicyApproved(formattedCount);
    } catch (error) {
      console.error("Error fetching policy status:", error);
    }
  };

  const getInflow = async () => {
    try {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endDate = new Date();

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const {
        data: { Premium },
      } = await axiosInstance.post("/Policy/getPolicyInflow", {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        TabsValue: "ALL",
        filterOn: "IssueDate",
      });
      const formattedInflow = formatNumberCrore(Premium[0]?.Inflow);
      if (userPermission === "Administrator") {
        setInflow(formattedInflow);
      } else {
        setInflow("-");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getOutflow = async () => {
    try {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endDate = new Date();

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const {
        data: { Premium },
      } = await axiosInstance.post("/Policy/getPolicyOutflow", {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        TabsValue: "ALL",
        filterOn: "IssueDate",
      });
      const formattedOutflow = formatNumberCrore(Premium[0]?.Outflow);
      if (userPermission === "Administrator") {
        setOutflow(formattedOutflow);
      } else {
        setOutflow("-");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getInflowApproved = async () => {
    try {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endDate = new Date();

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const {
        data: { Premium },
      } = await axiosInstance.post("/Policy/getPolicyInflow", {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        TabsValue: "APPROVED",
        filterOn: "IssueDate",
      });
      const formattedInflow = formatNumberCrore(Premium[0]?.Inflow);
      if (userPermission === "Administrator") {
        setInflowApproved(formattedInflow);
      } else {
        setInflowApproved("-");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getOutflowApproved = async () => {
    try {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const {
        data: { Premium },
      } = await axiosInstance.post("/Policy/getPolicyOutflow", {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        TabsValue: "APPROVED",
        filterOn: "IssueDate",
      });
      const formattedOutflow = formatNumberCrore(Premium[0]?.Outflow);
      if (userPermission === "Administrator") {
        setOutflowApproved(formattedOutflow);
      } else {
        setOutflowApproved("-");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getInsurancecompanies = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/getPolicyReportChart`, {
        params: {
          dbName: "insurancecompanies",
          localFieldName: "InsuranceCompany",
          asToSave: "InsuranceCompany",
          groupUpKey: "InsuranceCompany.AliasName",
          branchId: branchIdParam,
        },
      });

      const formattedData = data.map(([name, count, gwp]) => ({
        name,
        count,
        gwp,
      }));

      const gwpDataInsurance = formattedData.map((item) => ({
        name: `${item.name} (${formatNumberCrore(item.gwp ?? 0)})`,
        value: parseFloat(item.gwp),
      }));

      const xAxis = formattedData.map((item) => item.name);
      const seriesCount = formattedData.map((item) => item.count);
      const seriesGWP = formattedData.map((item) => item.gwp);

      setInsuranceCompanies(formattedData);
      setXAxisData(xAxis);
      setSeriesData([seriesCount, seriesGWP]); // Store both count and GWP in seriesData
    } catch (error) {
      ToastError(
        error?.response?.data?.message || "Failed to fetch insurance companies"
      );
    }
  };

  const getBranches = async () => {
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/getPolicyBranchesNETPremium`, {
        params: {
          dbName: "branches",
          localFieldName: "Branch",
          asToSave: "Branch",
          groupUpKey: "Branch.BranchName",
        },
      });

      const formattedData = data.map(([name, count, gwp]) => ({
        name,
        count,
        gwp,
      }));

      const countData = formattedData.map((item) => ({
        value: item.count,
        name: `${item.name} (${formatIndianNumber(item.count)})`,
      }));

      const gwpData = formattedData.map((item) => ({
        value: parseFloat(item.gwp),
        name: `${item.name} (${formatNumberCrore(item.gwp)})`,
      }));

      setBranchCountData(countData);
      setBranchNWPData(gwpData);
    } catch (error) {
      ToastError(
        error?.response?.data?.message || "Failed to fetch insurance companies"
      );
    }
  };

  const getPolicyTypes = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/getNetPremiumAndCountByPolicyType`, {
        params: {
          dbName: "policytypes",
          localFieldName: "PolicyType",
          asToSave: "PolicyType",
          groupUpKey: "PolicyType.PolicyTypeName",
          branchId: branchIdParam,
        },
      });

      const formattedData = data.map(([name, count, gwp]) => ({
        name,
        count,
        gwp,
      }));

      const gwpDataPolicyType = formattedData.map((item) => ({
        name: `${item.name} (${formatNumberCrore(item.gwp ?? 0)})`,
        value: parseFloat(item.gwp),
      }));

      const countDataPolicyType = formattedData.map((item) => ({
        name: `${item.name} (${formatIndianNumber(item.count ?? 0)})`,
        value: item.count,
      }));

      setPolicyTypes(formattedData);
      setXAxisDataPolicyType(gwpDataPolicyType);
      setSeriesDataPolicyType(countDataPolicyType);
    } catch (error) {
      ToastError(
        error?.response?.data?.message || "Failed to fetch policy types"
      );
    }
  };
  const getNetPremiumByStatus = async () => {
    try {
      const currentDate = new Date();
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endDate = new Date();

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const {
        data: { data },
      } = await axiosInstance.post("/Policy/getNetPremiumByStatus", {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
      setTableData(data);

      //  setOutflow(Premium[0]?.Outflow);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getLobCount = async () => {
    setLoading(true);
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/groupByLOBChart`, {
        params: {
          branchId: branchIdParam,
        },
      });
      if (data) {
        const NOP = [];
        const GWP = [];
        data.forEach((item) => {
          NOP.push({
            value: item.NOP ?? 0,
            name: `${item._id} (${formatIndianNumber(item.NOP ?? 0)})`,
          });
          GWP.push({
            value: Math.floor(item.GWP) ?? 0,
            name: `${item._id} (${formatNumberCrore(item.GWP ?? 0)})`,
          });
        });
        setDataForLOB({ NOP, GWP });
      }
    } catch (error) {
      ToastError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const getBusinessTypeCount = async () => {
    try {
      const branchIdParam =
        userBranchCode !== "0" && userBranchId ? userBranchId : undefined;

      const {
        data: { data },
      } = await axiosInstance.get(`/Policy/groupByBusinessTypeChart`, {
        params: { branchId: branchIdParam },
      });

      if (data) {
        const countData = [];
        const gwpData = [];

        data.forEach((item) => {
          countData.push({
            value: item.NOP ?? 0,
            name: `${item._id} (${formatIndianNumber(item.NOP ?? 0)})`,
          });

          gwpData.push({
            value: parseFloat(item.GWP ?? 0),
            name: `${item._id} (${formatNumberCrore(item.GWP ?? 0)})`,
          });
        });

        setBusinessTypeCountData(countData);
        setBusinessTypeNWPData(gwpData);
      }
    } catch (error) {
      ToastError(error?.response?.data?.message || error.message);
    }
  };

  const cardsAll = [
    {
      id: 1,
      title: `${NetPremium}`,
      description: "NWP (Crore)",
    },
    {
      id: 2,
      title: `${CountOfPolicy}`,
      description: "NOP ",
    },
    // {
    //   id: 3,
    //   title: `${Inflow}`,
    //   description: 'Total Inflow',
    // },
    // {
    //   id: 4,
    //   title: `${Outflow}`,
    //   description: 'Total Outflow',
    // },
  ];

  const cardsApproved = [
    {
      id: 1,
      title: `${NetPremiumApproved}`,
      description: "NWP (Crore)",
    },
    {
      id: 2,
      title: `${CountOfPolicyApproved}`,
      description: "NOP ",
    },
    {
      id: 3,
      title: `${InflowApproved}`,
      description: "Inflow (Crore)",
    },
    {
      id: 4,
      title: `${OutflowApproved}`,
      description: "Outflow (Crore)",
    },
  ];

  const formatIndianNumber = (num) => {
    return new Intl.NumberFormat("en-IN").format(Math.round(num));
  };

  const countDataInsurance = InsuranceCompanies.map((item) => ({
    name: `${item.name} (${formatIndianNumber(item.count ?? 0)})`,
    value: item.count,
  }));

  const gwpDataInsurance = InsuranceCompanies.map((item) => ({
    name: `${item.name} (${formatNumberCrore(item.gwp ?? 0)})`,
    value: parseFloat(item.gwp),
  }));

  const nwpDataProductMotor = product.motor.map((item) => ({
    name: `${item.name} (${formatNumberCrore(item.value ?? 0)})`,
    value: parseFloat(item.value),
  }));

  const nwpDataProductNonMotor = product.nonMotor.map((item) => ({
    name: `${item.name} (${formatNumberCrore(item.value ?? 0)})`,
    value: parseFloat(item.value),
  }));

  const countDataProductMotor = product.motor.map((item) => ({
    name: `${item.name} (${formatIndianNumber(item.count ?? 0)})`,
    value: parseFloat(item.count),
  }));

  const countDataProductNonMotor = product.nonMotor.map((item) => ({
    name: `${item.name} (${formatIndianNumber(item.count ?? 0)})`,
    value: parseFloat(item.count),
  }));

  const xAxisDataInsuraceTypeMotor = InsuranceTypes.filter(
    (arr) => arr.length > 0 && arr[arr.length - 1] === "Motor"
  ) // Filter arrays with "Motor" as the last value
    .map((arr) => arr[0]);

  const xAxisDataInsuraceTypeNonMotor = InsuranceTypes.filter(
    (arr) => arr.length > 0 && arr[arr.length - 1] === "Non-Motor"
  ) // Filter arrays with "Motor" as the last value
    .map((arr) => arr[0]);

  const seriesDataInsuraceTypeMotor = InsuranceTypes.reduce(
    (acc, arr) => {
      // Check if the last element is "Motor"
      if (arr[arr.length - 1] === "Motor") {
        if (arr.length > 1) acc[0].push(arr[1]); // Push value at index 1
        if (arr.length > 2) acc[1].push(arr[2]); // Push value at index 2
      }
      return acc;
    },
    [[], []] // Initialize with two empty arrays
  );

  const seriesDataInsuraceTypeNonMotor = InsuranceTypes.reduce(
    (acc, arr) => {
      // Check if the last element is "Motor"
      if (arr[arr.length - 1] === "Non-Motor") {
        if (arr.length > 1) acc[0].push(arr[1]); // Push value at index 1
        if (arr.length > 2) acc[1].push(arr[2]); // Push value at index 2
      }
      return acc;
    },
    [[], []] // Initialize with two empty arrays
  );

  const FetchDate = async () => {
    const currentDate = new Date();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endDate = new Date();

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${day}-${month}-${year}`;
    };
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    setDateStart(formattedStartDate);
    setDateEnd(formattedEndDate);
  };
  return (
    <>
      <div className="dashboard-container">
        <Header />
        {showDashboard === true ? (
          <div className="row" style={{ marginTop: "8vh", padding: "10px" }}>
            {/* cardsAll Section */}
            <div className="col-lg-4" style={{ float: "left" }}>
              <div style={{ float: "left", minWidth: "100%" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#333333",
                    marginBottom: "15px",
                    textAlign: "center",
                  }}
                >
                  MTD {DateStart} - {DateEnd}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                  }}
                >
                  {cardsAll.map((card, index) => (
                    <Card key={index}>
                      <CardActionArea
                        sx={{
                          height: "100%",
                          backgroundColor: "#faa66e",
                        }}
                      >
                        <CardContent
                          sx={{
                            height: "100%",
                            padding: "8px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              lineHeight: 1.2,
                            }}
                          >
                            {card.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              fontSize: "14px",
                              lineHeight: 1.2,
                            }}
                          >
                            {card.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  ))}
                </Box>
              </div>
              <div
                style={{
                  float: "left",
                  minWidth: "100%",
                  marginTop: "20px",
                  marginBottom: "30px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#333333",
                    marginBottom: "5px",
                    textAlign: "center",
                  }}
                >
                  Approved Policies
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                  }}
                >
                  {cardsApproved.map((card, index) => (
                    <Card key={index}>
                      <CardActionArea
                        sx={{
                          height: "100%",
                          backgroundColor: "#faa66e",
                        }}
                      >
                        <CardContent
                          sx={{
                            height: "100%",
                            padding: "8px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              lineHeight: 1.2,
                            }}
                          >
                            {card.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              fontSize: "14px",
                              lineHeight: 1.2,
                            }}
                          >
                            {card.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  ))}
                </Box>
              </div>

              <TableContainer
                component={Paper}
                style={{ marginTop: "40px", width: "100%", minHeight: "200px" }}
              >
                <Table aria-label="status table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          cursor: "pointer",
                          backgroundColor: "#003366",
                          color: "#ffffff",
                        }}
                        onClick={() => handleSort("count")}
                      >
                        Count{" "}
                        {sortBy === "count" &&
                          (sortOrder === "asc" ? (
                            <ArrowUpwardIcon fontSize="small" />
                          ) : (
                            <ArrowDownwardIcon fontSize="small" />
                          ))}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#003366",
                          color: "#ffffff",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          cursor: "pointer",
                          backgroundColor: "#003366",
                          color: "#ffffff",
                        }}
                        onClick={() => handleSort("nwp")}
                      >
                        NWP {"(Crore) "}
                        {sortBy === "nwp" &&
                          (sortOrder === "asc" ? (
                            <ArrowUpwardIcon fontSize="small" />
                          ) : (
                            <ArrowDownwardIcon fontSize="small" />
                          ))}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            index % 2 === 0 ? "#003366" : "#6699cc", // Alternate row colors
                          "& td": {
                            color: "#ffffff", // Set text color to white for all cells in the row
                          },
                        }}
                      >
                        <TableCell align="center">{row.count}</TableCell>
                        <TableCell align="center">{row.status}</TableCell>
                        <TableCell align="center">
                          {formatNumberCrore(row.totalNetPremium)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* policy type chart */}

              <div
                className="col-lg-12"
                style={{
                  marginTop: "10px",
                  float: "left",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <PieChart
                  height="245px"
                  title="GWP"
                  data={xAxisDataPolicyType}
                  xAxisLabel="PolicyType NWP (Crore)"
                />
              </div>

              <div
                className="col-lg-12"
                style={{
                  marginTop: "10px",
                  float: "left",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <PieChart
                  height="255px"
                  title="Policy Count"
                  data={seriesDataPolicyType}
                  xAxisLabel="PolicyType NOP "
                />
              </div>
            </div>

            <div className="col-lg-8" style={{ float: "left" }}>
              {/* insurance companies pire charts */}
              <div
                className="col-lg-6"
                style={{
                  marginTop: "10px",
                  float: "left",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  position: "relative",
                }}
              >
                <PieChart
                  height="295px"
                  title="GWP"
                  data={gwpDataInsurance}
                  xAxisLabel="Insurer NWP (Crore)"
                />

                {/* Typography inside the pie chart */}
                <Typography
                  variant="h6"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "43%",
                    transform: "translate(-50%, -50%)",
                    fontWeight: "bold",
                    color: "#000",
                    fontSize: "16px",
                  }}
                >
                  {`${NetPremium}`}
                </Typography>
              </div>

              <div
                className="col-lg-6"
                style={{
                  marginTop: "10px",
                  float: "left",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  position: "relative",
                }}
              >
                <PieChart
                  height="295px"
                  title="Policy Count"
                  data={countDataInsurance}
                  xAxisLabel="Insurer NOP"
                />

                {/* Typography inside the Pie Chart */}
                <Typography
                  variant="h6"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "43%",
                    transform: "translate(-50%, -50%)",
                    fontWeight: "bold",
                    color: "#000",
                    fontSize: "16px",
                  }}
                >
                  {`${CountOfPolicy}`}
                </Typography>
              </div>

              {/* Chart for NWP */}
              <div
                className="col-lg-6"
                style={{
                  float: "left",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                  position: "relative",
                }}
              >
                {/* NWP Pie Chart */}
                <PieChart
                  height="270px"
                  title="GWP Distribution"
                  data={BranchNWPData}
                  xAxisLabel="Branch NWP (Crore)"
                />
                <Typography
                  variant="h6"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "43%",
                    transform: "translate(-50%, -50%)",
                    fontWeight: "bold",
                    color: "#000",
                    fontSize: "16px",
                  }}
                >
                  {`${NetPremium}`}
                </Typography>
              </div>

              {/* Chart for Count */}
              <div
                className="col-lg-6"
                style={{
                  float: "left",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  position: "relative",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <PieChart
                  height="270px"
                  title="Policy Count"
                  data={BranchCountData}
                  xAxisLabel="Branch NOP "
                />
                <Typography
                  variant="h6"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "43%",
                    transform: "translate(-50%, -50%)",
                    fontWeight: "bold",
                    color: "#000",
                    fontSize: "16px",
                  }}
                >
                  {`${CountOfPolicy}`}
                </Typography>
              </div>
              {/*LOB Charts*/}

              {/* Horizontal Bar Chart for GWP */}
              <div
                className="col-lg-6"
                style={{
                  float: "left",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                {dataForLOB.GWP.length === 0 ? (
                  <Skeleton variant="rectangular" width="100%" height="150px" />
                ) : (
                  <PieChart
                    height="150px"
                    title="Sum of NET Premium"
                    data={dataForLOB.GWP}
                    xAxisLabel="LOB  NWP (Crore)"
                    // yAxisLabel="LOB"
                  />
                )}
              </div>

              {/* Horizontal Bar Chart for NOP */}
              <div
                className="col-lg-6"
                style={{
                  float: "left",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                {dataForLOB.NOP.length === 0 ? (
                  <Skeleton variant="rectangular" width="100%" height="150px" />
                ) : (
                  <PieChart
                    height="150px"
                    title="Count of Policies"
                    data={dataForLOB.NOP}
                    xAxisLabel="LOB  NOP "
                    // yAxisLabel="LOB"
                  />
                )}
              </div>

              {/*Business type Charts*/}

              {/* Chart for NWP */}
              <div
                className="col-lg-6"
                style={{
                  float: "left",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                {/* NWP Pie Chart */}
                <PieChart
                  height="200px"
                  title="NWP Distribution"
                  data={BusinessTypeNWPData}
                  xAxisLabel="BusinessType NWP (Crore)"
                />
              </div>

              {/* Chart for Count */}
              <div
                className="col-lg-6"
                style={{
                  float: "left",
                  marginTop: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <PieChart
                  height="200px"
                  title="Policy Count"
                  data={BusinessTypeCountData}
                  xAxisLabel="BusinessType NOP "
                />
              </div>

              {/* Product  pie chart */}
              {/* Motor */}

              <div
                className="col-lg-6"
                style={{
                  marginTop: "10px",
                  float: "left",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <PieChart
                  height="200px"
                  title="GWP"
                  data={nwpDataProductMotor}
                  xAxisLabel="Motor Product  NWP (Crore)"
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  marginTop: "10px",
                  float: "left",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <PieChart
                  height="200px"
                  title="Policy Count"
                  data={countDataProductMotor}
                  xAxisLabel="Motor Product  NOP "
                />
              </div>

              {/*Non Motor */}

              <div
                className="col-lg-6"
                style={{
                  marginTop: "10px",
                  float: "left",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <PieChart
                  height="200px"
                  title="GWP"
                  data={nwpDataProductNonMotor}
                  xAxisLabel="Non Motor Product  NWP (Crore)"
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  marginTop: "10px",
                  float: "left",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <PieChart
                  height="200px"
                  title="Policy Count"
                  data={countDataProductNonMotor}
                  xAxisLabel="Non Motor Product  NOP "
                />
              </div>
            </div>

            <div
              className="col-lg-12"
              style={{
                marginTop: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
                backgroundColor: "#ffffff",
                transition: "box-shadow 0.3s ease",
                "&:hover": {
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              {InsuranceCompanies.length === 0 ? (
                <Skeleton variant="rectangular" width="100%" height="300px" />
              ) : (
                <BarChartC
                  height="300px"
                  title="Policy Count"
                  xAxisData={xAxisData}
                  seriesData={[seriesData[1]]}
                  xAxisLabel={"Insurer Comapny NWP (Crore)"}
                />
              )}
              <br></br>
              {InsuranceCompanies.length === 0 ? (
                <Skeleton variant="rectangular" width="100%" height="300px" />
              ) : (
                <BarChartT
                  height="300px"
                  title="NET Premium"
                  xAxisData={xAxisData}
                  seriesData={[seriesData[0]]}
                  xAxisLabel={"Insurer Comapny NOP"}
                />
              )}
            </div>

            {/* insurance company nwp and nop comnined */}
            <div>
              <div
                style={{
                  width: "100%",
                  border: "none",
                  marginTop: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                {xAxisData.length === 0 ? (
                  <Skeleton variant="rectangular" width="100%" height="330px" />
                ) : (
                  <BarLineChart
                    height="330px"
                    xAxisData={xAxisData}
                    seriesData={seriesData}
                    xAxisLabel="InsurerCompany  NWP and NOP"
                  />
                )}
              </div>
            </div>

            {/* InsuranceType nwp and nop comined */}
            {/* Motor */}
            <div>
              <div
                style={{
                  width: "100%",
                  border: "none",
                  marginTop: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                {xAxisDataInsuraceTypeMotor.length === 0 ? (
                  <Skeleton variant="rectangular" width="100%" height="400px" />
                ) : (
                  <BarLineChart
                    height="400px"
                    xAxisData={xAxisDataInsuraceTypeMotor}
                    seriesData={seriesDataInsuraceTypeMotor}
                    xAxisLabel="Product Motor  NWP and NOP"
                  />
                )}
              </div>
            </div>

            {/* Non-Motor */}
            <div>
              <div
                style={{
                  width: "100%",
                  border: "none",
                  marginTop: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                {xAxisDataInsuraceTypeNonMotor.length === 0 ? (
                  <Skeleton variant="rectangular" width="100%" height="600px" />
                ) : (
                  <BarLineChart
                    height="500px"
                    xAxisData={xAxisDataInsuraceTypeNonMotor}
                    seriesData={seriesDataInsuraceTypeNonMotor}
                    xAxisLabel="Product Non-Motor  NWP and NOP"
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2vh",
              padding: "10px",
              height: "100vh",
              paddingLeft: "43%",
            }}
          >
            <h1>Dashboard</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
