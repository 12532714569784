//  policy and QC filter fields of url
const fieldMapping = {
  f1: "page",
  f2: "perPage",
  f3: "issueFrom",
  f4: "issueTo",
  f5: "createdAtFrom",
  f6: "createdAtTo",
  f7: "filterOn",
  f8: "insCompanyId",
  f9: "insCompanyName",
  f10: "branchId",
  f11: "branchName",
  f12: "posId",
  f13: "posName",
  f14: "product",
};

export const decodeQueryParams = (queryString) => {
  const params = new URLSearchParams(queryString);
  const decodedParams = {};

  for (const [key, value] of params.entries()) {
    const originalKey = fieldMapping[key] || key;
    decodedParams[originalKey] = value;
  }

  return decodedParams;
};

export const updateQueryParams = (currentUrl, newParams) => {
  const url = new URL(currentUrl);
  Object.entries(newParams).forEach(([key, value]) => {
    if (value) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }
  });
  return `${url.pathname}${url.search}`;
};
