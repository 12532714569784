import ReactEcharts from "echarts-for-react";
import { Card, CardContent, Divider, Typography } from "@mui/material";

export default function BarChartT({
  height,
  xAxisData,
  seriesData,
  xAxisLabel,
  yAxisLabel,
}) {
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return `${(num / 10000000).toFixed(2)} Cr`;
    } else if (num >= 100000) {
      return `${(num / 100000).toFixed(2)} L`;
    } else if (num >= 1000) {
      return `${(num / 1000).toFixed(2)} K`;
    } else {
      return num.toString();
    }
  };


const formatNumberThousand = (num) => {
    return (num / 1000).toFixed(4)?? 0;
  };

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: (params) => {
        let tooltipContent = "";
        params.forEach((param) => {
          const formattedValue = param.value;
          tooltipContent += `
            <div>
              <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span>
              ${param.name}: ${formattedValue}
            </div>
          `;
        });
        return tooltipContent;
      },
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "0%",
      top: "9%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      axisLabel: {
        rotate: 45,
        fontSize: 10,
      },
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
      splitNumber: 6,
      axisLabel: {
        formatter: (value) => value,
      },
    },
    series: [
      {
        type: "bar",
        data: seriesData[0], // Bar chart data
        itemStyle: {
          color: "#0231bf", // Bar color
        },
        label: {
          show: true, // Enable labels on bars
          position: "top", // Position the label on top of the bar
          formatter: (params) => params.value, // Format the label value
          fontSize: 10, // Font size for the label
          fontWeight: "bold", // Make the label bold
          color: "#000", // Label text color
        },
      },
    ],
  };

  return (
    <Card>
      <Divider sx={{ backgroundColor: "black" }} />
      <CardContent sx={{ paddingTop: 0 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: `${height}px`,
            marginLeft: "-30px",
            position: "absolute",
            zIndex: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              transform: "rotate(-90deg)",
              whiteSpace: "nowrap",
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "18vh",
            }}
          >
            {yAxisLabel}
          </Typography>
        </div>

        <div
          style={{
            width: "100%",
            overflowX: "auto",
            overflowY: "hidden",
            position: "relative",
            paddingLeft: "10px",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
          className="scroll-container"
        >
          <ReactEcharts
            style={{
              width: "100%",
              height: height,
            }}
            option={option}
          />
        </div>

        <Typography
          variant="subtitle2"
          style={{
            textAlign: "center",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {xAxisLabel}
        </Typography>
      </CardContent>
    </Card>
  );
}