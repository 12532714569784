export const policyPayingTermOpt = [
  { label: "Short term", value: "Short term" },
  { label: "Annually", value: "Annually" },
  { label: "2 years", value: "2 Years" },
  { label: "3 years", value: "3 Years" },
  { label: "5 Years", value: "5 Years" },
  { label: "10 Years", value: "10 Years" },
];

export const Risk_TypeData = [
  { value: "Preferred", label: "Preferred" },
  { value: "Non-Preferred", label: "Non-Preferred" },

];

export const Business_TypeData = [
  { value: "NEW", label: "New" },
  { value: "USED", label: "Used" },
  { value: "Roll Over", label: "Roll Over" },
  { value: "Renewal", label: "Renewal" },
];

export const NCB_STATUS = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const PA_TYPE = [
  { value: "Without PA", label: "Without PA" },
  { value: "With PA", label: "With PA" },
];

export const IMT_TYPE = [
  { value: "IMT 23", label: "IMT 23" },
  { value: "IMT 34", label: "IMT 34" },
  { value: "Not Available", label: "Not Available" },
];

// --------------- to validate excel data ---------------

export const PolicyPayingTerm = {
  "Short term": "Short term",
  Annually: "Annually",
  "2 Years": "2 Years",
  "3 Years": "3 Years",
  "5 Years": "5 Years",
  "10 Years": "10 Years",
  
};

export const BusinessTypeData = {
  NEW: "NEW",
  USED: "USED",
  "Roll Over": "Roll Over",
  Renewal: "Renewal",
};

export const RiskTypeData = {
  Preferred : "Preferred" ,
  "Non-Preferred" : "Non-Preferred",

};

export const NCBSTATUS = {
  Yes: "Yes",
  No: "No",
};

export const CustomerType = {
  Individual: "Individual",
  Corporate: "Corporate",
};

export const NillDepth = {
  Yes: "Yes",
  No: "No",
};

export const PA_Type = {
  "Without PA": "Without PA",
  "With PA": "With PA",
};

export const IMT_Type = {
  "IMT 23": "IMT 23",
  "IMT 34": "IMT 34",
  "Not Available": "Not Available",
};

export const BasedOn = {
  NET: "NET",
  OD: "OD",
  TP: "TP",
  "OD+TP": "OD+TP",
  "OD+TP (Exc. PA)": "OD+TP (Exc. PA)",
  FLAT: "FLAT",
};

export const BasedOnarr = [
  "NET",
  "OD",
  "TP",
  "OD+TP",
  "OD+TP (Exc. PA)",
  "FLAT",
];
